import { getDaysInMonth } from './getDaysInMonth';

export type RangeFormat = 'today' | 'yesterday' | 'week' | 'lastWeek' | 'month' | 'lastMonth' | 'last30Days';

export const RangeOptions = ['today', 'yesterday', 'week', 'lastWeek', 'month', 'lastMonth', 'last30Days'];

export const rangeFormat = (range: RangeFormat) => {
  const start = new Date();
  const todayWeekDay = new Date().getDay();

  let newDate = new Date();
  let end = new Date();

  if (range === 'today') {
    return {
      start,
      end,
      range,
    };
  }

  if (range === 'yesterday') {
    newDate = new Date(new Date().setDate(start.getDate() - 1));

    return {
      start: newDate,
      end: newDate,
      range,
    };
  }

  if (range === 'week') {
    newDate = new Date(new Date().setDate(start.getDate() - todayWeekDay));

    return {
      start: newDate,
      end: start,
      range,
    };
  }

  if (range === 'lastWeek') {
    newDate = new Date(new Date().setDate(start.getDate() - todayWeekDay - 7));
    end = new Date(new Date().setDate(start.getDate() - todayWeekDay - 1));

    return {
      start: newDate,
      end,
      range,
    };
  }

  if (range === 'month') {
    newDate = new Date(new Date().setDate(start.getDate() - start.getDate() + 1));

    return {
      start: newDate,
      end: start,
      range,
    };
  }

  if (range === 'lastMonth') {
    const { daysInCurrentMonth } = getDaysInMonth(start.getMonth() - 1, start.getFullYear());
    newDate = new Date(new Date().setMonth(start.getMonth() - 1));
    newDate = new Date(new Date(newDate).setDate(1));

    end = new Date(new Date().setMonth(start.getMonth() - 1));
    end = new Date(new Date(end).setDate(daysInCurrentMonth));

    return {
      start: newDate,
      end,
      range,
    };
  }

  if (range === 'last30Days') {
    newDate = new Date(new Date().setDate(start.getDate() - 30));

    return {
      start: newDate,
      end: start,
      range,
    };
  }

  return {
    start,
    end,
    range,
  };
};
