import { CommissionFormatted } from '../../../../utils/formatCommissions';

const isInRange = (dateString1: string, dateString2: string, dateString3: string) => {
  const date = new Date(dateString1);
  const startDate = new Date(dateString2);
  const endDate = new Date(dateString3);
  return date >= startDate && date <= endDate;
};

const isAfterOrEqual = (dateString1: string, dateString2: string) => {
  const date1Format = new Date(dateString1);
  const date2Format = new Date(dateString2);
  return date1Format >= date2Format;
};

/** From Fintel-App. For Removing default commissions if a publisher has an overlapping custom commission */
export const commissionFilter = (commissions: CommissionFormatted[], publisherId: string) => {
  const publisherGroupIds = commissions.filter((com) => (com.publisherGroups ? com.publisherGroups.length > 0 : false)).map((com) => (com.publisherGroups ? com.publisherGroups[0].id as string : '0'));

  let filteredCommissions: CommissionFormatted[] = [];
  const customCommissions: CommissionFormatted[] = [];
  const defaultCommissions: CommissionFormatted[] = [];
  commissions.forEach((commission) => {
    // for each commission
    if (
      commission.commissionTarget.publisherGroupTargets.length
      || commission.commissionTarget.publisherTargets.length
    ) {
      let isInTarget = false; // Check if the commission targets has that publisher
      commission.commissionTarget.publisherGroupTargets.forEach((publisherGroupTarget) => {
        if (publisherGroupIds.includes(publisherGroupTarget.publisherGroupId)) {
          isInTarget = true;
        }
      });
      commission.commissionTarget.publisherTargets.forEach((publisherTarget) => {
        if (publisherTarget.publisherId === publisherId) {
          isInTarget = true;
        }
      });
      if (isInTarget) {
        customCommissions.push(commission);
      }
    } else {
      defaultCommissions.push(commission);
    }
  });
  defaultCommissions.forEach((defaultCommission) => {
    let shouldNotFiltered = true;
    customCommissions.forEach((customCommission) => {
      if (
        customCommission.product.name === defaultCommission.product.name
        && customCommission.merchant.id === defaultCommission.merchant.id
      ) {
        if (defaultCommission.endDate && customCommission.endDate) {
          // both have endDate
          if (
            isInRange(
              defaultCommission.startDate || '',
              customCommission.startDate || '',
              customCommission.endDate || '',
            )
            && isInRange(
              defaultCommission.endDate || '',
              customCommission.startDate || '',
              customCommission.endDate || '',
            )
          ) {
            shouldNotFiltered = false;
          }
        } else if (
          (defaultCommission.endDate && !customCommission.endDate)
          || (!defaultCommission.endDate && !customCommission.endDate)
        ) {
          // only custom commission is endless or both are endless
          if (isAfterOrEqual(defaultCommission.startDate || '', customCommission.startDate || '')) {
            shouldNotFiltered = false;
          }
        }
      }
    });
    if (shouldNotFiltered) {
      filteredCommissions = [...filteredCommissions, defaultCommission];
    }
  });
  filteredCommissions = [...customCommissions, ...filteredCommissions];
  return filteredCommissions;
};
