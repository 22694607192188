import React from 'react';
import * as am5 from '@amcharts/amcharts5';
import ReactDOM from 'react-dom/client';
import { ApolloProvider } from '@apollo/client';
import jwtDecode from 'jwt-decode';

import Router from './routes';
import { apollo } from './services';
import environment from './config/environment';

import './styles/normalize.css';
import './styles/reset.css';
import './styles/fonts.css';
import './utils/chartsSetup';
import { Permission, PermissionType } from './entities';

am5.addLicense(environment.app.amChartGraphLicense);
am5.addLicense(environment.app.amChartMapLicense);

const storedPermissions = localStorage.getItem(environment.app.permissionStorageName);

if (storedPermissions !== null) {
  const permissionsDecoded = jwtDecode<{ permissions: PermissionType[] }>(storedPermissions);
  Permission.setPermissions(permissionsDecoded.permissions);
}

const root = ReactDOM.createRoot(
  document.getElementById('root') as HTMLElement,
);

root.render(
  <ApolloProvider client={apollo}>
    <script
      src="https://kit.fontawesome.com/2e2ed8649f.js"
      crossOrigin="anonymous"
    />
    <Router />
  </ApolloProvider>,
);
