import styled from 'styled-components';
import { colors, fonts } from '../../../../../../styles/theme';

export const ModalWrapperStyled = styled.div`
  color: ${colors.color1};
  display: flex;
  flex-direction: column;
  width: 640px;
  padding: 8px;
`;

export const TitleStyled = styled.h3`
  ${fonts.gotham.H24M};
  color: ${colors.color9};
  margin-bottom: 32px;
`;

export const DetailsTextStyled = styled.p`
  ${fonts.lato.H14};
  color: ${colors.color1};
  margin-bottom: 16px;
`;

export const WarningText = styled.span`
  ${fonts.gotham.H14M};
  color: ${colors.color14};
  margin-bottom: 20px;
`;

export const ButtonsWrapper = styled.div`
  display: flex;
  flex-direction: row-reverse;
  gap: 16px;
`;
