import styled from 'styled-components';
import {
  Button, Checkbox, DropZone, InputTextarea,
} from '../../../components';
import { colors, fonts } from '../../../styles/theme';
import { toRem } from '../../../utils';

type GridProps = {
  readonly columns?: number;
  readonly rows?: number;
  readonly rowGap?: number;
}

type FlexProps = {
  readonly column?: boolean;
  readonly justify?: 'start' | 'space-between' | 'center' | 'end';
  readonly align?: 'start' | 'center' | 'end';
  readonly marginTop?: number;
  readonly gap?: number
}

export const ButtonStyled = styled(Button)``;

export const ButtonsWrapperStyled = styled.div`
  display: flex;
  justify-content: space-between;

  ${ButtonStyled} {
    margin-left: 1rem;
  }
`;

export const DescriptionStyled = styled.p`
  margin-top: 0.5rem;
  ${fonts.gotham.H14M}
`;

export const GridStyled = styled.div<GridProps>`
  margin-top: 1.5rem;
  display: grid;
  grid-template-columns: repeat(${({ columns }) => columns}, 1fr);
  column-gap: 2%;
  row-gap: ${({ rowGap }) => (toRem(rowGap || 16))};
  width: 100%;
`;

export const TextareaStyled = styled(InputTextarea)`
  grid-column: 1 / 3;
`;

export const DropZoneStyled = styled(DropZone)`
  grid-column: 3;
  grid-row: 1 / 3;
`;

export const CheckboxStyled = styled(Checkbox)`
  margin-top: 15px;
`;

export const FlexStyled = styled.div<FlexProps>`
  display: flex;
  width: 100%;
  margin-top: ${({ marginTop }) => marginTop}rem;
  flex-direction: ${({ column }) => (column ? 'column' : 'row')};
  justify-content: ${({ justify }) => justify};
  align-items: ${({ align }) => align};
  gap: ${({ gap }) => gap}rem;
`;

export const FlexStyledDropDowns = styled.div<FlexProps>`
  display: flex;
  width: 70%;
  margin-top: ${({ marginTop }) => marginTop}rem;
  flex-direction: ${({ column }) => (column ? 'column' : 'row')};
  justify-content: ${({ justify }) => justify};
  align-items: ${({ align }) => align};
  gap: ${({ gap }) => gap}rem;
`;

export const GridTitleStyled = styled.span`
  color: ${colors.color13};
  ${fonts.gotham.H12B}
  text-transform: uppercase;
`;

export const RequiredStyled = styled.span`
  color: ${colors.color2};
`;

export const DemographicStyled = styled.div`
  margin: 4rem 0;
  padding-top: 2rem;
  border-top: 1px solid ${colors.color7};
`;

export const DemographicTitleStyled = styled.div`
  color: ${colors.color9};
  margin-bottom: 1rem;
  ${fonts.gotham.H16M}
`;

export const DemographicDescriprionStyled = styled.div`
  color: ${colors.color9};
  ${fonts.lato.H14}
`;

export const ErrorStyled = styled.span`
  margin-top: ${toRem(10)};
  ${fonts.lato.H14}
  color: ${colors.color14};
`;
