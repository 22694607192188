import React from 'react';
import { useNavigate } from 'react-router-dom';

import {
  AppWrapper, Button, InputPhone, InputText, PageTitle, Select,
} from '../../../components';
import { path } from '../../../utils';
import { useAddNewUser } from './hooks/index';
import { MerchantAccess } from './components/MerchantAccess';
import { ADD_NEW_USER as ENUM } from './enums';
import { DefaultPropsType } from '../../../types';

import * as Styled from './styles';

type AddNewUserProps = DefaultPropsType;

const AddNewUser = ({ permissionsCodeList = [] }: AddNewUserProps) => {
  const navigate = useNavigate();
  const hook = useAddNewUser();

  return (
    <AppWrapper permissionsCodeList={permissionsCodeList}>
      <Styled.TopContainerStyled>
        <PageTitle>{ENUM.PAGE_TITLE}</PageTitle>
        <Styled.TopContainerButtonsStyled>
          <Button
            onClick={() => navigate(path.userManagement.href)}
            theme="secondary"
          >
            {ENUM.CANCEL_BUTTON_TEXT}
          </Button>
          <Button
            onClick={() => hook.hookCreateAdminUserHandler()}
          >
            {ENUM.SAVE_BUTTON_TEXT}
          </Button>
        </Styled.TopContainerButtonsStyled>
      </Styled.TopContainerStyled>

      <Styled.DetailsStyled>
        <InputText
          label={ENUM.INPUTS.FIRST_NAME}
          name="firstName"
          type="text"
          value={hook.hookUserFirstName}
          required
          onChange={hook.hookSetUserFirstNameHandler}
          error={hook.hookCreateUserErrors.firstName}
          onBlur={hook.hookHandleValidation}
        />
        <InputText
          label={ENUM.INPUTS.LAST_NAME}
          name="lastName"
          type="text"
          value={hook.hookUserLastName}
          required
          onChange={hook.hookSetUserLastNameHandler}
          error={hook.hookCreateUserErrors.lastName}
          onBlur={hook.hookHandleValidation}
        />
        <Select
          label={ENUM.INPUTS.PREFERRED_LANGUAGE}
          name="preferredLanguage"
          selected={hook.hookUserLanguage}
          onChange={hook.hookSetUserLanguageHandler}
          options={hook.hookUserLanguageOptionsList}
        />
        <InputText
          label={ENUM.INPUTS.EMAIL_ADDRESS}
          name="email"
          type="email"
          value={hook.hookUserEmail}
          required
          onChange={hook.hookSetUserEmailHandler}
          error={hook.hookCreateUserErrors.userEmail}
          onBlur={hook.hookHandleValidation}
        />
        <InputPhone
          label={ENUM.INPUTS.PHONE_NUMBER}
          type="text"
          required
          value={hook.hookUserPhoneNumber}
          onChange={hook.hookSetUserPhoneNumberHandler}
          error={hook.hookCreateUserErrors.phone}
          onBlur={hook.hookHandleValidation}
        />
        <Select
          label={ENUM.INPUTS.SECURITY_ROLE}
          name="securityRole"
          selectedMulti={hook.hookUserSecurityRole}
          onChange={hook.hookSetUserSecurityRoleHandler}
          isMulti
          options={hook.hookUserSecurityRoleOptionsList}
          error={hook.hookCreateUserErrors.securityRole}
          onBlur={hook.hookHandleValidation}
          required
        />

        <InputText
          label={ENUM.INPUTS.POSITION}
          name="position"
          type="text"
          value={hook.hookUserPosition}
          onChange={hook.hookSetUserPositionHandler}
        />
      </Styled.DetailsStyled>

      <MerchantAccess
        allMerchantsAccess={hook.hookAllMerchantsAccess}
        handleSetAllMerchantsAccess={hook.hookSetAllMerchantAccessHandler}
        merchantList={hook.hookMerchantList}
        checkedMerchants={hook.hookCheckedMerchants}
        selectedMerchants={hook.hookSelectedMerchants}
        handleSetSelectedMerchants={hook.hookSetSelectedMerchantsHandler}
        handleSetCheckedMerchants={hook.hookSetCheckedMerchantHandler}
      />
    </AppWrapper>
  );
};

export default AddNewUser;
