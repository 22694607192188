import React from 'react';
import { useNavigate } from 'react-router-dom';

import {
  AdGenerateCodeModal, AppWrapper, Calendar, Checkbox, ErrorBanner, InputCalendar, InputText, LoadingDots, PageTitle, Pagination, RecordsDropdown, Select, Table,
} from '../../../../components';
import { path, REGEX_VALIDATORS } from '../../../../utils';
import {
  ASSOCIATED_ADS, BUTTON_TEXT, LABELS, NAMES, PLACEHOLDERS, REQUIRED_TEXT,
} from '../utils';
import { columns } from './contracts';
import { useEditCampaign } from './hooks';
import { DefaultPropsType } from '../../../../types';

import * as Styled from './styles';

type EditCampaignProps = DefaultPropsType;

const EditCampaign = ({ permissionsCodeList = [] }: EditCampaignProps) => {
  const navigate = useNavigate();
  const { hookIsReadOnlyList, ...hook } = useEditCampaign(permissionsCodeList);
  const [isReadOnly] = hookIsReadOnlyList;

  return (
    <AppWrapper permissionsCodeList={permissionsCodeList}>
      <ErrorBanner
        isOpen={!hook.hookValidated}
        message={REQUIRED_TEXT()}
      />
      <Styled.HeaderStyled>
        <PageTitle>{path.editCampaign.name}</PageTitle>
        <Styled.ButtonsWrapper>
          <Styled.ButtonStyled
            theme="secondary"
            onClick={() => navigate(-1)}
            disabled={hook.hookLoading}
            width="104px"
          >
            {BUTTON_TEXT.BACK}
          </Styled.ButtonStyled>
          <Styled.ButtonStyled
            onClick={hook.hookSubmitCampaign}
            disabled={hook.hookLoading || isReadOnly}
            width="104px"
          >
            {BUTTON_TEXT.SAVE}
          </Styled.ButtonStyled>
        </Styled.ButtonsWrapper>
      </Styled.HeaderStyled>

      <Styled.FieldsStyled>
        <InputText
          type="text"
          name={NAMES.CAMPAIGN_NAME}
          label={LABELS.CAMPAIGN_NAME}
          required
          value={hook.hookCampaignName}
          disabled={hook.hookLoading || isReadOnly}
          error={hook.hookEditCampaignErrors.campaignName}
          onBlur={hook.hookHandleValidation}
          onChange={hook.hookSetCampaignName}
        />
        <Select
          name={NAMES.STATUS}
          label={LABELS.STATUS}
          placeholder={PLACEHOLDERS.STATUS}
          options={hook.hookStatusOptions}
          selected={hook.hookStatus}
          error={hook.hookEditCampaignErrors.status}
          isDisabled={hook.hookLoading || isReadOnly}
          isLoading={hook.hookLoading}
          required
          onChange={hook.hookSetStatus}
        />
        <Styled.TextareaStyled
          height="150px"
          name={NAMES.DESCRIPTION}
          label={LABELS.DESCRIPTION}
          value={hook.hookDescription}
          disabled={hook.hookLoading || isReadOnly}
          error={hook.hookEditCampaignErrors.description}
          onBlur={hook.hookHandleValidation}
          onChange={hook.hookSetDescription}
          required
        />
        <Styled.DatesAreaStyled>
          <InputCalendar
            label={LABELS.START_DATE}
            placeholder={PLACEHOLDERS.DATE}
            required
            value={hook.hookStartDate?.toDateString() || ''}
            onClick={hook.hookOnOpenStartCalendar}
            isDisabled={hook.hookLoading || isReadOnly}
            error={hook.hookEditCampaignErrors.startDate}
          />
          <InputCalendar
            label={LABELS.END_DATE}
            placeholder={PLACEHOLDERS.DATE}
            value={hook.hookEndDate?.toDateString() || ''}
            onClick={hook.hookOnOpenEndCalendar}
            isDisabled={hook.hookLoading || isReadOnly}
            error={hook.hookEditCampaignErrors.endDate}
          />
        </Styled.DatesAreaStyled>
        <Select
          name={NAMES.TAGS}
          label={LABELS.TAGS}
          placeholder={PLACEHOLDERS.TAGS}
          selectedMulti={hook.hookTags}
          options={hook.hookTagsOptions}
          onChange={hook.hookSetTags}
          isDisabled={hook.hookLoading || isReadOnly}
          isLoading={hook.hookLoading}
          isMulti
          creatable
        />
        <Select
          name={NAMES.PRODUCTS}
          label={LABELS.PRODUCT}
          placeholder={PLACEHOLDERS.PRODUCT}
          selected={hook.hookProducts}
          onChange={hook.hookSetProducts}
          options={hook.hookProductsOptions}
          isDisabled={hook.hookLoading || isReadOnly}
          isLoading={hook.hookLoading}
        />
        <InputText
          type="url"
          name={NAMES.DEFAULT_URL}
          label={LABELS.DEFAULT_URL}
          value={hook.hookDefaultLandingPageUrl}
          onChange={hook.hookSetDefaultLandingPageUrl}
          regex={REGEX_VALIDATORS.URL}
          placeholder={hook.hookUrlPlaceholder || 'https://www.fintelconnect.com/?'}
          disabled={hook.hookLoading || isReadOnly}
          error={hook.hookLandingUrlError}
          onBlur={hook.hookValidateWebsite}
        />
        <Select
          name={NAMES.LANGUAGE}
          label={LABELS.LANGUAGE}
          placeholder={PLACEHOLDERS.LANGUAGE}
          required
          selected={hook.hookLanguage}
          options={hook.hookLanguageOptions}
          onChange={hook.hookSetLanguage}
          isDisabled={hook.hookLoading || isReadOnly}
          isLoading={hook.hookLoading}
          error={hook.hookEditCampaignErrors.language}
        />
        <InputText
          type="url"
          name={NAMES.THIRD_PARTY_URL}
          label={LABELS.THIRD_PARTY_URL}
          value={hook.hookThirdPartyTrackingUrl}
          onChange={hook.hookSetThirdPartyTrackingUrl}
          regex={REGEX_VALIDATORS.URL}
          disabled={hook.hookLoading || isReadOnly}
          error={hook.hookThirdPartyUrlError}
          onBlur={hook.hookValidateWebsite}
        />
      </Styled.FieldsStyled>

      <Styled.SectionTitleStyled>{LABELS.PUBLISHER_GROUPS}</Styled.SectionTitleStyled>
      <Styled.PublisherGroupStyled>
        {hook.hookPublisherGroupOptions.map((publisherGroup) => (
          <Checkbox
            label={publisherGroup.label}
            checked={publisherGroup.checked}
            onChange={() => hook.hookSetPublisherGroupHandler(publisherGroup)}
            disabled={hook.hookLoading || isReadOnly}
          />
        ))}
      </Styled.PublisherGroupStyled>

      <Styled.AssociatedAdsWrapper>
        <Styled.AssociatedAdsStyled>
          <Styled.AssociatedAdsTitleStyled>
            {ASSOCIATED_ADS}
          </Styled.AssociatedAdsTitleStyled>

          <RecordsDropdown
            selected={hook.hookRecords}
            options={hook.hookRecordOptions}
            currentPage={hook.hookCurrentPage}
            totalRecords={hook.hookTotalRecords}
            onChange={hook.hookSetRecords}
          />
        </Styled.AssociatedAdsStyled>

        {hook.hookLoading && (
          <LoadingDots />
        )}

        {!hook.hookLoading && hook.hookAds.length > 0 && (
          <>
            <Table
              columns={columns(hook.hookSetOpenAdModal)}
              data={hook.hookAds}
              onSort={hook.hookHandleSort}
              sortColumn={hook.hookSortColumn}
              noTableOverflow
            />
            <Pagination
              total={hook.hookTotalPages}
              currentPage={hook.hookPage}
              onPageChange={hook.hookChangePage}
            />
          </>
        )}
      </Styled.AssociatedAdsWrapper>

      <Calendar
        date={hook.hookStartDate}
        onApply={hook.hookOnApplyStartCalendar}
        onCancel={hook.hookOnCancelStartCalendar}
        isOpen={hook.hookStartCalendarOpen}
        hasRange={false}
        maxYear={new Date().getFullYear() + 2}
        isSingle
      />

      <Calendar
        date={hook.hookEndDate}
        onApply={hook.hookOnApplyEndCalendar}
        onCancel={hook.hookOnCancelEndCalendar}
        isOpen={hook.hookEndCalendarOpen}
        hasRange={false}
        maxYear={new Date().getFullYear() + 2}
        isSingle
      />

      <AdGenerateCodeModal
        isOpen={hook.hookAdModal}
        adId={hook.hookSelectedAd}
        onClose={hook.hookSetCloseAdModal}
      />
    </AppWrapper>
  );
};

export default EditCampaign;
