import React from 'react';
import { Checkbox, ErrorBanner } from '../../components';
import { USER_TYPES_NAMES, imageList } from '../../utils';
import * as Styled from './styles';
import { useSetPassword } from './hooks';

const ForgotPassword = () => {
  const hook = useSetPassword();

  return (
    <Styled.WrapperStyled>
      <Styled.LeftStyled>
        <figure>
          <img
            src={imageList.logoFull.src}
            alt="logo"
          />
        </figure>
      </Styled.LeftStyled>
      <Styled.RightStyled>
        <ErrorBanner
          message={hook.hookErrorMessage}
          isOpen={!!hook.hookErrorMessage}
        />
        <Styled.RightContentStyled>
          <div>
            <Styled.TitleStyled>Fintel Connect Network Consent:</Styled.TitleStyled>

            <Styled.CheckboxWrapper>
              <Checkbox
                checked={hook.hookCheckbox}
                onChange={hook.hookSetCheckbox}
              />
              <Styled.DescriptionWrapperStyled>
                {hook.hookUserType === USER_TYPES_NAMES.PUBLISHER && (
                  <>
                    <Styled.DescriptionStyled>
                      By ticking this box, you agree that part of your membership to Fintel Connect means receiving important updates and program information via email.
                    </Styled.DescriptionStyled>
                    <Styled.DescriptionStyled>
                      As a new member on an established account, you agree to be bound by the same network terms, found
                      {' '}
                      <Styled.LinkStyled
                        target="_blank"
                        rel="noopener noreferrer"
                        href="https://www.fintelconnect.com/affiliate-network-terms-conditions/"
                      >
                        here
                      </Styled.LinkStyled>
                      , as well as each of the Terms and Conditions of the brands with whom the account currently partners. These terms can be found upon login to the Fintel platform.
                    </Styled.DescriptionStyled>
                  </>
                )}
                {hook.hookUserType !== USER_TYPES_NAMES.PUBLISHER && (
                <Styled.DescriptionStyled>
                  By ticking this box, you agree that you have read and accept the Fintel Connect Network Terms, found
                  {' '}
                  <Styled.LinkStyled
                    target="_blank"
                    rel="noopener noreferrer"
                    href="https://www.fintelconnect.com/affiliate-network-terms-conditions/"
                  >
                    here
                  </Styled.LinkStyled>
                  . You agree that as part of your membership to Fintel Connect, you agree to receive important updates and program information via email.
                </Styled.DescriptionStyled>
                )}
              </Styled.DescriptionWrapperStyled>
            </Styled.CheckboxWrapper>
          </div>

          <Styled.ButtonStyled
            onClick={() => hook.hookVerifyUser()}
            disabled={!hook.hookCheckbox}
            loading={hook.hookVerifyUserLoading}
          >
            Submit
          </Styled.ButtonStyled>

        </Styled.RightContentStyled>
      </Styled.RightStyled>

    </Styled.WrapperStyled>
  );
};

export default ForgotPassword;
