import React, { useRef, useState } from 'react';
import ReCAPTCHA from 'react-google-recaptcha';
import { useNavigate } from 'react-router-dom';
import { InputText } from '../../components/Input';
import environment from '../../config/environment';
import { codeMessages, imageList, path } from '../../utils';
import { login } from '../../services';
import * as Styled from './styles';
import { Alert } from '../../components';

const Login = () => {
  const [emailValue, setEmailValue] = useState('');
  const [passwordValue, setPasswordValue] = useState('');
  const [isAlertOpen, setIsAlertOpen] = useState(false);
  const [alertMessage, setAlertMessage] = useState('');
  const navigate = useNavigate();

  const captchaRef = useRef();

  const captchaHandler = (value: any) => {
    captchaRef.current = value;
  };

  const onSubmitHandler = async (event: React.FormEvent<HTMLFormElement>) => {
    event.preventDefault();

    if (['production', 'staging', 'demo'].includes(environment.app.environment)) {
      if (!captchaRef.current) {
        setAlertMessage(codeMessages.errors.ERR0002);
        setIsAlertOpen(true);
      } else {
        try {
          await login(emailValue, passwordValue);
        } catch (error: any) {
          setIsAlertOpen(true);
          setAlertMessage(error);
        }
      }
    } else {
      try {
        await login(emailValue, passwordValue);
      } catch (error: any) {
        setIsAlertOpen(true);
        setAlertMessage(error);
      }
    }
  };

  return (
    <Styled.WrapperStyled>
      <Styled.LeftStyled>
        <figure>
          <img
            src={imageList.logoFull.src}
            alt="logo"
          />
        </figure>
      </Styled.LeftStyled>

      <Styled.RightStyled>
        <Styled.RightContentStyled>

          <h1>Welcome Back</h1>

          <Styled.FormStyled onSubmit={onSubmitHandler}>
            <InputText
              placeholder="Email Address"
              value={emailValue}
              onChange={(e) => setEmailValue(e.target.value)}
              required
              type="email"
              name="email"
              label="Email Address"
            />

            <InputText
              placeholder="Password"
              value={passwordValue}
              onChange={(e) => setPasswordValue(e.target.value)}
              required
              type="password"
              name="password"
              label="Password"
            />

            <Styled.RowStyled>
              {(['production', 'staging', 'demo'].includes(environment.app.environment)) && (
                <ReCAPTCHA
                  sitekey={environment.google.captcha}
                  onChange={captchaHandler}
                  grecaptcha={captchaRef}
                />
              )}

              <Styled.LinkStyled to={path.forgotPassword.href}>Forgot password?</Styled.LinkStyled>
            </Styled.RowStyled>

            <Styled.RowStyled>
              <Styled.ButtonStyled
                theme="secondary"
                type="button"
                onClick={() => navigate(path.createAccountStep1.href)}
              >
                Sign Up
              </Styled.ButtonStyled>
              <Styled.ButtonStyled
                onClick={() => undefined}
                type="submit"
              >
                Login
              </Styled.ButtonStyled>
            </Styled.RowStyled>
          </Styled.FormStyled>
        </Styled.RightContentStyled>

        <Alert
          isOpen={isAlertOpen}
          onClose={setIsAlertOpen}
          message={alertMessage}
          type="error"
        />
      </Styled.RightStyled>
    </Styled.WrapperStyled>
  );
};

export default Login;
