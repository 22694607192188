import React from 'react';
import jwtDecode from 'jwt-decode';
import { useNavigate } from 'react-router-dom';
import environment from '../../config/environment';
import { imageList, getCookie, path } from '../../utils';
import * as Styled from './styles';
import {
  INVALID_USER_MESSAGES, INVALID_COMPANY_MESSAGES, EMAIL_MESSAGE, PLACEHOLDER_BACK,
} from './enums';

const InvalidUser = () => {
  const navigate = useNavigate();
  const userCookie = getCookie(environment.app.cookieName);
  const decrypt: any = userCookie ? jwtDecode(userCookie) : {};
  let DISPLAY_MESSAGE = INVALID_USER_MESSAGES;

  if ((decrypt?.merchantsId !== null || decrypt?.publishersId !== null) && decrypt?.accStatus === 'Closed') {
    DISPLAY_MESSAGE = INVALID_COMPANY_MESSAGES;
  }

  return (
    <Styled.WrapperStyled>
      <Styled.LeftStyled>
        <figure>
          <img
            src={imageList.logoFull.src}
            alt="logo"
          />
        </figure>
      </Styled.LeftStyled>
      <Styled.RightStyled>
        <Styled.RightContentStyled>
          <h1>{DISPLAY_MESSAGE.TITLE}</h1>
          <span>
            {DISPLAY_MESSAGE.PART1}
            <a href="mailto:info@fintelconnect.com">
              {EMAIL_MESSAGE}
            </a>
            {DISPLAY_MESSAGE.PART2}
          </span>
          <figure>
            <img
              src={imageList.lightHousePlaceholder.src}
              alt={imageList.lightHousePlaceholder.alt}
            />
          </figure>

          <Styled.ButtonStyled
            theme="secondary"
            type="button"
            onClick={() => navigate(path.login.href)}
          >
            {PLACEHOLDER_BACK}
          </Styled.ButtonStyled>
        </Styled.RightContentStyled>
      </Styled.RightStyled>

    </Styled.WrapperStyled>
  );
};

export default InvalidUser;
