import styled from 'styled-components';
import { Select } from '../../../../components';
import { colors, fonts } from '../../../../styles/theme';

export const WrapperStyled = styled.div`
  padding-bottom: 80px;
`;

export const HeaderStyled = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 2rem;
`;

export const FiltersStyled = styled.div`
  display: grid;
  grid-template-columns: repeat(4, 1fr);
  grid-gap: 1.5rem;
  margin-bottom: 2rem;
`;

export const FooterWrapperStyled = styled.div`
  display: flex;
`;

export const RecordsStyled = styled.div`
  display: flex;
  justify-content: space-between;
  margin-bottom: 2rem;
`;

export const ExtendedRecordsStyled = styled.div`
  display: flex;
  align-items: center;
`;

export const RecordsLabelStyled = styled.div`
  ${fonts.lato.H14}
  color: ${colors.color1};
`;

export const SelectStyled = styled(Select)`
  width: 120px;
  margin: 0 1rem;
`;
