import { gql } from '@apollo/client';

export const GET_MERCHANT_PRODUCTS = gql`
  query ProductsOptions($programId: String) {
    products(programId: $programId) {
      products {
        name
        id
      }
    }
  }
`;

export type GetMerchantProductsInput = {
  programId: string
};

export type GetMerchantProductsOutput = {
  products: {
    products: {name: string, id: string}[] | null
  }
}
