import { gql } from '@apollo/client';

export const GET_USER = gql`
  query user($id: ID!) {
    user(id: $id){
        id
        auth0Id
        firstName
        lastName
        userType
        email
        phone
        status
        position
        preferredLanguage
        subscribeMerchantIds
        subscribeOffers
        roles {
          name
          id
        }
        newRoles {
          name
          id
          oldId
        }
    }
  }
  `;
