import { gql } from '@apollo/client';

export const GET_COMMISSION_DETAILS = gql`
  query Commission($commissionId: ID!) {
    commission(id: $commissionId) {
      id
      description
      endDate
      startDate
      products {
        name
        id
      }
      commissionType
      commissionStructure {
        cpaFlat {
          minimumSaleAmount
          commissionFee
          commissionBase
        }
        cpaTiered {
          commissionBase
          tiers {
            upTo
            commissionAmount
          }
          cutoffAmount
          minimumSaleAmount
        }
        referral {
          levels
        }
        revShareFlat {
          commissionFee
          commissionBase
        }
        revShareTiered {
          cutoffAmount
          commissionBase
          tiers {
              upTo
              commissionAmount
            }
        }
        bonusFlat {
          minimumSaleAmount
          commissionFee
          commissionBase
        }
        bonusTiered {
          minimumSaleAmount
          commissionBase
          period
          calculationType
          cutoffAmount
          tiers {
            upTo
            commissionAmount
          }
        }
      }
      commissionTarget {
        publisherGroupTargets {
          publisherGroupId
          startDate
          endDate
        }
        publisherTargets {
          publisherId
          startDate
          endDate
        }
      }
      products {
        id
        name
      }
      publisherGroups {
        id
        name
      }
      publishers {
        id
        companyName
      }
      merchant {
          id
        }
    }
  }
`;

export type GetCommissionDetailsInput = {
  commissionId: string
};

export type GCDCommissionStructureType = {
  cpaFlat: {
    minimumSaleAmount: string | null
    commissionFee: string | null
    commissionBase: 'GrossSales' | 'NetSales' | 'SalesNumber' | 'CPA' | null
  }
  cpaTiered: {
    commissionBase: 'GrossSales' | 'NetSales' | 'SalesNumber' | 'CPA' | null
    tiers: { upTo: string | null, commissionAmount: string | null }[]
    cutoffAmount: string | null
    minimumSaleAmount: string | null
  }
  referral: { levels: string[] }
  revShareFlat: {
    commissionFee: string | null
    commissionBase: 'GrossSales' | 'NetSales' | 'SalesNumber' | 'CPA' | null
  }
  revShareTiered: {
    tiers: { upTo: string, commissionAmount: string }[]
    cutoffAmount: string | null
    commissionBase: 'GrossSales' | 'NetSales' | 'SalesNumber' | 'CPA' | null
  }
  bonusFlat: {
    minimumSaleAmount: string | null
    commissionFee: string | null
    commissionBase: 'GrossSales' | 'NetSales' | 'SalesNumber' | 'CPA' | null
  }
  bonusTiered: {
    minimumSaleAmount: string | null
    commissionBase: 'GrossSales' | 'NetSales' | 'SalesNumber' | 'CPA' | null
    period: string | null
    calculationType: 'Stepped' | 'Lookup' | null
    tiers: { upTo: string, commissionAmount: string }[]
    cutoffAmount: string
  }
}

export type GCDCommissionTargetType = {
  publisherGroupTargets: { endDate: string | null, publisherGroupId: string, startDate: string | null }[]
  publisherTargets: { endDate: string | null, publisherId: string, startDate: string | null }[]
}

export type GCDCommission = {
  id: string
  description: string | null
  startDate: string | null
  endDate: string | null
  commissionType: string | null
  commissionStructure: GCDCommissionStructureType
  commissionTarget: GCDCommissionTargetType
  products: {id: string, name: string | null}[]
  publisherGroups: { id: string, name: string }[]
  publishers: { id: string, companyName: string }[]
  merchant: {id: string}
}

export type GetCommissionDetailsOutput = {
  commission: GCDCommission
}
