import styled from 'styled-components';
import { Select } from '../../../../components';
import { fonts } from '../../../../styles/theme';
import { toRem } from '../../../../utils';

export const HeaderStyled = styled.div`
  display: flex;
  justify-content: space-between;
`;

export const FilterStyled = styled.div`
  display: flex;
  flex-direction: column;
  align-items: end;
  margin-top: ${toRem(40)};
`;

export const Row1Styled = styled.div`
  width: 100%;
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  column-gap: 2%;
`;

export const SelectStyled = styled(Select)`
  ${fonts.lato.H14};
`;

export const Row2Styled = styled.div`
  align-items: start;
  padding: 1rem 0;
  button {
    ${fonts.lato.H16}
  }
`;

export const Row3Styled = styled.div`
  ${fonts.lato.H14}
  width: 500px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding-bottom: 2.5rem;
`;
