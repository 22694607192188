import { useState, useEffect } from 'react';
import {
  optionMaker,
  dateFormatter,
  checksMaker,
  USER_TYPES,
  CARD_NETWORK,
  ERROR_TYPES,
  PRODUCT_CATEGORIES,
} from '../../../../../../utils';
import { useValidation } from '../../../../../../utils/validation';
import { USER } from '../enums';

type ProductFeedSection = {
  validUntil: Date
  cardNetwork: [string]
  userTypes: [string]
  annualFee: string
  monthlyFee: string
  signupBonus: string
  supplementaryCardFee: string
  balanceTransferIntroRate: string
  balanceTransferRegular: string
  interestRate: string
  rewardsEarningRate: string
  annualInterestRate: string
  balanceTransferIntroFee: string
  balanceTransferFee: string
  cashAdvanceFee: string
  foreignCurrencyConversion: string
  dishonouredPaymentFee: string
  gracePeriod: string
  marketingItems: [string]
  legalReference: string
  promoInterestRate: string
  transactionFeeATM: string
  transactionFeeETR: string
  transactionFeeInternal: string
  freeTransactionVolume: string
  closingBalanceToWaiveMonthlyFee: string
  noMinimumBalance: boolean
  term: string
  redeemable: string
  insurer: string
  minimumAccountBalance: string
  managementFee: string
  tradeCost: string
  numberOfFreeMonthlyTrade: string
  mer: string
  coverageAmount: string
  guaranteedPremiums: string
  exclusionItems: [string]

  productUsageGuidelines: string
  productWelcomeOffers: string
  minimumOpenDeposit: string
  interestPaid: string
  interestPaidDays: string
  interestCalculated: string
  interestCalculatedDays: string
  bankingProductInsurance: string
  apyTiers: string
  apyRate: string
  mortgageType: string
  openMortgageType: string
  convertibleMortgageType: string
  preapprovalMortgageType: string
  mortgageRateType: string
  mortgageTypeDiscountedRate: string
  mortgageAprRate: string
  mortgageTermYear: string

  monthlyFeesDetails: string
  balanceTransferRegularDetails: string
  interestRateDetails: string
  AnnualInterestRateDetails: string
  bankingMinimumBalanceRate: string
}
type fieldsType = {[key: string]: any};

export const useProductFeed = (feedSection: ProductFeedSection, setIsUpdateDisabled: any, productCategory: any, geoAvalibility: string[], loading: any, geoStatesHanlder: (value: React.ChangeEvent<HTMLTextAreaElement>) => void) => {
  const vali = useValidation();
  const enableUpdate = () => setIsUpdateDisabled(false);
  const [usageValue, setUsageValue] = useState(feedSection.productUsageGuidelines);
  const [validUntil, setValidUntil] = useState(dateFormatter(new Date(feedSection.validUntil)));
  const [isCalendar, setIsCalendar] = useState(false);
  const [monthlyFee, setMonthlyFee] = useState(feedSection.monthlyFee);
  const [monthlyDetails, setMonthlyDetails] = useState(feedSection.monthlyFeesDetails);
  const [closingBalance, setClosingBalance] = useState(feedSection.closingBalanceToWaiveMonthlyFee);
  const [apy, setApy] = useState(feedSection.apyRate);
  const [apyDetails, setApyDetails] = useState(feedSection.apyTiers);
  const [interestRate, setInterestRate] = useState(feedSection.interestRate);
  const [interestDetails, setInterestDetails] = useState(feedSection.interestRateDetails);

  const [interestCalculated, setInterestCalculated] = useState<SelectOption>();
  const [interestCalculatedDays, setInterestCalculatedDays] = useState(feedSection.interestCalculatedDays);
  const [interestPaid, setInterestPaid] = useState<SelectOption>();
  const [interestPaidDays, setInterestPaidDays] = useState(feedSection.interestPaidDays);

  const [promoRate, setPromoRate] = useState(feedSection.promoInterestRate);
  const [minimumOpenDeposit, setOpenDeposit] = useState(feedSection.minimumOpenDeposit);
  const [transAtm, setTransAtm] = useState(feedSection.transactionFeeATM);
  const [etr, setEtr] = useState(feedSection.transactionFeeETR);
  const [transInternal, setInternal] = useState(feedSection.transactionFeeInternal);
  const [minBalance, setMinBalance] = useState(productCategory === 'Banking' ? feedSection.bankingMinimumBalanceRate : feedSection.minimumAccountBalance);
  const [minChecked, setMinChecked] = useState(feedSection.noMinimumBalance);
  const [marketingItems, setMarketingItems] = useState(['']);
  const [legal, setLegal] = useState(feedSection.legalReference);
  const [welcome, setWelcome] = useState(feedSection.productWelcomeOffers);
  const [insurance, setInsurance] = useState(feedSection.bankingProductInsurance);
  const [term, setTerm] = useState(feedSection.term);
  const [coverage, setCoverage] = useState(feedSection.coverageAmount);
  const [guaranteedPremiums, setGuaranteedPremiums] = useState(feedSection.guaranteedPremiums);
  const [exclusionItems, setExclusionItems] = useState(['', '', '', '']);
  const [balanceIntro, setBalanceIntro] = useState(feedSection.balanceTransferIntroFee);
  const [balanceFee, setBalanceFee] = useState(feedSection.balanceTransferFee);
  const [cashAdvanceFee, setCashAdvanceFee] = useState(feedSection.cashAdvanceFee);
  const [FCC, setFCC] = useState(feedSection.foreignCurrencyConversion);
  const [dishonouredPaymentFee, setDishonouredFee] = useState(feedSection.dishonouredPaymentFee);
  const [gracePeriod, setGracePeriod] = useState(feedSection.gracePeriod);
  const [rewards, setRewards] = useState(feedSection.rewardsEarningRate);
  const [annualRate, setAnnualRate] = useState(feedSection.annualInterestRate);
  const [annualDetails, setAnnualDetails] = useState(feedSection.AnnualInterestRateDetails);
  const [balanceTransferRegular, setBalanceRegular] = useState(feedSection.balanceTransferRegular);
  const [balanceTransferRegularDetails, setBalanceTransferDetails] = useState(feedSection.balanceTransferRegularDetails);
  const [signupBonus, setBonus] = useState(feedSection.signupBonus);
  const [annualFee, setAnnualFee] = useState(feedSection.annualFee);
  const [cardFee, setCardFee] = useState(feedSection.supplementaryCardFee);
  const [introRate, setIntroRate] = useState(feedSection.balanceTransferIntroRate);
  const [userTypes, setUserTypes] = useState(checksMaker(USER_TYPES, feedSection.userTypes));
  const [cardNetwork, setCardNetwork] = useState(checksMaker(CARD_NETWORK, feedSection.cardNetwork));

  const [mortgageTerm, setMortgageTerm] = useState(feedSection.mortgageTermYear);
  const [mortgageType, setMortgageType] = useState<SelectOption>();
  const [open, setOpen] = useState<SelectOption>();
  const [convertible, setConvertible] = useState<SelectOption>();
  const [pre, setPre] = useState<SelectOption>();
  const [mortgageRateType, setMortgageRateType] = useState<SelectOption>();

  const [apr, setApr] = useState(feedSection.mortgageAprRate);
  const [selectTerm, setSelectTerm] = useState(optionMaker(term));
  const [redeemable, setRedeemable] = useState(optionMaker(feedSection.redeemable));
  const [insurer, setInsurer] = useState(feedSection.insurer);
  const [manFees, setManFees] = useState(feedSection.managementFee);
  const [tradeCosts, setTradeCosts] = useState(feedSection.tradeCost);
  const [monthlyTrades, setMonthlyTrades] = useState(feedSection.numberOfFreeMonthlyTrade);
  const [mer, setMer] = useState(feedSection.mer);
  const [free, setFree] = useState(feedSection.freeTransactionVolume);

  const [secondRender, setSecondRender] = useState(false);
  const [newProductErrors, setNewProductErrors] = useState<Record<string, string>>({});

  const values = {
    monthlyFee,
    closingBalance,
    apy,
    interestRate,
    interestCalculatedDays,
    interestPaidDays,
    promoRate,
    minimumOpenDeposit,
    transAtm,
    etr,
    transInternal,
    minBalance,
    term,
    mortgageTerm,
    apr,
    annualFee,
    cardFee,
    introRate,
    balanceTransferRegular,
    annualRate,
    balanceIntro,
    balanceFee,
    cashAdvanceFee,
    FCC,
    free,
    dishonouredPaymentFee,
    gracePeriod,
    manFees,
    tradeCosts,
    monthlyTrades,
    mer,
    coverage,
    guaranteedPremiums,
  };

  const fields: fieldsType = {
    balanceFee: ERROR_TYPES.IS_DOLLAR_AMOUNT,
    monthlyFee: ERROR_TYPES.IS_DOLLAR_AMOUNT,
    closingBalance: ERROR_TYPES.IS_DOLLAR_AMOUNT,
    apy: ERROR_TYPES.IS_DOLLAR_AMOUNT,
    interestRate: ERROR_TYPES.IS_DOLLAR_AMOUNT,
    interestCalculatedDays: ERROR_TYPES.SELECTION_REQUIRED,
    interestPaidDays: ERROR_TYPES.SELECTION_REQUIRED,
    promoRate: ERROR_TYPES.IS_DOLLAR_AMOUNT,
    minimumOpenDeposit: ERROR_TYPES.IS_DOLLAR_AMOUNT,
    guaranteedPremiums: ERROR_TYPES.IS_DOLLAR_AMOUNT,
    transAtm: ERROR_TYPES.IS_DOLLAR_AMOUNT,
    etr: ERROR_TYPES.IS_DOLLAR_AMOUNT,
    transInternal: ERROR_TYPES.IS_DOLLAR_AMOUNT,
    minBalance: ERROR_TYPES.IS_DOLLAR_AMOUNT,
    term: ERROR_TYPES.SELECTION_REQUIRED,
    mortgageTerm: ERROR_TYPES.IS_DOLLAR_AMOUNT,
    apr: ERROR_TYPES.IS_DOLLAR_AMOUNT,
    annualFee: ERROR_TYPES.IS_DOLLAR_AMOUNT,
    cardFee: ERROR_TYPES.IS_DOLLAR_AMOUNT,
    introRate: ERROR_TYPES.IS_DOLLAR_AMOUNT,
    balanceTransferRegular: ERROR_TYPES.IS_DOLLAR_AMOUNT,
    annualRate: ERROR_TYPES.IS_DOLLAR_AMOUNT,
    balanceIntro: ERROR_TYPES.IS_DOLLAR_AMOUNT,
    cashAdvanceFee: ERROR_TYPES.IS_DOLLAR_AMOUNT,
    FCC: ERROR_TYPES.IS_DOLLAR_AMOUNT,
    free: ERROR_TYPES.IS_DOLLAR_AMOUNT,
    dishonouredPaymentFee: ERROR_TYPES.IS_DOLLAR_AMOUNT,
    gracePeriod: ERROR_TYPES.IS_DOLLAR_AMOUNT,
    manFees: ERROR_TYPES.IS_DOLLAR_AMOUNT,
    tradeCosts: ERROR_TYPES.IS_DOLLAR_AMOUNT,
    monthlyTrades: ERROR_TYPES.IS_DOLLAR_AMOUNT,
    mer: ERROR_TYPES.IS_DOLLAR_AMOUNT,
    coverage: ERROR_TYPES.IS_DOLLAR_AMOUNT,
  };

  const handleValidation = () => {
    const nonEmptyValues: { [key: string]: string } = Object.entries(values)
      .filter(([_, value]) => typeof value === 'string' && value !== '')
      .reduce((acc, [key, value]) => {
        acc[key] = value;
        return acc;
      }, {} as {[key: string]: string});

    const filteredKeys = Object.keys(nonEmptyValues);

    const filterNonEmptyField: fieldsType = Object.keys(fields)
      .filter((key:string) => filteredKeys.includes(key))
      .reduce((acc, key) => {
        acc[key] = fields[key];
        return acc;
      }, {} as fieldsType);

    return vali.validateAll(nonEmptyValues, filterNonEmptyField, setNewProductErrors, secondRender);
  };

  const handleMonthlyTrades = (event: React.ChangeEvent<HTMLTextAreaElement>) => {
    setMonthlyTrades(event.target.value);
    enableUpdate();
  };

  const handleFree = (event: React.ChangeEvent<HTMLTextAreaElement>) => {
    setFree(event.target.value);
    enableUpdate();
  };

  const handleMer = (event: React.ChangeEvent<HTMLTextAreaElement>) => {
    setMer(event.target.value);
    enableUpdate();
  };

  const handleInsurer = (event: React.ChangeEvent<HTMLTextAreaElement>) => {
    setInsurer(event.target.value);
    enableUpdate();
  };

  const handleManFees = (event: React.ChangeEvent<HTMLTextAreaElement>) => {
    setManFees(event.target.value);
    enableUpdate();
  };

  const handleTradeCosts = (event: React.ChangeEvent<HTMLTextAreaElement>) => {
    setTradeCosts(event.target.value);
    enableUpdate();
  };

  const handleSelectTerm = (value: any) => {
    setSelectTerm(value);
    setTerm(value.label);
    enableUpdate();
  };

  const handleRedeemable = (value: any) => {
    setRedeemable(value);
    enableUpdate();
  };

  const handleRateType = (value: any) => {
    setMortgageRateType(value);
    enableUpdate();
  };

  const handleMortgageTerm = (event: React.ChangeEvent<HTMLTextAreaElement>) => {
    setMortgageTerm(event.target.value);
    enableUpdate();
  };

  const handleApr = (event: React.ChangeEvent<HTMLTextAreaElement>) => {
    setApr(event.target.value);
    enableUpdate();
  };

  const handleOpen = (value: any) => {
    setOpen(value);
    enableUpdate();
  };

  const handleConvertible = (value: any) => {
    setConvertible(value);
    enableUpdate();
  };

  const handlePre = (value: any) => {
    setPre(value);
    enableUpdate();
  };

  const handleMortgageType = (value: any) => {
    setMortgageType(value);
    enableUpdate();
  };

  const handleChangeChecks = (index: any, listType: any) => {
    const newList = listType === USER ? [...userTypes] : [...cardNetwork];
    newList[index] = { ...newList[index], checked: !newList[index].checked };

    enableUpdate();
    if (listType === USER) {
      setUserTypes(newList);
    } else {
      setCardNetwork(newList);
    }
  };

  const handleXButton = (index: number) => {
    const newList = [...marketingItems];
    newList.splice(index, 1);
    setMarketingItems(newList);

    enableUpdate();
  };

  const handleAnnualFee = (event: React.ChangeEvent<HTMLTextAreaElement>) => {
    setAnnualFee(event.target.value);
    enableUpdate();
  };

  const handleCardFee = (event: React.ChangeEvent<HTMLTextAreaElement>) => {
    setCardFee(event.target.value);
    enableUpdate();
  };

  const handleIntroRate = (event: React.ChangeEvent<HTMLTextAreaElement>) => {
    setIntroRate(event.target.value);
    enableUpdate();
  };

  const handleBalanceRegular = (event: React.ChangeEvent<HTMLTextAreaElement>) => {
    setBalanceRegular(event.target.value);
    enableUpdate();
  };

  const handleRegularTransferDetails = (event: React.ChangeEvent<HTMLTextAreaElement>) => {
    setBalanceTransferDetails(event.target.value);
    enableUpdate();
  };

  const handleSignup = (event: React.ChangeEvent<HTMLTextAreaElement>) => {
    setBonus(event.target.value);
    enableUpdate();
  };

  const handleRewards = (event: React.ChangeEvent<HTMLTextAreaElement>) => {
    setRewards(event.target.value);
    enableUpdate();
  };

  const handleAnnualRate = (event: React.ChangeEvent<HTMLTextAreaElement>) => {
    setAnnualRate(event.target.value);
    enableUpdate();
  };

  const handleAnnualDetails = (event: React.ChangeEvent<HTMLTextAreaElement>) => {
    setAnnualDetails(event.target.value);
    enableUpdate();
  };

  const handleSetExclusion = (event: React.ChangeEvent<HTMLTextAreaElement>, index: number) => {
    const newList = [...exclusionItems]; // create a new copy of the array
    newList[index] = event.target.value; // update the value at the specified index
    setExclusionItems(newList);
  };

  const handleBalanceIntro = (event: React.ChangeEvent<HTMLTextAreaElement>) => {
    setBalanceIntro(event.target.value);
    enableUpdate();
  };

  const handleBalanceFee = (event: React.ChangeEvent<HTMLTextAreaElement>) => {
    setBalanceFee(event.target.value);
    enableUpdate();
  };

  const handleCashAdvanceFee = (event: React.ChangeEvent<HTMLTextAreaElement>) => {
    setCashAdvanceFee(event.target.value);
    enableUpdate();
  };

  const handleFCC = (event: React.ChangeEvent<HTMLTextAreaElement>) => {
    setFCC(event.target.value);
    enableUpdate();
  };

  const handleDishnoured = (event: React.ChangeEvent<HTMLTextAreaElement>) => {
    setDishonouredFee(event.target.value);
    enableUpdate();
  };

  const handleGrace = (event: React.ChangeEvent<HTMLTextAreaElement>) => {
    setGracePeriod(event.target.value);
    enableUpdate();
  };
  const handleTerm = (event: React.ChangeEvent<HTMLTextAreaElement>) => {
    setTerm(event.target.value);
    enableUpdate();
  };

  const handleCoverage = (event: React.ChangeEvent<HTMLTextAreaElement>) => {
    setCoverage(event.target.value);
    enableUpdate();
  };

  const handleGuaranteed = (event: React.ChangeEvent<HTMLTextAreaElement>) => {
    setGuaranteedPremiums(event.target.value);
    enableUpdate();
  };

  const handleLegal = (event: React.ChangeEvent<HTMLTextAreaElement>) => {
    setLegal(event.target.value);
    enableUpdate();
  };

  const handleWelcome = (event: React.ChangeEvent<HTMLTextAreaElement>) => {
    setWelcome(event.target.value);
    enableUpdate();
  };

  const handleInsurance = (event: React.ChangeEvent<HTMLTextAreaElement>) => {
    setInsurance(event.target.value);
    enableUpdate();
  };

  const handleSetMarketingItems = (event: React.ChangeEvent<HTMLTextAreaElement>, index: number) => {
    const newList = [...marketingItems]; // create a new copy of the array
    newList[index] = event.target.value; // update the value at the specified index
    setMarketingItems(newList);
    enableUpdate();
  };

  const handleAddMarketingItems = () => {
    const newList = [...marketingItems];
    newList.push('');
    setMarketingItems(newList);
  };

  const handleMinBalanceChecked = () => {
    setMinChecked(!minChecked);
    enableUpdate();
  };

  const handleMinBalance = (event: React.ChangeEvent<HTMLTextAreaElement>) => {
    setMinBalance(event.target.value);
    enableUpdate();
  };

  const handlePromoRate = (event: React.ChangeEvent<HTMLTextAreaElement>) => {
    setPromoRate(event.target.value);
    enableUpdate();
  };

  const handleOpenDeposit = (event: React.ChangeEvent<HTMLTextAreaElement>) => {
    setOpenDeposit(event.target.value);
    enableUpdate();
  };

  const handleTransAtm = (event: React.ChangeEvent<HTMLTextAreaElement>) => {
    setTransAtm(event.target.value);
    enableUpdate();
  };

  const handleEtr = (event: React.ChangeEvent<HTMLTextAreaElement>) => {
    setEtr(event.target.value);
    enableUpdate();
  };

  const handleInternal = (event: React.ChangeEvent<HTMLTextAreaElement>) => {
    setInternal(event.target.value);
    enableUpdate();
  };

  const handleCalculated = (value: any) => {
    setInterestCalculated(value);
    if (value.label !== 'Other') setInterestCalculatedDays('');
    enableUpdate();
  };

  const handleCalculatedDays = (event: React.ChangeEvent<HTMLTextAreaElement>) => {
    setInterestCalculatedDays(event.target.value);
    enableUpdate();
  };

  const handleInterestPaid = (value: any) => {
    setInterestPaid(value);
    if (value.label !== 'Other') setInterestPaidDays('');
    enableUpdate();
  };

  const handleInterestPaidDays = (event: React.ChangeEvent<HTMLTextAreaElement>) => {
    setInterestPaidDays(event.target.value);
    enableUpdate();
  };

  const handleApy = (event: React.ChangeEvent<HTMLTextAreaElement>) => {
    setApy(event.target.value);
    enableUpdate();
  };

  const handleApyDetails = (event: React.ChangeEvent<HTMLTextAreaElement>) => {
    setApyDetails(event.target.value);
    enableUpdate();
  };

  const handleInterest = (event: React.ChangeEvent<HTMLTextAreaElement>) => {
    setInterestRate(event.target.value);
    enableUpdate();
  };

  const handleInterestDetails = (event: React.ChangeEvent<HTMLTextAreaElement>) => {
    setInterestDetails(event.target.value);
    enableUpdate();
  };

  const handleApply = (date: Date) => {
    setValidUntil(dateFormatter(date));
    setIsCalendar(false);
    enableUpdate();
  };

  const handleCancelCalendar = () => {
    setIsCalendar(false);
  };

  const handleShowCalendar = () => {
    setIsCalendar(true);
  };

  const handleUsageChange = (event: React.ChangeEvent<HTMLTextAreaElement>) => {
    setUsageValue(event.target.value);
    enableUpdate();
  };

  const handleMonthlyFee = (event: React.ChangeEvent<HTMLTextAreaElement>) => {
    setMonthlyFee(event.target.value);
    enableUpdate();
  };

  const handleMonthlyDetails = (event: React.ChangeEvent<HTMLTextAreaElement>) => {
    setMonthlyDetails(event.target.value);
    enableUpdate();
  };

  const handleClosingBalance = (event: React.ChangeEvent<HTMLTextAreaElement>) => {
    setClosingBalance(event.target.value);
    enableUpdate();
  };

  useEffect(() => {
    let newList;
    if (feedSection.marketingItems) {
      newList = feedSection.marketingItems;
      if ([PRODUCT_CATEGORIES.INVESTMENTS, PRODUCT_CATEGORIES.INSURANCE].includes(productCategory)) {
        while (newList?.length < 6) {
          newList.push('');
        }
      }
    } else {
      newList = [''];
      if ([PRODUCT_CATEGORIES.INVESTMENTS, PRODUCT_CATEGORIES.INSURANCE].includes(productCategory)) {
        newList = ['', '', '', '', '', ''];
      }
    }
    setMarketingItems(newList);
    if (feedSection.validUntil) {
      setValidUntil(dateFormatter(new Date(feedSection.validUntil)));
    } else {
      setValidUntil('');
    }
    if (feedSection.exclusionItems) {
      setExclusionItems(feedSection.exclusionItems);
    }

    if (feedSection.interestCalculated) setInterestCalculated(optionMaker(feedSection.interestCalculated));
    if (feedSection.interestPaid) setInterestPaid(optionMaker(feedSection.interestPaid));
    if (feedSection.mortgageType) setMortgageType(optionMaker(feedSection.mortgageType));
    if (feedSection.openMortgageType) setOpen(optionMaker(feedSection.openMortgageType));
    if (feedSection.convertibleMortgageType) setConvertible(optionMaker(feedSection.convertibleMortgageType));
    if (feedSection.preapprovalMortgageType) setPre(optionMaker(feedSection.preapprovalMortgageType));
    if (feedSection.mortgageRateType) setMortgageRateType(optionMaker(feedSection.mortgageRateType));
  }, []);

  const handleUpdateProduct = () => {
    enableUpdate();
    setSecondRender(true);
    if (handleValidation()) {
      return true;
    }
    return false;
  };

  useEffect(() => {
    handleValidation();
  }, [secondRender]);

  return {
    hookChangeApy: handleApy,

    hookChangeApyDetails: handleApyDetails,

    hookChangeInterestRate: handleInterest,

    hookChangeInterestRateDtails: handleInterestDetails,

    hookCancelCalendar: handleCancelCalendar,

    hookUsageChange: handleUsageChange,

    hookApplyCalendar: handleApply,

    hookShowCalendar: handleShowCalendar,

    hookChangeMonthlyFee: handleMonthlyFee,

    hookChangeMonthlyDetails: handleMonthlyDetails,

    hookChangeClosingBalance: handleClosingBalance,

    hookChangeCalculated: handleCalculated,

    hookChangeCalculatedDays: handleCalculatedDays,

    hookChangePaid: handleInterestPaid,

    hookChangePaidDays: handleInterestPaidDays,

    hookChangePromo: handlePromoRate,

    hookChangeMinimumOpen: handleOpenDeposit,

    hookChangeTransFees: handleTransAtm,

    hookChangeTransET: handleEtr,

    hookChangeTransInternal: handleInternal,

    hookChangeMinChecked: handleMinBalanceChecked,

    hookChangeMinBalance: handleMinBalance,

    hookSetMarketingItems: handleSetMarketingItems,

    hookAddMarketingItem: handleAddMarketingItems,

    hookChangeGeoAval: geoStatesHanlder,

    hookChangeInsurance: handleInsurance,

    hookChangeWelcome: handleWelcome,

    hookChangeLegalValue: handleLegal,

    hookChangeTerm: handleTerm,

    hookChangeCoverage: handleCoverage,

    hookChangeGuaranteed: handleGuaranteed,

    hookChangeExclusion: handleSetExclusion,

    hookChangeBalanceFee: handleBalanceFee,

    hookChangeBalanceIntro: handleBalanceIntro,

    hookHandleX: handleXButton,

    hookChangeCash: handleCashAdvanceFee,

    hookChangeDishonoured: handleDishnoured,

    hookChangeFCC: handleFCC,

    hookChangeGrace: handleGrace,

    hookChangeRewards: handleRewards,

    hookChangeAnnualRate: handleAnnualRate,

    hookChangeAnnualDetails: handleAnnualDetails,

    hookChangeRegularDetails: handleRegularTransferDetails,

    hookChangeTransferRegular: handleBalanceRegular,

    hookChangeSignUp: handleSignup,

    hookChangeAnnualFee: handleAnnualFee,

    hookChangeCardFee: handleCardFee,

    hookChangeIntroRate: handleIntroRate,

    hookHandleChangeChecks: handleChangeChecks,

    hookChangeMortgageType: handleMortgageType,

    hookChangePre: handlePre,

    hookChangeOpen: handleOpen,

    hookChangeConvertible: handleConvertible,

    hookChangeMortageTerm: handleMortgageTerm,

    hookChangeApr: handleApr,

    hookChangeRateType: handleRateType,

    hookChangeSelectTerm: handleSelectTerm,

    hookChangeRedeemAble: handleRedeemable,

    hookChangeTrade: handleTradeCosts,

    hookChangeManFees: handleManFees,

    hookChangeInsurer: handleInsurer,

    hookChangeMer: handleMer,

    hookChangeMonthlyTrades: handleMonthlyTrades,

    hookChangeFree: handleFree,

    hookHandleUpdateProduct: handleUpdateProduct,

    hookValidate: handleValidation,

    hookNewProductErrors: newProductErrors,

    hookFree: free,

    hookMer: mer,

    hookMonthlyTrades: monthlyTrades,

    hookInsurer: insurer,

    hookManFees: manFees,

    hookTrade: tradeCosts,

    hookSelectTerm: selectTerm,

    hookRedeemable: redeemable,

    hookMortgageTerm: mortgageTerm,

    hookApr: apr,

    hookRateType: mortgageRateType,

    hookPre: pre,

    hookConvertible: convertible,

    hookOpen: open,

    hookMortgageType: mortgageType,

    hookUserTypes: userTypes,

    hookCardNetwork: cardNetwork,

    hookSignUp: signupBonus,

    hookAnnualFee: annualFee,

    hookCardFee: cardFee,

    hookIntroRate: introRate,

    hookBalanceRegular: balanceTransferRegular,

    hookBalanceDetails: balanceTransferRegularDetails,

    hookRewardsValue: rewards,

    hookAnnualRate: annualRate,

    hookAnnualDetails: annualDetails,

    hookGrace: gracePeriod,

    hookFCC: FCC,

    hookDishonoured: dishonouredPaymentFee,

    hookCash: cashAdvanceFee,

    hookBalanceTransferFee: balanceFee,

    hookBalanceIntro: balanceIntro,

    hookExclusion: exclusionItems,

    hookTerm: term,

    hookCoverage: coverage,

    hookGuaranteed: guaranteedPremiums,

    hookMarketingItems: marketingItems,

    hookInterestCalculated: interestCalculated,

    hookInterestCalculatedDays: interestCalculatedDays,

    hookInterestPaid: interestPaid,

    hookInterestPaidDays: interestPaidDays,

    hookApy: apy,

    hookApyDetails: apyDetails,

    hookInterestRate: interestRate,

    hookInterestRateDetails: interestDetails,

    hookClosingBalance: closingBalance,

    hookMonthlyFee: monthlyFee,

    hookMonthlyDetails: monthlyDetails,

    hookUsageValue: usageValue,

    hookValidValue: validUntil,

    hookCalendarOpen: isCalendar,

    hookPromo: promoRate,

    hookMinimumOpen: minimumOpenDeposit,

    hookTransAtm: transAtm,

    hookTransET: etr,

    hookTransInternal: transInternal,

    hookMinBalance: minBalance,

    hookMinBalanceChecked: minChecked,

    hookLegalValue: legal,

    hookWelcomeOffer: welcome,

    hookInsurance: insurance,

    hookGeoAval: geoAvalibility?.join(', ') || '',

    hookLoading: loading,

  };
};
