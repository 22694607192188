import { useEffect, useState } from 'react';
import { useNavigate, unstable_useBlocker as useBlocker } from 'react-router-dom';
import { useLazyQuery, useMutation } from '@apollo/client';
import { useToast, useUserInfo } from '../../../../../hooks';
import { MERCHANT_PREFIX, NUMBERS_OPTIONS, path } from '../../../../../utils';
import { SETTINGS_CHECK } from '../Tabs/Check/enums';
import { GET_FCHECK_LAST, GET_FCHECK_SETTINGS } from '../Tabs/Check/graphql/queries';
import { UPDATE_FCHECK_SETTINGS } from '../Tabs/Check/graphql/mutations';
import { Permission } from '../../../../../entities';

export const useFintelCheckSettings = (permissionsCodeList: string[] = []) => {
  type Overview = {
    lastCheckDate?: string
  }

  type Settings = {
    merchantId: number
    frequencySettings: string
    publisherSettings: string[]
    checkAllDomains: boolean
    crawlDepthSettings: string
    enabled: boolean
    manualUrls: string[]
    blacklistDomains: string[]
    dayToRun: number
    timesToWait: number
    urlCheckType: string
    trackingDomainDayNumberSetting: number
    trackingDomainDaySetting: string
  }

  type Frequency = {
    literal: string
    numeral: number
  }

  const DEFAULT_DAY_OPTION: SelectOption = {
    label: '1',
    value: '1',
  };

  const navigate = useNavigate();
  const { hookShowToast } = useToast();
  const userHook = useUserInfo();

  const [settings, setSettings] = useState<Settings>();
  const [overview, setOverview] = useState<Overview>();

  const [initialSettings, setInitialSettings] = useState<Settings>();
  const [currentSettings, setCurrentSettings] = useState<Settings>();

  // Frequency
  const [selectWeeklyButton, setSelectWeeklyButton] = useState(false);
  const [selectBiweeklyButton, setSelectBiweeklyButton] = useState(false);
  const [selectMonthlyButton, setSelectMonthlyButton] = useState(false);
  const [frequency, setFrequency] = useState<Frequency>({ literal: '', numeral: 1 });

  // Scans
  const [selectScan, setSelectScan] = useState<SelectOption>({ label: '', value: '' });
  const [nextReport, setNextReport] = useState('');

  // Non applicable urls
  const [days, setDays] = useState<number>(1);
  const [dayFrequency, setDayFrequency] = useState<SelectOption>({ label: '', value: '' });

  // Publisher Scan
  const [selectAllSettingsButton, setSelectAllSettingsButton] = useState(false);
  const [selectListPublishersButton, setSelectListPublishersButton] = useState(false);
  const [publishersSelected, setPublishersSelected] = useState<string[]>([]);

  // URL types
  const [selectRelevant, setSelectRelevant] = useState(false);
  const [selectManual, setSelectManual] = useState(false);
  const [selectBoth, setSelectBoth] = useState(false);
  const [selectUrlType, setSelectUrlType] = useState('');

  // URL Preferences
  const [selectAll, setSelectAll] = useState(false);
  const [selectSpecific, setSelectSpecific] = useState(true);
  const [selectTimeframeDay, setSelectTimeframeDay] = useState<SelectOption>({ label: '', value: '' });
  const [selectTimeframeFrequency, setSelectTimeframeFrequency] = useState<SelectOption>({ label: '', value: '' });
  const [selectTimeframeDayOptions, setSelectTimeframeDayOptions] = useState<SelectOption[]>([]);
  const [selectTimeframeFrequencyOptions, setSelectTimeframeFrequencyOptions] = useState<SelectOption[]>([]);

  // Page States
  const [fintelCheckToggle, setFintelCheckToggle] = useState(false);
  const [openSettings, setOpenSettings] = useState(false);
  const [updateSettingsButton, setUpdateSettingsButton] = useState(true);
  const [isOpen, setIsOpen] = useState(false);
  const newDate = new Date();
  const changesDate = `${newDate.getMonth() + 1}/${newDate.getDate() + 1}/${newDate.getFullYear()}`;
  const [pageDataLoad, setPageDataLoad] = useState(false);
  const blocker = useBlocker(!updateSettingsButton);

  // Queries & Mutations
  const [getFintelCheckSettings, { loading: settingsLoading }] = useLazyQuery(GET_FCHECK_SETTINGS);
  const [getOverview, { loading: overviewLoading }] = useLazyQuery(GET_FCHECK_LAST);
  const [updateFintelCheckSettings, { loading: updateLoading }] = useMutation(UPDATE_FCHECK_SETTINGS);

  const getSettingsHandler = async () => {
    const [{ data: fcheckSettings }, { data: fcheckOverview }] = await Promise.all([
      getFintelCheckSettings({
        variables: {
          input: {
            merchantId: Number(userHook?.hookWhoAmI?.companyId),
          },
        },
        fetchPolicy: 'no-cache',
      }),
      getOverview({
        variables: {
          input: {
            merchantId: userHook?.hookWhoAmI?.companyId?.toString(),
          },
        },
      })]);
    if (fcheckSettings?.getFintelCheckSettings?.settings) {
      setSettings(fcheckSettings.getFintelCheckSettings.settings);
      setInitialSettings(fcheckSettings.getFintelCheckSettings.settings);
      setCurrentSettings(fcheckSettings.getFintelCheckSettings.settings);
    }
    if (fcheckOverview?.fintelChecksOverview) setOverview(fcheckOverview.fintelChecksOverview);
  };

  const saveNewSettings = async (toggleEnabled?: boolean) => {
    const { data, errors } = await updateFintelCheckSettings({
      variables: {
        input: {
          activeDate: newDate,
          enabled: toggleEnabled !== undefined ? toggleEnabled : fintelCheckToggle,
          frequencySettings: frequency.literal,
          dayToRun: Number(selectScan.value),
          timesToWait: days * (dayFrequency.value === 'Days' ? 1 : 7),
          publisherSettings: selectAllSettingsButton ? ['All'] : settings?.publisherSettings,
          urlCheckType: selectUrlType,
          checkAllDomains: selectAll,
          trackingDomainDayNumberSetting: selectAll === false ? Number(selectTimeframeDay.value) : 1,
          trackingDomainDaySetting: selectAll === false ? selectTimeframeFrequency.value : 'Days',
          // immutable fields
          merchantId: Number(userHook.hookWhoAmI.companyId),
          crawlDepthSettings: settings?.crawlDepthSettings,
          manualUrls: settings?.manualUrls,
          blacklistDomains: settings?.blacklistDomains,
        },
      },
    });
    if (errors) {
      hookShowToast(SETTINGS_CHECK.TOAST.ERROR);
    } else if (data) {
      setInitialSettings(currentSettings);
      setUpdateSettingsButton(true);
      let toastMessage = SETTINGS_CHECK.TOAST.SUCCESS;
      if (toggleEnabled !== undefined) toastMessage = toggleEnabled ? SETTINGS_CHECK.TOAST.ENABLE : SETTINGS_CHECK.TOAST.DISABLE;
      hookShowToast(toastMessage);
    }
  };

  const checkSettingsChangedHandler = () => {
    if (JSON.stringify(currentSettings) === JSON.stringify(initialSettings)) {
      setUpdateSettingsButton(true);
    } else {
      setUpdateSettingsButton(false);
    }
  };

  const setSelectWeeklyButtonHandler = () => {
    setSelectWeeklyButton(true);
    setSelectMonthlyButton(false);
    setSelectBiweeklyButton(false);
    setFrequency({ numeral: 7, literal: SETTINGS_CHECK.FREQUENCY.WEEKLY });
    setCurrentSettings({ ...currentSettings, frequencySettings: SETTINGS_CHECK.FREQUENCY.WEEKLY } as Settings);
  };

  const setSelectBiweeklyButtonHandler = () => {
    setSelectBiweeklyButton(true);
    setSelectWeeklyButton(false);
    setSelectMonthlyButton(false);
    setFrequency({ numeral: 14, literal: SETTINGS_CHECK.FREQUENCY.BIWEEKLY });
    setCurrentSettings({ ...currentSettings, frequencySettings: SETTINGS_CHECK.FREQUENCY.BIWEEKLY } as Settings);
  };

  const setSelectMonthlyButtonHandler = () => {
    setSelectMonthlyButton(true);
    setSelectBiweeklyButton(false);
    setSelectWeeklyButton(false);
    setFrequency({ numeral: 31, literal: SETTINGS_CHECK.FREQUENCY.MONTHLY });
    setCurrentSettings({ ...currentSettings, frequencySettings: SETTINGS_CHECK.FREQUENCY.MONTHLY } as Settings);
  };

  const setSelectAllSettingsButtonHandler = () => {
    setSelectAllSettingsButton(true);
    setSelectListPublishersButton(false);
    setCurrentSettings({ ...currentSettings, publisherSettings: ['All'] } as Settings);
  };

  const setSelectListPublishersButtonHandler = () => {
    setSelectAllSettingsButton(false);
    setSelectListPublishersButton(true);
    setCurrentSettings({ ...currentSettings, publisherSettings: settings?.publisherSettings } as Settings);
  };

  const setSelectAllDomainHandler = () => {
    setSelectAll(true);
    setSelectSpecific(false);
    setCurrentSettings({ ...currentSettings, checkAllDomains: true } as Settings);
  };

  const setSelectTimeFrameDomainHandler = () => {
    setSelectSpecific(true);
    setSelectAll(false);
    setCurrentSettings({ ...currentSettings, checkAllDomains: false } as Settings);
  };

  const setNextReportHandle = (value: SelectOption) => {
    const lastRun = overview?.lastCheckDate ? new Date(overview.lastCheckDate) : new Date();
    const dayOfWeekSelected = (Number(value.value) + 1) % 7;
    const today = new Date();
    const dayFormat = 24 * 60 * 60 * 1000;

    let nextRun = new Date(lastRun.getTime() + ((frequency.numeral) * dayFormat));

    if (frequency.literal === SETTINGS_CHECK.FREQUENCY.MONTHLY) {
      while (nextRun < today) {
        nextRun = new Date(nextRun.getTime() + ((frequency.numeral) * dayFormat));
      }
      if (nextRun.getDay() !== Number(dayOfWeekSelected)) {
        const diff = (Number(dayOfWeekSelected) - nextRun.getDay()) % 7;
        nextRun = new Date(nextRun.getTime() + (diff * dayFormat));
      }
      setNextReport(nextRun.toDateString());
    } else if (Number(dayOfWeekSelected) === nextRun.getDay()) {
      while (nextRun < today) {
        nextRun = new Date(nextRun.getTime() + ((frequency.numeral) * dayFormat));
      }
      setNextReport(nextRun.toDateString());
    } else if (Number(dayOfWeekSelected) > nextRun.getDay()) {
      const diff = Number(dayOfWeekSelected) - nextRun.getDay();
      let nextDate = new Date(nextRun.getTime() + (diff * dayFormat));
      while (nextDate < today) {
        nextDate = new Date(nextDate.getTime() + ((frequency.numeral) * dayFormat));
      }
      setNextReport(nextDate.toDateString());
    } else {
      const diff = nextRun.getDay() - Number(dayOfWeekSelected);
      let nextDate = new Date(nextRun.getTime() - (diff * dayFormat));
      while (nextDate <= today) {
        nextDate = new Date(nextDate.getTime() + ((frequency.numeral) * dayFormat));
      }
      setNextReport(nextDate.toDateString());
    }
  };

  const setSelectedScanHandler = (value: SelectOption) => {
    setSelectScan(value);
    setNextReportHandle(value);
    setCurrentSettings({ ...currentSettings, dayToRun: Number(value.value) } as Settings);
  };

  const setDaysHandler = (e: React.ChangeEvent<HTMLInputElement>) => {
    if (e.target.value !== undefined && /^\d*$/.test(e.target.value)) {
      setDays(Number(e.target.value));
      const timesToWait = Number(e.target.value) * (dayFrequency.value === 'Days' ? 1 : 7);
      setCurrentSettings({ ...currentSettings, timesToWait } as Settings);
    }
  };

  const setDayFrequencyHandler = (value: SelectOption) => {
    setDayFrequency(value);
    const timesToWait = days * (value.value === 'Days' ? 1 : 7);
    setCurrentSettings({ ...currentSettings, timesToWait } as Settings);
  };

  const setSelectRelevantHandler = () => {
    setSelectUrlType('relevant');
    setSelectRelevant(true);
    setSelectManual(false);
    setSelectBoth(false);
    setCurrentSettings({ ...currentSettings, urlCheckType: 'relevant' } as Settings);
  };

  const setSelectManualHandler = () => {
    setSelectUrlType('manual');
    setSelectRelevant(false);
    setSelectManual(true);
    setSelectBoth(false);
    setCurrentSettings({ ...currentSettings, urlCheckType: 'manual' } as Settings);
  };

  const setSelectBothHandler = () => {
    setSelectUrlType('both');
    setSelectRelevant(false);
    setSelectManual(false);
    setSelectBoth(true);
    setCurrentSettings({ ...currentSettings, urlCheckType: 'both' } as Settings);
  };

  const setFintelCheckToggleHandler = (value: boolean) => {
    setFintelCheckToggle(!fintelCheckToggle);
    setOpenSettings(!openSettings);
    saveNewSettings(value);
  };

  const setSelectTimeframeDayHandler = (value: SelectOption) => {
    setSelectTimeframeDay(value);
    setCurrentSettings({ ...currentSettings, trackingDomainDayNumberSetting: Number(value.value) } as Settings);
  };

  const setSelectTimeframeFrequencyHandler = (value: SelectOption) => {
    setSelectTimeframeFrequency(value);
    setCurrentSettings({ ...currentSettings, trackingDomainDaySetting: value.value } as Settings);
  };

  const setTimeframeDayOptions = () => {
    switch (frequency.literal) {
      case SETTINGS_CHECK.FREQUENCY.WEEKLY:
        if (selectTimeframeFrequency.value === 'Days') {
          setSelectTimeframeDayOptions(NUMBERS_OPTIONS.DAYS.WEEKLY);
        }
        break;
      case SETTINGS_CHECK.FREQUENCY.BIWEEKLY:
        if (selectTimeframeFrequency.value === 'Days') {
          setSelectTimeframeDayOptions(NUMBERS_OPTIONS.DAYS.BIWEEKLY);
        } else {
          setSelectTimeframeDayOptions(NUMBERS_OPTIONS.WEEKS.BIWEEKLY);
        }
        break;
      case SETTINGS_CHECK.FREQUENCY.MONTHLY:
        if (selectTimeframeFrequency.value === 'Days') {
          setSelectTimeframeDayOptions(NUMBERS_OPTIONS.DAYS.MONTHLY);
        } else {
          setSelectTimeframeDayOptions(NUMBERS_OPTIONS.WEEKS.MONTHLY);
        }
        break;
      default:
    }
  };

  const setTimeframeFrequencyOptions = () => {
    switch (frequency.literal) {
      case SETTINGS_CHECK.FREQUENCY.WEEKLY:
        setSelectTimeframeFrequencyOptions(SETTINGS_CHECK.URL_PREFERENCES.OPTIONS.WEEKLY);
        break;
      case SETTINGS_CHECK.FREQUENCY.BIWEEKLY:
        setSelectTimeframeFrequencyOptions(SETTINGS_CHECK.URL_PREFERENCES.OPTIONS.BIWEEKLY);
        break;
      case SETTINGS_CHECK.FREQUENCY.MONTHLY:
        setSelectTimeframeFrequencyOptions(SETTINGS_CHECK.URL_PREFERENCES.OPTIONS.MONTHLY);
        break;
      default:
    }
  };

  const setIsOpenHandler = () => {
    setIsOpen(!isOpen);
  };

  const setPageData = () => {
    if (settings === undefined || settings === null) return;
    setFintelCheckToggle(settings?.enabled);
    setOpenSettings(settings?.enabled);
    switch (settings?.frequencySettings) {
      case SETTINGS_CHECK.FREQUENCY.WEEKLY:
        setSelectWeeklyButton(true);
        setSelectMonthlyButton(false);
        setSelectBiweeklyButton(false);
        setFrequency({ numeral: 7, literal: settings?.frequencySettings });
        break;
      case SETTINGS_CHECK.FREQUENCY.BIWEEKLY:
        setSelectBiweeklyButton(true);
        setSelectWeeklyButton(false);
        setSelectMonthlyButton(false);
        setFrequency({ numeral: 14, literal: settings?.frequencySettings });
        break;
      case SETTINGS_CHECK.FREQUENCY.MONTHLY:
        setSelectMonthlyButton(true);
        setSelectBiweeklyButton(false);
        setSelectWeeklyButton(false);
        setFrequency({ numeral: 31, literal: settings?.frequencySettings });
        break;
      default:
        setSelectWeeklyButton(false);
        setSelectBiweeklyButton(false);
        setSelectMonthlyButton(false);
    }
    SETTINGS_CHECK.SCAN.OPTIONS.map((day) => {
      if (settings?.dayToRun.toString() === day.value) {
        setSelectScan(day);
        setNextReportHandle(day);
      }
    });
    setDays(settings?.timesToWait && settings.timesToWait % 7 === 0 ? settings.timesToWait / 7 : settings.timesToWait);
    setDayFrequency(settings?.timesToWait && settings.timesToWait % 7 === 0 ? SETTINGS_CHECK.NON_APPLICABLE.OPTIONS[1] : SETTINGS_CHECK.NON_APPLICABLE.OPTIONS[0]);
    setPublishersSelected(settings?.publisherSettings);
    settings?.publisherSettings.map((publisher: any) => {
      if (publisher === 'All') {
        setSelectAllSettingsButton(true);
        setSelectListPublishersButton(false);
      } else {
        setSelectAllSettingsButton(false);
        setSelectListPublishersButton(true);
      }
    });
    switch (settings?.urlCheckType) {
      case 'relevant':
        setSelectRelevant(true);
        setSelectManual(false);
        setSelectBoth(false);
        break;
      case 'manual':
        setSelectRelevant(false);
        setSelectManual(true);
        setSelectBoth(false);
        break;
      default:
        setSelectRelevant(false);
        setSelectManual(false);
        setSelectBoth(true);
        break;
    }
    setSelectUrlType(settings?.urlCheckType);
    switch (settings?.checkAllDomains) {
      case (false):
        setSelectSpecific(true);
        setSelectAll(false);
        break;
      default:
        setSelectAll(true);
        setSelectSpecific(false);
    }
    setTimeframeFrequencyOptions();
    setTimeframeDayOptions();
    NUMBERS_OPTIONS.DAYS.MONTHLY.map((day) => {
      if (settings?.trackingDomainDayNumberSetting?.toString() === day.value) {
        setSelectTimeframeDay(day);
      }
    });
    SETTINGS_CHECK.URL_PREFERENCES.OPTIONS.MONTHLY.map((option) => {
      if (settings?.trackingDomainDaySetting?.toString() === option.value) {
        setSelectTimeframeFrequency(option);
      }
    });
    setPageDataLoad(true);
  };

  const isBlockerOpen = () => blocker.state === 'blocked' && blocker.location.pathname !== `${MERCHANT_PREFIX}${path.fintelCheckSettings.href}`;

  const onStayHandler = () => {
    blocker.reset?.();
  };

  const onLeaveHandler = () => {
    blocker.proceed?.();
  };

  useEffect(() => {
    if (!updateSettingsButton) {
      window.onbeforeunload = () => true;
    }

    return () => {
      window.onbeforeunload = null;
    };
  }, [updateSettingsButton]);

  useEffect(() => {
    setPageData();
  }, [settings, overview]);

  useEffect(() => {
    getSettingsHandler();
  }, []);

  useEffect(() => {
    setNextReportHandle(selectScan);
    setTimeframeFrequencyOptions();

    if (selectWeeklyButton && Number(selectTimeframeDay.value) > NUMBERS_OPTIONS.DAYS.WEEKLY.length) {
      setSelectTimeframeDayHandler(DEFAULT_DAY_OPTION);
    }
    if (selectBiweeklyButton && Number(selectTimeframeDay.value) > NUMBERS_OPTIONS.DAYS.BIWEEKLY.length) {
      setSelectTimeframeDayHandler(DEFAULT_DAY_OPTION);
    }
    if (selectMonthlyButton && Number(selectTimeframeDay.value) > NUMBERS_OPTIONS.DAYS.MONTHLY.length) {
      setSelectTimeframeDayHandler(DEFAULT_DAY_OPTION);
    }
  }, [selectWeeklyButton, selectBiweeklyButton, selectMonthlyButton]);

  useEffect(() => {
    setTimeframeDayOptions();

    if (selectWeeklyButton && (selectTimeframeFrequency.value === 'Weeks' || currentSettings?.trackingDomainDaySetting === 'Weeks')) {
      setSelectTimeframeDayHandler(DEFAULT_DAY_OPTION);
      setSelectTimeframeFrequencyHandler({ label: 'Days', value: 'Days' });
    }
    if (selectTimeframeFrequency.value === 'Weeks') {
      switch (frequency.literal) {
        case SETTINGS_CHECK.FREQUENCY.BIWEEKLY:
          if (Number(selectTimeframeDay.value) > NUMBERS_OPTIONS.WEEKS.BIWEEKLY.length) {
            setSelectTimeframeDayHandler(DEFAULT_DAY_OPTION);
          }
          break;
        case SETTINGS_CHECK.FREQUENCY.MONTHLY:
          if (Number(selectTimeframeDay.value) > NUMBERS_OPTIONS.WEEKS.MONTHLY.length) {
            setSelectTimeframeDayHandler(DEFAULT_DAY_OPTION);
          }
          break;
        default:
          setSelectTimeframeDayHandler(DEFAULT_DAY_OPTION);
      }
    }
  }, [selectWeeklyButton, selectBiweeklyButton, selectMonthlyButton, selectTimeframeFrequency]);

  useEffect(() => {
    checkSettingsChangedHandler();
  }, [currentSettings]);

  useEffect(() => {
    if (blocker.state === 'blocked' && updateSettingsButton) {
      blocker.reset();
    }
  }, [blocker, updateSettingsButton]);

  return {
    hookSelectWeeklyButton: selectWeeklyButton,
    hookSetSelectWeeklyButton: setSelectWeeklyButtonHandler,

    hookSelectBiweeklyButton: selectBiweeklyButton,
    hookSetSelectBiweeklyButton: setSelectBiweeklyButtonHandler,

    hookSelectMonthlyButton: selectMonthlyButton,
    hookSetSelectMonthlyButton: setSelectMonthlyButtonHandler,

    hookSelectAllSettingsButton: selectAllSettingsButton,
    hookSetSelectAllSettingsButton: setSelectAllSettingsButtonHandler,

    hookSelectListPublishersButton: selectListPublishersButton,
    hookSetSelectListPublishersButton: setSelectListPublishersButtonHandler,

    hookPublishersSelected: publishersSelected.length,

    hookSelectAllDomain: selectAll,
    hookSetSelectAllDomain: setSelectAllDomainHandler,

    hookSelectTimeFrame: selectSpecific,
    hookSetSelectTimeFrame: setSelectTimeFrameDomainHandler,

    hookUpdateSettingsButton: updateSettingsButton,

    hookFintelCheckToggle: fintelCheckToggle,
    hookSetFintelCheckToggle: setFintelCheckToggleHandler,

    hookOpenSettings: openSettings,

    hookSelectedScan: selectScan,
    hookSetSelectedScan: setSelectedScanHandler,

    hookNextReport: nextReport,

    hookDays: days,
    hookSetDays: setDaysHandler,

    hookDayFrequency: dayFrequency,
    hookSetDayFrequency: setDayFrequencyHandler,

    hookSelectRelevant: selectRelevant,
    hookSetSelectRelevant: setSelectRelevantHandler,

    hookSelectManual: selectManual,
    hookSetSelectManual: setSelectManualHandler,

    hookSelectBoth: selectBoth,
    hookSetSelectBoth: setSelectBothHandler,

    hookTimeframeDay: selectTimeframeDay,
    hookSetTimeframeDay: setSelectTimeframeDayHandler,

    hookTimeframeFrequency: selectTimeframeFrequency,
    hookSetTimeframeFrequency: setSelectTimeframeFrequencyHandler,

    hookTimeframeDayOptions: selectTimeframeDayOptions,
    hookSetTimeframeDayOptions: setSelectTimeframeDayOptions,

    hookTimeframeFrequencyOptions: selectTimeframeFrequencyOptions,
    hookSetTimeframeFrequencyOptions: setSelectTimeframeFrequencyOptions,

    hookIsModalOpen: isOpen,
    hookSetModalOpen: setIsOpenHandler,
    hookChangeDate: changesDate,
    hookSaveChanges: saveNewSettings,
    hookNavigate: navigate,

    hookLoading: overviewLoading || settingsLoading,
    hookUpdateLoading: updateLoading,
    hookPageDataLoad: pageDataLoad,

    hookIsBlockerOpen: isBlockerOpen(),
    hookOnStay: onStayHandler,
    hookOnLeave: onLeaveHandler,

    hookIsReadOnlyList: Permission.readOnlyPermissionsList(permissionsCodeList),
  };
};
