export const EDIT_COMMISSIONS = {
  PAGE_TITLE: 'Edit',
  BUTTONS: {
    CANCEL: 'Cancel',
    SAVE: 'Save',
    UPDATE: 'Update',
  },
  COMMISSION: 'Commission',
  DETAILS: {
    SECTION_TITLE: 'Commission Structure',
    INPUT: {
      COMMISSION_ID: 'Commission Id',
      PRODUCT_NAME: 'Product Name',
      END_DATE: 'End Date',
      END_DATE_PLACEHOLDER: 'Select End Date',
      COMMISSION_DESC: 'Commission Description',
      COMMISSION_BASE: 'Commission Base',
      FLAT_COMMISSION: 'Flat % Commission',
    },
  },
  PUBLISHERS: {
    SEARCH_PLACEHOLDER: 'Search by Publisher or Publisher Group Name/ID',
    SORT_BY: {
      LABEL: 'Sort by',
    },
    ORDER: {
      LABEL: '',
      NAME: 'order',
    },
    ORDER_DATE: {
      LABEL: '',
      NAME: 'order date',
    },
    FOOTER: {
      TEXT: 'Select publishers/groups above and update their commission date ranges below.',
      START_DATE: 'Select Start Date',
      END_DATE: 'Select End Date',
      WARNING: 'Changes have not been saved. Please click Save button.',
    },
    HEADING: {
      ID: 'ID',
      NAME_TYPE: 'Name/Type',
      START_END: 'Start/End Date',
    },
  },
};

export const DESC_ASC_OPTIONS = [
  {
    label: 'Ascending',
    value: 'asc',
  },
  {
    label: 'Descending',
    value: 'desc',
  },
];

export const SORT_DATE = [
  {
    label: 'ID',
    value: 'idNum',
  },
  {
    label: 'Start Date',
    value: 'startDate',
  },
  {
    label: 'End Date',
    value: 'endDate',
  },
  {
    label: 'Selected',
    value: 'selected',
  },
];
