import styled from 'styled-components';
import { Checkbox } from '../../../../components';
import { colors, fonts } from '../../../../styles/theme';

export const WrapperStyled = styled.div`
  display: grid;
  grid-template-columns: 23% calc(100% - 23%);
  height: 100vh;
`;

export const LeftStyled = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  background-color: ${colors.color1};
  padding-top: 9rem;
`;

export const RightStyled = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  position: relative;
`;

export const RightContentStyled = styled.div`
  width: 45%;
  display: flex;
  flex-flow: column nowrap;
  color: ${colors.color1};
  padding-top: 7rem;
  padding-bottom: 5rem;

  h1 {
    ${fonts.gotham.H36M}
  }

  h2 {
    margin-top: 3rem;
    margin-bottom: 3rem;
    ${fonts.gotham.H26M}
  }

  span {
    ${fonts.lato.H14}
    line-height: 1.5;
  }

  a {
    padding-left: 4px;
    ${fonts.lato.H14}
    line-height: 1.5;
  }
`;

export const FormStyled = styled.div`
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  column-gap: 5%;
  margin-top: 1rem;
`;

export const CheckboxStyled = styled(Checkbox)`
  margin-bottom: 2rem;
`;

export const TitleStyled = styled.p`
  color: ${colors.color9};
  ${fonts.gotham.H26M}
  padding-bottom: 2rem;
  text-align: center;
`;

export const DescriptionStyled = styled.p`
  color: ${colors.color1};
  ${fonts.lato.H16R}
  line-height: 1.5;

  a {
    color: ${colors.color2};
    ${fonts.lato.H16R}
    text-decoration: none;
  }
`;

export const FigureStyled = styled.figure`
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  margin-bottom: 3rem;
`;

export const ShowConfirmStyled = styled.div`
  width: 388px;
  max-width: 388px;
  display: flex;
  flex-flow: column nowrap;
  color: ${colors.color1};
  padding-top: 7rem;
  padding-bottom: 5rem;

  h1 {
    ${fonts.gotham.H36M}
  }

  h2 {
    margin-top: 3rem;
    margin-bottom: 3rem;
    ${fonts.gotham.H26M}
  }

  span {
    ${fonts.lato.H14}
    line-height: 1.5;
  }
`;

export const TermsAndConditionsStyled = styled.div`
  display: flex;
  flex-direction: row;
`;

export const ImageStyled = styled.img`
  width: 100%;
`;
