import { gql } from '@apollo/client';

export const GET_PRODUCT = gql`
query getProduct($productId: ID!) {
  product(id: $productId) {
    id
    program {
      id
    }
    customizedProductId
    name
    description
    status
    productUrl
    productImageUrl
    landingPage
    thirdPartyUrl
    createdAt
    updatedAt
    commissions {
      id
      startDate
      endDate
      description
      commissionType
      commissionStructure {
        cpaFlat {
          minimumSaleAmount
          commissionFee
          commissionBase
        }
        revShareFlat {
          commissionFee
          commissionBase
        }
        cpaTiered {
          commissionBase
          cutoffAmount
          tiers {
            upTo
            commissionAmount
          }
        }
        revShareTiered {
          cutoffAmount
          tiers {
            upTo
            commissionAmount
          }
        }
        referral {
          levels
        }
        bonusFlat {
          minimumSaleAmount
          commissionFee
          commissionBase
        }
        bonusTiered {
          commissionBase
          period
          calculationType
          tiers {
            upTo
            commissionAmount
          }
        }
      }
      commissionTarget {
        publisherGroupTargets {
          publisherGroupId
          startDate
          endDate
        }
        publisherTargets {
          publisherId
          startDate
          endDate
        }
      }
      products {
        id
        name
      }
      merchant {
        id
      }
      publisherGroups {
        id
        name
      }
      publishers {
        id
        companyName
      }
    }
    targetDemographics {
      gender
      geographicalCountry
      geographicalStates
      age
      commissionVisibility
      minimumIncome
      minimumHouseHoldIncome
      minimiumCreditScore
    }
    productCategory
    subCategories
    productFeedSection {
      validUntil
      cardNetwork
      userTypes
      annualFee
      monthlyFee
      signupBonus
      supplementaryCardFee
      balanceTransferIntroRate
      balanceTransferRegular
      interestRate
      rewardsEarningRate
      annualInterestRate
      balanceTransferIntroFee
      balanceTransferFee
      cashAdvanceFee
      foreignCurrencyConversion
      dishonouredPaymentFee
      gracePeriod
      marketingItems
      legalReference
      promoInterestRate
      transactionFeeATM
      transactionFeeETR
      transactionFeeInternal
      freeTransactionVolume
      closingBalanceToWaiveMonthlyFee
      noMinimumBalance
      term
      redeemable
      insurer
      minimumAccountBalance
      managementFee
      tradeCost
      numberOfFreeMonthlyTrade
      mer
      coverageAmount
      guaranteedPremiums
      exclusionItems
      productUsageGuidelines
      productWelcomeOffers
      minimumOpenDeposit
      interestPaid
      interestPaidDays
      interestCalculated
      interestCalculatedDays
      bankingProductInsurance
      apyTiers
      apyRate
      mortgageType
      openMortgageType
      convertibleMortgageType
      preapprovalMortgageType
      mortgageRateType
      mortgageTypeDiscountedRate
      mortgageAprRate
      mortgageTermYear
      monthlyFeesDetails
      balanceTransferRegularDetails
      interestRateDetails
      AnnualInterestRateDetails
      bankingMinimumBalanceRate
    }
  }
}
`;
