import styled from 'styled-components';
import { toRem } from '../../../utils';
import { Button } from '../../../components';
import { colors, fonts } from '../../../styles/theme';

export const HeaderStyled = styled.div`
  display: flex;
  justify-content: space-between;
`;

export const ButtonStyled = styled(Button)``;

export const ButtonWrapperStyled = styled.div`
  display: flex;
  justify-content: end;

  ${ButtonStyled} {
    margin-left: 1rem;
  }
`;

export const MerchantStyled = styled.div`
  display: flex;
  justify-content: start;
  margin: 2rem 0;
`;

export const MerchantImgStyled = styled.img`
  margin-right: 2rem;
  max-width: ${toRem(80)};
  height: auto;
  object-fit: contain;

`;

export const MerchantNameStyled = styled.p`
  color: ${colors.color9};
  ${fonts.lato.H22B}
  margin-bottom: 1rem;
`;

export const MerchantLinkStyled = styled.a`
  ${fonts.lato.H16}
  color: ${colors.color12};

  &:hover {
    color: ${colors.color2};
  }
`;

export const BoldText = styled.strong`
  font-weight: bolder;
`;

export const ModalDesc = styled.p`
  margin: ${toRem(24)} 0;
`;

export const InfoBox = styled.div`
  display: flex;
  gap: ${toRem(12)};
  margin-bottom: ${toRem(24)};
  align-items: center;
  ${fonts.lato.H14}
`;

export const ModalStyled = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  width: 38rem;
  min-height: 13rem;
  padding: ${toRem(12)};
  line-height: 1.5rem;
`;
