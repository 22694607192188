import styled from 'styled-components';
import { Button } from '../../../components';
import { toRem } from '../../../utils';

export const ButtonStyled = styled(Button)`
  margin-left: 1rem;

  svg {
    margin-right: 5px;
  }
`;

export const HeaderStyled = styled.div`
  display: flex;
  justify-content: space-between;
  margin-bottom: 2rem;
`;

export const ButtonWrapperStyled = styled.div`
  display: flex;
  justify-content: end;
  margin-bottom: ${toRem(32)};
`;
