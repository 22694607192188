import { useContext } from 'react';
import { context } from '../Context';

export const useFintelCheckContext = () => {
  const { state, dispatcherHandler } = useContext(context);

  return {
    contextState: state,
    contextDispatchHandler: dispatcherHandler,
  };
};
