import React from 'react';

import {
  AppWrapper, ErrorBanner, PageTitle, Pagination, Select, Table,
} from '../../../components';
import { path } from '../../../utils';
import { columns } from './contracts';
import { useActiveCommissions } from './hooks';
import { PAGE_DESCRIPTION } from './enums';
import { DefaultPropsType } from '../../../types';

import * as Styled from './styles';

type ActiveCommissionsProps = DefaultPropsType;

const ActiveCommissions = ({ permissionsCodeList = [] }: ActiveCommissionsProps) => {
  const hook = useActiveCommissions();

  return (
    <AppWrapper permissionsCodeList={permissionsCodeList}>
      <ErrorBanner
        message={hook.hookErrorMessage}
        isOpen={!!hook.hookErrorMessage}
      />
      <PageTitle isLoading={hook.hookPageLoading}>
        Active&nbsp;
        {path.activeComissions.name}
      </PageTitle>
      <Styled.DescriptionStyled>{PAGE_DESCRIPTION}</Styled.DescriptionStyled>
      <Styled.FilterWrapperStyled>
        <Select
          width="20%"
          onChange={hook.hookSetSelectedMerchant}
          options={hook.hookMerchantList}
          selected={hook.hookSelectedMerchant}
          isLoading={hook.hookPageLoading}
          isDisabled={hook.hookPageLoading}
        />
      </Styled.FilterWrapperStyled>
      <Table
        columns={columns}
        data={hook.hookTableData}
        isLoading={hook.hookPageLoading}
        onSort={hook.hookSortTableHandler}
        sortColumn={hook.hookSortColumn}
      />
      <Pagination
        total={hook.hookTotalPages}
        currentPage={hook.hookCurrentPage}
        onPageChange={hook.hookSetCurrentPage}
      />
    </AppWrapper>
  );
};

export default ActiveCommissions;
