import React, { useEffect } from 'react';
import { useMutation } from '@apollo/client';
import jwtDecode from 'jwt-decode';
import { useNavigate } from 'react-router-dom';

import { LoadingPage } from '../../components';
import environment from '../../config/environment';
import { useCreateSession } from '../../hooks';
import { CREATE_ENCRYPTION } from './graphql/mutations';
import { USER_TYPES_ID } from '../../utils';
import { Permission, PermissionType } from '../../entities';

type UserInfoType = {
  userTypesId: number
  verified: boolean
  merchantsId: number
  publishersId: number
  accStatus: string
  userStatus: string
  userTypeIdEncrypted: string
  dayCookieEncrypted: string
}

const Callback = () => {
  const crypt = useCreateSession();
  const history = useNavigate();
  const [trigger] = useMutation(CREATE_ENCRYPTION, {
    variables: {
      input: {
        crypt,
      },
    },
    fetchPolicy: 'no-cache',
  });

  const setUserCookieHandler = async () => {
    const { data } = await trigger();
    const now = new Date();
    const expiration = new Date(new Date(now.getTime() + 15 * 60 * 1000));

    if (data.createEncryption.hash) {
      document.cookie = `${environment.app.cookieName}=${data.createEncryption.hash}; expires=${expiration.toUTCString()}; path=/; SameSite=none;secure`;

      localStorage.setItem(environment.app.permissionStorageName, data.createEncryption.permissionsHash);

      const userInfo = jwtDecode<UserInfoType>(data.createEncryption.hash);

      // Legacy cookies to be used with Knowledge Base and Support App
      document.cookie = `FA_UT=${userInfo.userTypeIdEncrypted}; expires=${expiration.toUTCString()}; domain=${environment.app.domain}`;
      document.cookie = `24-hour-cookie=${userInfo.dayCookieEncrypted}; expires=${new Date(new Date(now.getTime() + 1440 * 60000))}; domain=${environment.app.domain}`;

      const permissionsDecoded = jwtDecode<{ permissions: PermissionType[] }>(data.createEncryption.permissionsHash);
      Permission.setPermissions(permissionsDecoded.permissions);

      if (!userInfo.verified) {
        history('/unverified');
      } else if (userInfo.userStatus !== 'Active' || ([2, 3].includes(userInfo.userTypesId) && ['Closed', 'Pending'].includes(userInfo.accStatus))) {
        history('/invalid-user');
      } else if (userInfo.userTypesId === USER_TYPES_ID.ADMIN) {
        history('/');
      } else if (userInfo.userTypesId === USER_TYPES_ID.MERCHANT) {
        history('/merchant/');
      } else if (userInfo.userTypesId === USER_TYPES_ID.PUBLISHER) {
        history('/publisher/');
      }
    }
  };

  useEffect(() => {
    setUserCookieHandler();
  }, []);

  return (
    <LoadingPage />
  );
};

export default Callback;
