import styled from 'styled-components';
import { colors, fonts } from '../../../styles/theme';
import { toRem } from '../../../utils';

type InputContainerProps = {
  readonly error: any;
  readonly disabled: boolean;
}

type WrapperProps = {
  readonly width: string;
}

export const WrapperStyled = styled.div<WrapperProps>`
  display: flex;
  flex-direction: column;
  background: ${colors.color4};
  width: ${({ width }) => (width)};
`;

export const LabelStyled = styled.span`
  display: flex;
  width: 100%;
  justify-content: flex-start;
  font-size: ${toRem(11)};
  text-transform: uppercase;
  margin-bottom: ${toRem(8)};
  color: ${colors.color13};
  ${fonts.gotham.H12M}
`;

export const RequiredStyled = styled.span`
  color: ${colors.color2};
  margin-left: ${toRem(4)};
`;

export const InputStyled = styled.span`
  width: 100%;
  padding: ${toRem(10)};
  text-align: left;
  ${fonts.lato.H14}
`;

export const InputContainerStyled = styled.button<InputContainerProps>`
  display: flex;
  background: ${({ disabled }) => (disabled ? colors.color11 : colors.color4)};
  color: ${({ disabled }) => (disabled ? colors.color7 : colors.color1)};
  border: 1px solid ${({ error }) => (error ? colors.color14 : colors.color3)};
  align-items: center;
  width: 100%;
  border-radius: 3px;
  padding: ${toRem(2)};
  height: ${toRem(40)};
  cursor: pointer;

  &:hover {
    border: 1px solid ${({ error }) => (error ? colors.color14 : colors.color1)};

    svg {
      color: ${({ disabled }) => (disabled ? colors.color11 : colors.color1)};
    }
  }

  svg {
    color: ${({ disabled }) => (disabled ? colors.color7 : colors.color13)};
    margin-left: 0.5rem;
  }
`;

export const PlaceholderStyled = styled.span`
  color: ${colors.color7};
`;

export const ErrorStyled = styled.span`
  margin-top: 0.3rem;
  color: ${colors.color14};
  ${fonts.lato.H14}
`;
