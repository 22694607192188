import React from 'react';
import { useNavigate } from 'react-router-dom';

import {
  AppWrapper, Button, InputPhone, InputText, PageTitle, Select, ErrorBanner, // Checkbox,
} from '../../../components';
import { path } from '../../../utils';
import * as Styled from './styles';
import { useUserProfileManagement } from './hooks/index';
import { MerchantAccess } from '../AddNewUser/components/MerchantAccess';
import { USER_PROFILE_MANAGEMENT_PAGE } from './enums';
import { DefaultPropsType } from '../../../types';

type UserProfileManagementPropsType = DefaultPropsType & {
  isSettings: boolean
}

const UserProfileManagement = ({ permissionsCodeList = [], isSettings }: UserProfileManagementPropsType) => {
  const navigate = useNavigate();
  const { hookIsReadOnlyList, ...hook } = useUserProfileManagement(permissionsCodeList);
  const [isReadOnly] = hookIsReadOnlyList;

  return (
    <AppWrapper permissionsCodeList={permissionsCodeList}>
      <ErrorBanner
        message={hook.hookErrorMessage}
        isOpen={!!hook.hookErrorMessage}
      />
      <Styled.TopContainerStyled>
        <PageTitle
          isLoading={hook.hookIsLoading}
          loadingText={hook.hookLoadingMessage}
        >
          {isSettings ? USER_PROFILE_MANAGEMENT_PAGE.SETTINGS_TITLE : USER_PROFILE_MANAGEMENT_PAGE.PAGE_TITLE}
        </PageTitle>
        <Styled.TopContainerButtonsStyled>
          <Button
            onClick={() => navigate(path.userManagement.href)}
            theme="secondary"
          >
            {USER_PROFILE_MANAGEMENT_PAGE.CANCEL_BUTTON_TEXT}
          </Button>
          <Button
            onClick={() => hook.hookUpdateAdminUserHandler()}
            disabled={!!(hook.hookIsLoading || hook.hookErrorMessage || isReadOnly)}
          >
            {USER_PROFILE_MANAGEMENT_PAGE.SAVE_BUTTON_TEXT}
          </Button>
        </Styled.TopContainerButtonsStyled>
      </Styled.TopContainerStyled>

      <Styled.DetailsStyled>
        <InputText
          label={USER_PROFILE_MANAGEMENT_PAGE.INPUT_LABELS.FIRST_NAME}
          name="firstName"
          type="text"
          value={hook.hookUserFirstName}
          required
          onChange={hook.hookSetUserFirstNameHandler}
          error={hook.hookUpdateUserErrors.firstName}
          onBlur={hook.hookHandleValidation}
          disabled={!!(hook.hookIsLoading || hook.hookErrorMessage || isReadOnly)}
        />
        <InputText
          label={USER_PROFILE_MANAGEMENT_PAGE.INPUT_LABELS.LAST_NAME}
          name="lastName"
          type="text"
          value={hook.hookUserLastName}
          required
          onChange={hook.hookSetUserLastNameHandler}
          error={hook.hookUpdateUserErrors.lastName}
          onBlur={hook.hookHandleValidation}
          disabled={!!(hook.hookIsLoading || hook.hookErrorMessage || isReadOnly)}
        />
        <Select
          label={USER_PROFILE_MANAGEMENT_PAGE.INPUT_LABELS.PREFERRED_LANGUAGE}
          name="preferredLanguage"
          selected={hook.hookUserLanguage}
          onChange={hook.hookSetUserLanguageHandler}
          options={hook.hookUserLanguageOptionsList}
          isDisabled={!!(hook.hookIsLoading || hook.hookErrorMessage || isReadOnly)}
        />
        <InputText
          label={USER_PROFILE_MANAGEMENT_PAGE.INPUT_LABELS.EMAIL_ADDRESS}
          name="email"
          type="email"
          value={hook.hookUserEmail}
          required
          disabled
        />
        <InputPhone
          label={USER_PROFILE_MANAGEMENT_PAGE.INPUT_LABELS.PHONE_NUMBER}
          type="text"
          required
          value={hook.hookUserPhoneNumber}
          onChange={hook.hookSetUserPhoneNumberHandler}
          error={hook.hookUpdateUserErrors.phone}
          onBlur={hook.hookHandleValidation}
          disabled={!!(hook.hookIsLoading || hook.hookErrorMessage || isReadOnly)}
        />
        <InputText
          label={USER_PROFILE_MANAGEMENT_PAGE.INPUT_LABELS.PASSWORD}
          name="password"
          type="password"
          value="DISPLAY_VALUE"
          forceResetButtonHandler={hook.hookForceResetPasswordHandler}
          disabled={!!(hook.hookIsLoading || hook.hookErrorMessage || isReadOnly)}
        />
        <Select
          label={USER_PROFILE_MANAGEMENT_PAGE.INPUT_LABELS.SECURITY_ROLE}
          name="securityRole"
          selectedMulti={hook.hookUserSecurityRole}
          onChange={hook.hookSetUserSecurityRoleHandler}
          isMulti
          options={hook.hookUserSecurityRoleOptionsList}
          isDisabled={!!(hook.hookIsLoading || hook.hookErrorMessage || isReadOnly || !isSettings)}
          error={hook.hookUpdateUserErrors.securityRole}
          onBlur={hook.hookHandleValidation}
          required
        />
        <InputText
          label={USER_PROFILE_MANAGEMENT_PAGE.INPUT_LABELS.POSITION}
          name="position"
          type="text"
          value={hook.hookUserPosition}
          onChange={hook.hookSetUserPositionHandler}
          disabled={!!(hook.hookIsLoading || hook.hookErrorMessage || isReadOnly)}
        />
      </Styled.DetailsStyled>

      {isSettings && (
        <MerchantAccess
          allMerchantsAccess={hook.hookAllMerchantsAccess}
          handleSetAllMerchantsAccess={hook.hookSetAllMerchantAccessHandler}
          merchantList={hook.hookMerchantList}
          checkedMerchants={hook.hookCheckedMerchants}
          selectedMerchants={hook.hookSelectedMerchants}
          handleSetSelectedMerchants={hook.hookSetSelectedMerchantsHandler}
          handleSetCheckedMerchants={hook.hookSetCheckedMerchantHandler}
          isReadOnly={isReadOnly}
        />
      )}
    </AppWrapper>
  );
};

export default UserProfileManagement;
