import Auth0 from 'auth0-js';
import environment from '../config/environment';

type Registration = {
  Id: string
  email: string
  emailVerified: boolean
  userMetadata: {
    userTypesId: string
    userSubTypesId: string
  }
}

const authentication = () => new Auth0.WebAuth({
  clientID: environment.auth0.clientId,
  domain: environment.auth0.customDomain,
  redirectUri: environment.auth0.redirectUri,
  audience: environment.auth0.audience,
  responseType: 'token id_token',
  scope: 'read:current_user update:current_user_metadata app_metadata',
});

export const login = async (username: string, password: string): Promise<void|string> => new Promise((resolve, reject) => {
  const auth = authentication();

  auth.login({
    realm: 'Username-Password-Authentication',
    username,
    password,
  }, (err) => {
    if (err) {
      reject(err.description);
    }

    resolve();
  });
});

export const registration = (user: any): Promise<Registration> => new Promise((resolve: any, reject: any) => {
  const auth = authentication();

  const connection = 'Username-Password-Authentication';

  const options = {
    connection,
    email: user.email,
    password: user.password,
    userMetadata: {
      userTypesId: user.userTypesId,
      userSubTypesId: user.userSubTypesId,
      userType: user.userType,
      firstUser: user.firstUser,
    },
  };

  auth.signup(options, (err, res) => {
    if (err) reject(err.description);

    auth.changePassword({
      connection,
      email: user.email,
    }, (err2) => {
      if (err2) reject(err2.description);

      resolve(res);
    });
  });
});

export const changePassword = (email: string) => new Promise((resolve: any, reject: any) => {
  const auth = authentication();
  const connection = 'Username-Password-Authentication';

  auth.changePassword(
    {
      connection,
      email,
    },
    (err) => {
      if (err) reject(new Error(err.description));
      resolve();
    },
  );
});
