import React from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCode } from '@fortawesome/free-solid-svg-icons';
import Joyride from 'react-joyride';
import * as Styled from './styles';
import { usePublisherJoyride } from './hooks';
import { colors } from '../../../../../styles/theme';

const steps = [
  {
    target: 'body',
    content: (
      <Styled.ContentStyled>
        Welcome to Fintel Connect, let us show you around!
      </Styled.ContentStyled>
    ),
    disableBeacon: true,
    placement: 'center' as const,
  },
  {
    target: '#Account',
    content: (
      <Styled.ContentStyled>
        Keep your team details, company details and payment information up to date here.
      </Styled.ContentStyled>
    ),
    disableBeacon: true,
    placement: 'right' as const,
  },
  {
    target: '#TrackingDetails',
    content: (
      <>
        <Styled.ContentStyled>
          Have multiple websites, channels or tactics? Set up separate tracking profiles for
          greater reporting and transparency.
        </Styled.ContentStyled>
        <Styled.SubContentStyled>
          Merchants look at this information when approving you into their program, so make it
          as descriptive as possible!
        </Styled.SubContentStyled>
      </>
    ),
    disableBeacon: true,
    placement: 'right' as const,
  },
  {
    target: '#Merchants',
    content: (
      <Styled.ContentStyled>
        All of Fintel Connect’s amazing brands live here. Click through to learn more and apply
        to promote. Your application will be reviewed by each brand.
      </Styled.ContentStyled>
    ),
    disableBeacon: true,
    placement: 'right' as const,
  },
  {
    target: '#Ads',
    content: (
      <>
        <div style={{ textAlign: 'left' }}>
          Ready to promote certain brands? Here you can find tracking links and creatives for
          all the products that you’ve been approved to promote.
        </div>
        <Styled.SubContentStyled>
          <FontAwesomeIcon
            icon={faCode}
          />
          {' '}
          Click this symbol to get your
          tracking code to place on your site. Use the Keyword feature for greater transparency
          and advanced tracking.
        </Styled.SubContentStyled>
      </>
    ),
    disableBeacon: true,
    placement: 'right' as const,
  },
  {
    target: '#Reports',
    content: (
      <Styled.ContentStyled>
        Your campaign performance lives here. Get the metrics you need to optimize your efforts.
        Prefer the data via API? Go to your Settings to find this here.
      </Styled.ContentStyled>
    ),
    disableBeacon: true,
    placement: 'right' as const,
  },
  {
    target: '#Dashboard',
    content: (
      <Styled.ContentStyled>
        Your dashboard gives you a quick snapshot of all you need to know about your brand
        partnerships.
      </Styled.ContentStyled>
    ),
    disableBeacon: true,
    placement: 'right' as const,
  },
  {
    target: 'body',
    content: (
      <Styled.ContentStyled>
        Need more help? Our team is here to support, find us
        {' '}
        <a href="mailto:publishersupport@fintelconnect.com">here</a>
        .
      </Styled.ContentStyled>
    ),
    disableBeacon: true,
    placement: 'center' as const,
  },
];

export const PublisherJoyride = () => {
  const hook = usePublisherJoyride();
  return (
    <Joyride
      steps={steps}
      continuous
      run={hook.hookRunTutorial}
      showProgress
      showSkipButton
      callback={(props) => { hook.hookTutorialStep(props); }}
      styles={{
        options: {
          primaryColor: colors.color2,
        },
      }}
    />
  );
};
