import { useLazyQuery, useMutation } from '@apollo/client';
import {
  useEffect, useMemo, useRef, useState,
} from 'react';
import { ReportListItemType } from '../../../../components/LoadReportProfile/hooks';
import { useToast, useUserInfo } from '../../../../hooks';
import {
  RECORDS_PER_PAGE_OPTIONS, USER_TYPES_ID, DEFAULT_TRANSACTION_STATUSES, toUTCHours, csvGenerator, RangeOptions, rangeFormat, RangeFormat,
} from '../../../../utils';
import { immutableProductCategories } from '../../PerfomanceReport/enums';
import {
  GET_MERCHANTS_FOR_REPORTS, GetMerchantsForReportsInputType, GetMerchantsForReportsOutputType,
  LIST_MERCHANTS, ListMerchantInputType, ListMerchantOutputType,
  SHOW_MERCHANT_INFO, ShowMerchantInputType, ShowMerchantOutputType,
  ShowMerchantTrackingsType, ShowMerchantCompaniesType, ShowMerchantProductsType, ShowMerchantMembershipsType, ShowMerchantCampaignsType, ShowMerchantPublisherGroupsType, ShowMerchantAdsType,
} from '../../PerfomanceReport/graphql/queries';
import { MerchantOptionsType } from '../../PerfomanceReport/types';
import { transactionMasterColumns } from '../contracts/columns';
import {
  DEFAULT_TRANSACTION_MASTER_REPORT_COLUMNS, defaultOption, defaultTableTotals, TRANSACTION_MASTER_REPORT_CUSTOMIZE_COLUMNS,
} from '../enums';
import {
  TRANSACTION_MASTER_REPORT,
  TRANSACTION_MASTER_CSV_REPORT, TransactionMasterCSVInputType, TransactionMasterCSVOutputType,
  TRANSACTION_MASTER_SAVED_REPORTS, TMSavedReportsInput, TMSavedReportsOutput, TMSavedReport,
} from '../graphql/queries';
import { SAVE_TRANSACTION_MASTER_REPORT, STMRInputType, STMROutputType } from '../graphql/mutations';
import {
  TMTableRowFlat, TMTotalsProcessed, TransactionMasterInputType, TransactionMasterOutputType,
} from '../types';
import {
  formatDataTableCSV, processDataToCSVString, processTableData, processTableTotals,
} from '../utils';
import { PERMISSION_OPTIONS } from '../../PerfomanceReport/Modals/SaveReportProfile/enums';
import { LIST_TRACKING_PROFILES_BY_PUBLISHER } from '../../../../components/AdGenerateCodeModal/graphql/queries';

const DefaultTableColumns = (() => {
  const columns = transactionMasterColumns(defaultTableTotals);
  const newColumns = columns.filter((column) => DEFAULT_TRANSACTION_MASTER_REPORT_COLUMNS.some((option) => column.dataField === option.value));
  return newColumns;
})();

const formatTrackProfile = (trackings: ShowMerchantTrackingsType[]) => {
  const formattedTrackings = trackings.map((tracking: ShowMerchantTrackingsType) => ({
    label: `${tracking.id} - ${tracking.profileName}`,
    value: tracking.id,
  }));
  return [defaultOption.trackingProfile, ...formattedTrackings];
};

export const useTransactionMasterReport = () => {
  // Global Values
  const { hookShowToast } = useToast();
  const { hookWhoAmI, hookUserInfo } = useUserInfo();
  const today = new Date();
  const defaultDateRange = rangeFormat('last30Days');
  const originalMerchantOption: SelectOption = useMemo(() => {
    if (hookWhoAmI?.isActingAsUserTypeId === USER_TYPES_ID.MERCHANT || hookUserInfo.userTypesId === USER_TYPES_ID.MERCHANT) {
      const merchantId = hookWhoAmI.companyId?.toString() || '0';
      const merchantName = hookWhoAmI.companyName || '';
      return { label: `${merchantId} - ${merchantName}`, value: merchantId };
    }
    return defaultOption.merchant;
  }, [window.location.href]);
  const originalPublisherOption: SelectOption = useMemo(() => {
    if (hookWhoAmI?.isActingAsUserTypeId === USER_TYPES_ID.PUBLISHER || hookUserInfo.userTypesId === USER_TYPES_ID.PUBLISHER) {
      const publisherId = hookWhoAmI.companyId?.toString() || '0';
      const publisherName = hookWhoAmI.companyName || '';
      return { label: `${publisherId} - ${publisherName}`, value: publisherId };
    }
    return defaultOption.publisher;
  }, [window.location.href]);
  const currentUserType: UserStringType = useMemo(() => {
    if (hookWhoAmI?.isActingAsUserTypeId === USER_TYPES_ID.PUBLISHER || hookUserInfo.userTypesId === USER_TYPES_ID.PUBLISHER) {
      return 'Publisher';
    }
    if (hookWhoAmI?.isActingAsUserTypeId === USER_TYPES_ID.MERCHANT || hookUserInfo.userTypesId === USER_TYPES_ID.MERCHANT) {
      return 'Merchant';
    }
    return 'Admin';
  }, [window.location.href]);
  const canLoadAndSave = hookWhoAmI.isActingAsUserTypeId !== USER_TYPES_ID.MERCHANT && hookWhoAmI.isActingAsUserTypeId !== USER_TYPES_ID.PUBLISHER;

  // Calendar
  const [openCalendar, setOpenCalendar] = useState(false);
  const [startDate, setStartDate] = useState(defaultDateRange.start);
  const [endDate, setEndDate] = useState(today);
  const [dateRange, setDateRange] = useState('');
  const [selectedDateRange, setSelectedDateRange] = useState(`${startDate.toDateString()} / ${endDate.toDateString()}`);
  const [calendarKey, setCalendarKey] = useState<number>(0);

  // Dropdowns
  // Merchant ID - Name
  const [merchantList, setMerchantList] = useState<SelectOption[]>([defaultOption.merchant]);
  const [selectedMerchant, setSelectedMerchant] = useState<SelectOption>(defaultOption.merchant);
  // Date Type
  const dateTypeList: SelectOption[] = [defaultOption.transactionDate, defaultOption.processedDate];
  const [selectedDateType, setSelectedDateType] = useState<SelectOption>(defaultOption.dateType);
  // Publisher ID - Name
  const [publisherList, setPublisherList] = useState<SelectOption[]>([defaultOption.publisher]);
  const [selectedPublisher, setSelectedPublisher] = useState<SelectOption>(defaultOption.publisher);
  // Product ID = Name
  const [productList, setProductList] = useState<SelectOption[]>([defaultOption.product]);
  const [selectedProduct, setSelectedProduct] = useState<SelectOption>(defaultOption.product);
  // Transaction Status
  const transactionStatusList = DEFAULT_TRANSACTION_STATUSES;
  const [selectedTransactionStatus, setSelectedTransactionStatus] = useState<SelectOption>(defaultOption.transactionStatus);
  // Tracking Profile
  const [immutableTrackingProfiles, setAllTrackingProfiles] = useState<ShowMerchantTrackingsType[]>([]); // List of all Options for Merchant
  const [trackingProfileList, setTrackingProfileList] = useState<SelectOption[]>([defaultOption.trackingProfile]); // List filtered by Publisher Selected
  const [publisherTrackingProfiles, setPublisherTrackingProfiles] = useState<SelectOption[]>([defaultOption.trackingProfile]); // List for if Publisher or Admin(Publisher)
  const [selectedTrackingProfile, setSelectedTrackingProfile] = useState<SelectOption>(defaultOption.trackingProfile);
  // Publisher Group
  const [publisherGroupList, setPublisherGroupList] = useState<SelectOption[]>([defaultOption.publisherGroup]);
  const [selectedPublisherGroup, setSelectedPublisherGroup] = useState<SelectOption>(defaultOption.publisherGroup);
  // Ad ID
  const [adList, setAdList] = useState<SelectOption[]>([defaultOption.ad]);
  const [selectedAd, setSelectedAd] = useState<SelectOption>(defaultOption.ad);
  // Ad Compaign
  const [adCampaignList, setAdCampaignList] = useState<SelectOption[]>([defaultOption.adCampaign]);
  const [selectedAdCampaign, setSelectedAdCampaign] = useState<SelectOption>(defaultOption.adCampaign);
  // Product Category
  const [productCategoryList, setProductCategoryList] = useState<SelectOption[]>([defaultOption.productCategory]);
  const [selectedProductCategory, setSelectedProductCategory] = useState<SelectOption>(defaultOption.productCategory);
  // Search
  const searchInputRef = useRef<HTMLInputElement | null>(null);

  // Table Data
  const [tableData, setTableData] = useState<TMTableRowFlat[] | undefined>([]);
  const [tableTotals, setTableTotals] = useState<TMTotalsProcessed>();
  const [totalPages, setTotalPages] = useState<number>(1);
  const [totalRecords, setTotalRecords] = useState<number>(0);
  // Table Status
  const [reportColumns, setReportColumns] = useState<TableColumn[]>(DefaultTableColumns);
  const [reportColumnsBackup, setReportColumnsBackup] = useState<TableColumn[]>(transactionMasterColumns(defaultTableTotals));
  const [globalColumnsState, setGlobalColumnsState] = useState<SelectOption[]>(DEFAULT_TRANSACTION_MASTER_REPORT_COLUMNS);
  const [currentPage, setCurrentPage] = useState(1);
  const [sortColumn, setSortColumn] = useState<TableSortColumn>();
  const [selectedRecordsPerPage, setRecordsPerPage] = useState<SelectOption>(RECORDS_PER_PAGE_OPTIONS[0]);
  const [tableGenerated, setTableGenerated] = useState<boolean>(false);
  const [tableLoading, setTableLoading] = useState<boolean>(false);
  const [refetchTable, setRefetchTable] = useState<boolean>(false);
  const [tableKey, setTableKey] = useState<number>(123456); // Used to force table to rerender from start

  const [recordsPerPageList] = useState<SelectOption[]>(RECORDS_PER_PAGE_OPTIONS);

  // Columns Modal
  const [columnsModal, setColumnsModal] = useState(false);

  // Load Saved Report
  const [savedReports, setSavedReports] = useState<TMSavedReport[]>([]);
  const [savedReportsDisplay, setSavedReportsDisplay] = useState<ReportListItemType[]>([]);
  const [loadingSavedReport, setLoadingSavedReport] = useState<boolean>(false);
  const [resetTrackingProfileList, setResetTrackingProfileList] = useState<boolean>(false);
  const [loadedReportId, setLoadedReportId] = useState<string>('');
  const [loadedReportName, setLoadedReportName] = useState<string>('');
  const [notMyReportLoaded, setNotMyReportLoaded] = useState<boolean>(false);

  // Ad Modal
  const [isAdModalOpen, setIsAdModalOpen] = useState<boolean>(false);
  const [adModalId, setAdModalId] = useState<string>('');

  // Page Status
  const [advancedSearch, setAdvancedSearch] = useState(false);
  const [disabledFields, setDisabledFields] = useState(true); // Sets if dropdowns should be locked to defaults
  const [isDisabled, setIsDisabled] = useState(true); // Controls Save Report Button
  const [pageLoadingText, setPageLoadingText] = useState('Loading Merchants...');
  const [errorMessage, setErrorMessage] = useState<string>('');

  // Queries and Mutations Declarations
  const [getAdminMerchants, { loading: merchantLoading }] = useLazyQuery<ListMerchantOutputType, ListMerchantInputType>(LIST_MERCHANTS);
  const [getPublisherMerchants, { loading: publisherMerchantsLoading }] = useLazyQuery<GetMerchantsForReportsOutputType, GetMerchantsForReportsInputType>(GET_MERCHANTS_FOR_REPORTS);
  const [getMerchantInfo, { loading: merchantInfoLoading }] = useLazyQuery<ShowMerchantOutputType, ShowMerchantInputType>(SHOW_MERCHANT_INFO);
  const [getTransactionMasterReport, { loading: reportLoading }] = useLazyQuery<TransactionMasterOutputType, TransactionMasterInputType>(TRANSACTION_MASTER_REPORT);
  const [getTransactionMasterCSV, { loading: CSVReportLoading }] = useLazyQuery<TransactionMasterCSVOutputType, TransactionMasterCSVInputType>(TRANSACTION_MASTER_CSV_REPORT);
  const [getSavedReports, { loading: SavedReportsLoading }] = useLazyQuery<TMSavedReportsOutput, TMSavedReportsInput>(TRANSACTION_MASTER_SAVED_REPORTS);
  const [getPublisherTrackings, { loading: publisherTrackingsLoading }] = useLazyQuery(LIST_TRACKING_PROFILES_BY_PUBLISHER);
  const [saveReport, { loading: saveReportLoading }] = useMutation<STMROutputType, STMRInputType>(SAVE_TRANSACTION_MASTER_REPORT);

  // List Creation Handlers
  /** Creates the list of available merchants for Merchant ID - Name dropdown */
  const createMerchantListHandler = (companyList: MerchantOptionsType[]) => {
    const newMerchantList = [originalMerchantOption];

    if (companyList) {
      const listOptions: SelectOption[] = companyList.map((merchant: MerchantOptionsType) => ({
        label: `${merchant.id} - ${merchant.companyName}`,
        value: merchant.id,
      }));
      newMerchantList.push(...listOptions);
    }
    setMerchantList(newMerchantList);
  };

  /** Gets the Merchant list if Admin, Publisher, or Publisher(Admin) */
  const getMerchantList = async () => {
    setErrorMessage('');

    // User is a Publisher or Admin as Publisher
    if (hookWhoAmI?.isActingAsUserTypeId === USER_TYPES_ID.PUBLISHER || hookUserInfo.userTypesId === USER_TYPES_ID.PUBLISHER) {
      const [
        { data: publisherMerchants },
        { data: publisherTrackings },
      ] = await Promise.all([
        getPublisherMerchants({
          variables: {
            input: {
              companyType: 'Publisher',
              accountStatus: 'Approved',
              id: hookWhoAmI.companyId?.toString() || '0',
            },
          },
          fetchPolicy: 'no-cache',
          onError(err) {
            setErrorMessage(err.message);
          },
        }),
        getPublisherTrackings({
          variables: {
            companyId: hookWhoAmI.companyId?.toString() || '0',
          },
          fetchPolicy: 'no-cache',
          onError(err) {
            setErrorMessage(err.message);
          },
        }),
      ]);

      if (publisherMerchants && publisherMerchants.companyOptions) {
        createMerchantListHandler(publisherMerchants.companyOptions.companies);
      }
      if (publisherTrackings && publisherTrackings.trackings) {
        const options = (publisherTrackings.trackings?.trackings || []).map((opt: any) => ({ label: `${opt.id} - ${opt.profileName}`, value: opt.id }));
        setPublisherTrackingProfiles([defaultOption.trackingProfile, ...options]);
      }
    }

    // User is Admin
    if (hookWhoAmI?.isActingAsUserTypeId === undefined && hookUserInfo.userTypesId === USER_TYPES_ID.ADMIN) {
      const { data } = await getAdminMerchants({
        variables: {
          input: {
            companyType: 'Merchant',
            accountStatus: 'Approved',
          },
        },
        fetchPolicy: 'no-cache',
        onError(err) {
          setErrorMessage(err.message);
        },
      });

      if (data && data.companyOptions) {
        createMerchantListHandler(data.companyOptions.companies);
      }
    }
  };

  /** Sets Product Category List to given array with All Categories added in front */
  const setProductCategoryListHandler = (productCategoriesArray: string[]) => {
    const returnList = [defaultOption.productCategory];

    if (productCategoriesArray) {
      const listOptions: SelectOption[] = productCategoriesArray.map((productCategory: string) => ({
        label: productCategory,
        value: productCategory,
      }));
      const options = listOptions.filter((option: SelectOption, index) => listOptions.findIndex((o) => option.value === o.value) === index);

      returnList.push(...options);
    }
    setProductCategoryList(returnList);
  };

  /** For setting all the dropdown values when a specific merchant is selelcted or used. */
  const getMerchantInfoHandler = async (merchantId: string) => {
    const productListOptions: SelectOption[] = [defaultOption.product, defaultOption.noProduct];
    const publisherListOptions: SelectOption[] = [originalPublisherOption];
    const immutableTrackingProfilesList: ShowMerchantTrackingsType[] = [];
    const productCategoriesList: string[] = [];
    const adCampaignListOptions: SelectOption[] = [defaultOption.adCampaign];
    const publisherGroupListOptions: SelectOption[] = [defaultOption.publisherGroup];
    const adsListOptions: SelectOption[] = [defaultOption.ad];

    const { data } = await getMerchantInfo({
      variables: {
        input: {
          companyType: 'Merchant',
          accountStatus: 'Approved',
          id: merchantId,
        },
      },
      fetchPolicy: 'no-cache',
    });

    if (data?.companyOptions?.companies && data.companyOptions.companies[0]) {
      const companyData = data.companyOptions;
      companyData.companies.forEach((company: ShowMerchantCompaniesType) => {
        // For Product ID - Name dropdown
        const productsFormatted = company.program.products.map((product: ShowMerchantProductsType) => ({
          label: `${product.customizedProductId} - ${product.name}`,
          value: product.id,
        }));
        if (productListOptions.length > 0) {
          productListOptions.push(...productsFormatted);
        }

        // For Publisher ID - Name dropdown
        // For Tracking Profile dropdown
        const publishersFormatted = company.memberships.map((membership: ShowMerchantMembershipsType) => {
          immutableTrackingProfilesList.push(...membership.publisher.trackings.map((tracking) => ({ ...tracking, publisherId: membership.publisher.id })));
          return {
            label: `${membership.publisher.id} - ${membership.publisher.companyName}`,
            value: membership.publisher.id,
          };
        });
        if (publisherListOptions.length > 0) {
          publisherListOptions.push(...publishersFormatted);
        }

        // For Ad Campaign dropdown
        const adCampaignFormatted = company.program.campaigns.map((campaign: ShowMerchantCampaignsType) => ({
          label: `${campaign.id} - ${campaign.name}`,
          value: campaign.id,
        }));
        if (adCampaignListOptions.length > 0) {
          adCampaignListOptions.push(...adCampaignFormatted);
        }

        // For Publisher Group downdown
        const publisherGroupFormatted = company.program.publisherGroups.map((publisherGroup: ShowMerchantPublisherGroupsType) => ({
          label: `${publisherGroup.id} - ${publisherGroup.name}`,
          value: publisherGroup.id,
        }));
        if (publisherGroupListOptions.length > 0) {
          publisherGroupListOptions.push(...publisherGroupFormatted);
        }

        // For Ad ID dropdown
        const adsFormatted = company.program.ads.map((ad: ShowMerchantAdsType) => ({
          label: `${ad.id} - ${ad.adName}`,
          value: ad.id,
        }));
        if (adsListOptions.length > 0) {
          adsListOptions.push(...adsFormatted);
        }

        // For Product Category dropdown
        productCategoriesList.push(...(company.program.products.map((product) => product.productCategory)));
      });
    }

    setProductList(productListOptions);
    setPublisherList(publisherListOptions);
    setAllTrackingProfiles(immutableTrackingProfilesList);
    setProductCategoryListHandler(productCategoriesList);
    setTrackingProfileList(formatTrackProfile(immutableTrackingProfilesList));
    setAdCampaignList(adCampaignListOptions);
    setPublisherGroupList(publisherGroupListOptions);
    setAdList(adsListOptions);
    setDisabledFields(false);
  };

  // Calendar Handlers
  const setOnApplyCalendarHandler = (startDateValue: Date, endDateValue?: Date, range?: string) => {
    setTableData([]);
    setTotalRecords(0);
    setCurrentPage(1);
    setTableGenerated(false);
    setOpenCalendar(false);
    setStartDate(startDateValue);
    if (endDateValue) setEndDate(endDateValue);
    if (range) setDateRange(range);
    else (setDateRange(''));

    setSelectedDateRange(`${startDateValue.toDateString()} / ${endDateValue?.toDateString()}`);
  };

  const setOpenCalendarHandler = (state: boolean) => {
    setOpenCalendar(state);
  };

  /**
    * Controls Changing the Merchant Selected.
    * Sets All Dropdowns dependant on Merchant to default
    * Clears all fields if "All Merchants" selected.
    */
  const selectMerchantHandler = (merchant: SelectOption, noDefaults?: boolean) => {
    setTableData([]);
    setTotalRecords(0);
    setCurrentPage(1);
    setSelectedMerchant(merchant);
    setTableGenerated(false);

    if (!noDefaults) {
      setSelectedPublisher(originalPublisherOption);
      setSelectedProduct(defaultOption.product);
      setSelectedProductCategory(defaultOption.productCategory);
      setProductCategoryListHandler(immutableProductCategories);
      setSelectedTrackingProfile(defaultOption.trackingProfile);
      setSelectedAdCampaign(defaultOption.adCampaign);
      setSelectedPublisherGroup(defaultOption.publisherGroup);
      setSelectedAd(defaultOption.ad);
    }

    if (merchant.value) {
      getMerchantInfoHandler(merchant.value);
      setPageLoadingText(`Loading ${merchant.label}`);
    } else {
      setDisabledFields(true);
      setPublisherList([]);
      setProductList([]);
      setProductCategoryListHandler(immutableProductCategories);
      setAllTrackingProfiles([]);
      setTrackingProfileList([]);
      setAdCampaignList([]);
      setPublisherGroupList([]);
      setAdList([]);
    }
  };

  const setSelectedDateTypeHandler = (dateTypeItem: SelectOption) => {
    setCurrentPage(1);
    setTableData([]);
    setTotalRecords(0);
    setTableGenerated(false);
    setSelectedDateType(dateTypeItem);
  };

  /** When Publisher is set filter Tracking Profile List to that Publishers and set Selected Tracking Profile to default */
  const setSelectedPublisherHandler = (publisher: SelectOption, noReset?: boolean) => {
    setCurrentPage(1);
    if (!noReset) {
      setTableData([]);
      setTotalRecords(0);
      setTableGenerated(false);
      setSelectedTrackingProfile(defaultOption.trackingProfile);
    }

    setSelectedPublisher(publisher);
    if (publisher.value) {
      const filteredTrackingProfiles = immutableTrackingProfiles.filter((trackingProfile: ShowMerchantTrackingsType) => trackingProfile.publisherId === publisher.value);
      setTrackingProfileList(formatTrackProfile(filteredTrackingProfiles));
    } else {
      setTrackingProfileList(formatTrackProfile(immutableTrackingProfiles));
    }
  };

  const selectProductHandler = (product: SelectOption) => {
    setTableData([]);
    setTotalRecords(0);
    setCurrentPage(1);
    setTableGenerated(false);
    setSelectedProduct(product);
  };

  const setSelectedTransactionStatusHandler = (newStatus: SelectOption) => {
    setTableData([]);
    setTotalRecords(0);
    setCurrentPage(1);
    setTableGenerated(false);
    setSelectedTransactionStatus(newStatus);
  };
  // Advanced Dropdowns Selection Handlers
  const setSelectedTrackingProfileHandler = (newOption: SelectOption) => {
    setTableData([]);
    setTotalRecords(0);
    setCurrentPage(1);
    setTableGenerated(false);
    setSelectedTrackingProfile(newOption);
  };

  const setSelectedPublisherGroupHandler = (newOption: SelectOption) => {
    setTableData([]);
    setTotalRecords(0);
    setCurrentPage(1);
    setTableGenerated(false);
    setSelectedPublisherGroup(newOption);
  };

  const setSelectedAdHandler = (newOption: SelectOption) => {
    setTableData([]);
    setTotalRecords(0);
    setCurrentPage(1);
    setTableGenerated(false);
    setSelectedAd(newOption);
  };

  const setSelectedAdCampaignHandler = (newOption: SelectOption) => {
    setTableData([]);
    setTotalRecords(0);
    setCurrentPage(1);
    setTableGenerated(false);
    setSelectedAdCampaign(newOption);
  };

  const setSelectedProductCategoryHandler = (newOption: SelectOption) => {
    setTableData([]);
    setTotalRecords(0);
    setCurrentPage(1);
    setTableGenerated(false);
    setSelectedProductCategory(newOption);
  };

  const advancedSearchHandler = () => {
    setAdvancedSearch(!advancedSearch);
  };

  const setClearFormHandler = () => {
    setTableData([]);
    setTableGenerated(false);
    setStartDate(defaultDateRange.start);
    setEndDate(today);
    setDateRange('');
    setSelectedDateRange(`${defaultDateRange.start.toDateString()} / ${today.toDateString()}`);
    setSelectedMerchant(originalMerchantOption);
    setSelectedTransactionStatus(defaultOption.transactionStatus);
    setSelectedProductCategory(defaultOption.productCategory);
    setSelectedDateTypeHandler(defaultOption.dateType);
    setSelectedPublisher(originalPublisherOption);
    setSelectedProduct(defaultOption.product);
    setSelectedTrackingProfile(defaultOption.transactionStatus);
    setSelectedPublisherGroup(defaultOption.publisherGroup);
    setSelectedAd(defaultOption.ad);
    setSelectedAdCampaign(defaultOption.adCampaign);
    if (searchInputRef.current) searchInputRef.current.value = '';
    if (!originalMerchantOption.value) setDisabledFields(true);
    setSortColumn(undefined);
    setGlobalColumnsState(DEFAULT_TRANSACTION_MASTER_REPORT_COLUMNS);
    setTotalRecords(0);
    setTotalPages(0);
    setIsDisabled(true);
    setLoadedReportId('');
    setLoadedReportName('');
    setNotMyReportLoaded(false);
    setCalendarKey(calendarKey + 1);
  };

  // Ads Modal Handlers
  const setAdModalHandler = (value: string) => {
    setIsAdModalOpen(!isAdModalOpen);
    setAdModalId(value.toString());
  };
  const setOpenCloseAdModalHandler = () => {
    setIsAdModalOpen(!isAdModalOpen);
  };

  // Customize Columns Modal Handlers
  const setColumnsModalHandler = () => {
    setColumnsModal(!columnsModal);
  };

  /** Controls Turning a column on or off in the table */
  const setColumnsHandler = (column: SelectOption, checked: boolean) => {
    // Add a column from the column state
    if (checked) {
      let backupIndex = 0;
      const reportIndex = reportColumnsBackup
        .filter((bCol, index) => {
          const inCurrentColumns = reportColumns.some((rCol) => rCol.dataField === bCol.dataField);
          let isNewColumn = false;
          if (bCol.dataField === column.value) {
            backupIndex = index;
            isNewColumn = true;
          }
          return inCurrentColumns || isNewColumn;
        })
        .findIndex((item) => item.dataField === column.value);

      const newGlobalColumnState = [...globalColumnsState];
      const newReportColumnState = [...reportColumns];
      if (reportIndex > newGlobalColumnState.length) newGlobalColumnState.splice(newGlobalColumnState.length, 0, column);
      else newGlobalColumnState.splice(reportIndex, 0, column);
      if (reportIndex > newReportColumnState.length) newReportColumnState.splice(newReportColumnState.length, 0, reportColumnsBackup[backupIndex]);
      else newReportColumnState.splice(reportIndex, 0, reportColumnsBackup[backupIndex]);

      setReportColumns(newReportColumnState);
      setGlobalColumnsState(newGlobalColumnState);

      // Remove a column to the column state
    } else {
      const removeFromGlobalColumns = globalColumnsState.filter((col: SelectOption) => col.value !== column.value);
      const newReportColumnState = reportColumns.filter((rColumn) => rColumn.dataField !== column.value);
      setReportColumns(newReportColumnState);
      setGlobalColumnsState(removeFromGlobalColumns);
    }
  };

  /** queues query to be made on Generate Report Button */
  const generatedReportButtonHandler = () => {
    setCurrentPage(1);
    setRefetchTable(true);
  };
  /** Queries Transaction Master Report Data and Sets Table to Data */
  const setTransactionMasterReportHandler = async (isSavedReportLoading?: boolean) => {
    setPageLoadingText(`Generating report for ${selectedMerchant.label}`);
    setTableLoading(true);
    setErrorMessage('');

    const transactionMasterInput: TransactionMasterInputType = {
      input: {
        isTrackerLinked: true,
        getNonApprovedStatus: true,
        currentPage,
        limit: Number(selectedRecordsPerPage.value),
        sortBy: sortColumn?.column || 'id',
        sortDirection: sortColumn?.direction || 'desc',
        ...selectedMerchant.value && { merchantId: selectedMerchant.value },
        ...selectedPublisher.value && { publisherId: selectedPublisher.value },
        ...selectedTransactionStatus.value && { transactionStatus: selectedTransactionStatus.value },
        ...selectedTrackingProfile.value && { trackingProfileId: selectedTrackingProfile.value },
        ...selectedProduct.value && { productId: selectedProduct.value },
        ...selectedAd.value && { adId: selectedAd.value },
        ...selectedProductCategory.value && { productCategory: selectedProductCategory.value },
        ...selectedAdCampaign.value && { adCampaignId: selectedAdCampaign.value },
        ...selectedPublisherGroup.value && { publisherGroupId: selectedPublisherGroup.value },
        ...(selectedDateType.value === 'transactionDate' || selectedDateType.value === 'transactionDateRange') && {
          transactionDateRange:
            {
              startDate: toUTCHours(startDate, 'beginning').toISOString(),
              endDate: toUTCHours(endDate, 'end').toISOString(),
            },
        },
        ...(selectedDateType.value === 'processedDate' || selectedDateType.value === 'processedDateRange') && {
          processedDateRange:
            {
              startDate: toUTCHours(startDate, 'beginning').toISOString(),
              endDate: toUTCHours(endDate, 'end').toISOString(),
            },
        },
      },
    };

    if (searchInputRef?.current?.value) {
      transactionMasterInput.input.search = searchInputRef.current.value;
    }
    setTableGenerated(false);
    const { data } = await getTransactionMasterReport({
      variables: transactionMasterInput,
      fetchPolicy: 'no-cache',
      onError(err) {
        setErrorMessage(err.message);
      },
      errorPolicy: 'all', // makes Appolo client fill in both data and error on errors
    });

    if (isSavedReportLoading) setResetTrackingProfileList(true);
    setTableGenerated(true);
    setTableLoading(false);
    if (data && data?.transactionMaster.transactions && data?.transactionMaster?.transactions?.length > 0) {
      setIsDisabled(false);

      const processedData = processTableData(data.transactionMaster.transactions);
      const processedTotals = processTableTotals(data.transactionMaster.calculatedTotal || []);

      // Table needs to know usertype
      processedData.forEach((row) => {
        // Purposely only selecting for real Publisher Users and not Admin(Publisher)'s
        if (hookUserInfo.userTypesId === 3) {
          // eslint-disable-next-line no-param-reassign
          row.userType = currentUserType;
        }
      });
      const getColumns = transactionMasterColumns(processedTotals, setAdModalHandler);

      const filteredOutNonMatchs = getColumns.filter((column) => ((globalColumnsState.some((shownColumn) => shownColumn.value === column.dataField))));

      if (isSavedReportLoading) {
        const sortColumns = (first: TableColumn, second: TableColumn) => {
          const firstIndex = 1000 - globalColumnsState.findIndex((col) => col.value === first.dataField);
          const secondIndex = 1000 - globalColumnsState.findIndex((col) => col.value === second.dataField);
          return secondIndex - firstIndex;
        };
        filteredOutNonMatchs.sort(sortColumns);
      }

      setReportColumns(filteredOutNonMatchs);
      setReportColumnsBackup(getColumns);
      setGlobalColumnsState(TRANSACTION_MASTER_REPORT_CUSTOMIZE_COLUMNS.filter((column) => (filteredOutNonMatchs.some((match) => column.value === match.dataField))));

      setTableData(processedData);
      setTotalPages(data?.transactionMaster.totalPages);
      setTableTotals(processedTotals);
      setTotalRecords(data.transactionMaster.count);
      setCurrentPage(data.transactionMaster.currentPage);
      setTableKey(tableKey + 1);
      if (!sortColumn) setSortColumn({ column: 'id', direction: 'desc' });
    } else {
      setIsDisabled(true);
      setTableData([]);
      setCurrentPage(1);
      setTotalRecords(0);
    }
  };

  // Table Handlers
  const setCurrentPageHandler = (pageNum: number) => {
    setRefetchTable(true);
    setCurrentPage(pageNum);
  };

  const setRecordsPerPageHandler = (recordItem: SelectOption) => {
    setCurrentPage(1);
    setRecordsPerPage(recordItem);
    if (tableGenerated) setRefetchTable(true);
  };

  const sortTableHandler = (column: string, direction: 'desc' | 'asc' | undefined) => {
    const newDirection = direction === 'desc' ? 'asc' : 'desc';
    setSortColumn({ column, direction: newDirection });
    setRefetchTable(true);
  };

  const setOnDragEndHandler = (result: any) => {
    setReportColumns(result);
  };

  /** Queries for CSV data, then processes it into CSV file */
  const downloadCSVHandler = async () => {
    setPageLoadingText('Generating your file');
    const transactionMasterCSVInput: TransactionMasterCSVInputType = {
      input: {
        isTrackerLinked: true,
        sortBy: sortColumn?.column || 'id',
        sortDirection: sortColumn?.direction || 'desc',
        ...selectedMerchant.value && { merchantId: selectedMerchant.value },
        ...selectedPublisher.value && { publisherId: selectedPublisher.value },
        ...selectedTransactionStatus.value && { transactionStatus: selectedTransactionStatus.value },
        ...selectedTrackingProfile.value && { trackingProfileId: selectedTrackingProfile.value },
        ...selectedProduct.value && { productId: selectedProduct.value },
        ...selectedAd.value && { adId: selectedAd.value },
        ...selectedProductCategory.value && { productCategory: selectedProductCategory.value },
        ...selectedAdCampaign.value && { adCampaignId: selectedAdCampaign.value },
        ...selectedPublisherGroup.value && { publisherGroupId: selectedPublisherGroup.value },
        ...(selectedDateType.value === 'transactionDate' || selectedDateType.value === 'transactionDateRange') && {
          transactionDateRange:
            {
              startDate: toUTCHours(startDate, 'beginning').toISOString(),
              endDate: toUTCHours(endDate, 'end').toISOString(),
            },
        },
        ...(selectedDateType.value === 'processedDate' || selectedDateType.value === 'processedDateRange') && {
          processedDateRange:
            {
              startDate: toUTCHours(startDate, 'beginning').toISOString(),
              endDate: toUTCHours(endDate, 'end').toISOString(),
            },
        },
      },
    };

    const { data } = await getTransactionMasterCSV({
      variables: transactionMasterCSVInput,
      fetchPolicy: 'no-cache',
      onError(err) {
        setErrorMessage(err.message);
      },
      errorPolicy: 'all',
    });

    if (!data) return;
    const { results, totals } = formatDataTableCSV(data.transactionMasterForCSV.transactions);
    const csvString = processDataToCSVString(results, totals, reportColumns);
    csvGenerator(csvString, 'Transaction Master Report');
  };

  // Loading Saved Report Handlers
  const setSavedReportsHandler = (newSavedReports: TMSavedReport[]) => {
    setSavedReports(newSavedReports);
  };

  /** Gets the Saved Reports of the user */
  const getSavedReportsList = async () => {
    setErrorMessage('');
    const getSavedReportsInput: TMSavedReportsInput = {
      user: {
        company: Number(hookWhoAmI.companyId) || null,
        user: {
          id: hookWhoAmI.id,
          userType: currentUserType,
        },
        report: 'Transaction Master',
      },
    };
    if (currentUserType !== 'Admin') getSavedReportsInput.user.company = Number(hookWhoAmI.companyId);
    const { data } = await getSavedReports({
      variables: getSavedReportsInput,
      fetchPolicy: 'no-cache',
      onError(err) {
        setErrorMessage(err.message);
      },
    });

    if (data && data.reportProfiles) {
      setSavedReportsHandler(data.reportProfiles.reports);
      const savedReportsSelectList: ReportListItemType[] = data.reportProfiles.reports.map((report) => ({ id: report.id, name: report.name, description: report.description }));
      setSavedReportsDisplay(savedReportsSelectList);
    }
  };

  /** Loads the saved report selected */
  const loadSavedReportHandler = (reportId: string) => {
    // Find the correct saved report
    const reportIndex = savedReports.findIndex((report) => report.id === reportId);
    if (reportIndex === -1) return;

    // Set the Dropdowns Correctly
    let newStartDate: Date = new Date();
    let newEndDate: Date = new Date();
    let newRange = '';
    let sortCol = 'id';
    let sortDir = 'desc';
    savedReports[reportIndex].filters.forEach((field) => {
      switch (field.field) {
        case 'startDate':
          newStartDate = new Date(field.value.value);
          setStartDate(newStartDate);
          break;
        case 'endDate':
          newEndDate = new Date(field.value.value);
          setEndDate(newEndDate);
          break;
        case 'dateRange':
          newRange = field.value.value;
          break;
        case 'merchantId':
          selectMerchantHandler(field.value, true);
          break;
        case 'dataType':
          setSelectedDateType(field.value);
          break;
        case 'publisherId':
          setSelectedPublisher(field.value);
          break;
        case 'productId':
          setSelectedProduct(field.value);
          break;
        case 'productCategory':
          setSelectedProductCategory(field.value);
          break;
        case 'trackingProfileId':
          setSelectedTrackingProfile(field.value);
          break;
        case 'campaignId':
          setSelectedAdCampaign(field.value);
          break;
        case 'adCampaignId':
          setSelectedAdCampaign(field.value);
          break;
        case 'publisherGroupId':
          setSelectedPublisherGroup(field.value);
          break;
        case 'adId':
          setSelectedAdHandler(field.value);
          break;
        case 'limit':
          setRecordsPerPage(field.value);
          break;
        case 'transactionStatus':
          setSelectedTransactionStatus(field.value);
          break;
        case 'sortBy':
          sortCol = field.value.value;
          break;
        case 'sortDirection':
          sortDir = field.value.value;
          break;
        case 'search':
          if (searchInputRef && searchInputRef.current) searchInputRef.current.value = field.value.value ? field.value.value : '';
          break;
        default:
          break;
      }
    });

    // Set the sortColumn Value
    setSortColumn({ column: sortCol, direction: sortDir === 'desc' ? 'desc' : 'asc' });

    // if Range sets the Date Picker to the Range
    setCalendarKey(calendarKey + 1);
    if (RangeOptions.includes(newRange)) {
      const { start, end, range } = rangeFormat(newRange as RangeFormat);
      setStartDate(start);
      setEndDate(end);
      setDateRange(range);
      setSelectedDateRange(`${start.toDateString()} / ${end.toDateString()}`);
    } else {
      setSelectedDateRange(`${newStartDate.toDateString()} / ${newEndDate.toDateString()}`);
      setDateRange('');
    }

    // Set the Table Columns correctly
    setGlobalColumnsState(savedReports[reportIndex].columns.map((column): SelectOption => ({ label: column.label, value: column.value })));
    const allColumns = [...DefaultTableColumns];
    const filteredOutNonMatchs = allColumns.filter((column) => savedReports[reportIndex].columns.some((shownColumn) => shownColumn.value === column.dataField));
    const orderedColumns: TableColumn[] = savedReports[reportIndex].columns.map((column) => filteredOutNonMatchs.find((col) => column.value === col.dataField)).filter((col) => col !== undefined) as TableColumn[];
    setReportColumns(orderedColumns);
    setReportColumnsBackup(allColumns);

    // Save which Report was loaded in
    setLoadedReportId(reportId);
    setLoadedReportName(savedReports[reportIndex].name);

    // Check if it is your Report that was loaded
    if (savedReports[reportIndex].user.id !== hookUserInfo.id) {
      setNotMyReportLoaded(true);
    } else {
      setNotMyReportLoaded(false);
    }

    // Trigger the Table Fetch
    setLoadingSavedReport(true);
  };

  // For Generating a saved report after fields have been set
  if (loadingSavedReport) {
    setTransactionMasterReportHandler(true);
    setLoadingSavedReport(false);
  }

  // For Getting Correct Options in the Tracking Profile Dropdown after loading a saved report
  if (resetTrackingProfileList) {
    setSelectedPublisherHandler(selectedPublisher, true);
    setResetTrackingProfileList(false);
  }

  // Save Report Handlers
  // Create or Updates a Saved Report
  const onSaveHandler = async (profile: any) => {
    // Create Input Object
    const setReportProfile: STMRInputType = {
      save: {
        filters: [
          {
            field: 'merchantId',
            value: selectedMerchant,
          },
          {
            field: 'publisherId',
            value: selectedPublisher,
          },
          {
            field: 'dataType',
            value: selectedDateType,
          },
          {
            field: 'productId',
            value: selectedProduct,
          },
          {
            field: 'productCategory',
            value: selectedProductCategory,
          },
          {
            field: 'trackingProfileId',
            value: selectedTrackingProfile,
          },
          {
            field: 'adCampaignId',
            value: selectedAdCampaign,
          },
          {
            field: 'publisherGroupId',
            value: selectedPublisherGroup,
          },
          {
            field: 'adId',
            value: selectedAd,
          },
          {
            field: 'limit',
            value: selectedRecordsPerPage,
          },
          {
            field: 'transactionStatus',
            value: selectedTransactionStatus,
          },
          {
            field: 'sortBy',
            value: {
              label: 'sortBy',
              value: sortColumn?.column || 'id',
            },
          },
          {
            field: 'sortDirection',
            value: {
              label: 'sortDirection',
              value: sortColumn?.direction || 'desc',
            },
          },
          {
            field: 'search',
            value: {
              label: 'searchValue',
              value: searchInputRef?.current ? searchInputRef.current.value : '',
            },
          },
        ],
        columns: reportColumns.map((column) => ({ label: column.text, value: column.dataField })),
        user: {
          id: hookWhoAmI.id,
          email: hookWhoAmI.email,
        },
        report: 'Transaction Master',
        name: profile.name || '',
        // Add in Optional Fields
        ...profile?.description && { description: profile.description },
        ...profile?.schedule?.frequency && { frequency: profile.schedule.frequency },
        ...profile?.schedule?.detailedFrequency && { every: profile.schedule.detailedFrequency },
        ...loadedReportId && { id: loadedReportId },
      },
    };
    if (profile?.permission?.value === PERMISSION_OPTIONS.ALL_USERS) {
      if (currentUserType === 'Admin') setReportProfile.save.company = 1;
      else setReportProfile.save.company = Number(hookWhoAmI.companyId);
    }

    // Determine if using a Date Range
    if (dateRange) {
      setReportProfile.save.filters.push({
        field: 'dateRange',
        value: {
          value: dateRange,
          label: dateRange,
        },
      });
    } else {
      setReportProfile.save.filters.push(
        {
          field: 'startDate',
          value: {
            value: startDate.toString(),
            label: startDate.toString(),
          },
        },
        {
          field: 'endDate',
          value: {
            value: endDate.toString(),
            label: endDate.toString(),
          },
        },
      );
    }

    // Create or Update Saved Report
    setErrorMessage('');
    const { data } = await saveReport({
      variables: setReportProfile,
      onError(err) {
        setErrorMessage(err.message);
      },
      errorPolicy: 'all', // makes Appolo client fill in both data and error on errors
    });

    if (data) {
      hookShowToast(`Your report "${profile.name || ''}" was saved successfully.`);
      getSavedReportsList();
    }
  };

  const updateReportHandler = () => {
    const savedReportIndex = savedReports.findIndex((report) => report.id === loadedReportId);
    if (savedReportIndex === -1) {
      hookShowToast('Your report failed to update.');
      return;
    }
    const profile = {
      name: savedReports[savedReportIndex].name,
      description: savedReports[savedReportIndex].description,
      permission: savedReports[savedReportIndex].company ? PERMISSION_OPTIONS.ALL_USERS : PERMISSION_OPTIONS.ONLY_ME,
      schedule: {
        frequency: savedReports[savedReportIndex].frequency || '',
        detailedFrequency: savedReports[savedReportIndex].every || '',
      },
    };
    onSaveHandler(profile);
  };

  // If Table Data needs refetching
  if (refetchTable) {
    setRefetchTable(false);
    setTransactionMasterReportHandler();
  }
  useEffect(() => {
    setClearFormHandler();
    setProductCategoryListHandler(immutableProductCategories);
    getMerchantList();
    selectMerchantHandler(originalMerchantOption);
    if (canLoadAndSave) getSavedReportsList();
  }, [window.location.href]);

  return {
    // Page State
    hookLoading: merchantInfoLoading || merchantLoading || publisherMerchantsLoading || CSVReportLoading || reportLoading || saveReportLoading || publisherTrackingsLoading,
    hookPageLoadingText: pageLoadingText,
    hookDisabledFields: disabledFields,
    hookMerchantInfoLoading: merchantInfoLoading,
    hookErrorMessage: errorMessage,
    hookCurrentUserType: currentUserType,
    hookCanLoadAndSave: canLoadAndSave,

    // Calendar
    hookOpenCalendar: openCalendar,
    hookSetOpenCalendar: setOpenCalendarHandler,
    hookOnApplyCalendar: setOnApplyCalendarHandler,
    hookSelectDate: selectedDateRange,
    hookStartDate: startDate,
    hookEndDate: endDate,
    hookRange: dateRange,
    hookCalendarKey: calendarKey,

    // Dropdowns
    // Merchant ID - Name
    hookMerchant: selectedMerchant,
    hookSetSelectedMerchant: selectMerchantHandler,
    hookMerchantList: merchantList,
    // Date Type
    hookDateType: selectedDateType,
    hookSetDateType: setSelectedDateTypeHandler,
    hookDateTypeList: dateTypeList,
    // Publisher ID - Name
    hookPublisher: selectedPublisher,
    hookSetPublisher: setSelectedPublisherHandler,
    hookPublisherList: publisherList,
    // Product ID - Name
    hookProduct: selectedProduct,
    hookSetProduct: selectProductHandler,
    hookProductList: productList,
    // Transaction Status
    hookTransactionStatus: selectedTransactionStatus,
    hookSetTransactionStatus: setSelectedTransactionStatusHandler,
    hookTransactionStatusList: transactionStatusList,
    // Clear Form
    hookClearFormHandler: setClearFormHandler,

    // Advanced Dropdowns
    hookAdvancedSearch: advancedSearch,
    hookSetAdvancedSearch: advancedSearchHandler,
    // Tracking Profile
    hookSelectedTrackingProfile: selectedTrackingProfile,
    hookSetSelectedTrackingProfile: setSelectedTrackingProfileHandler,
    hookTrackingProfileList: currentUserType === 'Publisher' ? publisherTrackingProfiles : trackingProfileList,
    // Publisher Group
    hookPublisherGroup: selectedPublisherGroup,
    hookSetPublisherGroup: setSelectedPublisherGroupHandler,
    hookPublisherGroupList: publisherGroupList,
    // Ad ID
    hookAd: selectedAd,
    hookSetAd: setSelectedAdHandler,
    hookAdList: adList,
    // Ad Campaign
    hookAdCampaign: selectedAdCampaign,
    hookSetAdCampaign: setSelectedAdCampaignHandler,
    hookAdCampaignList: adCampaignList,
    // Product Category
    hookProductCategory: selectedProductCategory,
    hookSetProductCategory: setSelectedProductCategoryHandler,
    hookProductCategoryList: productCategoryList,
    // Search
    hookSearchInputRef: searchInputRef,

    // Records
    hookRecordsPerPage: recordsPerPageList,
    hookSelectedRecordsPerPage: selectedRecordsPerPage,
    hookSetSelectedRecordsPerPage: setRecordsPerPageHandler,

    // Table Data
    hookTableData: tableData,
    hookTableTotals: tableTotals,
    hookTotalPages: totalPages,
    hookTotalRecords: totalRecords,
    // Table Status
    hookTableGenerated: tableGenerated,
    hookTableColumns: reportColumns,
    hookCurrentPage: currentPage,
    hookTableLoading: tableLoading,
    hookSortColumn: sortColumn,
    hookTableKey: tableKey,
    // Table Handlers
    hookGenerateReport: generatedReportButtonHandler,
    hookSetCurrentPage: setCurrentPageHandler,
    hookSortTableHandler: sortTableHandler,
    hookSetOnDragEnd: setOnDragEndHandler,

    // Customize Columns Modal
    hookColumnsModal: columnsModal,
    hookSetColumnsModal: setColumnsModalHandler,
    hookSetColumns: setColumnsHandler,
    hookGlobalColumnState: globalColumnsState,

    // CSV Handler
    hookDownloadCSV: downloadCSVHandler,
    hookCSVLoading: CSVReportLoading,

    // Load Report
    hookSavedReports: savedReportsDisplay,
    hookLoadSavedReport: loadSavedReportHandler,
    hookSavedReportsLoading: SavedReportsLoading,
    hookLoadedReportName: loadedReportName,
    hookNotMyReportLoaded: notMyReportLoaded,

    // Save Report
    hookIsDisabled: isDisabled,
    hookOnSave: onSaveHandler,
    hookOnUpdate: updateReportHandler,
    hookIsSavedReportLoaded: !!loadedReportId,

    // Ad Modal
    hookAdModalId: adModalId,
    hookAdModal: isAdModalOpen,
    hookSetAdModal: setAdModalHandler,
    hookOpenCloseAdModal: setOpenCloseAdModalHandler,
  };
};
