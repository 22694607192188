import { useEffect, useState } from 'react';
import { MENU_SESSION_STORAGE_KEY, SUB_MENU_MANAGEMENT_SESSION_STORAGE_KEY, SUB_MENU_SESSION_STORAGE_KEY } from '../../../utils';

export const useSideMenu = () => {
  const getStoreMenuOpenState = () => {
    const storageMenuOpenState = sessionStorage.getItem(MENU_SESSION_STORAGE_KEY);

    if (storageMenuOpenState) {
      return storageMenuOpenState;
    }

    return null;
  };

  const getStoreSubMenuOpenState = () => {
    const storageMenuOpenState = sessionStorage.getItem(SUB_MENU_SESSION_STORAGE_KEY);

    if (storageMenuOpenState) {
      return storageMenuOpenState;
    }

    return null;
  };

  const getStoreSubMenuManagementOpenState = () => {
    const storageMenuOpenState = sessionStorage.getItem(SUB_MENU_MANAGEMENT_SESSION_STORAGE_KEY);

    if (storageMenuOpenState) {
      return storageMenuOpenState;
    }

    return null;
  };

  const [menuOpenState, setMenuOpenState] = useState<string | null>(getStoreMenuOpenState);
  const [subMenuOpenState, setSubMenuOpenState] = useState<string | null>(getStoreSubMenuOpenState);
  const [subMenuManagementState, setSubMenuManagementState] = useState<string | null>(getStoreSubMenuManagementOpenState);

  const toggleMenu = (menuId: string | null) => {
    if (!menuId) {
      setMenuOpenState(null);
      sessionStorage.removeItem(MENU_SESSION_STORAGE_KEY);
    } else if (menuOpenState === menuId) {
      setMenuOpenState(null);
      sessionStorage.removeItem(MENU_SESSION_STORAGE_KEY);
    } else {
      sessionStorage.setItem(MENU_SESSION_STORAGE_KEY, menuId);
      setMenuOpenState(menuId);
    }
  };

  const toggleSubMenu = (subMenuId: string | null) => {
    if (!subMenuId) {
      setSubMenuOpenState(null);
      sessionStorage.removeItem(SUB_MENU_SESSION_STORAGE_KEY);
    } else if (subMenuOpenState === subMenuId) {
      setSubMenuOpenState(null);
      sessionStorage.removeItem(SUB_MENU_SESSION_STORAGE_KEY);
    } else {
      sessionStorage.setItem(SUB_MENU_SESSION_STORAGE_KEY, subMenuId);
      setSubMenuOpenState(subMenuId);
    }
  };

  const toggleSubMenuManagement = (subMenuManagementId: string | null) => {
    if (!subMenuManagementId) {
      setSubMenuManagementState(null);
      sessionStorage.removeItem(SUB_MENU_MANAGEMENT_SESSION_STORAGE_KEY);
    } else if (subMenuManagementState === subMenuManagementId) {
      setSubMenuManagementState(null);
      sessionStorage.removeItem(SUB_MENU_MANAGEMENT_SESSION_STORAGE_KEY);
    } else {
      sessionStorage.setItem(SUB_MENU_MANAGEMENT_SESSION_STORAGE_KEY, subMenuManagementId);
      setSubMenuManagementState(subMenuManagementId);
    }
  };

  useEffect(() => {
    getStoreMenuOpenState();
    getStoreSubMenuOpenState();
  }, []);

  return {
    hookMenuOpen: menuOpenState,
    hookSetMenuOpen: toggleMenu,
    hookSubMenuOpen: subMenuOpenState,
    hookSetSubMenuOpen: toggleSubMenu,
    hookSubMenuManagementOpen: subMenuManagementState,
    hookSetSubMenuManagementOpen: toggleSubMenuManagement,
  };
};
