import styled from 'styled-components';
import { InputText } from '../../../../../../components';
import { colors } from '../../../../../../styles/theme';

export const WrapperStyled = styled.div`
  display: grid;
  grid-template-columns: repeat(4, 1fr);
  margin-top: 2rem;
  grid-gap: 2rem;
`;

export const InputTextStyled = styled(InputText)`
  grid-column: 1 / -1;
  margin-bottom: 1.5rem;

  &:nth-child(3), &:last-child {
    grid-column: 1 / 4;
  }
`;

export const CheckBoxAreaStyled = styled.div`
  grid-column: 4/ -1;
  display: flex;
  align-items: center;
  color: ${colors.color1};
`;
