import React from 'react';
import { faCircleXmark, faPlus } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

import {
  AppWrapper, Calendar, ErrorBanner, InputCalendar, InputText, InputTextarea, PageTitle, Select,
} from '../../../../components';
import { CreateCampaignModal } from '../../../../components/CreateCampaignModal';
import { path, TOAST_ERR_MESSAGES_NO_PAGE } from '../../../../utils';
import {
  BUTTON_LABELS, LABELS, PLACEHOLDERS, SECTION_HEADERS,
} from '../../utils';
import { useNewAd } from '../hooks';
import { DefaultPropsType } from '../../../../types';

import * as Styled from './styles';

type AdTextLinkProps = DefaultPropsType;

const AdTextLink = ({ permissionsCodeList = [] }: AdTextLinkProps) => {
  const { hookIsReadOnlyList, ...hook } = useNewAd(permissionsCodeList);
  const [isReadOnly] = hookIsReadOnlyList;

  return (
    <AppWrapper permissionsCodeList={permissionsCodeList}>
      <ErrorBanner
        isOpen={hook.hookErrorBanner}
        message={TOAST_ERR_MESSAGES_NO_PAGE(hook.hookAdErrorMessage)}
      />
      <Styled.HeaderStyled>
        <PageTitle>{path.addNewAdLink.name}</PageTitle>
      </Styled.HeaderStyled>

      <InputText
        required
        type="text"
        label={LABELS.AD_NAME}
        value={hook.hookName}
        onChange={hook.hookSetName}
        error={hook.hookAdErrors.adName}
        onBlur={hook.hookHandleValidation}
        disabled={isReadOnly}
      />
      <Styled.FieldsStyled>
        <Select
          width="42%"
          required
          label={LABELS.STATUS}
          onChange={hook.hookSetSelectedStatus}
          selected={hook.hookSelectedStatus}
          options={hook.hookAdStatusTypeList}
          isDisabled={hook.hookLoading || isReadOnly}
          isLoading={hook.hookLoading}
          error={hook.hookAdErrors.status}
          placeholder={PLACEHOLDERS.STATUS}
        />
        <Select
          width="42%"
          label={LABELS.CAMPAIGN}
          onChange={hook.hookSetSelectedCampaign}
          selected={hook.hookSelectedCampaign}
          options={hook.hookCampaignList}
          isDisabled={hook.hookLoading || isReadOnly}
          isLoading={hook.hookLoading}
          placeholder={PLACEHOLDERS.CAMPAIGN}
        />
        <Styled.ButtonStyled
          theme="tertiary"
          onClick={() => hook.hookSetCampaignModal(true)}
          disabled={isReadOnly}
        >
          <FontAwesomeIcon icon={faPlus} />
          {BUTTON_LABELS.CREATE_CAMPAIGN}
        </Styled.ButtonStyled>
      </Styled.FieldsStyled>
      <InputTextarea
        required
        height="134px"
        label={LABELS.DESCRIPTION}
        onChange={hook.hookSetDescription}
        value={hook.hookDescription}
        error={hook.hookAdErrors.description}
        onBlur={hook.hookHandleValidation}
        disabled={isReadOnly}
      />
      <Styled.TitleStyled>{SECTION_HEADERS.LINK}</Styled.TitleStyled>
      {hook.hookTrackingLinkList.map((item: any, index: number) => (
        <Styled.LinkFieldsStyled key={index}>
          <InputText
            type="text"
            label={LABELS.LINK_CONTENT}
            onChange={(e) => hook.hookSetContent(e, index)}
            value={item.content}
            disabled={isReadOnly}
          />
          <InputText
            type="url"
            label={LABELS.LANDING_URL}
            placeholder={PLACEHOLDERS.URL}
            onChange={(e) => hook.hookSetLandingPage(e, index)}
            value={item.landingPage}
            error={hook.hookTrackingLinkErrors[index]}
            onBlur={hook.hookValidateTrackingWebsite}
            disabled={isReadOnly}
          />

          {index !== 0 && (
            <Styled.DeleteButtonStyled
              theme="tertiary"
              onClick={() => hook.hookRemoveTrackingLink(index)}
              disabled={hook.hookTrackingLinkList.length === 1}
            >
              <FontAwesomeIcon
                icon={faCircleXmark}
                size="sm"
              />
            </Styled.DeleteButtonStyled>
          )}
        </Styled.LinkFieldsStyled>
      ))}
      <Styled.ButtonFieldStyled>
        <Styled.ButtonStyled
          theme="tertiary"
          onClick={hook.hookAddNewTrackingLink}
          disabled={isReadOnly}
        >
          <FontAwesomeIcon icon={faPlus} />
          {BUTTON_LABELS.ADD_FIELD}
        </Styled.ButtonStyled>
      </Styled.ButtonFieldStyled>

      {!(hook.hookSelectedCampaign && hook.hookSelectedCampaign.label !== '') && (
        <>
          <Styled.TitleStyled>{SECTION_HEADERS.ADVANCED}</Styled.TitleStyled>
          <Styled.AdvancedStyled>
            <Select
              label={LABELS.PRODUCT}
              onChange={hook.hookSetSelectedProduct}
              selected={hook.hookSelectedProduct}
              isDisabled={hook.hookLoading || isReadOnly}
              isLoading={hook.hookLoading}
              options={hook.hookProductList}
              placeholder={PLACEHOLDERS.PRODUCT}
            />
            <InputCalendar
              label={LABELS.START_DATE}
              value={hook?.hookStartDate?.toDateString() || ''}
              placeholder={PLACEHOLDERS.DATE}
              onClick={hook.hookOnOpenStartCalendar}
              isDisabled={isReadOnly}
            />
            <InputCalendar
              label={LABELS.END_DATE}
              value={hook?.hookEndDate?.toDateString() || ''}
              placeholder={PLACEHOLDERS.DATE}
              onClick={hook.hookOnOpenEndCalendar}
              error={hook.hookAdErrors.endDate}
              isDisabled={isReadOnly}
            />
            <Styled.SelectStyled
              label={LABELS.TAGS}
              onChange={hook.hookSetTags}
              options={hook.hookTagList}
              placeholder={PLACEHOLDERS.TAGS}
              selectedMulti={hook.hookTags}
              isDisabled={hook.hookLoading || isReadOnly}
              isLoading={hook.hookLoading}
              isMulti
              creatable
            />
            <Select
              required
              label={LABELS.LANGUAGE}
              onChange={hook.hookSetLanguage}
              selected={hook.hookLanguage}
              isDisabled={hook.hookLoading || isReadOnly}
              isLoading={hook.hookLoading}
              options={hook.hookLanguageList}
              error={hook.hookAdErrors.language}
            />
          </Styled.AdvancedStyled>
        </>
      )}

      <Styled.FooterStyled>
        <Styled.ButtonStyled
          theme="secondary"
          onClick={hook.hookOnBack}
        >
          {BUTTON_LABELS.CANCEL}
        </Styled.ButtonStyled>
        <Styled.ButtonStyled
          onClick={hook.hookCreateLinkAd}
          disabled={isReadOnly}
        >
          {BUTTON_LABELS.SAVE}
        </Styled.ButtonStyled>
      </Styled.FooterStyled>

      <Calendar
        date={hook.hookStartDate}
        onApply={hook.hookOnApplyStartCalendar}
        onCancel={hook.hookOnCancelStartCalendar}
        isOpen={hook.hookStartCalendarOpen}
        hasRange={false}
        maxYear={new Date().getFullYear() + 2}
        isSingle
      />

      <Calendar
        date={hook.hookEndDate}
        onApply={hook.hookOnApplyEndCalendar}
        onCancel={hook.hookOnCancelEndCalendar}
        isOpen={hook.hookEndCalendarOpen}
        hasRange={false}
        maxYear={new Date().getFullYear() + 2}
        isSingle
      />
      <CreateCampaignModal
        isOpen={hook.hookCampaignModal}
        onClose={() => hook.hookSetCampaignModal(!hook.hookCampaignModal)}
        setCampaign={hook.hookSetCampaignAfterCreation}
      />
    </AppWrapper>
  );
};

export default AdTextLink;
