import { faChevronRight } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import React from 'react';
import { useNavigate } from 'react-router-dom';
import { Button } from '../../../../../components';
import { CellWrapper } from '../components';
import { path, MERCHANT_PREFIX, dateFormatter } from '../../../../../utils';
import { CommissionFormatted, Transaction } from '../../../../../utils/formatCommissions';

const ActionCell = ({ commissionId } : {commissionId: string}) => {
  const navigate = useNavigate();

  const goTo = () => {
    navigate(`${MERCHANT_PREFIX}${path.editCommissions.href}`, {
      state: commissionId,
    });
  };
  return (
    <CellWrapper>
      <Button
        theme="quaternary"
        onClick={goTo}
      >
        <FontAwesomeIcon icon={faChevronRight} />
      </Button>
    </CellWrapper>
  );
};

export const columns: TableColumn[] = [
  {
    dataField: 'id',
    text: 'ID',
    formatter: (cell: any) => (
      <CellWrapper>
        {cell}
      </CellWrapper>
    ),
    width: '70px',
  },
  {
    dataField: 'commissionType',
    text: 'Commission/Members',
    formatter: (val: any, row: CommissionFormatted) => (
      <CellWrapper>
        <b>
          {`${!row?.publishers?.length && !row?.publisherGroups?.length ? 'Default' : 'Custom'} ${val}`}
        </b>
        <p>
          {row.memberType}
        </p>
      </CellWrapper>
    ),
    width: '18%',
  },
  {
    dataField: 'transaction',
    text: 'Transaction',
    formatter: (cell: Transaction) => (
      <CellWrapper>
        <b>
          {cell.commissionBase}
        </b>
        <p>
          {cell.minimumSaleAmount}
        </p>
      </CellWrapper>
    ),
    width: '18%',
  },
  {
    dataField: 'product',
    text: 'Products',
    formatter: (cell: any) => (
      <CellWrapper>
        {cell?.name || ''}
      </CellWrapper>
    ),
    width: '18%',
  },
  {
    dataField: 'payable',
    text: 'Commission Payable',
    formatter: (cell: any) => (
      <CellWrapper>
        {cell || ''}
      </CellWrapper>
    ),
    width: '18%',
  },
  {
    dataField: 'startDate',
    text: 'Start/End Date',
    formatter: (cell: any, row: CommissionFormatted) => (
      <CellWrapper>
        {dateFormatter(new Date(cell))}
        {' '}
        -
        {' '}
        {row.endDate ? dateFormatter(new Date(row.endDate)) : ''}
      </CellWrapper>
    ),
    width: '18%',
  },
  {
    dataField: '_',
    text: '',
    formatter: (_:any, row: CommissionFormatted) => (
      <ActionCell commissionId={row.id} />
    ),
    width: '5%',
  },
];
