import React from 'react';
import { useNavigate } from 'react-router-dom';
import { faTriangleExclamation } from '@fortawesome/free-solid-svg-icons';

import {
  AppWrapper, Button, PageTitle, Tabs,
} from '../../../../components';
import TrackingSettings from './Tabs/TrackingSettings';
import TransactionSettings from './Tabs/TransactionSettings';
import { useManageSettings } from './hooks';
import { MANAGE_SETTINGS } from './enum';
import { DefaultPropsType } from '../../../../types';

import * as Styled from './styles';

type ManageTrackingTransactionSettingsProps = DefaultPropsType;

const ManageTrackingTransactionSettings = ({ permissionsCodeList = [] }: ManageTrackingTransactionSettingsProps) => {
  const navigate = useNavigate();
  const { hookIsReadOnlyList, ...hook } = useManageSettings(permissionsCodeList);
  const [isReadOnly] = hookIsReadOnlyList;

  return (
    <AppWrapper permissionsCodeList={permissionsCodeList}>
      {hook.hookTransaction.hookCurrencyError && !hook.hookIsTracking && (
        <Styled.ErrorStyled>
          <Styled.IconStyled icon={faTriangleExclamation} />
          {MANAGE_SETTINGS.CURRENCY_ERROR}
        </Styled.ErrorStyled>
      )}
      <Styled.HeaderStyled>
        <PageTitle>{MANAGE_SETTINGS.TITLE}</PageTitle>

        <Styled.HeaderButtonAreaStyled>
          <Button
            theme="secondary"
            onClick={() => navigate(-1)}
          >
            {MANAGE_SETTINGS.BUTTONS.CANCEL}
          </Button>

          <Button
            onClick={() => hook.hookSaveChanges()}
            disabled={isReadOnly}
          >
            {MANAGE_SETTINGS.BUTTONS.SAVE}
          </Button>
        </Styled.HeaderButtonAreaStyled>
      </Styled.HeaderStyled>

      <Tabs
        tabNames={['Tracking Settings', 'Transaction Settings']}
        elements={[
          <TrackingSettings
            hook={hook.hookTracking}
            setIsTracking={() => hook.hookSetIsTracking(true)}
            isReadOnly={isReadOnly}
          />,
          <TransactionSettings
            hook={hook.hookTransaction}
            setIsTracking={() => hook.hookSetIsTracking(false)}
            isReadOnly={isReadOnly}
          />,
        ]}
      />
    </AppWrapper>
  );
};

export default ManageTrackingTransactionSettings;
