import { useLazyQuery, useMutation } from '@apollo/client';
import { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { useModal, useQueries } from '../../../../../../../hooks';
import { GET_MEMBERS } from '../graphql/queries/getMembers';
import { DELETE_MEMBERS } from '../graphql/mutations';

const ROWS_PER_PAGE = 10;

export const useMembers = () => {
  const [memberList, setMemberList] = useState<any>([]);
  const [disableRemove, setDisableRemove] = useState(true);
  const [search, setSearch] = useState<string>();
  const [publishersToRemove, setPublishersToRemove] = useState(0);
  const [openModal, setOpenModal] = useModal(false);
  const [dataCopy, setDataCopy] = useState<any[]>([]);
  const [tableData, setTableData] = useState<any[]>([]);
  const [currentPage, setCurrentPage] = useState<number>(1);
  const [totalPages, setTotalPages] = useState<number>(0);

  const navigate = useNavigate();
  const { id } = useQueries();
  const [getMembers, { loading }] = useLazyQuery(GET_MEMBERS);
  const [deleteMembers] = useMutation(DELETE_MEMBERS);

  const setSearchHandler = (value: React.ChangeEvent<HTMLInputElement>) => {
    setSearch(value.target.value);
    if (value.target.value !== '') {
      const newList = memberList.filter((member: any) => {
        if (member.publisher.id.includes(value.target.value)
        || member.company.name.toLowerCase().includes(value.target.value.toLowerCase())
        || member.company.website.includes(value.target.value.toLowerCase())
        ) return member;
      });
      setMemberList(newList);
      setTableData(newList.slice(0, ROWS_PER_PAGE));
      setTotalPages(Math.ceil(newList.length / ROWS_PER_PAGE));
      setCurrentPage(1);
    } else {
      setMemberList(dataCopy);
      setTableData(dataCopy.slice(0, ROWS_PER_PAGE));
      setTotalPages(Math.ceil(dataCopy.length / ROWS_PER_PAGE));
      setCurrentPage(1);
    }
  };

  const getMembersHandler = async () => {
    const { data } = await getMembers({
      variables: {
        input: id,
      },
      fetchPolicy: 'no-cache',
    });
    if (data.group.memberships !== undefined) {
      const members = data.group.memberships.map((member: any) => ({
        id: member.id,
        check: false,
        publisher: {
          id: member.publisher.id,
          pendingTransactions: !!member.pendingTrans?.id,
        },
        company: {
          name: member.publisher.companyName,
          website: member.publisher.trackings[0].primaryWebsite,
        },
        location: {
          ip: member.publisher.geoIp.IP || 'Unavailable',
          country: member.publisher.geoIp.country || 'Unavailable',
        },
        application: new Date(member.createdAt).toLocaleDateString(undefined, { month: 'short', day: 'numeric', year: 'numeric' }),
        memberSince: new Date(member.approvedDate).toLocaleDateString(undefined, { month: 'short', day: 'numeric', year: 'numeric' }),
        url: member.publisher.trackings[0].websiteStatus,
        status: member.status,
        detail: member.id,
        publisherGroups: member.publisherGroups || [],
        disabled: member.status === 'Closed',
      }));

      setDataCopy(members);
      setMemberList(members);
      setCurrentPage(1);
      setTableData(members.slice(0, ROWS_PER_PAGE));
      setTotalPages(Math.ceil(members.length / ROWS_PER_PAGE));
    }
  };

  const deleteMembersHandler = async (list: Record<string, any>[]) => {
    await deleteMembers({
      variables: {
        inputs: list,
      },
    });
    getMembersHandler();
  };

  const setMemberListHandler = (value: any[]) => {
    const hasCheck = value.filter((row) => row.check);
    if (hasCheck.length > 0) {
      setPublishersToRemove(hasCheck.length);
      setDisableRemove(false);
    } else {
      setDisableRemove(true);
    }
    setTableData(value);
  };

  const removeFromGroupHandler = () => {
    const publishersChecked = memberList.filter((member: any) => member.check);
    const listToRemove = publishersChecked.map((publisher: any) => ({
      id: publisher.detail,
      publisherGroupIds: publisher.publisherGroups.filter((group: {id: string}) => group.id !== id).map((group: {id: string}) => group.id),
    }));
    setOpenModal();
    deleteMembersHandler(listToRemove);
  };

  const setPageHandler = (newPage: number) => {
    setTableData(memberList.slice((newPage - 1) * ROWS_PER_PAGE, newPage * ROWS_PER_PAGE));
    setCurrentPage(newPage);
  };

  useEffect(() => {
    getMembersHandler();
  }, []);

  return {
    hookSearch: search,
    hookSetSearch: setSearchHandler,
    hookLoading: loading,
    hookMembers: memberList,
    hookDisableRemove: disableRemove,
    hookSetTableData: setMemberListHandler,
    hookRemovePublishers: removeFromGroupHandler,
    hookOpenModal: openModal,
    hookSetOpenModal: setOpenModal,
    hookPublishersToRemove: publishersToRemove,
    hookNavigate: navigate,

    hookTableData: tableData,
    hookCurrentPage: currentPage,
    hookTotalPages: totalPages,
    hookSetPageHandler: setPageHandler,
  };
};
