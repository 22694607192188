export const typeOptions = [
  {
    label: 'All Type',
    value: '',
  },
  {
    label: 'CPA - Cost per Action',
    value: 'CPA',
  },
  {
    label: 'Referral',
    value: 'Referral',
  },
  {
    label: 'Revenue Share',
    value: 'RevShare',
  },
  {
    label: 'Bonus',
    value: 'Bonus',
  },
];

export const defaultTypeOption: SelectOption = {
  label: 'All Type',
  value: '',
};

export const defaultProductOptions: SelectOption[] = [
  {
    label: 'Any Products',
    value: '',
  },
  {
    label: 'All Products',
    value: 'allProducts',
  },
];
