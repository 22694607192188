import React from 'react';
import { faFileDownload } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

import {
  AppWrapper, PageTitle, Pagination, ReportPlaceholder,
} from '../../../components';
import { Table } from '../../../components/Table';
import { path } from '../../../utils';
import { useReportDetails } from './hooks/useReportDetails';
import { columns } from './contracts/columns';
import { DETAILED_RECORDS } from './enums';
import { DefaultPropsType } from '../../../types';

import * as Styled from './styles';

type PaymentReportDetailsProps = DefaultPropsType;

const PaymentReportDetails = ({ permissionsCodeList = [] }: PaymentReportDetailsProps) => {
  const hooks = useReportDetails();

  return (
    <AppWrapper permissionsCodeList={permissionsCodeList}>
      <Styled.HeaderStyled>
        <PageTitle
          isLoading={hooks.loading}
          loadingText={DETAILED_RECORDS.LOADING_TEXT}
        >
          {path.paymentReportDetails.name}
        </PageTitle>
        <Styled.ButtonStyled
          theme={DETAILED_RECORDS.BUTTON_THEME}
          onClick={hooks.hookHandleBack}
        >
          {DETAILED_RECORDS.BACK}
        </Styled.ButtonStyled>
      </Styled.HeaderStyled>
      <Styled.ButtonWrapperStyled>
        <Styled.ButtonStyled
          theme={DETAILED_RECORDS.BUTTON_THEME}
          onClick={hooks.hookDownloadCSV}
        >
          <FontAwesomeIcon icon={faFileDownload} />
          {DETAILED_RECORDS.DONWLOAD}
        </Styled.ButtonStyled>
      </Styled.ButtonWrapperStyled>
      {hooks.hookTableData.length > 0
        && (
          <Table
            columns={columns(hooks.hookGoToPdfPage, hooks.hookHandleRightArrow)}
            data={hooks.hookTableData}
            sortColumn={hooks.hookSort}
            onSort={hooks.hookOnSort}
            footerData={hooks.hookFooterData}
            onRowClick={hooks.hookHandleRightArrow}
          />
        )}
      {hooks.hookDataLoad && (
      <Pagination
        currentPage={hooks.hookCurrentPage}
        total={hooks.hookTotalPage}
        onPageChange={hooks.hookHandleChangePage}
      />
      )}
      {hooks.hookDataLoad && hooks.hookTableData.length === 0
        && (
          <ReportPlaceholder
            text={DETAILED_RECORDS.NO_RESULTS}
            subtext={DETAILED_RECORDS.RUN_ESTIMATE}
            display="not-found"
          />
        )}
    </AppWrapper>
  );
};

export default PaymentReportDetails;
