import React from 'react';
import { useEditCommissions } from '../../../hooks';
import { commissionBaseLabels } from '../utils';
import * as Styled from '../styles';

export const BonusStructure = ({ hook }: {hook: ReturnType<typeof useEditCommissions>}) => {
  if (!hook.hookCommissionData?.commissionStructure) return <p />;
  const { bonusFlat, bonusTiered } = hook.hookCommissionData.commissionStructure;
  const isTiered = bonusTiered.commissionBase;
  const commissionBase = isTiered ? bonusTiered.commissionBase : bonusFlat.commissionBase;
  const minimumSaleAmount = isTiered ? '' : bonusFlat.minimumSaleAmount;
  const commissionFee = isTiered ? '' : bonusFlat.commissionFee;
  const {
    tiers, calculationType, period, cutoffAmount,
  } = bonusTiered;
  const isHistory = period;
  const {
    // dynamic label based on different commission base
    commissionBaseFormat,
    labelOfSales,
    labelOfPerSales,
  } = commissionBaseLabels(commissionBase || '');

  const tierRows = tiers?.map((x, i) => (
    <Styled.TierRowStyled key={`tierRow${i}`}>
      <Styled.TierItemStyled>
        <Styled.InputTextStyled
          type="text"
          value={x.upTo}
          label={`Minimum ${labelOfSales} For Bonus Level ${i + 1}`}
          theme="readonly"
          readonly
        />
      </Styled.TierItemStyled>
      <Styled.TierItemStyled>
        <Styled.InputTextStyled
          type="text"
          value={x.commissionAmount}
          label={`Level ${i + 1} Bonus ($) Per ${labelOfPerSales}`}
          theme="readonly"
          readonly
        />
      </Styled.TierItemStyled>
    </Styled.TierRowStyled>
  ));
  return (
    <>
      <Styled.CommissionStructureRow>
        <Styled.InputTextStyled
          label="Commission Base"
          type="text"
          value={commissionBaseFormat}
          theme="readonly"
          readonly
        />
        <Styled.InputTextStyled
          type="text"
          value={minimumSaleAmount || ''}
          label={`Minimum ${commissionBase} to get bonus`}
          theme="readonly"
          readonly
        />
        <Styled.InputTextStyled
          type="text"
          value={commissionFee || ''}
          label="FLAT BONUS ($)"
          theme="readonly"
          readonly
        />
      </Styled.CommissionStructureRow>
      {tierRows}
      {isTiered && (
        <Styled.InputTextStyled
          type="text"
          value={cutoffAmount}
          label={`After Level ${tiers?.length || 1} Commissions`}
          theme="readonly"
          readonly
        />
      )}
      {isHistory && (
        <Styled.InputTextStyled
          type="text"
          value={period}
          label="Trailing Period in Months"
          theme="readonly"
          readonly
        />
      )}
      {isTiered && (
      <Styled.InputTextStyled
        type="text"
        value={calculationType || ''}
        label="Calculation Type"
        theme="readonly"
        readonly
      />
      )}
    </>
  );
};
