import React from 'react';
import { PRODUCT_FEED } from '../../enums';
import {
  Banking, Insurance, Investments, Mortgage, CreditCards,
} from './Variations';
import { PRODUCT_CATEGORIES } from '../../../../../../../utils';

const editProductTabs = (productCategory: string, hook: any, isReadOnly: boolean) => {
  switch (productCategory) {
    case PRODUCT_CATEGORIES.INVESTMENTS:
      return (
        <Investments
          hook={hook}
          isReadOnly={isReadOnly}
        />
      );
    case PRODUCT_CATEGORIES.INSURANCE:
      return (
        <Insurance
          hook={hook}
          isReadOnly={isReadOnly}
        />
      );
    case PRODUCT_CATEGORIES.BANKING:
      return (
        <Banking
          hook={hook}
          isReadOnly={isReadOnly}
        />
      );
    case PRODUCT_CATEGORIES.CREDIT_CARDS:
      return (
        <CreditCards
          hook={hook}
          isReadOnly={isReadOnly}
        />
      );
    case PRODUCT_CATEGORIES.MORTGAGE:
      return (
        <Mortgage
          hook={hook}
          isReadOnly={isReadOnly}
        />
      );
    default:
      return <div>{PRODUCT_FEED.NOT_FOUND}</div>;
  }
};

export default editProductTabs;
