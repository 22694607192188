import styled from 'styled-components';
import {
  Button, Checkbox, InputText, Select,
} from '../../../components';
import { InputPhone } from '../../../components/Input/Phone';
import { colors, fonts } from '../../../styles/theme';

export const SectionWrapperStyled = styled.div`
  display: grid;
  grid-template-columns: repeat(6, 1fr);
  grid-column-gap: 2%;
  grid-row-gap: 2rem;
  align-items: flex-start;
  margin-top: 2rem;
`;

export const SectionTitleStyled = styled.span`
  ${fonts.gotham.H22}
  color: ${colors.color9};
  grid-column: span 6;
`;

export const InputTwoColumnStyled = styled(InputText)`
  grid-column: span 2;
`;

export const InputThreeColumnStyled = styled(InputText)`
  grid-column: span 3;
`;

export const InputFourColumnStyled = styled(InputText)`
  grid-column: span 4;
`;

export const InputPhoneStyled = styled(InputPhone)`
  grid-column: span 2;
`;

export const CheckboxStyled = styled(Checkbox)`
  align-self: center;
  span {
    font-size: 16px;
  }
`;

export const SelectStyled = styled(Select)`
  grid-column: span 2;
`;

export const ButtonWrapperStyled = styled.div`
  display: flex;
  justify-content: end;
  margin-top: 2rem;
`;

export const ButtonStyled = styled(Button)`
  margin-left: 1rem;
`;
