import React from 'react';
import { faCircleXmark, faPlus } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Radio } from '../../../../../components';
import * as Styled from './styles';
import { BONUS_COMMISSION_BASES, TRAILING_PERIOD_OPTIONS } from '../enums';
import { CommissionStructureProps } from '../types';
import {
  BONUS_RADIO_OPTIONS, BUTTON_TEXT, COMMISSION_DEFAULT_CUSTOM, COMMISSION_LABELS, COMMISSION_STRUCTURE,
} from '../utils';

export const BonusCommissionStructure = ({ hook, isReadOnly }: CommissionStructureProps) => (
  <Styled.CommissionStructureWrapper isCustom={hook.hookCommissionSubType === COMMISSION_DEFAULT_CUSTOM.CUSTOM}>
    <Styled.TitleStyled>{COMMISSION_STRUCTURE}</Styled.TitleStyled>

    <Styled.RadioSectionStyled>
      {BONUS_RADIO_OPTIONS.map((x, index) => (
        <Styled.RadioStyled key={index}>
          <Radio
            name="commission"
            value={index}
            label={x}
            checked={hook.hookRadioSelected === index}
            onChange={hook.hookSetRadioSelected}
            disabled={isReadOnly}
          />
        </Styled.RadioStyled>
      ))}
    </Styled.RadioSectionStyled>

    <Styled.FlexWrapper>
      <Styled.StaticFieldsStyled>
        <Styled.SelectStyled
          label={COMMISSION_LABELS.COMMISSION_BASE}
          required
          options={BONUS_COMMISSION_BASES}
          selected={hook.hookCommissionBase}
          onChange={hook.hookSetCommissionBase}
          error={hook.hookCommissionStructureErrors.commissionBase}
          isDisabled={isReadOnly}
        />
        <Styled.InputTextThreeCStyled
          type="text"
          label={COMMISSION_LABELS.MIN_TO_GET(hook.hookCommissionBase.label)}
          value={hook.hookMinAmount}
          onChange={hook.hookSetMinAmount}
          error={hook.hookCommissionStructureErrors.minAmount}
          onBlur={hook.hookValidateStaticFields}
          disabled={isReadOnly}
        />
        <Styled.InputTextThreeCStyled
          type="number"
          value={hook.hookFlatValue}
          label={COMMISSION_LABELS.FLAT_BONUS}
          error={hook.hookCommissionStructureErrors.flatBonus}
          onChange={hook.hookSetFlatValue}
          required={hook.hookRadioSelected === 0}
          disabled={hook.hookRadioSelected !== 0 || isReadOnly}
        />
      </Styled.StaticFieldsStyled>
      {hook.hookTierList.map((item: any, index: number) => (
        <Styled.GridStyled key={index}>
          <Styled.InputTextTwoCStyled
            type="number"
            value={item.leftInput}
            error={hook.hookTierList[index].leftError}
            label={COMMISSION_LABELS.MIN_TRANSACTIONS(index + 1)}
            onChange={(e) => hook.hookSetLeftInput(e, index)}
            required={hook.hookRadioSelected !== 0}
            disabled={hook.hookRadioSelected === 0 || isReadOnly}
          />
          <Styled.InputTextTwoCStyled
            type="number"
            label={COMMISSION_LABELS.NUM_TRANSACTIONS(index + 1, true)}
            value={item.rightInput}
            error={hook.hookTierList[index].rightError}
            onChange={(e) => hook.hookSetRightInput(e, index)}
            required={hook.hookRadioSelected !== 0}
            disabled={hook.hookRadioSelected === 0 || isReadOnly}
          />
          {index !== 0 && index === hook.hookTierList.length - 1 && (
          <Styled.DeleteButtonStyled
            theme="tertiary"
            onClick={() => hook.hookRemoveItem(index)}
            disabled={isReadOnly}
          >
            <FontAwesomeIcon
              icon={faCircleXmark}
              size="sm"
            />
          </Styled.DeleteButtonStyled>
          )}
          {hook.hookRadioSelected !== 0 && index === hook.hookTierList.length - 1 && (
          <Styled.InputTextAfterStyled
            type="number"
            label={COMMISSION_LABELS.AFTER(hook.hookTierList.length)}
            value={hook.hookAfterLevels}
            onChange={hook.hookSetAfterLevels}
            disabled={isReadOnly}
          />
          )}
          {(hook.hookRadioSelected === 3 || hook.hookRadioSelected === 4) && index === hook.hookTierList.length - 1 && (
          <Styled.SelectAfterStyled
            label={COMMISSION_LABELS.TRAILING_PERIOD}
            required
            options={TRAILING_PERIOD_OPTIONS}
            selected={hook.hookTrailingPeriod}
            onChange={hook.hookSetTrailingPeriod}
            isDisabled={isReadOnly}
          />
          )}
        </Styled.GridStyled>
      ))}
      <Styled.ButtonFieldStyled>
        <Styled.ButtonStyled
          theme="tertiary"
          onClick={hook.hookAddItem}
          disabled={hook.hookRadioSelected === 0 || isReadOnly}
        >
          <FontAwesomeIcon icon={faPlus} />
          {BUTTON_TEXT.ADD_TIER}
        </Styled.ButtonStyled>
      </Styled.ButtonFieldStyled>
    </Styled.FlexWrapper>
  </Styled.CommissionStructureWrapper>
);
