import React from 'react';
import {
  faArrowDown, faGrip, faPlus,
} from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

import {
  AdGenerateCodeModal,
  AppWrapper, Button, Calendar, Checkbox, DragTable, ErrorBanner, LoadReportProfile, Modal, PageTitle, Pagination, RecordsDropdown, ReportPlaceholder, Select,
} from '../../../components';
import { InputCalendar } from '../../../components/Input/Calendar';
import { path, USER_TYPES_ID } from '../../../utils';
import { usePerformanceReport } from './hooks';
import CustomizeColumnsModal from './Modals/CustomizeColumns';
import { useUserInfo } from '../../../hooks';
import { SaveReportProfile } from './Modals/SaveReportProfile';
import { DefaultPropsType } from '../../../types';

import * as Styled from './styles';

type PerfomanceReportPropsType = DefaultPropsType

const PerfomanceReport = ({ permissionsCodeList = [] }: PerfomanceReportPropsType) => {
  const hook = usePerformanceReport();
  const userHook = useUserInfo();

  return (
    <AppWrapper permissionsCodeList={permissionsCodeList}>
      <Styled.WrapperStyled isAdvance={hook.hookAdvancedSearch}>
        <ErrorBanner
          message={hook.hookErrorMessage}
          isOpen={!!hook.hookErrorMessage}
        />
        <Styled.HeaderStyled>
          <PageTitle
            isLoading={hook.hookPageLoading}
            loadingText={hook.hookPageLoadingText}
          >
            {path.performanceReport.name}
            {hook.hookIsSavedReportLoaded && (
              <Styled.TitleInfoBox>
                {hook.hookLoadedReportName}
              </Styled.TitleInfoBox>
            )}

          </PageTitle>

          <Styled.HeaderButtonStyled>
            <LoadReportProfile
              disabled={hook.hookPageLoading || hook.hookSavedReportsLoading || !hook.hookCanLoadAndSave}
              loading={hook.hookPageLoading || hook.hookSavedReportsLoading}
              loadSavedReportHandler={hook.hookLoadSavedReport}
              reportList={hook.hookSavedReports}
            />
            <SaveReportProfile
              disabled={hook.hookIsDisabled || hook.hookPageLoading || !hook.hookReportGenerated || !hook.hookCanLoadAndSave || hook.hookNotMyReportLoaded}
              onSave={hook.hookOnSave}
              onUpdate={hook.hookOnUpdate}
              loading={hook.hookPageLoading}
              isUpdate={hook.hookIsSavedReportLoaded}
              reportName={hook.hookLoadedReportName}
            />
          </Styled.HeaderButtonStyled>
        </Styled.HeaderStyled>

        <Styled.FiltersStyled>
          <InputCalendar
            label="Date Range"
            value={hook.hookSelectDate}
            width="100%"
            onClick={hook.hookSetOpenCalendar}
            isDisabled={hook.hookPageLoading}
          />
          {/* Remove this field when acting as merchant */}
          {userHook.hookUserInfo.userTypesId !== USER_TYPES_ID.MERCHANT && userHook.hookWhoAmI.isActingAsUserTypeId !== USER_TYPES_ID.MERCHANT && (
          <Select
            label="Merchant ID - Name"
            name="merchantSelect"
            onChange={(option) => hook.hookSetSelectedMerchant(option)}
            selected={hook.hookSelectedMerchant}
            options={hook.hookMerchantList}
            width="100%"
            isLoading={hook.hookMerchantLoading}
            isDisabled={hook.hookPageLoading}
          />
          )}
          {/* Remove this field when acting as publisher */}
          {userHook.hookUserInfo.userTypesId !== USER_TYPES_ID.PUBLISHER && userHook.hookWhoAmI.isActingAsUserTypeId !== USER_TYPES_ID.PUBLISHER && (
            <Select
              label="Publisher ID - Name"
              name="publisherSelect"
              onChange={hook.hookSetSelectedPublisher}
              selected={hook.hookSelectedPublisher}
              options={hook.hookPublisherList}
              width="100%"
              isLoading={hook.hookMerchantInfoLoading}
              isDisabled={hook.hookDisabledFields || hook.hookPageLoading}
            />
          )}
          <Select
            label="Product ID - Name"
            name="productSelect"
            onChange={hook.hookSetSelectedProduct}
            selected={hook.hookSelectedProduct}
            options={hook.hookProductList}
            width="100%"
            isDisabled={hook.hookDisabledFields || hook.hookPageLoading}
            isLoading={hook.hookMerchantInfoLoading}
          />
          <Select
            label="Product Category"
            name="categorySelect"
            onChange={hook.hookSetSelectedProductCategory}
            selected={hook.hookSelectedProductCategory}
            options={hook.hookProductCategoryList}
            width="100%"
            isLoading={hook.hookProductCategoriesLoading}
            isDisabled={hook.hookPageLoading}
          />
          <Select
            label="Tracking Profile"
            name="trackingProfileSelect"
            onChange={hook.hookSetSelectedtrackingProfile}
            selected={hook.hookSelectedtrackingProfile}
            options={hook.hookTrackingProfileList}
            width="100%"
            isDisabled={(hook.hookDisabledFields && hook.hookCurrentUserType !== 'Publisher') || hook.hookPageLoading}
            isLoading={hook.hookMerchantInfoLoading}
          />
          <Select
            label="Group By Options"
            name="groupSelect"
            onChange={hook.hookSetSelectedGroupBy}
            selected={hook.hookSelectedGroupBy}
            options={hook.hookGroupByList}
            isDisabled={hook.hookPageLoading}
            width="100%"
          />

          <Styled.AdvanceSelectionStyled>
            <Checkbox
              label="Show Advanced Search"
              onChange={hook.hookSetAdvancedSearch}
              partial={false}
              checked={hook.hookAdvancedSearch}
            />
            <Button
              theme="text-only"
              onClick={hook.hookClearForm}
            >
              Clear Form
            </Button>
          </Styled.AdvanceSelectionStyled>
        </Styled.FiltersStyled>

        <Styled.AdvancedFiltersRowStyled>
          <Styled.SelectStyled
            label="AD Campaign"
            name="adCampaignSelect"
            onChange={hook.hookSetSelectedAdCampaign}
            selected={hook.hookSelectedAdCampaign}
            options={hook.hookAdCampaignList}
            width="32%"
            isDisabled={hook.hookDisabledFields || hook.hookPageLoading}
            isLoading={hook.hookMerchantInfoLoading}
          />
          {/* // TODO: Remove this field when acting as publisher */}
          {userHook.hookUserInfo.userTypesId !== USER_TYPES_ID.PUBLISHER && userHook.hookWhoAmI.isActingAsUserTypeId !== USER_TYPES_ID.PUBLISHER && (
            <Styled.SelectStyled
              label="Publisher Group"
              name="publisherGroupSelect"
              onChange={hook.hookSetSelectedPublisherGroup}
              selected={hook.hookSelectedPublisherGroup}
              options={hook.hookPublisherGroupList}
              width="32%"
              isDisabled={hook.hookDisabledFields || hook.hookPageLoading}
              isLoading={hook.hookMerchantInfoLoading}
            />
          )}
          <Styled.SelectStyled
            label="Ad ID"
            name="adIdSelect"
            onChange={hook.hookSetSelectedAd}
            selected={hook.hookSelectedAd}
            options={hook.hookAdsList}
            width="32%"
            isDisabled={hook.hookDisabledFields || hook.hookPageLoading}
            isLoading={hook.hookMerchantInfoLoading}
          />
        </Styled.AdvancedFiltersRowStyled>

        <Styled.OptionsRowStyled>
          <Styled.LeftStyled>
            <RecordsDropdown
              onChange={hook.hookSetSelectedRecordsPerPage}
              selected={hook.hookSelectedRecordsPerPage}
              options={hook.hookRecordsPerPage}
              isDisabled={hook.hookPageLoading}
              currentPage={hook.hookCurrentPage}
              totalRecords={hook.hookTotalRecords}
            />

            <Button
              theme="tertiary"
              onClick={hook.hookGenerateReportCSV}
              disabled={hook.hookIsDisabled || hook.hookPageLoading}
            >
              <FontAwesomeIcon
                icon={faArrowDown}
                style={{ marginRight: 5 }}
              />
              {' '}
              Download CSV
            </Button>
          </Styled.LeftStyled>

          <Styled.RightStyled>
            <Styled.ButtonStyled
              theme="tertiary"
              onClick={hook.hookSetColumnsModal}
              disabled={hook.hookPageLoading}
              loading={hook.hookPerformanceLoading}
              width="174px"
            >
              <FontAwesomeIcon
                icon={faGrip}
                style={{ marginRight: 5 }}
              />
              {' '}
              Customize Columns
            </Styled.ButtonStyled>

            <Styled.ButtonStyled
              onClick={() => hook.hookGenerateReport()}
              disabled={hook.hookPageLoading}
              loading={hook.hookPerformanceLoading}
              width="164px"
            >
              <FontAwesomeIcon
                icon={faPlus}
                style={{ marginRight: 5 }}
              />
              {' '}
              Generate Report
            </Styled.ButtonStyled>
          </Styled.RightStyled>
        </Styled.OptionsRowStyled>

        {hook.hookTableData.length > 0 && (
        <>
          <DragTable
            columns={hook.hookReportColumns}
            data={hook.hookTableData}
            onDragEnd={hook.hookSetOnDragEnd}
            onSort={hook.hookSortTableHandler}
            sortColumn={hook.hookSortColumn}
          />
          <Pagination
            total={hook.hookTotalPages}
            currentPage={hook.hookCurrentPage}
            onPageChange={hook.hookSetCurrentPage}
          />
        </>
        )}

        {!hook.hookTableData.length && (
          <ReportPlaceholder
            display={hook.hookReportGenerated ? 'not-found' : 'wait'}
            text={hook.hookReportGenerated ? 'Sorry! No results found' : 'Click generate reports to get started'}
            subtext={hook.hookReportGenerated ? "We couldn't find what you're looking for. Please try it again." : ''}
          />
        )}

        <Calendar
          key={hook.hookCalendarKey}
          date={hook.hookStartDate}
          endDate={hook.hookEndDate}
          onApply={hook.hookOnApplyCalendar}
          onCancel={hook.hookOnCancelCalendar}
          isOpen={hook.hookOpenCalendar}
          hasRange
          byRange={hook.hookRange}
        />
      </Styled.WrapperStyled>

      {/** Shown when grouping by Ad ID and click on Ad ID Value */}
      <AdGenerateCodeModal
        adId={hook.hookAdModalId}
        isOpen={hook.hookAdModal}
        onClose={hook.hookOpenCloseAdModal}
      />

      <Modal isOpen={hook.hookColumnsModal}>
        <CustomizeColumnsModal
          checkFn={hook.hookSetColumns}
          closeFn={hook.hookSetColumnsModal}
          columns={hook.hookGlobalColumns}
        />
      </Modal>
    </AppWrapper>
  );
};

export default PerfomanceReport;
