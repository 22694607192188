import { useLazyQuery, useMutation } from '@apollo/client';
import { ChangeEvent, useEffect, useState } from 'react';
import { useLocation } from 'react-router-dom';
import {
  useCountryRegions, useMerchantAddress, useTaxation, useToast, useUpload, useUserInfo,
} from '../../../../../hooks';
import {
  COUNTRIES, MERCHANT_STATUS_OPTIONS, QST_STATES, sortDate, sortString, SUCCESS_MESSAGES, SUPERUSER_SUBTYPE_IDS, USER_SORT_BY_OPTIONS, USER_TYPES_ID,
} from '../../../../../utils';
import { useValidation } from '../../../../../utils/validation';
import {
  ERROR_PREFLEX,
  NEVER, SORT_DEFAULT, STATUS_TYPES, TABLE_ACTIONS, validationfields, UPDATE_ERROR,
} from '../contracts';
import {
  GET_COMPANY, GET_COMPANY_USER, GET_USER, LIST_MERCHANT_FILTER_OPTIONS,
} from '../graphql/queries';
import { UPDATE_COMPANY, UPDATE_USER } from '../graphql/mutations';
import { Permission } from '../../../../../entities';
import { useActAsContext } from '../../../../../context';

type ImageFile = {
  file?: File;
  height: number;
  width: number;
}

export const useMerchantDetails = (isAdmin: boolean, permissionsCodeList: string[] = []) => {
  const { state } = useLocation();
  const { setActAsMerchantHandler } = useActAsContext();

  const userHook = useUserInfo();
  const { hookShowToast } = useToast();
  const upload = useUpload();

  const merchantAddressHook = useMerchantAddress();
  const taxationHook = useTaxation();
  const countryRegionHook = useCountryRegions();

  const validtionHook = useValidation();

  const [merchantId, setMerchantId] = useState<string>('');
  const [merchantName, setMerchantName] = useState<string>('');
  const [accountStatus, setAccountStatus] = useState<SelectOption | undefined>();
  const [statusHistory, setStatusHistory] = useState<SelectOption | undefined>();
  const [networkStatus, setNetworkStatus] = useState<SelectOption | undefined>();
  const [merchantType, setMerchantType] = useState<SelectOption | undefined>();
  const [url, setUrl] = useState<string>('');
  const [phoneOne, setPhoneOne] = useState<string>('');
  const [phoneTwo, setPhoneTwo] = useState<string>('');
  const [email, setEmail] = useState<string>('');
  const [userList, setUserList] = useState<any>([]);
  const [loadingMessage, setLoadingMessage] = useState<string>('');
  const [billingEmail, setBillingEmail] = useState<string>('');
  const [isSuperUser, setIsSuperUser] = useState<boolean>(true);
  const [coverUrl, setCoverUrl] = useState<string>('');
  const [userSort, setUserSort] = useState<SelectOption>(USER_SORT_BY_OPTIONS[0]);
  const [openModal, setOpenModal] = useState<boolean>(false);

  const [userInfo] = useState(userHook.hookUserInfo);
  const [onlyAdminFields, setOnlyAdminFields] = useState(true);

  const [billingInfoErrors, setBillingInfoErrors] = useState<{ [key: string]: string }>({});
  const [merchantErrors, setMerchantoErrors] = useState<{ [key: string]: string }>({});
  const [errorMessages, setErrorMessages] = useState<{ [key: string]: string }>({});
  const [validOnBlur, setValidOnBlur] = useState(false);
  const [urlError, setUrlError] = useState('');

  const [currentUser, setCurrentUser] = useState<any>();
  const [deactivateModal, setDeactivateModal] = useState(false);

  const [accountStatusTypesOptions, setAccountStatusTypesOptions] = useState<SelectOption[]>([]);
  const [regionOptions, setRegionOptions] = useState<SelectOption[]>([]);

  const [sortColumn, setSortColumn] = useState<TableSortColumn>(SORT_DEFAULT);
  const [isSubmiting, setIsSubmiting] = useState(false);

  const [imageFile, setImageFile] = useState<ImageFile>({
    file: undefined, height: 0, width: 0,
  });

  const [getCompany] = useLazyQuery(GET_COMPANY);
  const [compantyUsers, { loading }] = useLazyQuery(GET_COMPANY_USER);
  const [compantyUser] = useLazyQuery(GET_USER);
  const [getFilterOptions] = useLazyQuery(LIST_MERCHANT_FILTER_OPTIONS);

  const [updateUserHandle] = useMutation(UPDATE_USER);
  const [updateCompanyHandle] = useMutation(UPDATE_COMPANY);

  const formatterForOption = (element: any, key: string, key2: string) => ({
    value: element[key2],
    label: element[key],
  });

  const selectedFormat = (value: string): SelectOption => ({
    label: value,
    value,
  });

  const CLOSED = 'Closed';

  const regionsSetupHandler = () => {
    if (!merchantAddressHook.hookCountry?.label || !countryRegionHook.hookOriginalCountryList) return;
    const regionList = countryRegionHook.hookOriginalCountryList.find((country: {name: string}) => country.name === merchantAddressHook.hookCountry.label);
    if (!regionList || !regionList?.subdivisions) setRegionOptions([]);

    setRegionOptions(regionList?.subdivisions.map((region: {name: string}) => formatterForOption(region, 'name', 'name')));

    if (merchantAddressHook.hookRegion.label) {
      const companyRegion = regionList?.subdivisions.find((reg: {name: string}) => reg.name === merchantAddressHook.hookRegion.value);
      if (!companyRegion) merchantAddressHook.hookSetRegion({});
    }
  };

  const getCompanyInfo = async () => {
    try {
      let selectedMerchant;

      if (state?.merchantId !== undefined) {
        selectedMerchant = state.merchantId;
      } else {
        selectedMerchant = userHook.hookWhoAmI.companyId;
      }

      const { data: { company }, error } = await getCompany({
        variables: {
          id: selectedMerchant,
        },
        fetchPolicy: 'no-cache',
      });
      if (error) throw error;

      setMerchantId(selectedMerchant);
      setMerchantName(company.companyName);
      setAccountStatus(MERCHANT_STATUS_OPTIONS.find((option) => option.value === company.accountStatus));
      setNetworkStatus(selectedFormat(company.networkStatus));
      setMerchantType(selectedFormat(company.merchantType));
      setUrl(company.companyUrl);
      const historyStatusLabel = MERCHANT_STATUS_OPTIONS.find((option) => option.value === company.accountStatusHistory[0]?.status) || { value: '' };
      const historySattusDate = new Date(company.accountStatusHistory[0]?.date).toLocaleDateString(undefined, { month: 'short', day: 'numeric', year: 'numeric' }) || '';
      setStatusHistory(selectedFormat(`${historyStatusLabel?.value} on ${historySattusDate}`));

      merchantAddressHook.hookSetStringAddress(company.address1);
      merchantAddressHook.hookSetStringAddressTwo(company.address2);
      merchantAddressHook.hookSetStringCity(company.city);
      merchantAddressHook.hookSetStringPostalCode(company.zip);
      merchantAddressHook.hookSetRegion(selectedFormat(company.state));
      merchantAddressHook.hookSetCountry(selectedFormat(company.country));

      setPhoneOne(company.phone);
      setPhoneTwo(company.phone2);
      setEmail(company.companyEmail);
      setLoadingMessage('');
      setBillingEmail(company.paymentInfo.email || '');
      setCoverUrl(company.companyImgUrl);
      setErrorMessages({});
      taxationHook.hookSetStringGst(company.paymentInfo.gst);
      taxationHook.hookSetStringHst(company.paymentInfo.hst);
      taxationHook.hookSetStringPst(company.paymentInfo.pst);
      taxationHook.hookSetStringTaxation(company.paymentInfo.tax);
    } catch (error: any) {
      setErrorMessages({ message: error.message });
    }
  };

  const getUsersInfo = async () => {
    try {
      let selectedMerchant;

      if (state?.merchantId !== undefined) {
        selectedMerchant = state.merchantId;
      } else {
        selectedMerchant = userHook.hookWhoAmI.companyId?.toString();
      }

      const selectUserType = userHook.hookWhoAmI?.isActingAsUserTypeId ? USER_TYPES_ID[userHook.hookWhoAmI?.isActingAsUserTypeId] : null;

      const { data: { users: { users } }, error } = await compantyUsers({
        variables: {
          companyId: selectedMerchant,
          userType: selectUserType,
        },
        fetchPolicy: 'no-cache',
      });
      if (error) throw error;
      const userListFormatted = users.map((user: any) => ({
        id: user.id,
        auth0Id: user.auth0Id,
        position: user.position,
        firstName: user.firstName,
        lastName: user.lastName,
        email: user.email,
        status: user.status,
        fullName: `${user.firstName} ${user.lastName}`,
        securityRole: [...user.newRoles].sort((a: any, b: any) => (a.name > b.name ? 1 : -1)).map((role: {id: number, name: string, type: string}) => role.name).join(', '),
        isSuperUser: (user.roles.filter((role: {id: string}) => SUPERUSER_SUBTYPE_IDS.includes(role.id))).length > 0,
        lastLogin: user.lastLogin ? new Date(user.lastLogin).toLocaleDateString() : NEVER,
      }));
      setUserList(userListFormatted);
      const currUser = userListFormatted.find((user: any) => user.id === userInfo.id);
      setIsSuperUser(currUser.isSuperUser);
    } catch (error: any) {
      setMerchantoErrors({ message: error.message });
    }
  };

  const getUser = async (id: string) => {
    try {
      const { data: { user }, error } = await compantyUser({
        variables: {
          id,
        },
        fetchPolicy: 'no-cache',
      });
      if (error) throw error;
      setCurrentUser(user);
      setOpenModal(!openModal);
    } catch (error: any) {
      setMerchantoErrors({ message: error.message });
    }
  };

  const getFilterList = async () => {
    try {
      const { data: { getSearchCompaniesFilterOptions: { accountStatusTypes } }, error } = await getFilterOptions({
        variables: {
          input: { companyType: 'Merchant' },
        },
        fetchPolicy: 'no-cache',
      });
      if (error) throw error;

      const accountTypesList: any[] = [];
      accountStatusTypes.forEach((item: any) => {
        if (item.merchantDisplayValue !== CLOSED || (item.merchantDisplayValue === CLOSED && item.type === CLOSED)) {
          accountTypesList.push({
            label: item.merchantDisplayValue,
            value: item.type,
          });
        }
      });

      setAccountStatusTypesOptions(accountTypesList);
    } catch (error: any) {
      setMerchantoErrors({ countries: error.message });
    }
  };

  const updateUserState = async (newStatus: string, auth0Id: string) => {
    try {
      const { data: { updateUser }, errors } = await updateUserHandle({
        variables: {
          input: {
            auth0Id,
            status: newStatus,
          },
        },
        fetchPolicy: 'no-cache',
      });
      if (errors) throw errors;
      if (updateUser.user.id) getUsersInfo();
      return !!updateUser.user.id;
    } catch (error: any) {
      setMerchantoErrors({ message: error.message });
    }
  };

  const handleValidation = (submitting = false) => {
    if (!submitting && !validOnBlur) return;

    const validationValues = {
      companyName: merchantName,
      website: url,
      address: merchantAddressHook.hookAddress,
      city: merchantAddressHook.hookCity,
      region: merchantAddressHook.hookRegion.label,
      country: merchantAddressHook.hookCountry.label,
      postalCode: merchantAddressHook.hookPostalCode,
      phone: phoneOne,
      phoneTwo: phoneTwo || '',
      companyEmail: email,
      billingContact: billingEmail,
    };
    const valid = validtionHook.validateAll(validationValues, validationfields, setMerchantoErrors, true);
    return valid;
  };

  const validateBillingInfo = (submitting = false) => {
    if (!submitting && !validOnBlur) return;
    const errors: { [key: string]: string } = {};

    const pstCountry = merchantAddressHook.hookCountry.label === COUNTRIES.US || merchantAddressHook.hookCountry.label === COUNTRIES.AU;
    errors.taxation = validtionHook.validateTax(
      'taxation',
      taxationHook.hookTaxation,
      pstCountry ? merchantAddressHook.hookTaxationNumberDisabled : true,
    );

    errors.gstTaxation = validtionHook.validateTax(
      'gst',
      taxationHook.hookGst,
      merchantAddressHook.hookGstDisabled,
    );
    const pstQst = merchantAddressHook.hookCountry.label === COUNTRIES.CA && merchantAddressHook.hookRegion.label === QST_STATES[0] ? 'qst' : 'pst';
    errors.pstTaxation = validtionHook.validateTax(
      pstQst,
      taxationHook.hookPst,
      merchantAddressHook.hookPstDisabled,
    );

    errors.hstTaxation = validtionHook.validateTax('hst', taxationHook.hookHst, merchantAddressHook.hookHstDisabled);

    setBillingInfoErrors({ ...errors });
    return !!(errors.hstTaxation === '' && errors.gstTaxation === '' && errors.pstTaxation === '' && errors.taxation === '');
  };

  const validateWebsite = async () => {
    const newStatus = await validtionHook.validateUrlStatus(url);
    validtionHook.renderUrlCheck(newStatus, setUrlError);
  };

  const onUploadHandler = (file: any) => {
    if (file instanceof File) {
      const img = new Image();
      img.src = URL.createObjectURL(file);
      img.onload = () => {
        const image: ImageFile = {
          file, height: img.naturalHeight, width: img.naturalWidth,
        };
        setImageFile(image);
      };
    }
  };

  const returnImageurl = async () => {
    let productImageUrl = '';
    if (imageFile.file !== undefined) productImageUrl = await upload.hookUploadImageFile(imageFile.file);
    return productImageUrl;
  };

  const updateMerchantHandler = async () => {
    setIsSubmiting(true);
    setValidOnBlur(true);
    const valid = handleValidation(true);
    const validBilling = validateBillingInfo(true);
    setErrorMessages({});
    if (!valid || !validBilling) { setIsSubmiting(false); setValidOnBlur(false); setErrorMessages({ message: `${ERROR_PREFLEX} ${UPDATE_ERROR}` }); return; }
    const im: any = await returnImageurl();
    let selectedMerchant;

    if (state?.merchantId !== undefined) {
      selectedMerchant = state.merchantId;
    } else {
      selectedMerchant = userHook.hookWhoAmI.companyId?.toString();
    }

    try {
      const input = {
        id: selectedMerchant,
        accountStatus: accountStatus?.value,
        companyUrl: url,
        networkStatus: networkStatus?.value,
        companyName: merchantName,
        paymentInfo: {
          tax: taxationHook.hookTaxation,
          gst: taxationHook.hookGst,
          pst: taxationHook.hookPst,
          hst: taxationHook.hookHst,
          email: billingEmail,
        },
        address1: merchantAddressHook.hookAddress,
        address2: merchantAddressHook.hookAddressTwo,
        city: merchantAddressHook.hookCity,
        state: merchantAddressHook.hookRegion.value,
        country: merchantAddressHook.hookCountry.label,
        zip: merchantAddressHook.hookPostalCode,
        phone: phoneOne,
        phone2: phoneTwo,
        companyEmail: email,
        companyImgUrl: im?.url || coverUrl,
        merchantType: merchantType?.value,
      };
      const { data, errors } = await updateCompanyHandle({
        variables: {
          input,
        },
        fetchPolicy: 'no-cache',
      });
      if (errors) throw errors;
      if (data) {
        setValidOnBlur(false);
        hookShowToast(SUCCESS_MESSAGES.MERCHANT_EDIT(merchantName));
        if (isAdmin && data?.updateCompany?.company.id) {
          setActAsMerchantHandler(data.updateCompany.company.id);
        }
      }
    } catch (error: any) {
      setErrorMessages({ message: `${ERROR_PREFLEX} ${error.message}` });
    }
    setIsSubmiting(false);
  };

  const setOnlyAdminFieldsHandler = () => {
    if (userInfo.userTypesId !== USER_TYPES_ID.ADMIN) {
      setOnlyAdminFields(false);
    }
  };

  const setSortColumnHandler = (dataField: string, direction: 'asc' | 'desc' | undefined) => {
    if (sortColumn.direction === null) {
      setSortColumn({ column: dataField, direction });
    } else {
      setSortColumn({ column: dataField, direction: sortColumn.direction === 'asc' ? 'desc' : 'asc' });
    }
    if (dataField === 'lastLogin') {
      setUserList(sortDate(userList, dataField, direction));
    } else {
      setUserList(sortString(userList, dataField, direction));
    }
  };

  const checkActiveStatusHandler = (row: any) => row.status === 'Inactive';

  const setAccountStatusHandler = (value: SelectOption) => {
    setAccountStatus(value);
  };

  const setNetworkStatusHandler = (value: SelectOption) => {
    setNetworkStatus(value);
  };

  const setMerchantTypeHandler = (value: SelectOption) => {
    setMerchantType(value);
  };

  const setCompanyNameHandler = (e: ChangeEvent<HTMLInputElement>) => {
    setMerchantName(e.target.value);
  };

  const setUrlHandler = (e: ChangeEvent<HTMLInputElement>) => {
    setUrl(e.target.value);
  };

  const setPhoneOneHandler = (e: ChangeEvent<HTMLInputElement>) => {
    setPhoneOne(e.target.value);
  };

  const setPhoneTwoHandler = (e: ChangeEvent<HTMLInputElement>) => {
    setPhoneTwo(e.target.value);
  };

  const setEmailHandler = (e: ChangeEvent<HTMLInputElement>) => {
    setEmail(e.target.value);
  };

  const setBillingEmailHandler = (e: ChangeEvent<HTMLInputElement>) => {
    setBillingEmail(e.target.value);
  };

  const setUserSortHandler = (value: SelectOption) => {
    setUserSort(value);
  };

  const setDeactivateModalHandler = () => setDeactivateModal(!deactivateModal);

  const setModalHandler = async (value: any, row: any) => {
    switch (value) {
      case TABLE_ACTIONS.EDIT:
        getUser(row.id);
        break;
      case TABLE_ACTIONS.ACTIVE:
        updateUserState(STATUS_TYPES.ACTIVE, row.auth0Id);
        break;
      case TABLE_ACTIONS.DEACTIVE:
        setCurrentUser(row);
        setDeactivateModal(true);
        break;
      default:
        break;
    }
  };

  const handleDeactiveUser = async (auth0Id: string) => {
    const isDeactived = await updateUserState(STATUS_TYPES.INACTIVE, auth0Id);
    if (isDeactived) setDeactivateModal(false);
  };

  const setSaveCloseModalHandler = (isSave = false) => {
    if (isSave) getUsersInfo();
    setOpenModal(!openModal);
    setCurrentUser(undefined);
  };

  const setCloseModalHandler = () => {
    setCurrentUser(undefined);
    setOpenModal(!openModal);
  };

  useEffect(() => {
    getCompanyInfo();
    getUsersInfo();
    setOnlyAdminFieldsHandler();
    getFilterList();
  }, []);

  useEffect(() => {
    regionsSetupHandler();
  }, [JSON.stringify(merchantAddressHook.hookCountry), JSON.stringify(countryRegionHook.hookOriginalCountryList)]);

  return {
    hookOnlyAdminFields: onlyAdminFields,
    hookMerchantId: merchantId,
    hookMerchantName: merchantName,
    hookAccountStatus: accountStatus,
    hookStatusHistory: statusHistory,
    hookNetworkStatus: networkStatus,
    hookMerchantType: merchantType,
    hookUrl: url,
    hookIsSuperUser: isSuperUser,
    hookAddressOne: merchantAddressHook.hookAddress,
    hookAddressTwo: merchantAddressHook.hookAddressTwo,
    hookCity: merchantAddressHook.hookCity,
    hookPostalCode: merchantAddressHook.hookPostalCode,
    hookRegion: merchantAddressHook.hookRegion,
    hookCountry: merchantAddressHook.hookCountry,

    hookPhoneOne: phoneOne,
    hookPhoneTwo: phoneTwo,
    hookEmail: email,
    hookUserList: userList,

    hookSetSelectedCountry: merchantAddressHook.hookSetCountry,
    hookCountryOptions: countryRegionHook.hookCountriesList,

    hookRegionOptions: regionOptions,

    hookSetAccountStatus: setAccountStatusHandler,
    hookSetNetworkStatus: setNetworkStatusHandler,
    hookSetMerchantType: setMerchantTypeHandler,
    hookSetCompanyName: setCompanyNameHandler,
    hookPageLoading: loading,
    hookLoadingMessage: loadingMessage,

    hookBillingEmail: billingEmail,
    hookGst: taxationHook.hookGst,
    hookPst: taxationHook.hookPst,
    hookHst: taxationHook.hookHst,
    hookTax: taxationHook.hookTaxation,

    hookSetUrl: setUrlHandler,

    hookSetAddressOne: merchantAddressHook.hookSetAddress,
    hookSetAddressTwo: merchantAddressHook.hookSetAddressTwo,
    hookSetCity: merchantAddressHook.hookSetCity,
    hookSetPostalCode: merchantAddressHook.hookSetPostalCode,

    hookSetRegion: merchantAddressHook.hookSetRegion,
    hookSetPhoneOne: setPhoneOneHandler,
    hookSetPhoneTwo: setPhoneTwoHandler,
    hookCoverUrl: coverUrl,
    hookOnUploadHandler: onUploadHandler,
    hookSetEmail: setEmailHandler,

    hookSetBillingEmail: setBillingEmailHandler,
    hookSetGst: taxationHook.hookSetGst,
    hookSetPst: taxationHook.hookSetPst,
    hookSetHst: taxationHook.hookSetHst,
    hookSetTax: taxationHook.hookSetTaxation,

    hookGstDisabled: merchantAddressHook.hookGstDisabled,
    hookPstDisabled: merchantAddressHook.hookPstDisabled,
    hookHstDisabled: merchantAddressHook.hookHstDisabled,
    hookTaxationNumberDisabled: merchantAddressHook.hookTaxationNumberDisabled,

    hookUserSort: userSort,
    hookSetUserSort: setUserSortHandler,
    hookOpenModal: openModal,
    hookSetModalHandler: setModalHandler,
    hookSetToggleModal: setCloseModalHandler,
    hookSetSaveCloseModal: setSaveCloseModalHandler,
    hookUpdateMerchant: updateMerchantHandler,

    hookCurrentUser: currentUser,
    hookDeactivateModal: deactivateModal,
    hookSetDeactivateModal: setDeactivateModalHandler,

    hookValidateFields: handleValidation,
    hookValidateBillingInfo: validateBillingInfo,
    hookValidateWebsite: validateWebsite,

    hookAccountStatusTypeOptions: accountStatusTypesOptions,

    hookcheckActiveStatus: checkActiveStatusHandler,
    hookHandleDeactiveUser: handleDeactiveUser,

    hookSortColumn: sortColumn,
    hookSetSortColumn: setSortColumnHandler,

    hookBillingInfoErrors: billingInfoErrors,
    hookMerchantErrors: merchantErrors,
    hookUrlError: urlError,

    hookErrorMessages: errorMessages,

    hookIsLoading: loading,
    hookIsSubmiting: isSubmiting,

    hookId: userInfo.id,

    hookIsReadOnlyList: Permission.readOnlyPermissionsList(permissionsCodeList),
    hookUserManagementCanAccess: Permission.canAccess([permissionsCodeList[isAdmin ? 2 : 1]]),
    hookAccountDetailsCanAccess: Permission.canAccess([permissionsCodeList[isAdmin ? 1 : 0]]),
  };
};
