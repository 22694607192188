export const RULE_MANAGER = {
  ADD_RULE_BUTTON: 'Add Rule',
  CLEAR_FORM_BUTTON: 'Clear Form',
  DEACTIVATE_RULE: 'Deactivate Rule',
  LABELS: {
    PRODUCT_CATEGORY: 'Product Category',
    PRODUCT_NAME: 'Product Name/ID',
    STATUS: 'Status',
    RULES: 'Rules',
  },
  RULE_DEACTIVATED_TOAST: 'Rule successfully deactivated.',
};
