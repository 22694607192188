import { useLazyQuery } from '@apollo/client';
import { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { CHECK_EMAIL, LIST_LANGUAGES } from '../../graphql';
import { useSignUp } from '../../hooks';
import { USER_INFO } from '../enums';
import { path, REGEX_VALIDATORS } from '../../../../../utils';

export const useUserInfo = () => {
  const { contextState, contextDispatcher } = useSignUp();
  const [languageList, setLanguageList] = useState<SelectOption[]>([]);
  const navigate = useNavigate();
  const [emailError, setEmailError] = useState('');
  const [inputErrors, setInputErrors] = useState<{[key: string]: string}>({});
  const [revalidate, setRevalidate] = useState<boolean>(false);
  const [failedNext, setFailedNext] = useState<boolean>(false);
  const [errorMessage, setErrorMessage] = useState<string>('');

  const [checkEmail, { loading: checkEmailLoading }] = useLazyQuery(CHECK_EMAIL);
  const [getLanguageList, { loading: loadingLanguage }] = useLazyQuery(LIST_LANGUAGES);
  const languageFormatList = (list: any) => {
    const formatted = list.map((item: any) => ({
      label: item.language,
      value: item.language,
    }));

    return formatted;
  };

  const getLanguageListHandler = async () => {
    const { data } = await getLanguageList();
    if (data?.listLanguages !== undefined) {
      setLanguageList(languageFormatList(data.listLanguages));
    }
  };

  const emailValidation = async () => {
    const regex = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
    if (!regex.test(contextState.userEmail)) {
      setEmailError('Please enter a valid email address');
      return true;
    }
    const { data, error } = await checkEmail({
      variables: {
        email: contextState.userEmail,
      },
      onError(err) {
        setErrorMessage(err.message);
      },
    });
    if (error) {
      setErrorMessage(error.message);
      return true;
    }
    if (data && data.checkEmailExist) {
      setEmailError(USER_INFO.VALIDATIONS.EMAIL.EXISTS);
      return true;
    }
    setErrorMessage('');
    setEmailError('');
    return false;
  };

  const inputValidation = () => {
    const newErrorMessages = { ...inputErrors };
    // First Name
    const validFirstName = REGEX_VALIDATORS.NAME.REGEX.test(contextState.userFirstName);
    if (!validFirstName) newErrorMessages.firstName = 'Please enter your first name.';
    else newErrorMessages.firstName = '';
    // Last Name
    const validLastName = REGEX_VALIDATORS.NAME.REGEX.test(contextState.userLastName);
    if (!validLastName) newErrorMessages.lastName = 'Please enter your last name.';
    else newErrorMessages.lastName = '';
    // Password
    const validPassword = contextState.userPassword.length > 7;
    if (!validPassword) newErrorMessages.password = 'Password must have at least 8 characters';
    else newErrorMessages.password = '';

    setInputErrors(newErrorMessages);
    return !validFirstName || !validLastName || !validPassword;
  };

  const validateForm = async () => {
    emailValidation();
    inputValidation();

    if (await emailValidation() || inputValidation()) {
      return false;
    }
    return true;
  };

  const nextDisabled = !contextState.userFirstName || !contextState.userLastName || !contextState.userEmail || !contextState.userPassword;

  const handleInputUpdate = (value: Record<string, any>) => {
    contextDispatcher(value);
    if (failedNext) {
      setRevalidate(true);
    }
  };

  const navigateNextPageHandler = async () => {
    setFailedNext(true);
    if (await validateForm()) navigate(path.createAccountStep2.href);
  };

  const navigateHandler = (url: string) => {
    navigate(url);
  };

  if (revalidate) {
    setRevalidate(false);
    validateForm();
  }

  useEffect(() => {
    getLanguageListHandler();
  }, []);

  return {
    hookLoadingLanguage: loadingLanguage,
    hookCheckEmailLoading: checkEmailLoading,
    hookErrorMessage: errorMessage,
    hookLanguageList: languageList,
    hookContextState: contextState,
    hookContextDispatcher: contextDispatcher,
    hookNextDisabled: nextDisabled,
    hookNavigate: navigateHandler,
    hookNavigateNextPage: navigateNextPageHandler,
    hookFormValidation: validateForm,
    hookEmailError: emailError,
    hookInputErrors: inputErrors,
    hookHandleInputChange: handleInputUpdate,
  };
};
