import styled from 'styled-components';
import { Button, Select } from '../../../../components';
import { fonts } from '../../../../styles/theme';
import { toRem } from '../../../../utils';

export const HeaderStyled = styled.div`
  display: flex;
  justify-content: space-between;
  margin-bottom: 2.5rem;
`;

export const ButtonStyled = styled(Button)`
  svg {
    margin-right: 5px;
  }
`;

export const ButtonsStyled = styled.div`
  display: flex;
  justify-content: space-between;

  ${ButtonStyled} {
    margin-left: 1rem;
  }
`;

export const FooterButtonStyled = styled.div`
  display: flex;
  justify-content: end;
  margin-top: ${toRem(40)};
`;

export const ModalWrapperStyled = styled.div`
  padding: ${toRem(30)};
  width: 600px;
`;

export const ModalTitleStyled = styled.p`
  ${fonts.gotham.H26M}
`;

export const SelectStyled = styled(Select)`
  margin-top: ${toRem(40)};
`;
