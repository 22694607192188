import environment from '../config/environment';

export const useLogOut = () => {
  const logout = () => {
    // Workaround to fix Firefox cookie bug. Refer to https://nautilusdigital.atlassian.net/browse/FIN-3046
    document.cookie = `${environment.app.cookieName}='fakeValue';  expires=${(new Date(2099, 1, 1)).toUTCString()}; path=/; Secure`;
    document.cookie = `${environment.app.cookieName}=; expires=Thu, 01 Jan 1970 00:00:00 UTC; path=/;`;

    document.cookie = 'access_token=; expires=Thu, 01 Jan 1970 00:00:00 UTC; path=/; SameSite=none; Secure';
    document.cookie = 'id_token=; expires=Thu, 01 Jan 1970 00:00:00 UTC; path=/; SameSite=none; Secure';

    // Legacy cookies to be used with Knowledge Base and Support App
    document.cookie = `accessToken=; expires=Thu, 01 Jan 1970 00:00:00 UTC; domain=${environment.app.domain}`;
    document.cookie = `idToken=; expires=Thu, 01 Jan 1970 00:00:00 UTC; domain=${environment.app.domain}`;
    document.cookie = `FA_UT=; expires=Thu, 01 Jan 1970 00:00:00 UTC; domain=${environment.app.domain}`;
    document.cookie = `24-hour-cookie=;  expires=Thu, 01 Jan 1970 00:00:00 UTC; domain=${environment.app.domain}`;

    localStorage.clear();
    sessionStorage.clear();
    window.location.href = '/login';
  };

  return {
    hookLogout: logout,
  };
};
