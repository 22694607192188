import { useEffect, useState } from 'react';
import { useNavigate, useLocation } from 'react-router-dom';
import { useLazyQuery } from '@apollo/client';
import {
  PDF_TYPES, monthNames, paginator, dateFormatter,
  csvGenerator,
} from '../../../../utils';
import { path } from '../../../../utils/path';
import { GET_REPORT_DETAIL } from '../graphql/getReportDetail';
import exportCSV from '../../../../utils/exportCsv';
import { CSV } from '../enums';

export const useReportDetails = () => {
  const location = useLocation();
  const {
    month, year, publisherID, id, paymentStatus, paymentMethod, previousPath,
  } = location.state;

  const [tableData, setTableData] = useState<any>([]);
  const navigate = useNavigate();
  const [getReportDetail, { loading: getReportDetailLoading }] = useLazyQuery(GET_REPORT_DETAIL);
  const [reportDetails, setReportDetails] = useState<any[]>([]);
  const [currentPage, setCurrentPage] = useState(1);
  const [totalPages, setTotalPages] = useState(1);
  const [errorMessage, setErrorMessage] = useState('');
  const [sortColumn, setSortColumn] = useState<TableSortColumn>();
  const [footerData, setFooterData] = useState<any>({
    cpaCommissions: 0,
    commissionsPercent: 0,
    totalCommissions: 0,
    commissionsTax: 0,
    bonusAmount: 0,
    bonusTax: 0,
    totalPayable: 0,
    periodBalance: 0,
  });
  const [dataLoad, setDataLoad] = useState(false);

  const goToPdfPage = (values: any) => {
    navigate(path.invoiceStatement.href, {
      state: {
        publisherId: publisherID,
        pdfType: PDF_TYPES.PUBLISHER,
        month,
        year,
        productCategory: values.productCategory,
        merchantId: values.merchant.id,
        paymentId: values.paymentId,
        rate: values.rate,
      },
    });
  };

  const handleBack = () => {
    navigate(previousPath, {
      state: {
        context: true,
      },
    });
  };

  const handleRightArrow = (values: Record<string, any>) => {
    navigate(path.detailedPaymentRecords.href, {
      state: {
        publisherId: publisherID,
        month,
        year,
        merchantId: values.merchant.id,
        inheritFields: location.state,
      },
    });
  };

  const handleFooterData = (value: any[]) => {
    const FooterData = { ...footerData };
    value.forEach((row: any) => {
      FooterData.cpaCommissions += Number(row.cpaCommission);
      FooterData.commissionsPercent += Number(row.revShareCommission);
      FooterData.totalCommissions += Number(row.totalCommissions);
      FooterData.commissionsTax += Number(row.totalTaxAmount);
      FooterData.bonusAmount += Number(row.bonusAmount);
      FooterData.bonusTax += Number(row.bonusTax);
      FooterData.totalPayable += Number(row.totalPayable);
      FooterData.periodBalance += Number(row.periodBalance);
    });
    setFooterData(FooterData);
  };

  const handleChangePage = (value: any) => {
    setCurrentPage(value);
    setTableData(paginator(reportDetails, 10, value));
  };

  const onSort = (dataField: string, direction = 'asc') => {
    const nextDirection: number = direction === 'asc' ? 1 : -1;
    const compare = (a: any, b: any) => {
      let compareCondition = false;
      if (CSV.AMOUNTS.includes(dataField)) {
        compareCondition = Number(a[dataField]) < Number(b[dataField]);
      } else if (dataField === 'merchant') {
        compareCondition = a.merchant.companyName.toLowerCase() < b.merchant.companyName.toLowerCase();
      } else if (dataField === 'merchantId') {
        compareCondition = a.merchant.id < b.merchant.id;
      } else {
        compareCondition = a[dataField] < b[dataField];
      }
      return compareCondition ? nextDirection : nextDirection * -1;
    };
    const copyArray = [...reportDetails];
    const sortedArray = copyArray.sort((a, b) => compare(a, b));
    setReportDetails(sortedArray);
    setTableData(paginator(sortedArray, 10, 1));
    setSortColumn({ column: dataField, direction: sortColumn?.direction === 'desc' ? 'asc' : 'desc' });
    setCurrentPage(1);
  };

  const FormatTableData = (data: any[]) => {
    const tData = data.map((payment) => ({
      ...payment,
      paymentId: id,
      paymentStatus,
      paymentMethod,
      paymentPeriod: `${monthNames[month - 1]} / ${year}`,
      cpaCommission: (payment.cpaCommission * payment.rate),
      revShareCommission: (payment.revShareCommission * payment.rate),
      totalCommissions: (payment.totalCommissions * payment.rate),
      totalTaxAmount: (payment.totalTaxAmount * payment.rate),
      bonusAmount: (payment.bonusAmount * payment.rate),
      bonusTax: (payment.bonusTax * payment.rate),
      totalPayable: (payment.totalPayable * payment.rate),
    }));
    return tData;
  };

  const getRawCommission = async () => {
    const { data, error } = await getReportDetail({
      variables: {
        input: {
          publisherId: publisherID, month, year,
        },
      },
      errorPolicy: 'all',
    });
    setDataLoad(true);
    if (data?.rawCommissionsGroupByMerchant?.rawCommissions) {
      setReportDetails(FormatTableData(data.rawCommissionsGroupByMerchant.rawCommissions));
      setTableData(FormatTableData(paginator(data.rawCommissionsGroupByMerchant.rawCommissions, 10, 1)));
      setCurrentPage(1);
      setTotalPages(Math.ceil(data.rawCommissionsGroupByMerchant.count / 10));
      handleFooterData(FormatTableData(data.rawCommissionsGroupByMerchant.rawCommissions));
    }
    if (error && !data) {
      setErrorMessage('Failed to load report detail');
    }
  };

  const exportPaymentCsv = () => {
    const unpaginated = [...reportDetails];
    const formattedArray = unpaginated.map((item) => {
      const newItem = { ...item };
      CSV.COLUMNS.forEach((column: any) => {
        let data;
        if (column.dataField === 'merchant') {
          data = item.merchant.companyName;
        } else if (CSV.AMOUNTS.includes(column.dataField)) {
          data = Number(item[column.dataField]).toFixed(2);
        } else if (column.dataField === 'merchantId') {
          data = item.merchant.id;
        } else if (column.dataField === 'paymentPeriod') {
          data = dateFormatter(new Date(item[column.dataField]));
        } else {
          data = item[column.dataField];
        }
        newItem[column.dataField] = typeof data === 'string' ? data.replace(/,/g, '') : data;
      });
      return newItem;
    });
    const csv = exportCSV(CSV.COLUMNS, formattedArray);
    return csv;
  };

  const handleDownload = () => {
    csvGenerator(exportPaymentCsv(), 'payment-report.csv');
  };

  useEffect(() => {
    getRawCommission();
  }, []);

  return {
    hookTableData: tableData,

    hookCurrentPage: currentPage,

    hookTotalPage: totalPages,

    hookSort: sortColumn,

    hookOnSort: onSort,

    hookErrorMessage: errorMessage,

    loading: getReportDetailLoading,

    hookDataLoad: dataLoad,

    hookFooterData: footerData,

    hookHandleDownload: handleDownload,

    hookHandleChangePage: handleChangePage,

    hookDownloadCSV: handleDownload,

    hookGoToPdfPage: goToPdfPage,

    hookHandleBack: handleBack,

    hookHandleRightArrow: handleRightArrow,
  };
};
