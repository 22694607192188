export const INVALID_USER_MESSAGES = {
  TITLE: 'Thanks for signing up to Fintel Connect, your application has been submitted for approval.',
  PART1: 'Please expect a response within 48 hours. If you have questions in the interim, contact us ',
  PART2: '.',
};

export const INVALID_COMPANY_MESSAGES = {
  TITLE: 'We do not recognize an active account associated with these credentials.',
  PART1: 'Please contact our support team ',
  PART2: ' if you believe this is an error.',
};

export const EMAIL_MESSAGE = ' here';
export const PLACEHOLDER_BACK = 'Back to Website';
