import React from 'react';
import { faPlus } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

import {
  AddCommissionModal, AppWrapper, ErrorBanner, PageTitle, Pagination, Table,
} from '../../../../components';
import { path } from '../../../../utils';
import { columns } from './contracts';
import { useManageCommissions } from './hooks';
import { BUTTON_LABELS } from './utils';
import { DefaultPropsType } from '../../../../types';

import * as Styled from './styles';

type ManageCommissionsProps = DefaultPropsType;

const ManageCommissions = ({ permissionsCodeList = [] }: ManageCommissionsProps) => {
  const { hookIsReadOnlyList, ...hook } = useManageCommissions(permissionsCodeList);
  const [isReadOnly] = hookIsReadOnlyList;

  return (
    <AppWrapper permissionsCodeList={permissionsCodeList}>
      <ErrorBanner
        isOpen={!!hook.hookErrorMessage}
        message={hook.hookErrorMessage}
      />
      <Styled.HeaderStyled>
        <PageTitle>{path.manageCommissions.name}</PageTitle>
        <Styled.ButtonsStyled>
          <Styled.ButtonStyled
            theme="secondary"
            onClick={hook.hookNavigate}
          >
            {BUTTON_LABELS.VIEW_HISTORY}
          </Styled.ButtonStyled>
          <Styled.ButtonStyled
            onClick={hook.hookSetAddModal}
            disabled={isReadOnly}
          >
            <FontAwesomeIcon icon={faPlus} />
            {BUTTON_LABELS.ADD_COMMISSION}
          </Styled.ButtonStyled>
        </Styled.ButtonsStyled>
      </Styled.HeaderStyled>

      <Table
        columns={columns}
        data={hook.hookCommissionsData}
        isLoading={hook.hookIsLoading}
        loadWithData={hook.hookCommissionsData.length > 0}
        onRowClick={hook.hookOnRowClick}
      />
      {hook.hookCommissionsData.length > 0 && (
      <Pagination
        currentPage={hook.hookCurrentPage}
        total={hook.hookTotalPages}
        onPageChange={hook.hookSetCurrentPage}
      />
      )}

      <AddCommissionModal
        isOpen={hook.hookAddModal}
        onClose={hook.hookSetAddModal}
      />
    </AppWrapper>
  );
};

export default ManageCommissions;
