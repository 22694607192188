import styled from 'styled-components';
import { toRem } from '../../../../../../utils';
import { fonts, colors } from '../../../../../../styles/theme';
import { InputText } from '../../../../../../components';

type InputTextProps = {
  big?: boolean
}

export const WrapperStyled = styled.div`
  display: flex;
  flex-direction: column;
  gap: 30px;
  margin-top: ${toRem(40)};
`;

export const SectionTitleStyled = styled.h2`
  ${fonts.gotham.H18}
  color: ${colors.color1};
  display: flex;
  justify-content: space-between;
`;

export const GridedRowStyled = styled.div`
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  grid-template-rows: 100px;
  grid-row-gap: 2rem;
  grid-gap: 2%;
  margin-bottom: ${toRem(40)};
`;

export const CommissionStructureRow = styled.div`
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  grid-template-rows: 100px;
  grid-row-gap: 2rem;
  grid-gap: 2%;
`;

export const TierRowStyled = styled.div`
  display: flex;
  padding-bottom: 16px;
`;

export const TierItemStyled = styled.div`
  flex: 1;
`;

export const InputTextStyled = styled(InputText)<InputTextProps>`
  [value] {
    ${fonts.lato.H16R}; 
  }
  grid-column: ${({ big }) => (big ? 'span 3' : '')};
`;

export const ReferralTierSectionStyled = styled.div`
  display: grid;
  grid-template-columns: 1fr 1fr;
  row-gap: ${toRem(40)};
`;
