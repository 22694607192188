import { useLazyQuery } from '@apollo/client';
import { useEffect, useState } from 'react';
import { useUserInfo } from '../../../../hooks';
import {
  commissionsFormatter, CommissionFormatted,
} from '../../../../utils/formatCommissions';
import { compareValues } from '../../../Reports/PerfomanceReport/utils';
import {
  GET_PUBLISHER_COMMISSIONS, GetPublisherCommissionsInput, GetPublisherCommissionsOutput,
  GET_MEMBERSHIPS, GetMembershipsInput, GetMembershipsOutput,
} from '../queries';
import { commissionFilter } from '../utils/commissionFilter';

const TABLE_ROW_SIZE = 8;

const defaultMerchantOption: SelectOption = { label: 'All Approved Merchants', value: '' };
export const useActiveCommissions = () => {
  const { hookWhoAmI } = useUserInfo();

  const [selectedMerchant, setSelectedMerchant] = useState<SelectOption>(defaultMerchantOption);
  const [merchantList, setMerchantList] = useState<SelectOption[]>([]);

  const [completeCommissionList, setCompleteCommissionList] = useState<CommissionFormatted[]>([]);
  const [commissionList, setCommissionList] = useState<CommissionFormatted[]>([]);
  const [tableData, setTableData] = useState<CommissionFormatted[]>([]);

  const [sortColumn, setSortColumn] = useState<TableSortColumn>({ column: 'idNum', direction: 'asc' });
  const [totalPages, setTotalPages] = useState<number>(0);
  const [currentPage, setCurrentPage] = useState<number>(1);

  const [errorMessage, setErrorMessage] = useState<string>('');

  const [getPublisherCommissions, { loading: getPublisherCommissionsLoading }] = useLazyQuery<GetPublisherCommissionsOutput, GetPublisherCommissionsInput>(GET_PUBLISHER_COMMISSIONS);
  const [getMemberships, { loading: getMembershipsLoading }] = useLazyQuery<GetMembershipsOutput, GetMembershipsInput>(GET_MEMBERSHIPS);

  const getMerchantListHandler = async () => {
    setErrorMessage('');
    if (!hookWhoAmI.companyId) return;

    const { data: merchantListData } = await getMemberships({
      variables: {
        input: {
          publisherId: hookWhoAmI.companyId.toString(),
          status: 'Approved',
        },
      },
      fetchPolicy: 'no-cache',
      onError(err) {
        setErrorMessage(err.message);
      },
    });
    if (merchantListData && merchantListData.memberships) {
      const newMerchantList = merchantListData.memberships.memberships.map((company): SelectOption => ({ label: company.merchant.companyName, value: company.merchant.id }));
      setMerchantList([defaultMerchantOption, ...newMerchantList]);
    }
  };

  const getPublisherActiveCommissions = async () => {
    setErrorMessage('');
    if (!hookWhoAmI.companyId) return;

    const { data: merchantListData } = await getPublisherCommissions({
      variables: {
        input: {
          publisherId: hookWhoAmI.companyId.toString(),
        },
      },
      fetchPolicy: 'no-cache',
      onError(err) {
        setErrorMessage(err.message);
      },
    });
    if (merchantListData && merchantListData.getPublisherCommissions) {
      const formattedCommissions = commissionsFormatter(merchantListData.getPublisherCommissions.commissions, true);

      // Remove commission that have a custom and default collision
      const filteredCommissions = commissionFilter(formattedCommissions, hookWhoAmI.companyId.toString());

      const sortFunction = compareValues(sortColumn.column, sortColumn.direction);
      filteredCommissions.sort(sortFunction);

      setCompleteCommissionList(filteredCommissions);
      setCommissionList(filteredCommissions);
      setTableData(filteredCommissions.slice(0, TABLE_ROW_SIZE));
      setTotalPages(Math.ceil(filteredCommissions.length / TABLE_ROW_SIZE));
    }
  };

  const setCurrentPageHandler = (newPage: number) => {
    setCurrentPage(newPage);
    setTableData(commissionList.slice((newPage - 1) * TABLE_ROW_SIZE, newPage * TABLE_ROW_SIZE));
  };

  /** Sorts the table by the column and direction given */
  const sortTableHandler = (column: string, direction: 'desc' | 'asc' | undefined) => {
    const newDirection = direction === 'desc' ? 'asc' : 'desc';
    const copy: CommissionFormatted[] = structuredClone(completeCommissionList);
    const sortedCopy: CommissionFormatted[] = copy.sort(compareValues(column, newDirection));

    setCompleteCommissionList(sortedCopy);
    if (selectedMerchant.value) {
      const filteredCommissions = sortedCopy.filter((commission) => commission.merchant.id === selectedMerchant.value);
      setCommissionList(filteredCommissions);
      setTableData(filteredCommissions.slice((currentPage - 1) * TABLE_ROW_SIZE, currentPage * TABLE_ROW_SIZE));
    } else {
      setCommissionList(sortedCopy);
      setTableData(sortedCopy.slice((currentPage - 1) * TABLE_ROW_SIZE, currentPage * TABLE_ROW_SIZE));
    }
    setSortColumn({ column, direction: newDirection });
  };

  const setSelectedMerchantHandler = (newMerchant: SelectOption) => {
    setSelectedMerchant(newMerchant);
    setCurrentPage(1);
    // Check if All Merchants or not
    if (newMerchant.value) {
      const matchingCommissions = completeCommissionList.filter((commission) => commission.merchant.id === newMerchant.value);
      setCommissionList(matchingCommissions);
      setTableData(matchingCommissions.slice(0, TABLE_ROW_SIZE));
      setTotalPages(Math.ceil(matchingCommissions.length / TABLE_ROW_SIZE));
    } else {
      setCommissionList(completeCommissionList);
      setTableData(completeCommissionList.slice(0, TABLE_ROW_SIZE));
      setTotalPages(Math.ceil(completeCommissionList.length / TABLE_ROW_SIZE));
    }
  };

  useEffect(() => {
    setSelectedMerchant(defaultMerchantOption);
    getMerchantListHandler();
    getPublisherActiveCommissions();
  }, [window.location.href]);

  return {
    hookPageLoading: getPublisherCommissionsLoading || getMembershipsLoading,
    hookErrorMessage: errorMessage,

    hookSelectedMerchant: selectedMerchant,
    hookMerchantList: merchantList,
    hookSetSelectedMerchant: setSelectedMerchantHandler,

    hookTableData: tableData,
    hookTotalPages: totalPages,
    hookCurrentPage: currentPage,
    hookSetCurrentPage: setCurrentPageHandler,

    hookSortColumn: sortColumn,
    hookSortTableHandler: sortTableHandler,
  };
};
