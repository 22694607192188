import React from 'react';
import { faAngleRight, faFile } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { formatAmount } from '../../../../utils/amountFormatter';
import * as Styled from './styles';
import { Button } from '../../../../components';
import { PAYMENT_DETAILS } from '../enums';
import { TABLE_FOOTER } from '../../../../utils';

export const columns = (handleNavigationPdf: (row: any) => void, handleNavigation: (row: any) => void): TableColumn[] => [
  {
    dataField: 'paymentId',
    text: 'payment id',
    sortFunction: (a: any, b: any) => a.name.localeCompare(b.name),
    width: '8%',
    footer: TABLE_FOOTER,
  },
  {
    dataField: 'paymentPeriod',
    text: 'payment period',
    sortFunction: (a: any, b: any) => a.name.localeCompare(b.name),
    width: '8%',
  },
  {
    dataField: 'paymentStatus',
    text: 'payment status',
    sortFunction: (a: any, b: any) => a.name.localeCompare(b.name),
    width: '8%',
  },
  {
    dataField: 'paymentMethod',
    text: 'payment method',
    sortFunction: (a: any, b: any) => a.name.localeCompare(b.name),
    width: '8%',
  },
  {
    dataField: 'processedPeriod',
    text: 'proccessed period',
    sortFunction: (a: any, b: any) => a.name.localeCompare(b.name),
    width: '8%',
  },
  {
    dataField: 'merchantId',
    text: 'merchant id',
    formatter: (_: any, row:any) => (
      <div>
        {row.merchant.id}
      </div>
    ),
    sortFunction: (a: any, b: any) => a.name.localeCompare(b.name),
    width: '8%',
  },
  {
    dataField: 'merchant',
    text: 'merchant',
    formatter: (value: any) => (
      <div>
        {value.companyName}
      </div>
    ),
    sortFunction: (a: any, b: any) => a.name.localeCompare(b.name),
    width: '8%',
    wrapText: true,
  },
  {
    dataField: 'productCategory',
    text: 'product category',
    sortFunction: (a: any, b: any) => a.name.localeCompare(b.name),
    width: '8%',
  },
  {
    dataField: 'cpaCommission',
    text: 'commission cpa',
    sortFunction: (a: any, b: any) => a.name.localeCompare(b.name),
    formatter: (value: any) => (
      <div>
        {formatAmount(value)}
      </div>
    ),
    width: '8%',
    footerFormatter: (_: any, __: any, footerData?: { [key: string]: any }) => (
      <div>{new Intl.NumberFormat('en-US', { style: 'currency', currency: 'USD' }).format(footerData?.cpaCommissions)}</div>
    ),
  },
  {
    dataField: 'revShareCommission',
    text: '% commission',
    sortFunction: (a: any, b: any) => a.name.localeCompare(b.name),
    formatter: (value: any) => (
      <div>
        {formatAmount(value)}
      </div>
    ),
    width: '8%',
    footer: PAYMENT_DETAILS.COMMISSION,
    footerFormatter: (_: any, __: any, footerData?: { [key: string]: any }) => (
      <div>{new Intl.NumberFormat('en-US', { style: 'currency', currency: 'USD' }).format(footerData?.commissionsPercent)}</div>
    ),
  },
  {
    dataField: 'totalCommissions',
    text: 'total commissions',
    sortFunction: (a: any, b: any) => a.name.localeCompare(b.name),
    formatter: (value: any) => (
      <div>
        {formatAmount(value)}
      </div>
    ),
    width: '8%',
    footerFormatter: (_: any, __: any, footerData?: { [key: string]: any }) => (
      <div>{new Intl.NumberFormat('en-US', { style: 'currency', currency: 'USD' }).format(footerData?.totalCommissions)}</div>
    ),
  },
  {
    dataField: 'totalTaxAmount',
    text: 'commission tax',
    sortFunction: (a: any, b: any) => a.name.localeCompare(b.name),
    formatter: (value: any) => (
      <div>
        {formatAmount(value)}
      </div>
    ),
    width: '8%',
    footerFormatter: (_: any, __: any, footerData?: { [key: string]: any }) => (
      <div>{new Intl.NumberFormat('en-US', { style: 'currency', currency: 'USD' }).format(footerData?.commissionsTax)}</div>
    ),
  },
  {
    dataField: 'bonusAmount',
    text: 'bonus amount',
    sortFunction: (a: any, b: any) => a.name.localeCompare(b.name),
    formatter: (value: any) => (
      <div>
        {formatAmount(value)}
      </div>
    ),
    width: '8%',
    footerFormatter: (_: any, __: any, footerData?: { [key: string]: any }) => (
      <div>{new Intl.NumberFormat('en-US', { style: 'currency', currency: 'USD' }).format(footerData?.bonusAmount)}</div>
    ),
  },
  {
    dataField: 'bonusTax',
    text: 'bonus tax',
    sortFunction: (a: any, b: any) => a.name.localeCompare(b.name),
    formatter: (value: any) => (
      <div>
        {formatAmount(value)}
      </div>
    ),
    width: '8%',
    footerFormatter: (_: any, __: any, footerData?: { [key: string]: any }) => (
      <div>{new Intl.NumberFormat('en-US', { style: 'currency', currency: 'USD' }).format(footerData?.bonusTax)}</div>
    ),
  },
  {
    dataField: 'totalPayable',
    text: 'total payable',
    sortFunction: (a: any, b: any) => a.name.localeCompare(b.name),
    formatter: (value: any) => (
      <div>
        {formatAmount(value)}
      </div>
    ),
    width: '8%',
    footerFormatter: (_: any, __: any, footerData?: { [key: string]: any }) => (
      <div>{new Intl.NumberFormat('en-US', { style: 'currency', currency: 'USD' }).format(footerData?.totalPayable)}</div>
    ),
  },
  {
    dataField: 'periodBalance',
    text: 'period balance',
    sortFunction: (a: any, b: any) => a.name.localeCompare(b.name),
    formatter: (value: any) => (
      <div>
        {formatAmount(value)}
      </div>
    ),
    width: '8%',
    footerFormatter: (_: any, __: any, footerData?: { [key: string]: any }) => (
      <div>{new Intl.NumberFormat('en-US', { style: 'currency', currency: 'USD' }).format(footerData?.periodBalance)}</div>
    ),
  },
  {
    dataField: 'totalPayable',
    text: 'statement',
    formatter: (val: string, row: any) => (
      <Styled.BoxStyled theme="amountCol">
        <Button
          onClick={() => handleNavigationPdf(row)}
          theme="quaternary"
        >
          <FontAwesomeIcon icon={faFile} />
        </Button>
        <Button
          onClick={() => handleNavigation(row)}
          theme="quaternary"
        >
          <FontAwesomeIcon icon={faAngleRight} />
        </Button>
      </Styled.BoxStyled>
    ),
    width: '8%',
    notClickable: true,
  },
];
