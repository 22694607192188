const environment = {
  google: {
    captcha: process.env.REACT_APP_RECAPTCHA_SITE_KEY as string,
  },
  auth0: {
    clientId: process.env.REACT_APP_AUTH0_CLIENT_ID as string,
    domain: process.env.REACT_APP_AUTH0_DOMAIN as string,
    redirectUri: process.env.REACT_APP_AUTH0_REDIRECT_URI as string,
    customDomain: process.env.REACT_APP_AUTH0_CUSTOM_DOMAIN as string,
    audience: process.env.REACT_APP_AUTH0_AUDIENCE as string,
  },
  api: {
    baseUrl: process.env.REACT_APP_API_URL as string,
    uploadUrl: process.env.REACT_APP_UPLOAD_API_URL as string,
    adsBasePath: process.env.REACT_APP_ADS_BASE_PATH as string,
    pageViewScripts: process.env.REACT_APP_PAGE_VIEW_SCRIPTS_CDN as string,
  },
  app: {
    cookieName: process.env.REACT_APP_FINTEL_USER_COOKIE_NAME as string,
    permissionStorageName: process.env.REACT_APP_FINTEL_PERMISSION_STORAGE_NAME as string,
    environment: process.env.REACT_APP_ENVIRONMENT as string,
    amChartGraphLicense: process.env.REACT_APP_AM_CHARTS_GRAPH_LICENSE_KEY as string,
    amChartMapLicense: process.env.REACT_APP_AM_CHARTS_MAP_LICENSE_KEY as string,
    paypal: process.env.REACT_APP_PAYPAL_CLIENT_ID as string,
    supportUrl: process.env.REACT_APP_SUPPORT_URL as string,
    kbUrl: process.env.REACT_APP_KB_URL as string,
    domain: process.env.REACT_APP_COOKIE_DOMAIN as string,
  },
};

export default environment;
