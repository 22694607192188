import environment from '../config/environment';

export const useCreateSession = () => {
  const breakHash = window.location.hash.split('#')[1];
  if (!breakHash) return;
  const cleanHash = breakHash.split('&');
  let crypt = '';

  const filter = cleanHash.filter((item) => {
    const [key] = item.split('=');

    if (key === 'access_token' || key === 'id_token') {
      return true;
    }

    return false;
  });

  const expiration = (['production', 'staging', 'demo'].includes(environment.app.environment)) ? new Date(Date.now() + 15 * 60 * 1000) : new Date(new Date().getTime() + 10 * 60 * 60 * 1000);

  filter.forEach((item) => {
    const [key, value] = item.split('=');
    if (key === 'access_token') {
      crypt = (value);
      // Legacy cookie to be used with Knowledge Base and Support App
      document.cookie = `accessToken=${value}; expires=${expiration.toUTCString()}; domain=${environment.app.domain}`;
    }
    if (key === 'id_token') {
      // Legacy cookies to be used with Knowledge Base and Support App
      document.cookie = `idToken=${value}; expires=${expiration.toUTCString()}; domain=${environment.app.domain}`;
    }

    document.cookie = `${key}=${value}; expires=${expiration.toUTCString()}; path=/; SameSite=none; Secure`;
  });

  return crypt;
};
