import { gql } from '@apollo/client';

export const LIST_HISTORY_MERCHANT_COMMISSIONS = gql`
  query ListHistoryCommissions($input: NewCommissionsInput!) {
    getCommissionsNew(input: $input) {
      count
      totalPages
      currentPage
      commissions {
        id
        endDate
        startDate
        description
        commissionStructure {
          bonusFlat {
            commissionBase
            minimumSaleAmount
            commissionFee
          }
          cpaFlat {
            minimumSaleAmount
            commissionFee
            commissionBase
          }
          cpaTiered {
            commissionBase
            tiers {
              upTo
              commissionAmount
            }
            cutoffAmount
            minimumSaleAmount
          }
          referral {
            levels
          }
          revShareFlat {
            commissionFee
            commissionBase
          }
          revShareTiered {
            cutoffAmount
            commissionBase
            tiers {
              upTo
              commissionAmount
            }
          }
          bonusTiered {
            minimumSaleAmount
            commissionBase
            period
            calculationType
            cutoffAmount
            tiers {
              upTo
              commissionAmount
            }
          }
        }
        commissionType
        commissionTarget {
          publisherGroupTargets {
            endDate
            publisherGroupId
            startDate
          }
          publisherTargets {
            endDate
            publisherId
            startDate
          }
        }
        products {
          id
          name
        }
        merchant {
          id
        }
        publisherGroups {
          id
          name
        }
        publishers {
          id
          companyName
        }
      }
    }
  }
`;

export type ListHistoryMerchantCommissionsInput = {
  input: {
    filters: {
      merchantId: string,
      typeId?: string,
    },
    limit: number,
    currentPage: number,
    sortBy?: string,
    sortDirection?: 'asc' | 'desc'
    targetDate?: Date,
    productId?: string,
  }
};

export type LHMCCommissionStructureType = {
  cpaFlat: {
    minimumSaleAmount: string | null
    commissionFee: string | null
    commissionBase: 'GrossSales' | 'NetSales' | 'SalesNumber' | 'CPA' | null
  }
  cpaTiered: {
    commissionBase: 'GrossSales' | 'NetSales' | 'SalesNumber' | 'CPA' | null
    tiers: { upTo: string | null, commissionAmount: string | null }[]
    cutoffAmount: string | null
    minimumSaleAmount: string | null
  }
  referral: { levels: string[] }
  revShareFlat: {
    commissionFee: string | null
    commissionBase: 'GrossSales' | 'NetSales' | 'SalesNumber' | 'CPA' | null
  }
  revShareTiered: {
    tiers: { upTo: string, commissionAmount: string }[]
    cutoffAmount: string | null
    commissionBase: 'GrossSales' | 'NetSales' | 'SalesNumber' | 'CPA' | null
  }
  bonusFlat: {
    minimumSaleAmount: string | null
    commissionFee: string | null
    commissionBase: 'GrossSales' | 'NetSales' | 'SalesNumber' | 'CPA' | null
  }
  bonusTiered: {
    minimumSaleAmount: string | null
    commissionBase: 'GrossSales' | 'NetSales' | 'SalesNumber' | 'CPA' | null
    period: string | null
    calculationType: 'Stepped' | 'Lookup' | null
    tiers: { upTo: string, commissionAmount: string }[]
    cutoffAmount: string
  }
}

export type LHMCCommissionTargetType = {
  publisherGroupTargets: { endDate: string | null, publisherGroupId: string, startDate: string | null }[]
  publisherTargets: { endDate: string | null, publisherId: string, startDate: string | null }[]
}

export type LHMCCommissionsType = {
  id: string
  endDate: string | null
  startDate: string | null
  description: string | null
  commissionType: string | null
  commissionStructure: LHMCCommissionStructureType
  commissionTarget: LHMCCommissionTargetType
  products: {id: string, name: string | null}[]
  merchant: {id: string}
  publisherGroups: { id: string, name: string }[]
  publishers: { id: string, companyName: string }[]
}

export type ListHistoryMerchantCommissionsOutput = {
  getCommissionsNew: {
    count: number | null
    totalPages: number | null
    currentPage: number | null
    commissions: LHMCCommissionsType[]
  }
};
