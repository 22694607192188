import React, {
  createContext, useReducer, useMemo,
} from 'react';
import { useFintelCheckReducer } from '../reducers';

const initalState = {
  merchantId: '',
  publisherId: '',
  publisherName: '',
  productId: '',
  productName: '',
  ruleId: '',
  ruleName: '',
  date: '',
  referralUrl: '',
  zipFile: '',
  fintelCheckSummaryId: '',
  product: null as SelectOption | null,
  productCategory: null as SelectOption | null,
  status: null as SelectOption | null,
  publisher: null as SelectOption | null,
};

type FintelCheckContextValuesType = {
  state: typeof initalState
  dispatcherHandler: (value: Record<string, any>) => void
}

type FintelCheckContextProps = {
  children: React.ReactNode
}

export const context = createContext<FintelCheckContextValuesType>({} as FintelCheckContextValuesType);

export const FintelCheckContext = ({ children }: FintelCheckContextProps) => {
  const [fintelCheckState, fintelCheckDispatch] = useReducer(useFintelCheckReducer, initalState);

  const fintelCheckDispathHandler = (value: Record<string, any>) => {
    fintelCheckDispatch({ type: value.type, data: value.data });
  };

  const state = useMemo(() => ({
    state: fintelCheckState,
    dispatcherHandler: fintelCheckDispathHandler,
  }), [fintelCheckState]);

  return (
    <context.Provider value={state}>
      {children}
    </context.Provider>
  );
};
