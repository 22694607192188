import { useLazyQuery } from '@apollo/client';
import { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { useModal, useUserInfo } from '../../../../../hooks';
import {
  MERCHANT_PREFIX, path, toUTCHours,
} from '../../../../../utils';
import {
  LIST_ACTIVE_MERCHANT_COMMISSIONS, ListActiveMerchantCommissionsInput, ListActiveMerchantCommissionsOutput,
} from '../graphql/queries';
import { commissionsFormatter, CommissionFormatted } from '../../../../../utils/formatCommissions';
import { Permission } from '../../../../../entities';

export const useManageCommissions = (permissionsCodeList: string[] = []) => {
  const { hookWhoAmI } = useUserInfo();
  const navigate = useNavigate();

  const [addModal, setAddModal] = useModal(false);

  const [pageloading, setPageLoading] = useState(false);
  const [commissionsData, setCommissionsData] = useState<CommissionFormatted[]>([]);

  const [currentPage, setCurrentPage] = useState(1);
  const [totalPages, setTotalPages] = useState(0);

  const [errorMessage, setErrorMessage] = useState<string>('');
  const [refetchPage, setRefetchPage] = useState<boolean>(false);

  const [getMerchantCommissionStructure, { loading: getMerchantCommissionStructureLoading }] = useLazyQuery<ListActiveMerchantCommissionsOutput, ListActiveMerchantCommissionsInput>(LIST_ACTIVE_MERCHANT_COMMISSIONS);

  const resetPage = () => {
    setCurrentPage(1);
    setTotalPages(0);
    setErrorMessage('');
    setCommissionsData([]);
  };

  const setCurrentPageHandler = (chosenPage: number) => {
    setCurrentPage(chosenPage);
    setRefetchPage(true);
  };

  const getMerchantCommissionStructureHandler = async () => {
    setErrorMessage('');
    if (!hookWhoAmI.programId) {
      return;
    }
    setPageLoading(true);
    const { data } = await getMerchantCommissionStructure({
      variables: {
        input: {
          filters: {
            merchantId: hookWhoAmI.companyId?.toString() || '0',
          },
          targetDate: toUTCHours(new Date(), 'beginning'),
          limit: 8,
          currentPage,
        },
      },
      fetchPolicy: 'no-cache',
      onError(err) {
        setPageLoading(false);
        resetPage();
        setErrorMessage(err.message);
      },
    });
    setPageLoading(false);
    if (data && data.getCommissionsNew) {
      setTotalPages(data.getCommissionsNew.totalPages || 0);
      const list = data.getCommissionsNew.commissions;

      const formattedList = commissionsFormatter(list, false);
      setCommissionsData(formattedList);
    }
  };

  const onRowClick = (rowData: CommissionFormatted) => {
    navigate(`${MERCHANT_PREFIX}${path.editCommissions.href}`, {
      state: rowData.id,
    });
  };

  const navigateHandler = () => {
    navigate(`${MERCHANT_PREFIX}${path.commissionsHistory.href}`);
  };

  if (refetchPage) {
    setRefetchPage(false);
    getMerchantCommissionStructureHandler();
  }

  useEffect(() => {
    resetPage();
    getMerchantCommissionStructureHandler();
  }, [window.location.href]);

  return {
    hookCommissionsData: commissionsData,
    hookSetCommissionsData: setCommissionsData,

    hookAddModal: addModal,
    hookSetAddModal: setAddModal,

    hookCurrentPage: currentPage,
    hookTotalPages: totalPages,
    hookSetCurrentPage: setCurrentPageHandler,
    hookOnRowClick: onRowClick,

    hookIsLoading: getMerchantCommissionStructureLoading || pageloading,
    hookSetIsLoading: setPageLoading,
    hookNavigate: navigateHandler,
    hookErrorMessage: errorMessage,

    hookIsReadOnlyList: Permission.readOnlyPermissionsList(permissionsCodeList),
  };
};
