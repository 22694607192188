import React from 'react';
import { Checkbox } from '../../../../../../components';
import * as Styled from './styles';
import { TRACKING_SETTINGS } from './enums';

type TrackingSettingsProps = {
  hook: any
  setIsTracking: (isTracking: boolean) => void
  isReadOnly: boolean
}

const TrackingSettings = ({ hook, setIsTracking, isReadOnly }: TrackingSettingsProps) => {
  setIsTracking(true);
  return (
    <Styled.WrapperStyled>
      <Styled.InputTextStyled
        label={TRACKING_SETTINGS.LABELS.LANDING_URL}
        value={hook.hookLandingPageURL}
        type="text"
        placeholder={TRACKING_SETTINGS.PLACEHOLDERS.LANDING_URL}
        onChange={hook.hookSetLandingpageURL}
        error={hook.hookLandingUrlError}
        onBlur={hook.hookValidateWebsite}
        required
        disabled={isReadOnly}
      />
      <Styled.InputTextStyled
        label={TRACKING_SETTINGS.LABELS.QUERY_STRING}
        value={hook.hookQueryURL}
        type="text"
        placeholder={TRACKING_SETTINGS.PLACEHOLDERS.QUERY_STRING}
        onChange={hook.hookSetQueryURL}
        disabled={isReadOnly}
      />
      <Styled.InputTextStyled
        label={TRACKING_SETTINGS.LABELS.TRACKING_URL}
        value={hook.hookThirdPartyTrackingURL}
        type="text"
        onChange={hook.hookSetThirdPartyTrackingURL}
        error={hook.hookThirdPartyUrlError}
        onBlur={hook.hookValidateWebsite}
        placeholder={TRACKING_SETTINGS.PLACEHOLDERS.TRACKING_URL}
        disabled={isReadOnly}
      />
      <Styled.CheckBoxAreaStyled>
        <Checkbox
          label={TRACKING_SETTINGS.LABELS.TRACKER}
          onChange={hook.hookCheckThirdPartyStatus}
          checked={hook.hookThirdPartyStatus}
          disabled={isReadOnly}
        />
      </Styled.CheckBoxAreaStyled>
      <Styled.InputTextStyled
        label={TRACKING_SETTINGS.LABELS.URL_FORMAT}
        value={hook.hookThirdPartyURLFormat}
        type="text"
        placeholder={TRACKING_SETTINGS.PLACEHOLDERS.URL_FORMAT}
        onChange={hook.hookSetThirdPartyURLFormat}
        disabled={isReadOnly}
      />
    </Styled.WrapperStyled>
  );
};
export default TrackingSettings;
