import { useEffect, useState } from 'react';
import { useLazyQuery, useMutation } from '@apollo/client';
import {
  REGEX_VALIDATORS, dateOptionsThisAndLastMonth, MONTH_NAMES as monthNames, rangeFormat,
} from '../../../../utils';
import { BonusTransaction, BonusModalDetials } from '../types';
import {
  GET_FILTERS, GET_MANUAL, GET_ACCOUNT_STATS, GET_MERCHANT_OPTIONS,
} from '../graphql/queries';
import { CREATE_MANUAL } from '../graphql/mutations/createManualBonus';
import {
  EMPTY_SELECT_MERCHANT, EMPTY_SELECT_PUBLISHER, MANUAL_PUBLISHER_BONUS, MODAL_ERROR,
} from '../enums';
import { MerchantPublisherOptionsMaker } from '../../../../utils/MerchantPublisherOptionsMaker';
import { GET_COUNT } from '../graphql/queries/getCount';
import { getFirstDayPrevMonth } from '../../../../utils/getPrevMonth';
import { Permission } from '../../../../entities';

export const useManualPublisherBonus = (permissionsCodeList: string[] = []) => {
  const today = new Date();

  const productOptionsMaker = (productOptions: any[]) => productOptions.map((opt: any) => (
    { label: opt, value: opt.replace(/\s/g, '') }
  ));

  const defaultRange = rangeFormat('last30Days');
  const thirtyDaysAgo = defaultRange.start;

  const [loadingState, setLoadingState] = useState(false);
  const [loadingMessage, setLoadingMessage] = useState<string>('');
  const [errorMsg, setErrorMsg] = useState<string>('');

  const [merchantFilter, setMerchantFilter] = useState<SelectOption>({ label: 'All Merchants', value: '1' });
  const [publisherFilter, setPublisherFilter] = useState<SelectOption>({ label: 'All Publishers', value: '1' });
  const [startDate, setStartDate] = useState<Date>(new Date(thirtyDaysAgo.setHours(0, 0, 0, 0)));
  const [endDate, setEndDate] = useState<Date>(new Date(today.setHours((23 - 8), 59, 59, 999)));
  const [currentPage, setCurrentPage] = useState<number>(1);
  const [totalPages, setTotalPages] = useState(0);
  const [sortColumn, setSortColumn] = useState<TableSortColumn>();

  const [tableData, setTableData] = useState<BonusTransaction[]>([]);
  const [isCalenderOpen, setIsCalendarOpen] = useState<boolean>(false);
  const [displayRange, setDisplayRange] = useState<string>(`${startDate.toDateString()} - ${endDate.toDateString()}`);
  const [isAddModalOpen, setIsAddModalOpen] = useState<boolean>(false);
  const [publisherIdSelectedModal, setPublisherIdSelectedModal] = useState<SelectOption>({ label: '', value: '0' });
  const [paymentAmountModal, setPaymentAmountModal] = useState<string>('');
  const [paymentTypeModal, setPaymentTypeModal] = useState<SelectOption>({ label: 'Manual Bonus', value: 'ManualBonus' });
  const [paymentPeriodModal, setPaymentPeriodModal] = useState<SelectOption>();
  const [paymentPeriodModalOptions, setPaymentPeriodModalOptions] = useState<SelectOption[]>([]);
  const [merchantModal, setMerchantModal] = useState<SelectOption>({ label: '', value: '0' });
  const [noteModal, setNoteModal] = useState<string>('');
  const [errorPublisherId, setErrorPublisherId] = useState<boolean>(false);
  const [errorPayment, setErrorPayment] = useState<boolean>(false);
  const [errorMerchant, setErrorMerchant] = useState<boolean>(false);
  const [errorProduct, setErrorProduct] = useState<boolean>(false);

  const [errorModal, setErrorModal] = useState<string>('');

  const [productCategoryModal, setProductCategoryModal] = useState<SelectOption>({ label: '', value: '' });
  const [productOptionsModal, setProductOptionsModal] = useState<SelectOption[]>([]);
  const [isManualBonusOpen, setIsManualBonusOpen] = useState<boolean>(false);
  const [allMerchantOptionsData, setAllMerchantOptionsData] = useState([]);
  const [publisherOptions, setPublisherOptions] = useState<SelectOption[]>([]);
  const [merchantOptions, setMerchantOptions] = useState<SelectOption[]>([]);
  const [bonusModalInfo, setBonusModalInfo] = useState<BonusModalDetials>({
    addedBy: '',
    publisherIdName: '',
    paymentAmount: '',
    paymentPeriod: '',
    paymentType: '',
    merchant: '',
    note: '',
    productCategory: '',
  });
  const [errorMessage, setErrorMessage] = useState<string>();
  const [hasNonEstimate, setHasNonEstimated] = useState<boolean>();

  const [getFilters, { loading: filtersLoading }] = useLazyQuery(GET_FILTERS);
  const [getManualBonuses, { loading: manualBonusesLoading }] = useLazyQuery(GET_MANUAL);
  const [getAccountStats, { loading: accountStatsLoading }] = useLazyQuery(GET_ACCOUNT_STATS);
  const [createUserManual, { loading: saveButtonLoading }] = useMutation(CREATE_MANUAL);
  const [getCount, { loading: getCountLoading }] = useLazyQuery(GET_COUNT);
  const [getMerchantOptions, { loading: merchantOptionsLoading }] = useLazyQuery(GET_MERCHANT_OPTIONS);

  const handleGetCount = async () => {
    const lastMonth = getFirstDayPrevMonth(today);
    const { data } = await getCount({ variables: { input: { month: (lastMonth.getMonth() + 1).toString(), year: (lastMonth.getFullYear()).toString(), paymentStatus: 'NotEstimated' } } });
    if (data?.countPayments !== undefined) {
      setHasNonEstimated(data.countPayments > 0);
      const paymentPeriodOptions = dateOptionsThisAndLastMonth(data.countPayments > 0);
      setPaymentPeriodModalOptions(paymentPeriodOptions);
      setPaymentPeriodModal(paymentPeriodOptions[0]);
    } else {
      setErrorMessage(MODAL_ERROR);
    }
  };

  const handleSetManualOpen = (row: any) => {
    setBonusModalInfo({
      addedBy: `${new Date(row.createdAt).toLocaleDateString(undefined, { month: 'short', day: 'numeric', year: 'numeric' })}  ${row.createdBy.firstName} ${row.createdBy.lastName}`,
      publisherIdName: `${row.publisher.id} ${row.publisher.companyName}`,
      paymentAmount: `$${row.bonusAmount}`,
      paymentPeriod: `${monthNames[row.month - 1]} ${row.year}`,
      paymentType: row.bonusType,
      merchant: row.merchant.companyName,
      note: row.bonusNote,
      productCategory: row.productCategory,
    });
    setIsManualBonusOpen(!isManualBonusOpen);
  };

  const clearFormHandler = () => {
    setMerchantFilter(EMPTY_SELECT_MERCHANT);
    setPublisherFilter(EMPTY_SELECT_PUBLISHER);
    setStartDate(new Date(today.getFullYear(), today.getMonth(), 1, 0, 0, 0, 0));
    setEndDate(new Date(today.setHours((23 - 8), 59, 59, 999)));
    setDisplayRange(`${new Date(today.getFullYear(), today.getMonth(), 1, 0, 0, 0, 0).toDateString()} - ${new Date(today.setHours((23 - 8), 59, 59, 999)).toDateString()}`);
  };

  const changePageHandler = (pageValue: number) => {
    setCurrentPage(pageValue);
  };

  const onSortHandler = (column: string, direction: 'desc' | 'asc' | undefined) => {
    setSortColumn({
      column,
      direction: direction === 'desc' ? 'asc' : 'desc',
    });
  };

  const setManualListHandler = async () => {
    const { data, error } = await getManualBonuses({
      variables: {
        input: {
          currentPage,
          endDate,
          startDate,
          publisherId: publisherFilter.value === '1' ? undefined : publisherFilter.value,
          merchantId: merchantFilter.value === '1' ? undefined : merchantFilter.value,
          sortOrder: sortColumn?.direction === 'asc' ? 1 : -1,
          sortBy: sortColumn?.column || 'month',
        },
      },
      onError(err: any) {
        setLoadingMessage('');
        setErrorMessage(err.message);
        setTableData([]);
      },
    });
    if (error) {
      setTableData([]);
      setErrorMessage(error.message);
      return;
    }
    if (data.manualBonusesV2 && data.manualBonusesV2.manualBonuses) {
      setTableData(data.manualBonusesV2.manualBonuses);
      setTotalPages(Math.ceil(data.manualBonusesV2.count / 10));
    }
  };

  const filterMerchantOptions = async (selectedPublisher: any, allOptions: any) => {
    if (!selectedPublisher || (selectedPublisher && selectedPublisher.value === '1')) {
      return setMerchantOptions(MerchantPublisherOptionsMaker(allOptions, 'Merchant'));
    }
    const { data } = await getMerchantOptions({
      variables: { input: { publisherId: selectedPublisher.value } },
    });

    const merchantArray = data?.manualBonusMerchantfiltersV2?.merchantOptions;
    const selectedOptions = allOptions.filter((item:any) => merchantArray.includes(item.id));

    setMerchantOptions(MerchantPublisherOptionsMaker(selectedOptions, 'Merchant'));
  };

  const getPublishersMerchants = async () => {
    setLoadingMessage(MANUAL_PUBLISHER_BONUS.LOADING_FILTERS);
    setLoadingState(true);
    const { data } = await getFilters();
    if (data.manualBonusfiltersV2 && data.manualBonusfiltersV2.publisherOptions) {
      setPublisherOptions(MerchantPublisherOptionsMaker(data.manualBonusfiltersV2.publisherOptions, 'Publisher'));
    } else {
      setErrorMsg(MANUAL_PUBLISHER_BONUS.FAILED_MERCHANTS);
    }
    if (data.manualBonusfiltersV2 && data.manualBonusfiltersV2.merchantOptions) {
      setMerchantOptions(MerchantPublisherOptionsMaker(data.manualBonusfiltersV2.merchantOptions, 'Merchant'));
      setAllMerchantOptionsData(data.manualBonusfiltersV2.merchantOptions);
    } else {
      setErrorMsg(MANUAL_PUBLISHER_BONUS.FAILED_MERCHANTS);
    }
    setLoadingState(false);
  };

  const getProductCategories = async () => {
    try {
      if (!paymentPeriodModal) return;
      const { data } = await getAccountStats({
        variables: {
          input: {
            merchantId: merchantModal.value,
            currentDate: new Date(today.getFullYear(), Number(paymentPeriodModal.value) - 1, 1),
          },
        },
      });

      if (data?.accountStat && data?.accountStat?.activeCategories) {
        setProductOptionsModal(productOptionsMaker(data.accountStat.activeCategories));
      } else {
        setProductOptionsModal([]);
      }
    } catch (e: any) {
      setErrorMessage(e);
    }
  };

  useEffect(() => {
    getProductCategories();
  }, [merchantModal, paymentPeriodModal]);

  useEffect(() => {
    getPublishersMerchants();
    setManualListHandler();
    handleGetCount();
  }, []);

  useEffect(() => {
    setTableData([]);
    setManualListHandler();
    setCurrentPage(1);
    setTotalPages(1);
  }, [publisherFilter, merchantFilter, sortColumn, startDate, endDate]);

  useEffect(() => {
    setTableData([]);
    setManualListHandler();
  }, [currentPage]);

  useEffect(() => {
    setCurrentPage(1);
  }, [sortColumn]);

  const handleSaveManualModal = () => {
    setIsManualBonusOpen(!isManualBonusOpen);
  };

  const handlePaymentAmountModal = (event: React.ChangeEvent<HTMLInputElement>) => {
    setErrorPayment(false);
    setPaymentAmountModal(event.target.value);
  };

  const handleSetAddModalOpen = () => {
    setIsAddModalOpen(!isAddModalOpen);
  };

  const handleSetPaymentPeriodModal = (value: SelectOption) => {
    setPaymentPeriodModal(value);
  };

  const handleSetPaymentTypeModal = (value: SelectOption) => {
    setPaymentTypeModal(value);
  };

  const handleSetProductCategoryModal = (value: SelectOption) => {
    setErrorProduct(false);
    setProductCategoryModal(value);
  };

  const handleSetNoteModal = (event: React.ChangeEvent<HTMLInputElement>) => {
    setNoteModal(event.target.value);
  };

  const handleSetMerchantFilter = (value: any) => {
    setMerchantFilter(value);
  };

  const handleSetPublisherFilter = (value: any) => {
    setPublisherFilter(value);
    filterMerchantOptions(value, allMerchantOptionsData);
  };

  const handleSetCalendarOpen = () => {
    setIsCalendarOpen(!isCalenderOpen);
  };

  const handleApplyDate = (start: Date, end: Date | undefined) => {
    handleSetCalendarOpen();
    setStartDate(start);
    if (end !== undefined) {
      setEndDate(end);
      setDisplayRange(`${start.toDateString()} - ${end.toDateString()}`);
    }
  };

  const handleSaveModal = async () => {
    setErrorModal('');
    setErrorMerchant(merchantModal.label === '');
    setErrorPublisherId(publisherIdSelectedModal.label === '');
    setErrorProduct(productCategoryModal.label === '');
    setErrorPayment(paymentAmountModal === '');
    if (!(!paymentPeriodModal || merchantModal.label === '' || publisherIdSelectedModal.label === '' || paymentAmountModal === '' || (productCategoryModal.label === '' && productOptionsModal.length !== 0))) {
      const { errors } = await createUserManual({
        variables: {
          input: {
            publisherId: publisherIdSelectedModal.value,
            bonusAmount: paymentAmountModal,
            month: paymentPeriodModal.value,
            year: today.getFullYear().toString(),
            bonusType: paymentTypeModal.value,
            merchantId: merchantModal.value,
            bonusNote: noteModal,
            productCategory: productCategoryModal.label === '' ? null : productCategoryModal.label,
          },
        },
      });
      if (errors) {
        setErrorModal(errors[0].message);
        return;
      }
      setNoteModal('');
      setPaymentAmountModal('');
      setPublisherIdSelectedModal({ label: '', value: '' });
      setPaymentPeriodModal(paymentPeriodModalOptions ? paymentPeriodModalOptions[0] : undefined);
      setPaymentTypeModal({ label: 'Manual Bonus', value: 'ManualBonus' });
      setMerchantModal({ label: '', value: '' });
      setProductCategoryModal({ label: '', value: '' });
      setIsAddModalOpen(false);
      setProductOptionsModal([]);
    }
  };

  const handleCancelModal = () => {
    setIsAddModalOpen(!isAddModalOpen);
    setErrorMerchant(false);
    setErrorPayment(false);
    setErrorProduct(false);
    setErrorPublisherId(false);
    setErrorModal('');
    setProductCategoryModal({ label: '', value: '' });
    setNoteModal('');
    setMerchantModal({ label: '', value: '' });
    setPaymentPeriodModal(paymentPeriodModalOptions ? paymentPeriodModalOptions[0] : undefined);
    setPaymentAmountModal('');
    setPublisherIdSelectedModal({ label: '', value: '' });
    setPaymentTypeModal({ label: 'Manual Bonus', value: 'ManualBonus' });
  };

  const handleSetMerchantModal = (value: SelectOption) => {
    setProductCategoryModal({ label: '', value: '' });
    setErrorMerchant(false);
    setMerchantModal(value);
  };

  const handleSetPublisherModal = (value: SelectOption) => {
    setErrorPublisherId(false);
    setPublisherIdSelectedModal(value);
    filterMerchantOptions(value, allMerchantOptionsData);
  };

  return {

    hookHandleSetMerchantModal: handleSetMerchantModal,

    hookHandleSetPublisherModal: handleSetPublisherModal,

    hookHandleCancelModal: handleCancelModal,

    hookHandleSaveModal: handleSaveModal,

    hookHandleNoteModal: handleSetNoteModal,

    hookHandlePaymentAmountModal: handlePaymentAmountModal,

    hookHandleProductCategoryModal: handleSetProductCategoryModal,

    hookHandlesSetPaymentType: handleSetPaymentTypeModal,

    hookHandleSetPaymentPeriodModal: handleSetPaymentPeriodModal,

    hookHandleApplyDate: handleApplyDate,

    hookSetDate: setStartDate,

    hookHandleMerchantFilter: handleSetMerchantFilter,

    hookHandlePublisherFilter: handleSetPublisherFilter,

    hookHandleIsCalendarOpen: handleSetCalendarOpen,

    hookHandleIsAddModalOpen: handleSetAddModalOpen,

    hookHandleIsManualModalOpen: handleSetManualOpen,

    hookHandleSaveManualModal: handleSaveManualModal,

    hookChangePage: changePageHandler,

    hookSortHandler: onSortHandler,

    hookClearFormHandler: clearFormHandler,

    hookSaveButtonLoading: saveButtonLoading,

    hookErrorMessage: errorMessage,

    hookTotalPage: totalPages,

    hookCurrentPage: currentPage,

    hookSort: sortColumn,

    hookLoadingMessage: loadingMessage,

    hookLoading: loadingState || filtersLoading || manualBonusesLoading || merchantOptionsLoading,

    hookBonusModalInfo: bonusModalInfo,

    hookPublisherOptions: publisherOptions,

    hookMerchantOptions: merchantOptions,

    hookIsManualModalOpen: isManualBonusOpen,

    hookPublisherIdError: errorPublisherId,

    hookProductCategoryError: errorProduct,

    hookMerchantError: errorMerchant,

    hookPaymentError: errorPayment,

    hookPublisherIdOptionsModal: publisherOptions.slice(1),

    hookMerchantOptionsModal: merchantOptions.slice(1),

    hookModalIsOpen: isAddModalOpen,

    hookMerchantModal: merchantModal,

    hookPublisherIdModal: publisherIdSelectedModal,

    hookPaymentAmountModal: paymentAmountModal,

    hookPaymentPeriodModal: paymentPeriodModal,

    hookPaymentPeriodModalOptions: paymentPeriodModalOptions,

    hookPaymentTypeModal: paymentTypeModal,

    hookNoteModal: noteModal,

    hookProductCategoryModal: productCategoryModal,

    hookTableData: tableData,

    hookMerchantFilter: merchantFilter,

    hookPublisherFilter: publisherFilter,

    hookIsCalenderOpen: isCalenderOpen,

    hookDateFilter: startDate,

    hookDisplayRange: displayRange,

    hookProductOptionsModal: productOptionsModal,

    hookErrorMsg: errorMsg,

    hookDataLoading: manualBonusesLoading,

    hookFiltersLoading: filtersLoading,

    hookHasNoEstimate: hasNonEstimate,

    hookCountLoading: getCountLoading,

    hookErrorModal: errorModal,

    hookHookAccountStatsLoading: accountStatsLoading,

    hookIsReadOnlyList: Permission.readOnlyPermissionsList(permissionsCodeList),
  };
};
