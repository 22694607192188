import React from 'react';
import * as Styled from '../../styles';
import {
  InputTextarea, InputCalendar, Calendar, InputText, Checkbox,
} from '../../../../../../../../../components';
import { MarketingItems } from '../../MarketingItemComponent';
import { PRODUCT_FEED, USER } from '../../../../enums';

type CreditCardsProps = {
  hook: any
  isReadOnly: boolean
}

export const CreditCards = ({ hook, isReadOnly }:CreditCardsProps) => (
  <Styled.WrapperStyled>
    <Styled.InnerWrapperStyled isFirst>
      <Styled.InputWrappers>
        <InputCalendar
          isDisabled={hook.hookLoading || isReadOnly}
          label={PRODUCT_FEED.VALID_UNTIL.label}
          value={hook.hookValidValue}
          onClick={hook.hookShowCalendar}
          placeholder={PRODUCT_FEED.VALID_UNTIL.placeholder}
        />
        <Calendar
          onCancel={hook.hookCancelCalendar}
          onApply={hook.hookApplyCalendar}
          isOpen={hook.hookCalendarOpen}
          isSingle
        />
      </Styled.InputWrappers>
      <Styled.InnerWrapperStyled>
        <Styled.LabelTextStyled>
          {PRODUCT_FEED.CARD_NETWORK}
        </Styled.LabelTextStyled>
        <Styled.CheckBoxesWrapper>
          {hook.hookCardNetwork.map((card: any, index: number) => (
            <Checkbox
              label={card.label}
              checked={card.checked}
              onChange={() => hook.hookHandleChangeChecks(index)}
              disabled={isReadOnly}
            />
          ))}
        </Styled.CheckBoxesWrapper>
      </Styled.InnerWrapperStyled>

      <Styled.InnerWrapperStyled>
        <Styled.LabelTextStyled>
          {PRODUCT_FEED.USER_TYPES}
        </Styled.LabelTextStyled>
        <Styled.CheckBoxesWrapper>
          {hook.hookUserTypes.map((user: any, index: number) => (
            <Checkbox
              label={user.label}
              checked={user.checked}
              onChange={() => hook.hookHandleChangeChecks(index, USER)}
              disabled={isReadOnly}
            />
          ))}
        </Styled.CheckBoxesWrapper>
      </Styled.InnerWrapperStyled>
      <Styled.InputWrappers>
        <InputText
          disabled={hook.hookLoading || isReadOnly}
          label={PRODUCT_FEED.MONTHLY_FEES.label}
          value={hook.hookMonthlyFee}
          type="text"
          onChange={hook.hookChangeMonthlyFee}
          error={hook.hookNewProductErrors.monthlyFee}
          placeholder={PRODUCT_FEED.MONTHLY_FEES.placeholder}
          onBlur={hook.hookValidate}
        />
        <InputText
          disabled={hook.hookLoading || isReadOnly}
          label={PRODUCT_FEED.MONTHLY_FEES_DETAILS.label}
          value={hook.hookMonthlyDetails}
          onChange={hook.hookChangeMonthlyDetails}
          type="text"
          placeholder={PRODUCT_FEED.MONTHLY_FEES_DETAILS.placeholder}
        />
      </Styled.InputWrappers>

      <Styled.InputWrappers>
        <InputText
          disabled={hook.hookLoading || isReadOnly}
          label={PRODUCT_FEED.ANNUAL_FEES.label}
          value={hook.hookAnnualFee}
          type="text"
          onChange={hook.hookChangeAnnualFee}
          placeholder={PRODUCT_FEED.ANNUAL_FEES.placeholder}
          error={hook.hookNewProductErrors.annualFee}
          onBlur={hook.hookValidate}
        />
        <Styled.DoubleWrapper>
          <InputText
            disabled={hook.hookLoading || isReadOnly}
            label={PRODUCT_FEED.CARD_FEE.label}
            value={hook.hookCardFee}
            onChange={hook.hookChangeCardFee}
            type="text"
            placeholder={PRODUCT_FEED.CARD_FEE.placeholder}
            error={hook.hookNewProductErrors.cardFee}
            onBlur={hook.hookValidate}
          />
          <InputText
            disabled={hook.hookLoading || isReadOnly}
            label={PRODUCT_FEED.BALANCE_INTRO.label}
            value={hook.hookIntroRate}
            onChange={hook.hookChangeIntroRate}
            type="text"
            placeholder={PRODUCT_FEED.BALANCE_INTRO.placeholder}
            error={hook.hookNewProductErrors.introRate}
            tooltip={PRODUCT_FEED.MONETARY_TOOL_TIP}
            onBlur={hook.hookValidate}
          />
        </Styled.DoubleWrapper>
      </Styled.InputWrappers>

      <Styled.InputWrappers inverted>
        <InputTextarea
          disabled={hook.hookLoading || isReadOnly}
          label={PRODUCT_FEED.SIGN_UP.label}
          placeholder={PRODUCT_FEED.SIGN_UP.placeholder}
          value={hook.hookSignUp}
          onChange={hook.hookChangeSignUp}
        />
      </Styled.InputWrappers>
      <Styled.InputWrappers>
        <InputText
          disabled={hook.hookLoading || isReadOnly}
          label={PRODUCT_FEED.BALANCE_TRANSFER.label}
          value={hook.hookBalanceRegular}
          type="text"
          onChange={hook.hookChangeTransferRegular}
          error={hook.hookNewProductErrors.balanceTransferRegular}
          placeholder={PRODUCT_FEED.BALANCE_TRANSFER.placeholder}
          tooltip={PRODUCT_FEED.MONETARY_TOOL_TIP}
          onBlur={hook.hookValidate}
        />
        <InputText
          disabled={hook.hookLoading || isReadOnly}
          label={PRODUCT_FEED.BALANCE_DETAILS.label}
          value={hook.hookBalanceDetails}
          onChange={hook.hookChangeRegularDetails}
          type="text"
          placeholder={PRODUCT_FEED.BALANCE_DETAILS.placeholder}
        />
      </Styled.InputWrappers>
      <Styled.InputWrappers>
        <InputText
          disabled={hook.hookLoading || isReadOnly}
          label={PRODUCT_FEED.INTEREST_RATE.label}
          value={hook.hookInterestRate}
          type="text"
          onChange={hook.hookChangeInterestRate}
          error={hook.hookNewProductErrors.interestRate}
          placeholder={PRODUCT_FEED.INTEREST_RATE.placeholder}
          tooltip={PRODUCT_FEED.MONETARY_TOOL_TIP}
          onBlur={hook.hookValidate}
        />
        <InputText
          disabled={hook.hookLoading || isReadOnly}
          label={PRODUCT_FEED.INTEREST_RATE_DETAILS.label}
          value={hook.hookInterestRateDetails}
          onChange={hook.hookChangeInterestRateDtails}
          type="text"
          placeholder={PRODUCT_FEED.INTEREST_RATE_DETAILS.placeholder}
        />
      </Styled.InputWrappers>

      <Styled.InputWrappers inverted>
        <InputTextarea
          disabled={hook.hookLoading || isReadOnly}
          label={PRODUCT_FEED.REWARDS.label}
          placeholder={PRODUCT_FEED.REWARDS.placeholder}
          value={hook.hookRewardsValue}
          onChange={hook.hookChangeRewards}
        />
      </Styled.InputWrappers>
      <Styled.InputWrappers>
        <InputText
          disabled={hook.hookLoading || isReadOnly}
          label={PRODUCT_FEED.ANNUAL_INTEREST_RATE.label}
          value={hook.hookAnnualRate}
          type="text"
          onChange={hook.hookChangeAnnualRate}
          error={hook.hookNewProductErrors.annualRate}
          placeholder={PRODUCT_FEED.ANNUAL_INTEREST_RATE.placeholder}
          tooltip={PRODUCT_FEED.MONETARY_TOOL_TIP}
          onBlur={hook.hookValidate}
        />
        <InputText
          disabled={hook.hookLoading || isReadOnly}
          label={PRODUCT_FEED.ANNUAL_INTEREST.label}
          value={hook.hookAnnualDetails}
          onChange={hook.hookChangeAnnualDetails}
          type="text"
          placeholder="0"
        />
      </Styled.InputWrappers>

      <Styled.InputWrappers>
        <InputText
          disabled={hook.hookLoading || isReadOnly}
          label={PRODUCT_FEED.BALANCE_INTRO.label}
          value={hook.hookBalanceIntro}
          type="text"
          onChange={hook.hookChangeBalanceIntro}
          error={hook.hookNewProductErrors.balanceIntro}
          placeholder={PRODUCT_FEED.BALANCE_INTRO.placeholder}
          tooltip={PRODUCT_FEED.MONETARY_TOOL_TIP}
          onBlur={hook.hookValidate}
        />
        <Styled.DoubleWrapper>
          <InputText
            disabled={hook.hookLoading || isReadOnly}
            label={PRODUCT_FEED.BALANCE_TRANSFER.label}
            value={hook.hookBalanceTransferFee}
            onChange={hook.hookChangeBalanceFee}
            error={hook.hookNewProductErrors.balanceFee}
            type="text"
            placeholder={PRODUCT_FEED.BALANCE_TRANSFER.placeholder}
            tooltip={PRODUCT_FEED.MONETARY_TOOL_TIP}
            onBlur={hook.hookValidate}
          />
          <InputText
            disabled={hook.hookLoading || isReadOnly}
            label={PRODUCT_FEED.CASH_ADVANCE.label}
            value={hook.hookCash}
            onChange={hook.hookChangeCash}
            error={hook.hookNewProductErrors.cashAdvanceFee}
            type="text"
            placeholder={PRODUCT_FEED.CASH_ADVANCE.placeholder}
            tooltip={PRODUCT_FEED.MONETARY_TOOL_TIP}
            onBlur={hook.hookValidate}
          />
        </Styled.DoubleWrapper>
      </Styled.InputWrappers>
      <Styled.InputWrappers>
        <InputText
          disabled={hook.hookLoading || isReadOnly}
          label={PRODUCT_FEED.FCC.label}
          value={hook.hookFCC}
          onChange={hook.hookChangeFCC}
          error={hook.hookNewProductErrors.FCC}
          type="text"
          placeholder={PRODUCT_FEED.FCC.placeholder}
          tooltip={PRODUCT_FEED.MONETARY_TOOL_TIP}
          onBlur={hook.hookValidate}
        />
        <Styled.DoubleWrapper>
          <InputText
            disabled={hook.hookLoading || isReadOnly}
            label={PRODUCT_FEED.DISHONOURED.label}
            value={hook.hookDishonoured}
            onChange={hook.hookChangeDishonoured}
            error={hook.hookNewProductErrors.dishonouredPaymentFee}
            type="text"
            placeholder="0"
            onBlur={hook.hookValidate}
          />
          <InputText
            disabled={hook.hookLoading || isReadOnly}
            label={PRODUCT_FEED.GRACE.label}
            value={hook.hookGrace}
            type="text"
            onChange={hook.hookChangeGrace}
            error={hook.hookNewProductErrors.gracePeriod}
            placeholder={PRODUCT_FEED.GRACE.placeholder}
            onBlur={hook.hookValidate}
          />
        </Styled.DoubleWrapper>
      </Styled.InputWrappers>
    </Styled.InnerWrapperStyled>
    <MarketingItems
      disabled={hook.hookLoading || isReadOnly}
      setMarketingItems={hook.hookSetMarketingItems}
      addMarketingItem={hook.hookAddMarketingItem}
      marketingItems={hook.hookMarketingItems}
      handleXbutton={hook.hookHandleX}
    />
    <Styled.InputWrappers
      inverted
      isLast
    >
      <InputTextarea
        disabled={hook.hookLoading || isReadOnly}
        label={PRODUCT_FEED.LEGAL_REF.label}
        placeholder={PRODUCT_FEED.LEGAL_REF.placeholder}
        value={hook.hookLegalValue}
        onChange={hook.hookChangeLegalValue}
        tooltip={PRODUCT_FEED.LEGAL_PREFERENCE_TOOL_TIP}
      />
    </Styled.InputWrappers>
  </Styled.WrapperStyled>
);
