export const END_DATE_ERROR = 'Please check the expiration date.';
export const DUPLICATE_NAME = 'duplicate name';
export const DUPLICATE_NAME_ERROR = (name: string) => `Ad name "${name}" already exists. Please try with a different name`;

export const BUTTON_LABELS = {
  ADD_FIELD: 'Add Field',
  CANCEL: 'Cancel',
  SAVE: 'Save',
  CREATE_CAMPAIGN: 'Create Campaign',
  VIEW_AD: 'View Ad',
};
export const LABELS = {
  ID: 'ID',
  COMPANY_NAME: 'COMPANY NAME',
  CREATED_DATE: 'CREATED DATE',
  LAST_UPDATED: 'LAST UPDATED',
  AD_NAME: 'Ad Name',
  STATUS: 'Status',
  CAMPAIGN: 'Campaign',
  DESCRIPTION: 'Description',
  PROFILE_PHOTO: 'Profile Photo',
  IMAGE: 'Image',
  IMAGE_ALT: 'Image Alt',
  HTML_CODE: 'HTML5 Code',
  AD_WIDTH: 'Ad Width',
  AD_HEIGHT: 'Ad Height',
  ASSET_FILE: 'Asset File',
  LINK_CONTENT: 'Text Link Content',
  LANDING_URL: 'Default Landing Page Url',
  LANDING_PAGE: 'Landing Page',
  PRODUCT: 'Product',
  START_DATE: 'Start Date',
  END_DATE: 'End Date',
  TAGS: 'Tags',
  LANGUAGE: 'Language',
};

export const INFO = {
  DROP_ZONE: 'Maximum upload size: 200KB Image Format: .png/.jpg/.jpeg/.gif',
  JS_ONLY: 'Click here to confirm this is a Javascript only Ad',
  ASSET: 'If you upload to your CDN/servers, please confirm the file link URL. File Format: ZIP',
};

export const PLACEHOLDERS = {
  STATUS: 'Select Status',
  CAMPAIGN: 'Select Campaign',
  PRODUCT: 'Select Product',
  DATE: 'Select Date',
  TAGS: 'Select Tags',
  PX: 'px',
  URL: 'https://www.example.com',
};

export const SECTION_HEADERS = {
  BANNER: 'Image Upload',
  LINK: 'Tracking Link',
  HTML: 'File Upload',
  ADVANCED: 'Advanced Options',
};

export const TOAST_MESSAGES = {
  FAILED: 'Failed to save changes. Please try again.',
  SUCCESS: 'Ad edited successfully',
};
