import { useLazyQuery, useMutation } from '@apollo/client';
import { useEffect, useState } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import { useToast } from '../../../../../hooks';
import {
  RECORDS_PER_PAGE_OPTIONS, formatterForOption, MERCHANT_PREFIX, path, USER_TYPES_ID, ERROR_MESSAGES, SUCCESS_MESSAGES,
} from '../../../../../utils';
import { useCreateCampaign } from '../../CreateCampaign/hooks';
import { EDIT_CAMPAIGN } from '../graphql/mutation';
import { LIST_SEARCH_AD, SHOW_CAMPAIGN } from '../graphql/queries';
import { Permission } from '../../../../../entities';

type Filter = {
  campaignId?: string;
  q: string;
  management: boolean;
};

export const useEditCampaign = (permissionsCodeList: string[] = []) => {
  const hook = useCreateCampaign();
  const { hookShowToast } = useToast();
  const navigate = useNavigate();
  const location = useLocation();
  const [adminProgramId, setAdminProgramId] = useState('');

  const [adList, setAdList] = useState([]);
  const [search, setSearch] = useState('');

  const [sortColumn, setSortColumn] = useState<TableSortColumn>({ column: 'createdAt', direction: 'asc' });
  const [page, setPage] = useState(1);
  const [totalPages, setTotalPages] = useState(0);
  const [records, setRecords] = useState({ label: '10', value: '10' });
  const [recordsCount, setRecordsCount] = useState(0);
  const [adModal, setAdModal] = useState(false);
  const [selectedAd, setSelectedAd] = useState('');

  const [getExistingCampaign, { loading: getExistingCampaignLoading }] = useLazyQuery(SHOW_CAMPAIGN);
  const [editCampaign, { loading: updateCampaignLoading }] = useMutation(EDIT_CAMPAIGN);
  const [getAssociatedAds, { loading: getAssociatedAdsLoading }] = useLazyQuery(LIST_SEARCH_AD);
  const [loading, setLoading] = useState(false);

  const defaultFilter: Filter = {
    q: search,
    management: true,
  };

  const getAdsHandler = async () => {
    if (location.state?.campaignId) {
      defaultFilter.campaignId = location.state?.campaignId;
    }
    const { data } = await getAssociatedAds({
      variables: {
        input: {
          filter: {
            campaignId: location.state?.campaignId.toString(),
          },
          options: {
            page,
            order: sortColumn.direction?.toUpperCase(),
            items: Number(records.value),
          },
          sortBy: sortColumn.column,
        },
      },
    });

    if (data?.listSearchAds?.ads) {
      setAdList(data?.listSearchAds?.ads);
    }

    if (data?.listSearchAds?.count) {
      setRecordsCount(data.listSearchAds.count);
      setTotalPages(data.listSearchAds.totalPages);
    }
  };

  const setSearchHandler = (value: string) => {
    setSearch(value);
  };

  const changePageHandler = (pageValue: number) => {
    setPage(pageValue);
  };

  const setOpenAdModalHandler = (adId: string) => {
    setAdModal(true);
    setSelectedAd(adId);
  };

  const setCloseAdModalHandler = () => {
    setAdModal(false);
  };

  const getCampaignHandler = async () => {
    setLoading(true);
    if (!location.state?.campaignId) {
      if (hook.hookWhoAmI.programId) {
        navigate(`${MERCHANT_PREFIX}${path.campaignManagement.href}`);
      } else {
        navigate(path.campaignManagement.href);
      }
    }

    getAdsHandler();

    const { data } = await getExistingCampaign({
      variables: {
        input: {
          campaignId: location.state.campaignId.toString(),
        },
      },
      fetchPolicy: 'no-cache',
    });

    const {
      programId,
      name,
      description,
      status,
      startDate,
      endDate,
      tagIds,
      productId,
      defaultLandingPage,
      language,
      thirdPartyUrl,
      publisherGroupIds,
    } = data.showCampaign;

    setAdminProgramId(programId);
    hook.hookSetCampaignName(name);
    hook.hookSetDescription(description);
    hook.hookSetStatus(formatterForOption({ status }, 'status'));
    hook.hookOnApplyStartCalendar(new Date(startDate));
    hook.hookSetDefaultLandingPageUrl(defaultLandingPage);
    hook.hookSetLanguage(formatterForOption({ language }, 'language'));
    hook.hookSetThirdPartyTrackingUrl(thirdPartyUrl);
    hook.hookSetPublisherGroup(publisherGroupIds);
    hook.hookSetPublisherGroupOptions(hook.hookPublisherGroupBackup.map((publisherGroup: { label: string, value: string, checked: boolean }) => {
      if (publisherGroupIds.find((groupId: any) => Number(groupId) === Number(publisherGroup.value))) {
        return { ...publisherGroup, checked: true };
      }
      return publisherGroup;
    }));
    if (tagIds !== null && Array.isArray(tagIds)) {
      const tempTags: {label: string, value: string }[] = [];
      hook.hookTagsOptions.map((tag: { label: string, value: string }) => {
        if (tagIds.find((tagId: any) => tagId === tag.value)) {
          tempTags.push(tag);
        }
      });
      hook.hookSetTags(tempTags);
    }

    if (endDate) hook.hookOnApplyEndCalendar(new Date(endDate));
    const product = hook.hookProductsOptions.find((prod) => prod.value === productId);
    if (productId) hook.hookSetProducts(formatterForOption({ productName: product?.label, productId }, 'productName', 'productId'));
    setLoading(false);
  };

  const editAdCampaignHandler = async () => {
    hook.hookSetSecondRender(true);
    const validated = hook.hookHandleValidation();
    hook.hookSetValidated(validated);
    if (!validated) return;

    const formattedTags = hook.hookTags.map((tag) => tag?.label);
    const { data, errors } = await editCampaign({
      variables: {
        input: {
          name: hook.hookCampaignName,
          description: hook.hookDescription,
          startDate: hook.hookStartDate?.toUTCString(),
          endDate: hook.hookEndDate?.toUTCString() || undefined,
          defaultLandingPage: hook.hookDefaultLandingPageUrl || undefined,
          thirdPartyUrl: hook.hookThirdPartyTrackingUrl || undefined,
          language: hook.hookLanguage?.value,
          productId: hook.hookProducts?.value || undefined,
          tags: formattedTags,
          status: hook.hookStatus?.value,
          programId: adminProgramId || hook.hookWhoAmI.programId,
          publisherGroupIds: hook.hookPublisherGroups || undefined,
          id: location.state.campaignId,
        },
      },
    });

    if (errors) {
      hookShowToast(ERROR_MESSAGES.FAIL_TO_SAVE);
      return;
    }
    if (data && data?.editCampaign?.name) {
      if (hook.hookWhoAmI.userTypeId === USER_TYPES_ID.MERCHANT || hook.hookWhoAmI.isActingAsUserTypeId === USER_TYPES_ID.MERCHANT) {
        navigate(`${MERCHANT_PREFIX}${path.campaignManagement.href}`);
      } else {
        navigate(path.campaignManagement.href);
      }
      hookShowToast(SUCCESS_MESSAGES.CAMPAIGN_EDIT(data?.editCampaign?.name));
    }
  };

  const setRecordsHandler = (value: any) => {
    setRecords({
      label: value.label,
      value: value.value,
    });
  };

  const handleSort = (dataField: string, direction: any) => {
    if (sortColumn.direction === null) {
      setSortColumn({ column: dataField, direction });
    } else {
      setSortColumn({ column: dataField, direction: sortColumn.direction === 'asc' ? 'desc' : 'asc' });
    }
  };

  useEffect(() => {
    if (hook.hookPublisherGroupBackup !== undefined) {
      getCampaignHandler();
    }
  }, [JSON.stringify(hook.hookPublisherGroupBackup)]);

  useEffect(() => {
    getAdsHandler();
  }, [page, sortColumn, records, window.location.pathname]);

  useEffect(() => {
    setPage(1);
  }, [records]);

  return {
    hookAds: adList,

    hookAdModal: adModal,
    hookSelectedAd: selectedAd,
    hookSetOpenAdModal: setOpenAdModalHandler,
    hookSetCloseAdModal: setCloseAdModalHandler,

    hookSortColumn: sortColumn,
    hookHandleSort: handleSort,

    hookSetSearch: setSearchHandler,
    hookChangePage: changePageHandler,
    hookPage: page,
    hookTotalPages: totalPages,

    hookCampaignName: hook.hookCampaignName,
    hookSetCampaignName: hook.hookSetCampaignName,

    hookDescription: hook.hookDescription,
    hookSetDescription: hook.hookSetDescription,

    hookStatus: hook.hookStatus,
    hookStatusOptions: hook.hookStatusOptions,
    hookSetStatus: hook.hookSetStatus,

    hookStartDate: hook.hookStartDate,
    hookStartCalendarOpen: hook.hookStartCalendarOpen,
    hookOnOpenStartCalendar: hook.hookOnOpenStartCalendar,
    hookOnApplyStartCalendar: hook.hookOnApplyStartCalendar,
    hookOnCancelStartCalendar: hook.hookOnCancelStartCalendar,

    hookEndDate: hook.hookEndDate,
    hookEndCalendarOpen: hook.hookEndCalendarOpen,
    hookOnOpenEndCalendar: hook.hookOnOpenEndCalendar,
    hookOnApplyEndCalendar: hook.hookOnApplyEndCalendar,
    hookOnCancelEndCalendar: hook.hookOnCancelEndCalendar,

    hookTags: hook.hookTags,
    hookTagsOptions: hook.hookTagsOptions,
    hookSetTags: hook.hookSetTags,

    hookProducts: hook.hookProducts,
    hookProductsOptions: hook.hookProductsOptions,
    hookSetProducts: hook.hookSetProducts,

    hookDefaultLandingPageUrl: hook.hookDefaultLandingPageUrl,
    hookSetDefaultLandingPageUrl: hook.hookSetDefaultLandingPageUrl,
    hookUrlPlaceholder: hook.hookUrlPlaceholder,

    hookLanguage: hook.hookLanguage,
    hookLanguageOptions: hook.hookLanguageOptions,
    hookSetLanguage: hook.hookSetLanguage,

    hookThirdPartyTrackingUrl: hook.hookThirdPartyTrackingUrl,
    hookSetThirdPartyTrackingUrl: hook.hookSetThirdPartyTrackingUrl,

    hookPublisherGroupOptions: hook.hookPublisherGroupOptions,
    hookSetPublisherGroupHandler: hook.hookSetPublisherGroupHandler,

    hookEditCampaignErrors: hook.hookCreateCampaignErrors,
    hookLandingUrlError: hook.hookLandingUrlError,
    hookThirdPartyUrlError: hook.hookThirdPartyUrlError,
    hookHandleValidation: hook.hookHandleValidation,
    hookValidateWebsite: hook.hookValidateWebsite,
    hookValidated: hook.hookValidated,

    hookLoading: hook.hookLoading || getExistingCampaignLoading || updateCampaignLoading || getAssociatedAdsLoading || loading,
    hookSubmitCampaign: editAdCampaignHandler,

    hookCurrentPage: page,
    hookRecords: records,
    hookTotalRecords: recordsCount,
    hookSetRecords: setRecordsHandler,
    hookRecordOptions: RECORDS_PER_PAGE_OPTIONS,

    hookIsReadOnlyList: Permission.readOnlyPermissionsList(permissionsCodeList),
  };
};
