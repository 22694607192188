import React from 'react';
import { faExclamationTriangle, faMinusCircle } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { FormatedCell } from '../components';
import { CheckRulesOutputType } from '../hooks';
import { dateFormatter } from '../../../../../utils';
import { Button, Checkbox } from '../../../../../components';
import * as Styled from './styles';

const iconRed = {
  fontSize: 16,
  color: 'red',
  marginRight: 10,
  paddingLeft: 56,
};

const review = {
  fontSize: 16,
  color: 'orange',
  marginRight: 10,
};

const formatProductNameColumn = (row: CheckRulesOutputType): string => {
  if (row.applicableProducts === 'All Products' || row.applicableProducts === 'No Specific Products') {
    return row.applicableProducts;
  }
  return `${row.customizedProductId} - ${row.productName}`;
};

const formatStartEndDateColumn = (row: CheckRulesOutputType): string => {
  const startDate = dateFormatter(new Date(row.startDate));
  const endDate = row.endDate ? dateFormatter(new Date(row.endDate)) : '';
  return `${startDate} - ${endDate}`;
};

const formatContentColumn = (row: CheckRulesOutputType, onClick: () => void) => (
  <Styled.ContentButtonWrapper>
    <Button
      theme="text-only"
      onClick={onClick}
    >
      Show Details
    </Button>
  </Styled.ContentButtonWrapper>
);

const formatErrorsColumn = (row: CheckRulesOutputType) => (
  <Styled.ErrorColumnWrapper>
    <Styled.ErrorSpanWrapper>
      <FontAwesomeIcon
        icon={faExclamationTriangle}
        style={{ ...review }}
      />
      {row.reviewCount || 0}
    </Styled.ErrorSpanWrapper>
    <Styled.ErrorSpanWrapper>
      <FontAwesomeIcon
        icon={faMinusCircle}
        style={{ ...iconRed }}
      />
      {row.failCount || 0}
    </Styled.ErrorSpanWrapper>
  </Styled.ErrorColumnWrapper>
);

const CheckBoxCellFormatter = (checked: boolean, onChange: (state: any) => void, isReadOnly: boolean) => (
  <Styled.CellWrapperStyled theme="checkBox">
    <Checkbox
      checked={checked}
      onChange={onChange}
      disabled={isReadOnly}
    />
  </Styled.CellWrapperStyled>
);

export const columns = (isReadOnly: boolean): TableColumn[] => [
  {
    dataField: 'checkBox',
    text: '',
    headerFormatter: (headerCheckBox: boolean, headerCheckBoxHandler: (state: boolean) => void) => (
      CheckBoxCellFormatter(headerCheckBox, headerCheckBoxHandler, isReadOnly)
    ),
    formatter: (_: any, row: any, __: any, ___: any, handleCheckBox?: (id: string, add: boolean) => void) => (CheckBoxCellFormatter(row.checked, ((state: boolean) => { if (handleCheckBox) handleCheckBox(row.id, state); }), isReadOnly)),
    width: '46px',
    isCheckBox: true,
  },
  {
    dataField: 'ruleName',
    text: 'Rule Name',
    sortFunction: (a: any, b: any) => a.name.localeCompare(b.name),
    formatter: (value: any) => (
      <FormatedCell
        value={value}
      />
    ),
    width: '12.5%',
  },
  {
    dataField: 'productCategory',
    text: 'Category',
    sortFunction: (a: any, b: any) => a.name.localeCompare(b.name),
    formatter: (_, row: CheckRulesOutputType) => (
      <FormatedCell
        value={row.productCategory}
      />
    ),
    width: '150px',
  },
  {
    dataField: 'applicableProducts',
    text: 'Product',
    sortFunction: (a: any, b: any) => a.name.localeCompare(b.name),
    formatter: (_, row: CheckRulesOutputType) => (
      <FormatedCell
        value={formatProductNameColumn(row)}
      />
    ),
    width: '15%',
  },
  {
    dataField: 'content',
    text: 'Content',
    formatter: (_, row: CheckRulesOutputType, __, ___, ____, setModalOpen, setModalInfo) => (
      formatContentColumn(
        row,
        (() => {
          setModalOpen();
          setModalInfo({ rule: row });
        }),
      )),
    width: '10%',
  },
  {
    dataField: 'status',
    text: 'Status',
    sortFunction: (a: any, b: any) => a.name.localeCompare(b.name),
    formatter: (value: string, _: CheckRulesOutputType) => (
      <FormatedCell
        value={value}
      />
    ),
    width: '8%',
  },
  {
    dataField: 'startDate',
    text: 'Start/End Date',
    sortFunction: (a: any, b: any) => a.name.localeCompare(b.name),
    formatter: (_: string, row: CheckRulesOutputType) => (
      <FormatedCell
        value={formatStartEndDateColumn(row)}
      />
    ),
    width: '15%',
  },
  {
    dataField: 'lastRun',
    text: 'Last Run',
    sortFunction: (a: any, b: any) => a.name.localeCompare(b.name),
    formatter: (value: string) => (
      <FormatedCell
        value={value ? dateFormatter(new Date(value)) : ''}
      />
    ),
    width: '12.5%',
  },
  {
    dataField: 'failCount',
    text: 'Pages with Errors',
    sortFunction: (a: any, b: any) => a.name.localeCompare(b.name),
    formatter: (_, row: CheckRulesOutputType) => formatErrorsColumn(row),
    width: '12.5%',
  },
];
