import styled from 'styled-components';
import { colors, fonts } from '../../../styles/theme';
import { toRem } from '../../../utils';

type WrapperStyledProps = {
  readonly disabled?: boolean;
  readonly error?: boolean;
  readonly width?: string;
}

type ButtonProps = {
  readonly theme: Theme;
};

export const ButtonStyled = styled.span<ButtonProps>`
  ${fonts.lato.H14}
  display: flex;
  align-items: center;
  justify-content: center;
  padding: ${({ theme }) => {
    switch (theme) {
      case 'quaternary':
        return 0;
      case 'tertiary':
      case 'primary':
        return `${toRem(15)} ${toRem(0)}`;
      default:
        return `${toRem(20)} ${toRem(32)}`;
    }
  }};
  border-radius: ${toRem(4)};
  transition: all 0.2s ease-in-out;
  width: ${({ theme }) => {
    switch (theme) {
      case 'primary':
        return `${toRem(95)}`;
      case 'tertiary':
        return `${toRem(95)}`;
      default:
        return `${toRem(150)}`;
    }
  }};
  
  height: ${({ theme }) => {
    switch (theme) {
      case 'primary':
        return `${toRem(10)}`;
      case 'tertiary':
        return `${toRem(40)}`;
      default:
        return `${toRem(50)}`;
    }
  }};

  margin-left: ${({ theme }) => {
    switch (theme) {
      case 'primary':
        return `${toRem(10)}`;
      case 'tertiary':
        return `${toRem(10)}`;
      default:
        return `${toRem(0)}`;
    }
  }};

  border: 1px solid ${({ theme }) => {
    switch (theme) {
      case 'secondary':
        return colors.color1;
      case 'tertiary':
        return colors.color9;
      case 'quaternary':
        return colors.transparent;
      case 'text-only':
        return colors.transparent;
      case 'calendar':
        return colors.color15;
      default:
        return colors.color2;
    }
  }};

  background-color: ${({ theme }) => {
    switch (theme) {
      case 'secondary':
        return colors.transparent;
      case 'tertiary':
        return colors.color9;
      case 'quaternary':
        return colors.transparent;
      case 'text-only':
        return colors.transparent;
      case 'calendar':
        return colors.color15;
      default:
        return colors.color2;
    }
  }};

  color: ${({ theme }) => {
    switch (theme) {
      case 'secondary':
        return colors.color1;
      case 'quaternary':
        return colors.color10;
      case 'calendar':
        return colors.color1;
      case 'text-only':
        return colors.color12;
      default:
        return colors.color4;
    }
  }};

  &:hover {
    background-color: ${({ theme }) => {
    switch (theme) {
      case 'secondary':
        return colors.color1;
      case 'tertiary':
        return colors.color10;
      case 'quaternary':
        return colors.transparent;
      case 'text-only':
        return colors.transparent;
      case 'calendar':
        return colors.color13;
      default:
        return colors.color8;
    }
  }};
    
    border: 1px solid ${({ theme }) => {
    switch (theme) {
      case 'secondary':
        return colors.color1;
      case 'tertiary':
        return colors.color10;
      case 'quaternary':
        return colors.transparent;
      case 'text-only':
        return colors.transparent;
      case 'calendar':
        return colors.color13;
      default:
        return colors.color8;
    }
  }};

    color: ${({ theme }) => {
    switch (theme) {
      case 'secondary':
        return colors.color4;
      case 'quaternary':
        return colors.color10;
      case 'text-only':
        return colors.color2;
      default:
        return colors.color4;
    }
  }};

    ${({ theme }) => theme === 'text-only' && 'text-decoration: underline;'}

  }
`;

export const WrapperStyled = styled.label<WrapperStyledProps>`
  display: flex;
  flex-flow: row wrap;
  width: ${({ width }) => (width)};
  cursor: ${({ disabled }) => (disabled ? 'not-allowed' : 'pointer')};
  width: ${({ width }) => width};

  ${ButtonStyled} {
    cursor: ${({ disabled }) => (disabled ? 'not-allowed' : 'pointer')};
    opacity: ${({ disabled }) => (disabled ? 0.5 : 1)};
  }
`;

export const LabelStyled = styled.span`
  display: flex;
  width: 100%;
  justify-content: flex-start;
  font-size: ${toRem(11)};
  text-transform: uppercase;
  margin-bottom: ${toRem(8)};
  color: ${colors.color13};
  ${fonts.gotham.H12M}
`;

export const InputStyled = styled.input`
  display: none;
`;

export const RequiredStyled = styled.span`
  color: ${colors.color2};
  margin-left: ${toRem(4)};
`;

export const FakeInputStyled = styled.div`
  width: calc(100% - 150px);
  display: flex;
  justify-content: flex-start;
  align-items: center;
  margin: 0 1rem;
  ${fonts.lato.H16}
`;

export const InfoStyled = styled.span`
  display: flex;
  width: 100%;
  justify-content: flex-start;
  font-size: ${toRem(11)};
  margin-top: ${toRem(8)};
  color: ${colors.color13};
  ${fonts.lato.H14}
`;

export const ButtonWrapperStyled = styled.div<WrapperStyledProps>`
  display: flex;
  align-items: center;
  padding-right: ${toRem(10)};
  height: 40px;
  width: 100%;
  border: 1px solid ${({ error }) => (error ? colors.color14 : colors.color3)};
  border-radius: ${toRem(4)};
`;

export const ErrorStyled = styled.span`
  display: flex;
  width: 100%;
  color: ${colors.color14};
  margin-top: 0.5rem;
  ${fonts.lato.H14}
`;
