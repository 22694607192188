import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import React from 'react';
import { faPlus, faSearch } from '@fortawesome/free-solid-svg-icons';
import {
  Button, Table, FixedFooter, Modal, Pagination,
} from '../../../../../../components';
import * as Styled from './styles';
import * as TabStyled from '../styles';
import { columns } from './contracts';
import { useMembers } from './hooks';
import { MERCHANT_PREFIX, path } from '../../../../../../utils';

type MembersTabProps = {
  isReadOnly: boolean;
}

const MembersTab = ({ isReadOnly }: MembersTabProps) => {
  const hook = useMembers();

  return (
    <TabStyled.WrapperStyled>
      <Styled.FiltersStyled>
        <Styled.InputTextStyled
          type="text"
          faIcon={faSearch}
          placeholder="Search by Company ID / Name / URL"
          value={hook.hookSearch}
          onChange={hook.hookSetSearch}
        />

        <Styled.ExtendedFilterStyled>
          <Styled.ButtonStyled
            theme="tertiary"
            onClick={() => hook.hookNavigate(`${MERCHANT_PREFIX}${path.publisherSearch.href}`)}
            disabled={isReadOnly}
          >
            <FontAwesomeIcon
              icon={faPlus}
              style={{ marginRight: 8 }}
            />
            Add Member
          </Styled.ButtonStyled>
        </Styled.ExtendedFilterStyled>
      </Styled.FiltersStyled>

      <Table
        columns={columns(isReadOnly)}
        data={hook.hookTableData}
        setData={hook.hookSetTableData}
      />

      {!hook.hookLoading && (
        <Pagination
          total={hook.hookTotalPages}
          currentPage={hook.hookMembers?.length > 0 ? hook.hookCurrentPage : 0}
          onPageChange={hook.hookSetPageHandler}
        />
      )}

      <FixedFooter>
        <Styled.FixedFooterWrapperStyled>
          <Button
            theme="tertiary"
            onClick={hook.hookSetOpenModal}
            disabled={hook.hookDisableRemove || isReadOnly}
          >
            Remove From Group
          </Button>
        </Styled.FixedFooterWrapperStyled>
      </FixedFooter>
      <Modal isOpen={hook.hookOpenModal}>
        <Styled.ModalWrapper>
          <Styled.ModalTitleStyled>Remove from Group</Styled.ModalTitleStyled>
          <Styled.ModalDescriptioStyled>
            {`Are you sure you want to remove ${hook.hookPublishersToRemove} publisher(s)?`}
          </Styled.ModalDescriptioStyled>
          <Styled.ButtonWrapperStyled>
            <Button
              theme="secondary"
              onClick={hook.hookSetOpenModal}
            >
              Cancel
            </Button>
            <Button
              onClick={hook.hookRemovePublishers}
            >
              Remove
            </Button>
          </Styled.ButtonWrapperStyled>
        </Styled.ModalWrapper>
      </Modal>
    </TabStyled.WrapperStyled>
  );
};

export default MembersTab;
