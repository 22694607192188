import styled from 'styled-components';
import { toRem } from '../../utils';
import { colors, fonts } from '../../styles/theme';

export const UserDescriptionWrapperStyled = styled.div`
  display: flex;
  justify-content: flex-start;
  min-width: ${toRem(400)};
  margin-bottom: ${toRem(32)};
  gap: ${toRem(40)};
`;

export const UserDescriptionInnerWrapperStyled = styled.div`
  display: flex;
  flex-direction: column;
`;

export const UserDescTitleStyled = styled.div`
  display: flex;
  color: ${colors.color13};
  ${fonts.gotham.H12};
  line-height: ${toRem(16)};
  margin-bottom: ${toRem(5)};
  text-transform: uppercase;
`;

export const UserDescInformationStyled = styled.p`
  ${fonts.lato.H16};
  line-height: ${toRem(20)};
  color: ${colors.color9};
  min-width: ${toRem(140)};
`;
