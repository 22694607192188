import { gql } from '@apollo/client';

export const GET_MERCHANT_PAYMENT_REPORTS = gql`
  query paymentsForMerchant($input: PaymentSearchInput) {
  paymentsForMerchant(input: $input) {
    count
    payments {
      id
      publisher {
        id
        companyName
      }
      totalCommissions
      totalTaxes
      paymentMethod
      referenceNumber
      paymentGeneratedDate
      bonus
      bonusTax
      paymentPaidDate
      currency
      month
      year
      totalPayable
      paymentStatus
      cpaCommission
      revShareCommission
    }
  }
}
`;
