export const INPUT_LABELS = {
  NAME: 'first name',
  LAST_NAME: 'last name',
  EMAIL: 'email address',
  PASSWORD: 'password',
  PHONE: 'phone number',
  LANGUGE: 'preferred Language',
  ROLE: 'security role',
  POSITION: 'position',
};

export const TOAST_MESSAGES = {
  CREATE_USER: 'User created successfully',
};

export const ERROR_MESSAGES = {
  FAIL_USER: 'Failed to add user',
};

export const DEFAULT_VALIDATION_STATE = {
  firstName: '',
  lastName: '',
  email: '',
  role: '',
  phone: '',
};
