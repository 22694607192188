import React from 'react';
import * as Styled from '../../styles';
import {
  InputCalendar, Calendar, InputText, InputTextarea, Select,
} from '../../../../../../../../../components';
import { REDEEMABLE_OPTIONS, TERMS } from '../../../../../../../../../utils';
import { PRODUCT_FEED } from '../../../../enums';

type InvestmentsProps = {
  hook: any
  isReadOnly: boolean
}

export const Investments = ({ hook, isReadOnly }:InvestmentsProps) => (
  <Styled.WrapperStyled>
    <Styled.HalfWrapper>
      <Styled.DoubleWrapper>
        <InputCalendar
          isDisabled={hook.hookLoading || isReadOnly}
          label={PRODUCT_FEED.VALID_UNTIL.label}
          value={hook.hookValidValue}
          onClick={hook.hookShowCalendar}
          placeholder={PRODUCT_FEED.VALID_UNTIL.placeholder}
        />
        <Calendar
          onCancel={hook.hookCancelCalendar}
          onApply={hook.hookApplyCalendar}
          isOpen={hook.hookCalendarOpen}
          isSingle
        />
        <InputText
          disabled={hook.hookLoading || isReadOnly}
          type="text"
          label={PRODUCT_FEED.INTEREST_RATE.label}
          value={hook.hookInterestRate}
          error={hook.hookNewProductErrors.interestRate}
          placeholder={PRODUCT_FEED.VALID_UNTIL.label}
          onChange={hook.hookChangeInterestRate}
          onBlur={hook.hookValidate}
        />
      </Styled.DoubleWrapper>
      <Styled.DoubleWrapper>
        <Select
          isDisabled={hook.hookLoading || isReadOnly}
          label={PRODUCT_FEED.TERM.label}
          selected={hook.hookSelectTerm}
          placeholder={PRODUCT_FEED.TERM.placeholder}
          onChange={hook.hookChangeSelectTerm}
          options={TERMS}
        />
        <Select
          isDisabled={hook.hookLoading || isReadOnly}
          label={PRODUCT_FEED.REDEEMABLE.label}
          placeholder={PRODUCT_FEED.REDEEMABLE.placeholder}
          selected={hook.hookRedeemable}
          onChange={hook.hookChangeRedeemAble}
          options={REDEEMABLE_OPTIONS}
        />
      </Styled.DoubleWrapper>
    </Styled.HalfWrapper>
    <Styled.HalfWrapper>
      <Styled.DoubleWrapper>
        <InputText
          disabled={hook.hookLoading || isReadOnly}
          type="text"
          label={PRODUCT_FEED.INSURER.label}
          value={hook.hookInsurer}
          onChange={hook.hookChangeInsurer}
        />
        <InputText
          disabled={hook.hookLoading || isReadOnly}
          type="text"
          label={PRODUCT_FEED.MINIMUM_BALANCE.label}
          value={hook.hookMinBalance}
          error={hook.hookNewProductErrors.minBalance}
          placeholder={PRODUCT_FEED.MINIMUM_BALANCE.placeholder}
          onChange={hook.hookChangeMinBalance}
          onBlur={hook.hookValidate}
        />
      </Styled.DoubleWrapper>
      <Styled.DoubleWrapper>
        <InputText
          disabled={hook.hookLoading || isReadOnly}
          type="text"
          label={PRODUCT_FEED.MAN_FEES.label}
          value={hook.hookManFees}
          error={hook.hookNewProductErrors.manFees}
          placeholder={PRODUCT_FEED.MAN_FEES.placeholder}
          onChange={hook.hookChangeManFees}
          onBlur={hook.hookValidate}
        />
        <InputText
          disabled={hook.hookLoading || isReadOnly}
          type="text"
          label={PRODUCT_FEED.TRADE_COSTS.label}
          value={hook.hookTrade}
          placeholder={PRODUCT_FEED.TRADE_COSTS.placeholder}
          onChange={hook.hookChangeTrade}
          onBlur={hook.hookValidate}
        />
      </Styled.DoubleWrapper>
    </Styled.HalfWrapper>
    <Styled.HalfWrapper>
      <Styled.DoubleWrapper>
        <InputText
          disabled={hook.hookLoading || isReadOnly}
          type="text"
          label={PRODUCT_FEED.NUMBER_TRADES.label}
          value={hook.hookMonthlyTrades}
          onChange={hook.hookChangeMonthlyTrades}
          error={hook.hookNewProductErrors.monthlyTrades}
          placeholder={PRODUCT_FEED.NUMBER_TRADES.placeholder}
          onBlur={hook.hookValidate}
        />
        <InputText
          disabled={hook.hookLoading || isReadOnly}
          type="text"
          label={PRODUCT_FEED.MER.label}
          value={hook.hookMer}
          error={hook.hookNewProductErrors.mer}
          placeholder={PRODUCT_FEED.MER.placeholder}
          onChange={hook.hookChangeMer}
          onBlur={hook.hookValidate}
        />
      </Styled.DoubleWrapper>
    </Styled.HalfWrapper>
    <Styled.HalfWrapper isEnd>
      {hook.hookMarketingItems.map((item: any, index: number) => (
        <InputTextarea
          disabled={hook.hookLoading || isReadOnly}
          label={`${PRODUCT_FEED.MARKETING_ITEMS.label}${index + 1}`}
          value={item}
          onChange={(event: React.ChangeEvent<HTMLTextAreaElement>) => hook.hookSetMarketingItems(event, index)}
        />
      ))}
    </Styled.HalfWrapper>
    <InputTextarea
      disabled={hook.hookLoading || isReadOnly}
      label={PRODUCT_FEED.LEGAL_REF.label}
      placeholder={PRODUCT_FEED.LEGAL_REF.placeholder}
      value={hook.hookLegalValue}
      onChange={hook.hookChangeLegalValue}
      tooltip={PRODUCT_FEED.LEGAL_PREFERENCE_TOOL_TIP}
    />
  </Styled.WrapperStyled>
);
