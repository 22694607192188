import styled from 'styled-components';
import { colors, fonts } from '../../../styles/theme';

export const DescriptionStyled = styled.div`
  ${fonts.lato.H16}
  color: ${colors.color17};
  margin: 2rem 0;
`;

export const FilterWrapperStyled = styled.div`
  display: flex;
  justify-content: space-between;
  margin-bottom: 1rem;
`;
