import React from 'react';
import { faFlag as checked } from '@fortawesome/free-solid-svg-icons';
import { faFlag as unchecked } from '@fortawesome/free-regular-svg-icons';

import {
  AppWrapper, Button, ErrorBanner, LoadingDots, Modal, PageTitle, Pagination, Table,
} from '../../../../components';
import { useRuleDetails } from './hooks';
import { DETAILS } from './enums';
import { statusColumns } from './contracts';
import { EligibilityFeedbackModal } from './Components';
import { dateFormatter } from '../../../../utils';
import { RunDate } from '../Components';
import { FeedbackModal } from '../FintelCheckDetails/Modal';
import { DefaultPropsType } from '../../../../types';

import * as Styled from './styles';

const formatStatus = (value: string) => {
  switch (value) {
    case 'NF':
      return 'Not Applicable';
    case 'Check Fail':
      return 'Fail';
    default:
      return value;
  }
};

const camelCaseToSentenceCase = (str: string) => (str ? str.replace(/([A-Z])/g, ' $1').replace(/^./, (string) => string.toUpperCase()) : '');

type FintelCheckRuleDetailsProps = DefaultPropsType;

const FintelCheckRuleDetails = ({ permissionsCodeList = [] }: FintelCheckRuleDetailsProps) => {
  const hook = useRuleDetails();

  return (
    <AppWrapper
      background
      permissionsCodeList={permissionsCodeList}
    >
      <ErrorBanner
        message={hook.hookErrorMessage}
        isOpen={!!hook.hookErrorMessage}
      />
      <Styled.HeaderStyled>
        <Styled.HeaderLeftWrapper>
          <Styled.HeaderLeftStyled>
            <PageTitle>
              {
                hook.hookContext.publisherId
                  ? `${hook.hookContext.publisherId} - ${hook.hookContext.publisherName}`
                  : 'Manual URLs'
              }
            </PageTitle>
            <RunDate
              date={dateFormatter(new Date(hook.hookContext.date), ',')}
            />
          </Styled.HeaderLeftStyled>
          <Styled.RuleNameStyled>{hook.hookContext.ruleName}</Styled.RuleNameStyled>
          <Styled.RefferalUrlStyled
            href={hook.hookContext.referralUrl}
            target="_blank"
          >
            {hook.hookContext.referralUrl}
          </Styled.RefferalUrlStyled>
        </Styled.HeaderLeftWrapper>
        <Button
          theme="denary"
          onClick={hook.hookBack}
        >
          {DETAILS.BACK}
        </Button>
      </Styled.HeaderStyled>

      <Styled.ReportWrapper>
        <Styled.ReportTitleStyled>
          {DETAILS.ELIGIBILITY}
          <Styled.ButtonStyled
            theme="text-only"
            onClick={hook.hookSetEligibilityModalOpen}
          >
            <Styled.TooltipStyled
              fontSize="20px"
              text={hook.hookSummaryData?.eligibilityFeedback ? 'Update Eligibility Feedback' : 'Add Eligibility Feedback'}
              icon={hook.hookSummaryData?.eligibilityFeedback ? checked : unchecked}
            />
          </Styled.ButtonStyled>

        </Styled.ReportTitleStyled>

        {hook.hookLoading ? (
          <LoadingDots />
        ) : (
          <>
            <Styled.CriteriaRowStyled>
              <Styled.CriteriaItemStyled>
                <Styled.CriteriaTitleStyled>Rule Status</Styled.CriteriaTitleStyled>
                <Styled.CriteriaValueStyled>{formatStatus(hook.hookSummaryData?.ruleStatus || '')}</Styled.CriteriaValueStyled>
              </Styled.CriteriaItemStyled>
              <Styled.CriteriaItemStyled>
                <Styled.CriteriaTitleStyled>Product Category</Styled.CriteriaTitleStyled>
                <Styled.CriteriaValueStyled>{hook.hookSummaryData?.productCategory}</Styled.CriteriaValueStyled>
              </Styled.CriteriaItemStyled>
              <Styled.CriteriaItemStyled>
                <Styled.CriteriaTitleStyled>Product</Styled.CriteriaTitleStyled>
                <Styled.CriteriaValueStyled>{hook.hookSummaryData?.productName}</Styled.CriteriaValueStyled>
              </Styled.CriteriaItemStyled>
              <Styled.CriteriaItemStyled>
                <Styled.CriteriaTitleStyled>Nominated Field</Styled.CriteriaTitleStyled>
                <Styled.CriteriaValueStyled>
                  {hook.hookRuleData?.nominatedField || camelCaseToSentenceCase(hook.hookRuleData?.productFeed?.[0]?.productFeed || '')}
                </Styled.CriteriaValueStyled>
              </Styled.CriteriaItemStyled>
            </Styled.CriteriaRowStyled>

            <Styled.ReportTitleStyled>{DETAILS.RULE}</Styled.ReportTitleStyled>
            <Styled.TableWrapper>
              <Table
                columns={statusColumns}
                data={hook.hookData}
                setOpenModal={hook.hookSetStatusModalOpen}
                setModalInfo={hook.hookStatusModalSetInfo as any}
                isLoading={hook.hookLoading}
                sortColumn={hook.hookSortColumn}
                onSort={hook.hookHandleSort}
                noTableOverflow
              />
            </Styled.TableWrapper>
            <Pagination
              total={hook.hookTotalPages}
              currentPage={hook.hookCurrentPage}
              onPageChange={hook.hookSetCurrentPageHandler}
            />
          </>
        )}

      </Styled.ReportWrapper>
      <Modal isOpen={hook.hookStatusModalOpen}>
        <FeedbackModal
          hook={hook.hookStatusModalInfo}
        />
      </Modal>
      <Modal isOpen={hook.hookEligibilityModalOpen}>
        <EligibilityFeedbackModal
          hook={hook.hookEligibilityModalInfo}
        />
      </Modal>
    </AppWrapper>
  );
};

export default FintelCheckRuleDetails;
