/* eslint-disable no-param-reassign */
import React, { useEffect, useState } from 'react';
import { useLazyQuery, useMutation } from '@apollo/client';
import { useLocation, useNavigate } from 'react-router-dom';
import { useDebounce } from '../../../../../utils/useDebounce';
import { useValidation } from '../../../../../utils/validation';
import { useCommissionStructure } from './useCommissionStructure';
import { BONUS_CALCULATION_TYPES, COMMISSION_DEFAULT_CUSTOM, REV_SHARE } from '../utils';
import {
  COMMISSION_TYPES, ERROR_TYPES, paginator, REGEX_VALIDATORS, TOAST_ERR_MESSAGES_NO_PAGE, toUTCHours,
} from '../../../../../utils';
import { useToast, useUserInfo } from '../../../../../hooks';
import { CREATE_COMMISSION } from '../graphql/mutations';
import { GET_PRODUCTS, CHECK_COMMISSION_CONFLICTS, LIST_PUBLISHERS_AND_GROUPS } from '../graphql/queries';
import { CommissionTarget, publisherOrGroup } from '../types';
import { CommissionStructureDefaultStructure, CREATION_ERROR_MESSAGE } from '../enums';
import { Permission } from '../../../../../entities';

export const useNewCommission = (permissionsCodeList: string[] = []) => {
  // ===== Common States =====
  const user = useUserInfo();
  const navigate = useNavigate();
  const { hookShowToast } = useToast();

  const [startDate, setStartDate] = useState<Date>();
  const minStartDate = new Date();
  const [maxStartDate, setMaxStartDate] = useState<Date>();
  const [endDate, setEndDate] = useState<Date>();
  const [minEndDate, setMinEndDate] = useState<Date>(new Date());
  const [startCalendarOpen, setStartCalendarOpen] = useState(false);
  const [endCalendarOpen, setEndCalendarOpen] = useState(false);
  const [product, setProduct] = useState<SelectOption>({ label: 'All Products', value: '' });
  const [productOptions, setProductOptions] = useState<SelectOption[]>([]);
  const [description, setDescription] = useState('');
  const [getProducts, { loading: getProductsLoading }] = useLazyQuery(GET_PRODUCTS);

  const location = useLocation();
  const commissionType: string = location.state.commissionType.value === 'RevShare' ? REV_SHARE : location.state.commissionType.value;
  const commissionSubType = location.state.commissionSubType.value;

  const vali = useValidation();
  const [newCommissionErrors, setNewCommissionErrors] = useState<{ [key: string]: string }>({});
  const [secondRender, setSecondRender] = useState(false);
  const [hasConflicts, setHasConflicts] = useState(false);
  const [conflictingCommissions, setConflictingCommissions] = useState<any[]>([]);
  const [hasEndlessCommissions, setHasEndlessCommissions] = useState(false);

  const [createCommission, { loading: createCommissionLoading }] = useMutation(CREATE_COMMISSION);
  const [checkCommissionConflicts, { loading: checkCommissionConflictsLoading }] = useLazyQuery(CHECK_COMMISSION_CONFLICTS);

  // ===== Comm. Structure States =====
  const {
    hookAddItem,
    hookAfterLevels,
    hookCommissionAmount,
    hookCommissionBase,
    hookCommissionStructureErrors,
    hookFlatValue,
    hookMinAmount,
    hookRadioSelected,
    hookRemoveItem,
    hookSetAfterLevels,
    hookSetCommissionAmount,
    hookSetCommissionBase,
    hookSetFlatValue,
    hookSetLeftInput,
    hookSetMinAmount,
    hookSetRadioSelected,
    hookSetRightInput,
    hookSetTrailingPeriod,
    hookTierList,
    hookTrailingPeriod,
    hookValidateCommissionStructure,
    hookValidateSingleTierList,
    hookValidateStaticFields,
    hookValidateTierList,
  } = useCommissionStructure();

  // ===== Pubs & Groups States =====
  const [search, setSearch] = useState('');
  const debouncedSearch = useDebounce(search, 800);
  const [sortColumn, setSortColumn] = useState<TableSortColumn>({ column: 'id', direction: 'asc' });

  const [tableData, setTableData] = useState<any[]>([]);
  const [publishersAndGroups, setPublishersAndGroups] = useState<any[]>([]);
  const [publishersAndGroupsTemp, setPublishersAndGroupsTemp] = useState<any[]>([]);
  const [publisherGroupsSelected, setPublisherGroupsSelected] = useState(false);
  const [publishersInGroups, setPublishersInGroups] = useState<{ [key: string]: string[] }>({});

  const recordsPerPage = 6;
  const [currentPage, setCurrentPage] = useState(1);
  const [totalPages, setTotalPages] = useState(0);

  const [getPublishersAndGroups, { loading: publishersAndGroupsLoading }] = useLazyQuery(LIST_PUBLISHERS_AND_GROUPS);

  const setStartDateHandler = (value: Date) => {
    setStartDate(value);
  };

  const setEndDateHandler = (value: Date) => {
    setEndDate(value);
  };

  const onApplyStartCalendarHandler = (start: Date) => {
    setStartDate(start);
    setStartCalendarOpen(false);
    setMinEndDate(start);
  };

  const onCancelStartCalendarHandler = () => {
    setStartCalendarOpen(false);
  };

  const onOpenStartCalendarHandler = () => {
    setStartCalendarOpen(true);
  };

  const onApplyEndCalendarHandler = (end: Date) => {
    setEndDate(end);
    setEndCalendarOpen(false);
    setMaxStartDate(end);
  };

  const onCancelEndCalendarHandler = () => {
    setEndCalendarOpen(false);
    setEndDate(undefined);
  };

  const onOpenEndCalendarHandler = () => {
    setEndCalendarOpen(true);
  };

  const setProductHandler = (value: SelectOption) => {
    setProduct(value);
  };

  const clearDateHandler = () => {
    setStartDate(undefined);
    setEndDate(undefined);
  };

  const setDescriptionHandler = (e: React.ChangeEvent<HTMLTextAreaElement>) => {
    setDescription(e.target.value);
  };

  const setSearchHandler = (e: React.ChangeEvent<HTMLInputElement>) => {
    setSearch(e.target.value);
  };

  // ===== Hanlders for Table =====

  const handleGetData = async () => {
    const promiseArray: any[] = [getProducts({ variables: { programId: user.hookWhoAmI.programId }, fetchPolicy: 'no-cache' })];
    if (commissionSubType === COMMISSION_DEFAULT_CUSTOM.CUSTOM) promiseArray.push(getPublishersAndGroups({ variables: { input: { programId: user.hookWhoAmI.programId } }, fetchPolicy: 'no-cache' }));
    const [getProductsRes, getPubsAndGroupsRes] = await Promise.all(promiseArray);

    if (getProductsRes?.data?.products?.products) {
      const productList: SelectOption[] = [{ label: 'All Products', value: '' }];
      getProductsRes.data.products.products.forEach((item: any) => {
        productList.push({
          label: item.name,
          value: item.id,
        });
      });
      setProductOptions(productList);
    }

    if (getPubsAndGroupsRes?.data?.listPublishersAndGroups?.pubsAndGroups) {
      const pubsInGroups: { [key: string]: string[] } = {};
      const formattedList = getPubsAndGroupsRes.data.listPublishersAndGroups.pubsAndGroups.map(
        (pubOrGroup: publisherOrGroup) => {
          if (pubOrGroup.type === 'Publisher Group') pubsInGroups[pubOrGroup.id] = pubOrGroup.publisherIds || [];
          return {
            ...pubOrGroup,
            acceptedDate: pubOrGroup.acceptedDate ? pubOrGroup.acceptedDate : 'N/A',
            checked: false,
            disabled: false,
          };
        },
      );
      setPublishersInGroups(pubsInGroups);
      setPublishersAndGroups(formattedList);
      setTableData(paginator(formattedList, recordsPerPage, 1));
      setTotalPages(Math.ceil(formattedList.length / recordsPerPage));
      setCurrentPage(1);
    }
  };

  const handleSearch = () => {
    const pubsAndGroupsToSet = (debouncedSearch === '')
      ? publishersAndGroups
      : [...publishersAndGroups].filter((item: publisherOrGroup) => item.name.toLowerCase().includes(debouncedSearch.toLowerCase()) || item.id.toString().includes(debouncedSearch.toLowerCase()));
    setPublishersAndGroupsTemp(pubsAndGroupsToSet);
    setTableData(paginator(pubsAndGroupsToSet, recordsPerPage, 1));
    setTotalPages(Math.ceil(pubsAndGroupsToSet.length / recordsPerPage));
    setCurrentPage(1);
  };

  const handleSort = (dataField: string, direction: any) => {
    const nextDirection: number = direction === 'asc' ? -1 : 1;
    const compare = (a: any, b: any) => {
      let compareCondition = false;
      switch (dataField) {
        case 'id': {
          compareCondition = Number(a.id) >= Number(b.id);
          break;
        }
        case 'name': {
          compareCondition = a.type === b.type ? a.name >= b.name : a.type > b.type;
          break;
        }
        default: {
          compareCondition = a[dataField] === b[dataField] ? Number(a.id) >= Number(b.id) : a[dataField] > b[dataField];
          break;
        }
      }
      return compareCondition ? nextDirection : nextDirection * -1;
    };
    const copyArray = debouncedSearch ? [...publishersAndGroupsTemp] : [...publishersAndGroups];
    const sortedArray = copyArray.sort((a, b) => compare(a, b));
    setPublishersAndGroupsTemp(sortedArray);
    setTableData(paginator(sortedArray, recordsPerPage, 1));
    setSortColumn({ column: dataField, direction: sortColumn.direction === 'desc' ? 'asc' : 'desc' });
    setCurrentPage(1);
  };

  const handlePageChange = (page: number) => {
    const pageData = paginator(debouncedSearch ? publishersAndGroupsTemp : publishersAndGroups, recordsPerPage, page);
    setTableData(pageData);
    setCurrentPage(page);
  };

  const handleRowsDisableCheck = (pubsAndGroups: any[], pubOrGroupId: string, type: string, rowChecked: boolean) => {
    if (type === 'Publisher Group') {
      // find every publisher in the group & any other group that has any of the publishers of this publisher group and set them as disabled
      publishersInGroups[pubOrGroupId].forEach((publisherId) => {
        const index = pubsAndGroups.findIndex((item) => item.id === publisherId);
        if (index >= 0) pubsAndGroups[index] = { ...pubsAndGroups[index], disabled: rowChecked };
        Object.entries(publishersInGroups).forEach((pair) => {
          // pair structure is [groupId, [publisherIds]]
          if (pair[1].includes(publisherId) && pair[0] !== pubOrGroupId) {
            const index2 = pubsAndGroups.findIndex((item) => item.id === pair[0]);
            if (index2 >= 0) pubsAndGroups[index2] = { ...pubsAndGroups[index2], disabled: rowChecked };
          }
        });
      });
    }
    if (type === 'Publisher') {
      // find every group the publisher is in and set them as disabled
      Object.entries(publishersInGroups).forEach((pair) => {
        if (pair[1].includes(pubOrGroupId)) {
          const index = pubsAndGroups.findIndex((item) => item.id === pair[0]);
          if (index >= 0) pubsAndGroups[index] = { ...pubsAndGroups[index], disabled: rowChecked };
        }
      });
    }
    return pubsAndGroups;
  };

  const handleRowClick = (row: publisherOrGroup) => {
    const publisherGroupsArrCopy = [...publishersAndGroups];
    const index1 = publisherGroupsArrCopy.findIndex((entry) => entry.id === row.id);
    publisherGroupsArrCopy[index1].checked = !publisherGroupsArrCopy[index1].checked;
    const publisherGroupsArrCopyWithDisables = handleRowsDisableCheck(publisherGroupsArrCopy, row.id, row.type, publisherGroupsArrCopy[index1].checked);
    setPublishersAndGroups(publisherGroupsArrCopyWithDisables);

    const tableDataCopy = [...tableData];
    const index = tableDataCopy.findIndex((entry) => entry.id === row.id);
    tableDataCopy[index].checked = !tableDataCopy[index].checked;
    const tableDataCopyWithDisables = handleRowsDisableCheck(tableDataCopy, row.id, row.type, tableDataCopy[index].checked);
    setTableData(tableDataCopyWithDisables);
  };

  // ===== Validations =====

  const globalValues: { [key: string]: string } = {
    startDate: startDate?.toString() || '',
    endDate: endDate?.toString() || '',
  };

  const globalFields = {
    startDate: ERROR_TYPES.SELECTION_REQUIRED,
    endDate: ERROR_TYPES.END_DATE,
  };

  const handleValidation = () => vali.validateAll(globalValues, globalFields, setNewCommissionErrors, secondRender);

  const [afterLevelsError, setAfterLevelsError] = useState('');

  const handleValidateAfterLevels = () => {
    if (commissionType === COMMISSION_TYPES.REVSHARE) {
      if (!REGEX_VALIDATORS.PERCENTAGE.REGEX.test(hookAfterLevels)) {
        setAfterLevelsError(REGEX_VALIDATORS.PERCENTAGE.MESSAGE);
        return false;
      }
      setAfterLevelsError('');
      return true;
    }
    return true;
  };

  const setAfterLevelsHandler = (e: React.ChangeEvent<HTMLInputElement>) => {
    hookSetAfterLevels(e);
    setAfterLevelsError('');
  };

  const handleSubmit = async () => {
    setSecondRender(true);

    // validations
    // -> if subtype === 'Default' then check if there are any conflicting commissions
    // -> if subtype === 'Custom' then check at least one publisher or group is selected
    let checkedPublisherArray: string[] = [];
    if (commissionSubType === COMMISSION_DEFAULT_CUSTOM.CUSTOM) {
      checkedPublisherArray = publishersAndGroups.filter((group: publisherOrGroup) => group.checked === true).map((group: publisherOrGroup) => group.id);
      setPublisherGroupsSelected(checkedPublisherArray.length > 0);
    }

    const commissionStructureCheck = hookValidateCommissionStructure();
    const valiGlobal = handleValidation();
    const afterLevels = hookRadioSelected === 1 ? handleValidateAfterLevels() : true;
    const globalCheck = valiGlobal && afterLevels;

    if (!commissionStructureCheck || !globalCheck || (commissionSubType === COMMISSION_DEFAULT_CUSTOM.CUSTOM && checkedPublisherArray.length === 0)) return;

    if (commissionSubType === COMMISSION_DEFAULT_CUSTOM.DEFAULT) {
      const { data } = await checkCommissionConflicts({
        variables: {
          input: {
            merchantId: user.hookWhoAmI.companyId?.toString(),
            commissionType,
            startDate: startDate ? toUTCHours(startDate, 'beginning') : null,
            endDate: endDate ? toUTCHours(endDate, 'end') : null,
            productId: product.value,
          },
        },
        fetchPolicy: 'no-cache',
      });
      if (data?.checkCommissionConflicts?.count > 0) {
        setHasConflicts(true);
        setHasEndlessCommissions(data.checkCommissionConflicts.commissions.some((item: any) => !item.endDate));
        setConflictingCommissions(data.checkCommissionConflicts.commissions);
        return;
      }
    }

    // formatting commission structures
    const commissionStructure = structuredClone(CommissionStructureDefaultStructure);
    switch (commissionType) {
      case COMMISSION_TYPES.CPA:
        // old APP behaviour for CPA: since commissionBase is not required, if it is not selected -> save as 'SalesNumber' instead
        // on the manage commissions page it will show 'Any Transactions' under the transaction column
        if (hookRadioSelected <= 0) {
          commissionStructure.cpaFlat = {
            commissionBase: hookCommissionBase?.value || 'SalesNumber',
            commissionFee: hookCommissionAmount,
            minimumSaleAmount: hookMinAmount,
          };
        } else {
          commissionStructure.cpaTiered = {
            commissionBase: hookCommissionBase?.value || 'SalesNumber',
            cutoffAmount: hookAfterLevels,
            minimumSaleAmount: hookMinAmount,
            tiers: hookTierList.map((item) => ({ upTo: item.leftInput, commissionAmount: item.rightInput })),
          };
        }
        break;
      case COMMISSION_TYPES.REFERRAL: {
        const levels: string[] = [];
        hookTierList.forEach((item) => {
          levels.push(item.leftInput);
          levels.push(item.rightInput);
        });
        commissionStructure.referral.levels = levels;
        break;
      }
      case COMMISSION_TYPES.REVSHARE:
        if (hookRadioSelected <= 0) {
          commissionStructure.revShareFlat = {
            commissionFee: hookFlatValue,
            commissionBase: hookCommissionBase?.value,
          };
        } else {
          commissionStructure.revShareTiered = {
            cutoffAmount: hookAfterLevels,
            commissionBase: hookCommissionBase?.value,
            tiers: hookTierList.map((item) => ({ upTo: item.leftInput, commissionAmount: item.rightInput })),
          };
        }
        break;
      case COMMISSION_TYPES.BONUS:
        if (hookRadioSelected <= 0) {
          commissionStructure.bonusFlat = {
            commissionBase: hookCommissionBase?.value,
            commissionFee: hookFlatValue,
            minimumSaleAmount: hookMinAmount,
          };
        } else {
          commissionStructure.bonusTiered = {
            commissionBase: hookCommissionBase?.value,
            calculationType: BONUS_CALCULATION_TYPES[hookRadioSelected],
            period: hookRadioSelected <= 2 ? '' : hookTrailingPeriod.value,
            tiers: hookTierList.map((item) => ({ upTo: item.leftInput, commissionAmount: item.rightInput })),
            cutoffAmount: hookAfterLevels,
          };
        }
        break;
      default:
        break;
    }

    // formatting commission targets
    const selectedPublishers: any[] = [];
    const selectedPublisherGroups: any[] = [];
    publishersAndGroups.map((item) => {
      if (!item.checked) return;
      if (item.type === 'Publisher') selectedPublishers.push({ publisherId: item.id, startDate, endDate });
      if (item.type === 'Publisher Group') selectedPublisherGroups.push({ publisherGroupId: item.id, startDate, endDate });
    });
    const commissionTarget: CommissionTarget = {
      productIds: product.value !== '' ? [product.value] : [null],
      publisherTargets: selectedPublishers,
      publisherGroupTargets: selectedPublisherGroups,
    };

    const formattedInput = {
      merchantId: user.hookWhoAmI.companyId?.toString(),
      description,
      commissionType,
      startDate: startDate ? toUTCHours(startDate, 'beginning') : null,
      endDate: endDate ? toUTCHours(endDate, 'end') : null,
      commissionStructure,
      commissionTarget,
    };

    try {
      const { data } = await createCommission({
        variables: {
          input: formattedInput,
        },
      });
      if (data) { navigate(-1); }
    } catch (error: any) {
      hookShowToast(TOAST_ERR_MESSAGES_NO_PAGE(CREATION_ERROR_MESSAGE));
    }
  };

  const onCloseHandler = () => {
    setHasConflicts(false);
  };

  useEffect(() => {
    handleSearch();
  }, [debouncedSearch]);

  useEffect(() => {
    if (secondRender) {
      handleValidation();
      hookValidateCommissionStructure();
    }
  }, [secondRender, startDate, endDate, hookCommissionBase]);

  useEffect(() => {
    setAfterLevelsError('');
  }, [hookRadioSelected]);

  useEffect(() => {
    handleGetData();
  }, []);

  return {
    hookLoading: createCommissionLoading || checkCommissionConflictsLoading,

    hookCommissionType: commissionType,
    hookCommissionSubType: commissionSubType,

    hookPublisherGroupsSelected: publisherGroupsSelected,

    hookStartDate: startDate,
    hookMinStartDate: minStartDate,
    hookMaxStartDate: maxStartDate,
    hookSetStartDate: setStartDateHandler,

    hookEndDate: endDate,
    hookMinEndDate: minEndDate,
    hookSetEndDate: setEndDateHandler,

    hookStartCalendarOpen: startCalendarOpen,
    hookOnOpenStartCalendar: onOpenStartCalendarHandler,
    hookEndCalendarOpen: endCalendarOpen,
    hookOnOpenEndCalendar: onOpenEndCalendarHandler,

    hookOnApplyEndCalendar: onApplyEndCalendarHandler,
    hookOnApplyStartCalendar: onApplyStartCalendarHandler,
    hookOnCancelEndCalendar: onCancelEndCalendarHandler,
    hookOnCancelStartCalendar: onCancelStartCalendarHandler,

    hookProduct: product,
    hookSetProduct: setProductHandler,
    hookProductOptions: productOptions,
    hookProductsLoading: getProductsLoading,

    hookSecondRender: secondRender,
    hookHasConflicts: hasConflicts,
    hookConflictingCommissions: conflictingCommissions,
    hookHasEndlessCommissions: hasEndlessCommissions,

    hookOnClose: onCloseHandler,

    hookClearDate: clearDateHandler,

    hookDescription: description,
    hookSetDescription: setDescriptionHandler,

    hookRadioSelected,
    hookSetRadioSelected,
    hookCommissionBase,
    hookSetCommissionBase,
    hookMinAmount,
    hookSetMinAmount,
    hookFlatValue,
    hookSetFlatValue,
    hookCommissionAmount,
    hookSetCommissionAmount,
    hookTrailingPeriod,
    hookSetTrailingPeriod,
    hookTierList,
    hookAddItem,
    hookRemoveItem,
    hookSetLeftInput,
    hookSetRightInput,
    hookAfterLevels,
    hookSetAfterLevels: setAfterLevelsHandler,
    hookValidateSingleTierList,
    hookValidateTierList,
    hookCommissionStructureErrors,
    hookValidateStaticFields,
    hookNewCommissionErrors: newCommissionErrors,

    hookAfterLevelsError: afterLevelsError,

    hookSearch: search,
    hookSetSearch: setSearchHandler,

    hookTableData: tableData,
    hookPublisherGroups: publishersAndGroups,

    hookOnPageChange: handlePageChange,
    hookCurrentPage: currentPage,
    hookTotalPages: totalPages,

    hookHandleSort: handleSort,
    hookSortColumn: sortColumn,

    hookSubmit: handleSubmit,
    hookOnRowClick: handleRowClick,

    hookTableLoading: publishersAndGroupsLoading,

    hookIsReadOnlyList: Permission.readOnlyPermissionsList(permissionsCodeList),
  };
};
