import { PerformanceGroupBy } from './types';

export const GROUP_BY_OPTIONS: PerformanceGroupBy[] = [
  {
    label: 'Merchant',
    value: 'MERCHANT',
  },
  {
    label: 'Publisher (ID)',
    value: 'PUBLISHER',
  },
  {
    label: 'Publisher (ID, Name, URL)',
    value: 'PUBLISHER_NAME',
  },
  {
    label: 'Date',
    value: 'DATE',
  },
  {
    label: 'Month',
    value: 'MONTH',
  },
  {
    label: 'Ad (ID)',
    value: 'AD_ID',
  },
  {
    label: 'Tracking Profile (ID)',
    value: 'TRACKING_PROFILE',
  },
  {
    label: 'Product',
    value: 'PRODUCT',
  },
  {
    label: 'Product Category',
    value: 'PRODUCT_CATEGORY',
  },
  {
    label: 'Ad Campaign',
    value: 'AD_CAMPAIGN',
  },
];

export const PERFORMANCE_REPORT_CUSTOMIZE_COLUMNS = [
  {
    label: 'Tracking',
    columns: [
      { label: 'Impressions', value: 'impressions' },
      { label: 'Unique Impressions', value: 'uniqueImpressions' },
      { label: 'Clicks', value: 'clicks' },
      { label: 'Unique Clicks', value: 'uniqueClicks' },
      { label: 'CTR', value: 'ctr' },
      { label: 'Conversions %', value: 'conversions' },
      { label: 'EPC', value: 'epc' },
    ],
  },
  {
    label: 'Transaction Counts',
    columns: [
      { label: 'New Pending Transactions', value: 'newPendingTransactions' },
      { label: 'Declined Transactions', value: 'declinedTransactions' },
      { label: 'Soft Declined Transactions', value: 'softDeclinedTransactions' },
      { label: 'Verify Transactions', value: 'verifiedTransactions' },
      { label: 'Old Pending Transactions', value: 'oldPendingTransactions' },
      { label: 'Old Approved Transactions', value: 'oldApprovedTransactions' },
      { label: 'Approved Transactions', value: 'approvedTransactions' },
      { label: 'Total Transactions', value: 'totalTransactions' },
      { label: 'Approval Rate', value: 'approvalRate' },
    ],
  },
  {
    label: 'Transaction Sales',
    columns: [
      { label: 'Pending Gross Value', value: 'pendingGrossValue' },
      { label: 'Total Gross Value', value: 'totalGrossValue' },
      { label: 'Total Net Value', value: 'totalNetValue' },
    ],
  },
  {
    label: 'Commission',
    columns: [
      { label: 'CPA Commission', value: 'commissionCpa' },
      { label: 'Commission %', value: 'commission' },
      { label: 'Total Commission', value: 'totalCommission' },
    ],
  },
];

export const defaultMerchantOption = {
  label: 'All Merchants',
  value: '',
};

export const defaultProductCategoryOption = {
  label: 'All Categories',
  value: '',
};

export const defaultPublisherOption = {
  label: 'All Publishers',
  value: '',
};

export const defaultProductOption = {
  label: 'All Products',
  value: '',
};

export const defaultTrackingsOption = {
  label: 'All Tracking Profiles',
  value: '',
};

export const defaultAdCampaignOption = {
  label: 'All Ad Campaigns',
  value: '',
};

export const defaultPublisherGroupOption = {
  label: 'All Publisher Groups',
  value: '',
};

export const defaultAdsOption = {
  label: 'All Ads',
  value: '',
};

export const immutableProductCategories: string[] = [
  'Banking',
  'Business Products',
  'Credit Cards',
  'Credit Reporting',
  'Cryptocurrency',
  'Insurance',
  'Investments',
  'Loans',
  'Mortgage',
  'Real Estate',
  'Tax Services',
  'Wills & Estate Planning',
];

export const immutableGlobalColumns = [
  ...PERFORMANCE_REPORT_CUSTOMIZE_COLUMNS[0].columns,
  ...PERFORMANCE_REPORT_CUSTOMIZE_COLUMNS[1].columns,
  ...PERFORMANCE_REPORT_CUSTOMIZE_COLUMNS[2].columns,
  ...PERFORMANCE_REPORT_CUSTOMIZE_COLUMNS[3].columns,
];
