import React from 'react';

import {
  AppWrapper, PageTitle, Tabs, Button, ErrorBanner,
} from '../../../../components';
import { Details, PublishersGroups } from './Tabs';
import { EDIT_COMMISSIONS } from './enums';
import { useEditCommissions } from './hooks';
import { DefaultPropsType } from '../../../../types';

import * as Styled from './styles';

type EditCommissionsProps = DefaultPropsType;

const EditCommissions = ({ permissionsCodeList = [] }: EditCommissionsProps) => {
  const hook = useEditCommissions(permissionsCodeList);
  const { hookIsReadOnlyList } = hook;
  const [isReadOnly] = hookIsReadOnlyList;

  return (
    <AppWrapper permissionsCodeList={permissionsCodeList}>
      <ErrorBanner
        isOpen={!!hook.hookErrorMessage}
        message={hook.hookErrorMessage}
      />
      <Styled.HeaderStyled>
        <PageTitle
          isLoading={hook.hookPageLoading}
          loadingText={hook.hookLoadingMessage}
        >
          {`${EDIT_COMMISSIONS.PAGE_TITLE} ${hook.hookIsCustom ? 'Custom' : 'Default'} ${hook.hookCommissionType} ${EDIT_COMMISSIONS.COMMISSION}`}
        </PageTitle>
        <Styled.ButtonsWrapper>
          <Button
            theme="secondary"
            onClick={() => hook.hookNavigateBack()}
            width="104px"
            loading={hook.hookSaveCommissionLoading}
          >
            {EDIT_COMMISSIONS.BUTTONS.CANCEL}
          </Button>
          <Button
            onClick={hook.hookSaveCommission}
            width="104px"
            loading={hook.hookPageLoading}
            disabled={isReadOnly}
          >
            {EDIT_COMMISSIONS.BUTTONS.SAVE}
          </Button>
        </Styled.ButtonsWrapper>
      </Styled.HeaderStyled>
      {hook.hookIsCustom ? (
        <Tabs
          tabNames={['Details', 'Publishers/Groups']}
          elements={[
            <Details
              hook={hook}
              isReadOnly={isReadOnly}
            />,
            <PublishersGroups
              hook={hook}
              isReadOnly={isReadOnly}
            />,
          ]}
        />
      ) : (
        <Details
          hook={hook}
          isReadOnly={isReadOnly}
        />
      )}

    </AppWrapper>
  );
};

export default EditCommissions;
