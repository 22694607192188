import styled from 'styled-components';
import { colors, fonts } from '../../styles/theme';
import { toRem } from '../../utils';

type WrapperProps = {
  readonly theme: Theme;
  readonly width?: string;
};

export const WrapperStyled = styled.button<WrapperProps>`
  ${({ theme }) => (theme === 'quinary' ? fonts.lato.H16 : fonts.lato.H14)};
  display: flex;
  align-items: center;
  justify-content: center;
  width: ${({ width }) => width};
  padding: ${({ theme }) => {
    switch (theme) {
      case 'quinary':
        return `0 ${toRem(4)} 0 0`;
      case 'quaternary':
        return 0;
      case 'senary':
        return 0;
      default:
        return `${toRem(10)} ${toRem(16)}`;
    }
  }};
  border-radius: ${({ theme }) => (theme === 'quinary' ? 0 : toRem(2))};
  cursor: pointer;
  transition: all 0.2s ease-in-out;
  ${({ theme }) => theme === 'text-only' && 'text-decoration: underline;'}

  border: 1px solid ${({ theme }) => {
    switch (theme) {
      case 'secondary':
        return colors.color1;
      case 'tertiary':
        return colors.color9;
      case 'quaternary':
        return colors.transparent;
      case 'quinary':
        return colors.transparent;
      case 'senary':
        return colors.color4;
      case 'septenary':
        return colors.color19;
      case 'octonary':
        return colors.transparent;
      case 'nonary':
        return colors.color34;
      case 'denary':
        return colors.color1;
      case 'text-only':
        return colors.transparent;
      case 'calendar':
        return colors.color15;
      case 'placeholder':
        return colors.color19;
      default:
        return colors.color2;
    }
  }};

  background-color: ${({ theme }) => {
    switch (theme) {
      case 'secondary':
        return colors.transparent;
      case 'tertiary':
        return colors.color9;
      case 'quaternary':
        return colors.transparent;
      case 'quinary':
        return colors.transparent;
      case 'senary':
        return colors.color4;
      case 'septenary':
        return colors.transparent;
      case 'text-only':
        return colors.transparent;
      case 'octonary':
        return colors.transparent;
      case 'nonary':
        return colors.color34;
      case 'denary':
        return colors.color4;
      case 'calendar':
        return colors.color15;
      case 'placeholder':
        return colors.color19;
      default:
        return colors.color2;
    }
  }};

  color: ${({ theme }) => {
    switch (theme) {
      case 'secondary':
        return colors.color1;
      case 'quaternary':
        return colors.color10;
      case 'quinary':
        return colors.color2;
      case 'senary':
        return colors.color10;
      case 'septenary':
        return colors.color17;
      case 'octonary':
        return colors.color1;
      case 'nonary':
        return colors.color1;
      case 'denary':
        return colors.color1;
      case 'calendar':
        return colors.color1;
      case 'text-only':
        return colors.color12;
      default:
        return colors.color4;
    }
  }};

  &:hover {
    background-color: ${({ theme }) => {
    switch (theme) {
      case 'secondary':
        return colors.color1;
      case 'tertiary':
        return colors.color10;
      case 'quaternary':
        return colors.transparent;
      case 'quinary':
        return colors.transparent;
      case 'senary':
        return colors.color27;
      case 'septenary':
        return colors.color19;
      case 'octonary':
        return colors.transparent;
      case 'nonary':
        return colors.color19;
      case 'denary':
        return colors.color1;
      case 'text-only':
        return colors.transparent;
      case 'calendar':
        return colors.color13;
      case 'placeholder':
        return colors.color19;
      default:
        return colors.color8;
    }
  }};
    
    border: 1px solid ${({ theme }) => {
    switch (theme) {
      case 'secondary':
        return colors.color1;
      case 'tertiary':
        return colors.color10;
      case 'quaternary':
        return colors.transparent;
      case 'quinary':
        return colors.transparent;
      case 'senary':
        return colors.color27;
      case 'septenary':
        return colors.color19;
      case 'octonary':
        return colors.transparent;
      case 'nonary':
        return colors.color19;
      case 'denary':
        return colors.color1;
      case 'text-only':
        return colors.transparent;
      case 'calendar':
        return colors.color13;
      case 'placeholder':
        return colors.color19;
      default:
        return colors.color8;
    }
  }};

    color: ${({ theme }) => {
    switch (theme) {
      case 'secondary':
        return colors.color4;
      case 'quaternary':
        return colors.color10;
      case 'quinary':
        return colors.color2;
      case 'senary':
        return colors.color10;
      case 'septenary':
        return colors.color17;
      case 'octonary':
        return colors.color1;
      case 'nonary':
        return colors.color1;
      case 'denary':
        return colors.color4;
      case 'text-only':
        return colors.color2;
      default:
        return colors.color4;
    }
  }};

    ${({ theme }) => theme === 'text-only' && 'text-decoration: underline;'}
  }

  &:disabled {
    cursor: not-allowed;
    opacity: 0.5;
  }
`;
