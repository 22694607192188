import { useEffect, useReducer, useState } from 'react';
import { faChevronDown } from '@fortawesome/free-solid-svg-icons';
import { useMutation, useQuery } from '@apollo/client';
import { useModal, useUserInfo } from '../../../../../hooks';
import { ModalInfoType, fintelCheckReducer, modalReducer } from '../Reducers';
import { REPORT } from '../enums';
import { useFintelCheckContext } from '../../hooks';
import { UPDATE_FINTEL_CHECK_VISIBILITY, UpdateFintelCheckVisibilityInput, UpdateFintelCheckVisibilityOutput } from '../Components/Graphs/graphql/mutations';
import {
  GET_FINTEL_CHECK_RULES, GET_FINTEL_CHECK_VISIBILITY, GetFintelCheckVisibilityInput, GetFintelCheckVisibilityOutput,
} from '../Components/Graphs/graphql/queries';

export const useFintelCheckReport = () => {
  const { hookWhoAmI } = useUserInfo();
  const [isOpen, setIsOpen] = useModal(false);
  const [modalState, modalDispatch] = useReducer(modalReducer, {} as ModalInfoType);
  const [fintelCheckState, fintelCheckDispatch] = useReducer(fintelCheckReducer, { show: true, icon: faChevronDown });
  const { contextState } = useFintelCheckContext();
  const [errorMessage, setErrorMessage] = useState<string>('');
  const setInvisibleData = {
    title: REPORT.INVISIBLE_DATA.TITLE,
    visibilityName: REPORT.INVISIBLE_DATA.VISIBILITY_NAME,
    list: REPORT.INVISIBLE_DATA.LIST,
    buttonName: REPORT.INVISIBLE_DATA.BUTTON_NAME,
  };
  const setVisibleData = {
    title: REPORT.VISIBLE_DATA.TITLE,
    visibilityName: REPORT.VISIBLE_DATA.VISIBILITY_NAME,
    list: REPORT.VISIBLE_DATA.LIST,
    buttonName: REPORT.VISIBLE_DATA.BUTTON_NAME,
  };

  // Mutations && Queries
  const { data: visibilityState, loading: visibilityStateLoading, error: visibilityStateError } = useQuery<GetFintelCheckVisibilityOutput, GetFintelCheckVisibilityInput>(GET_FINTEL_CHECK_VISIBILITY, {
    variables: {
      companyId: hookWhoAmI.companyId?.toString() || '',
    },
    fetchPolicy: 'no-cache',
    onError(error) {
      setErrorMessage(error.message);
    },
  });
  const { data: fintelCheckRules, loading: getFintelCheckRulesLoading } = useQuery(GET_FINTEL_CHECK_RULES, {
    variables: {
      input: {
        merchantId: hookWhoAmI.companyId?.toString() || '',
      },
    },
    fetchPolicy: 'no-cache',
    onError(error) {
      setErrorMessage(error.message);
    },
  });

  const [UpdateFintelCheckVisibility, { loading: updateFintelCheckVisibilityLoading }] = useMutation<UpdateFintelCheckVisibilityOutput, UpdateFintelCheckVisibilityInput>(UPDATE_FINTEL_CHECK_VISIBILITY);

  const UpdateFintelCheckVisibilityHandler = async (newVisibility: boolean) => {
    setErrorMessage('');
    await UpdateFintelCheckVisibility({
      variables: {
        input: {
          id: hookWhoAmI.companyId?.toString() || '',
          visibleToFintelCheck: newVisibility,
        },
      },
      fetchPolicy: 'no-cache',
      onError(err) {
        setErrorMessage(err.message);
      },
    });
  };

  useEffect(() => {
    if (!visibilityStateLoading && !visibilityStateError) {
      if (visibilityState?.company.visibleToFintelCheck) {
        modalDispatch({
          data: {
            ...setVisibleData,
            visibility: true,
          },
        });
      } else {
        modalDispatch({
          data: {
            ...setInvisibleData,
            visibility: false,
          },
        });
      }
    }
  }, [visibilityState]);

  return {
    hookWhoAmI,
    hookModalIsOpen: isOpen,
    hookModalSetIsOpen: setIsOpen,
    hookModalInfo: modalState,
    hookChangeModalInfo: modalDispatch,
    hookFintelCheckState: fintelCheckState,
    hookFintelCheckReducer: fintelCheckDispatch,
    hookInvisibleData: setInvisibleData,
    hookVisibleData: setVisibleData,
    hookHasCheckRules: fintelCheckRules?.checkRules?.count > 0 || false,
    hookContextState: contextState,

    hookLoading: updateFintelCheckVisibilityLoading || visibilityStateLoading,
    hookCheckRulesLoading: getFintelCheckRulesLoading,
    hookUpdateFintelCheckVisibility: UpdateFintelCheckVisibilityHandler,
    hookErrorMessage: errorMessage,
  };
};
