import React from 'react';
import { faClose } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Checkbox, PageTitle, VisibilityHidden } from '../../../../../components';
import * as Styled from './styles';
import { TRANSACTION_MASTER_REPORT_CUSTOMIZE_COLUMNS } from '../../enums';

type CustomizeColumnsProps = {
  closeFn: () => void;
  checkFn: (column: SelectOption, checked: boolean) => void;
  columns: SelectOption[];
};

const CustomizeColumnsModal = ({ closeFn, checkFn, columns }: CustomizeColumnsProps) => (
  <Styled.WrapperStyled>
    <Styled.HeaderStyled>
      <PageTitle>Customize Columns</PageTitle>

      <Styled.ButtonStyled onClick={closeFn}>
        <FontAwesomeIcon icon={faClose} />
        <VisibilityHidden>Close</VisibilityHidden>
      </Styled.ButtonStyled>
    </Styled.HeaderStyled>

    <Styled.ContentStyled>
      {TRANSACTION_MASTER_REPORT_CUSTOMIZE_COLUMNS.map((column) => (
        <Styled.ColumnStyled key={column.label}>
          <Checkbox
            checked={columns.some((checkColumn) => column.value === checkColumn.value)}
            onChange={() => checkFn(column, !columns.some((checkColumn) => column.value === checkColumn.value))}
            label={column.label}
          />
        </Styled.ColumnStyled>
      ))}
    </Styled.ContentStyled>
  </Styled.WrapperStyled>
);

export default CustomizeColumnsModal;
