import React from 'react';
import * as Styled from '../../styles';
import {
  InputTextarea, InputText,
} from '../../../../../../../../../components';
import { PRODUCT_FEED } from '../../../../enums';

type InsuranceProps = {
  hook: any,
  isReadOnly: boolean
}

export const Insurance = ({ hook, isReadOnly }: InsuranceProps) => (
  <Styled.WrapperStyled>
    <Styled.HalfWrapper>
      <Styled.DoubleWrapper>
        <InputText
          disabled={hook.hookLoading || isReadOnly}
          label={PRODUCT_FEED.TERM.label}
          value={hook.hookTerm}
          onChange={hook.hookChangeTerm}
          type="text"
          error={hook.hookNewProductErrors.term}
          onBlur={hook.hookValidate}
        />
        <InputText
          disabled={hook.hookLoading || isReadOnly}
          label={PRODUCT_FEED.COVERAGE_AMOUNT.label}
          value={hook.hookCoverage}
          onChange={hook.hookChangeCoverage}
          type="text"
          error={hook.hookNewProductErrors.coverage}
          onBlur={hook.hookValidate}
        />
      </Styled.DoubleWrapper>
      <InputText
        disabled={hook.hookLoading || isReadOnly}
        label={PRODUCT_FEED.GUARANTEED.label}
        value={hook.hookGaraunteed}
        placeholder={PRODUCT_FEED.GUARANTEED.placeholder}
        onChange={hook.hookChangeGuaranteed}
        type="text"
        error={hook.hookNewProductErrors.guaranteedPremiums}
        onBlur={hook.hookValidate}
      />
    </Styled.HalfWrapper>
    <Styled.HalfWrapper>
      {hook.hookExclusion.map((item: any, index: number) => (
        <InputTextarea
          disabled={hook.hookLoading || isReadOnly}
          label={`${PRODUCT_FEED.EXCLUSION_ITEM.label} ${index + 1}`}
          value={item}
          onChange={(event: React.ChangeEvent<HTMLTextAreaElement>) => hook.hookChangeExclusion(event, index)}
        />
      ))}
    </Styled.HalfWrapper>
    <Styled.HalfWrapper isEnd>
      {hook.hookMarketingItems.map((item: any, index: number) => (
        <InputTextarea
          disabled={hook.hookLoading || isReadOnly}
          label={`${PRODUCT_FEED.MARKETING_ITEMS.label} ${index + 1}`}
          value={item}
          onChange={(event: React.ChangeEvent<HTMLTextAreaElement>) => hook.hookSetMarketingItems(event, index)}
        />
      ))}
    </Styled.HalfWrapper>
    <InputTextarea
      disabled={hook.hookLoading || isReadOnly}
      label={PRODUCT_FEED.LEGAL_REF.label}
      placeholder={PRODUCT_FEED.LEGAL_REF.placeholder}
      value={hook.hookLegalValue}
      onChange={hook.hookChangeLegalValue}
      tooltip={PRODUCT_FEED.LEGAL_PREFERENCE_TOOL_TIP}
    />
  </Styled.WrapperStyled>
);
