import { useState } from 'react';

export const useConfirmationModal = (confirmCallback?: () => (void | boolean) | Promise<void | boolean>, cancelCallback?: () => (void | boolean) | Promise<void | boolean>) => {
  const [isOpen, setIsOpen] = useState<boolean>(false);

  const onClose = async () => {
    if (cancelCallback) {
      if (await cancelCallback() === false) {
        return;
      }
    }
    setIsOpen(false);
  };

  const onConfirm = async () => {
    if (confirmCallback) {
      if (await confirmCallback() === false) {
        return;
      }
    }
    setIsOpen(false);
  };

  return {
    hookOnClose: onClose,
    hookOnConfirm: onConfirm,
    hookIsOpen: isOpen,
    hookSetIsOpen: setIsOpen,
  };
};
