export const STATUS_OPTIONS = [
  {
    label: 'Active',
    value: 'Active',
  },
  {
    label: 'Inactive',
    value: 'Inactive',
  },
];

export const VISIBILITY_OPTIONS = [
  {
    label: 'Hide',
    value: false,
  },
  {
    label: 'Members Only',
    value: true,
  },
];

export const TARGET_GENDER_OPTIONS = [
  {
    label: 'Any Gender',
    value: 'Any Gender',
  },
  {
    label: 'Mostly Female',
    value: 'Mostly Female',
  },
  {
    label: 'Mostly Male',
    value: 'Mostly Male',
  },
];

export const TARGET_COUNTRY_OPTIONS = [
  {
    label: 'Australia',
    value: 'Australia',
  },
  {
    label: 'Canada',
    value: 'Canada',
  },
  {
    label: 'United States',
    value: 'United States',
  },
];

export const AGE_OPTIONS = ['All ages', '18-24', '35-44', 'Under 18', '25-34', '45+'];

export const ageChecker = (toCheck: string, age: string[]) => AGE_OPTIONS.map((a: string) => ({ label: a, checked: age.includes(a) || a === toCheck }));

export const PRODUCT_INFORMATION = {
  PRODUCT_NAME: {
    LABEL: 'product name',
    PLACEHOLDER: 'First Auto Loan',
  },
  STATUS: 'product status',
  PRODUCT_URL: 'product url',
  ID: 'id',
  PRODUCT_DESC: 'product description',
  LANDING: 'default landing page url',
  THIRD: 'third party tracker url',
  PRODUCT_IMAGE: 'product image',
  IMAGE_LABEL: 'default product image',
  PRODUCT_CATEGORY: 'product category',
  SUB: 'sub category',
  TARGET: 'Target Demographics',
  GENDER_LABEL: 'gender',
  COMMISSIONS: 'commissions visibility',
  AGE_RANGES: 'age range(s)',
  COUNTRY: 'target geographical market country',
  STATE: 'target geographical market state/province',
  INCOME: 'minimum personal income required',
  HOUSHOLD: 'minimum household income required',
  CREDIT: 'minimum credit score required',
  INCOME_PLACEHOLDER: 'Eg. CAD $75,000/yr',
  CREDIT_PLACEHOLDER: 'Eg. 750',
};

export const EDIT_PRODUCT = {
  CANCEL: 'Cancel',
  UPDATE_PRODUCT: 'Update Product',
  NOTIFY_PUBLISHERS: 'Notify Publishers',
  TAB_NAMES: ['Product Information', 'Product Feed', 'Commissions'],
  TAB_NAMES_NO_FEED: ['Product Information', 'Commissions'],
  FEED: ['Investments', 'Mortgage', 'Banking', 'Insurance', 'Credit Cards'],
  URL_ERROR: 'Oops! Something went wrong for update program, Please enter the product URL.',
};

export const USER = 'user';

export const PRODUCT_FEED = {
  USAGE: {
    label: 'usage guidelines',
    placeholder: 'Please provide the instructions on the use of data in the feed',
  },
  VALID_UNTIL: {
    label: 'Offer Valid Until',
    placeholder: 'Select Date',
  },
  MONTHLY_FEES: {
    label: 'monthly fees ($)',
    placeholder: '0.00',
  },
  MONETARY_TOOL_TIP: 'This is a Monetary field - Enter a \nnumber with up to 2 decimal places',
  LEGAL_PREFERENCE_TOOL_TIP: 'Please do not include any special character',
  MONTHLY_FEES_DETAILS: {
    label: 'monthly fees details',
    placeholder: 'Provide more context on the monthly fees',
  },
  CLOSING_BALANCE: {
    label: 'closing balance to waive monthly fee ($)',
    placeholder: '0',
  },
  APY: {
    label: 'apy (%)',
    placeholder: '0.00',
  },
  APY_DETAILS: {
    label: 'apy details',
    placeholder: 'Provide more context on the APY tiers',
  },
  INTEREST_RATE: {
    label: 'interest rate (%)',
    placeholder: '0.00',
  },
  INTEREST_RATE_DETAILS: {
    label: 'interest rate details',
    placeholder: 'Provide more context on the interest rate',
  },
  INTEREST_CALCULATED: {
    label: 'interest calculated',
    placeholder: 'Period',
  },
  INTEREST_CALCULATED_DAYS: {
    label: 'days of calculated period',
    placeholder: '0',
  },
  INTEREST_PAID: {
    label: 'interest paid',
    placeholder: 'Period',
  },
  INTEREST_PAID_DAYS: {
    label: 'days of interest paid',
    placeholder: '0',
  },
  PROMO_INTEREST: {
    label: 'promo interest rate (%)',
    placeholder: '0.00',
  },
  MIN_OPEN_DEPOSIT: {
    label: 'minimum open deposit ($)',
    placeholder: '0',
  },
  TRANS_ATM: {
    label: 'transaction fees - atm ($)',
    placeholder: '0.00',
  },
  TRANS_ETR: {
    label: 'transaction fees - e-transfer ($)',
    placeholder: '0',
  },
  TRANS_INTERNAL: {
    label: 'transaction fees - internal ($)',
    placeholder: '0',
  },
  FREE_VOLUME: {
    label: 'free transaction volume',
    placeholder: '0',
  },
  MINIMUM_BALANCE: {
    label: 'minimum balance ($)',
    placeholder: '0.00',
  },
  NO_MIN_BALANCE: {
    label: 'No minimum balance',
  },
  BANKING_INFO: 'Banking Product Information',
  LEGAL_REF: {
    label: 'legal reference',
    placeholder: 'Provide the description for legal reference',
  },
  WELCOME_OFFERS: {
    label: 'welcome offers',
    placeholder: 'Provide the description for welcome offer',
  },
  INSURANCE: {
    label: 'insurance',
    placeholder: 'Provide the description for insurance',
  },
  GEO_AVAL: {
    label: 'geographical avalibility',
    placeholder: 'Provide the description for geographical avalibility',
  },
  CARD_NETWORK: 'card network',
  USER_TYPES: 'user types',
  ANNUAL_FEES: {
    label: 'Annual Fee',
    placeholder: '0.00',
  },
  CARD_FEE: {
    label: 'supplementary card fee',
    placeholder: '0',
  },
  BALANCE_INTRO: {
    label: 'balance transfer intro rate (%)',
    placeholder: 'This is a Monetary field - Enter a number with up to 2 decimal places',
  },
  SIGN_UP: {
    label: 'sign up bonus',
    placeholder: 'Provide the information for signup bonus',
  },
  BALANCE_REGULAR: {
    label: 'balance transfer regular (%)',
    placeholder: '0.00',
  },
  BALANCE_DETAILS: {
    label: 'balance transfer regular details',
    placeholder: '0',
  },
  REWARDS: {
    label: 'rewards earning rate',
    placeholder: 'Provide the information for rewards earning rate',
  },
  ANNUAL_INTEREST_RATE: {
    label: 'annual interest rate (%)',
    placeholder: '0.00',
  },
  ANNUAL_INTEREST: {
    label: 'annual interest rate details',
    placeholder: '0',
  },
  BALANCE_TRANSFER: {
    label: 'balance transfer regular(%)',
    placeholder: '0',
  },
  CASH_ADVANCE: {
    label: 'cash advance fee (%)',
    placeholder: '0',
  },
  DISHONOURED: {
    label: 'dishonoured payment fee ($)',
    placeholder: '0',
  },
  FCC: {
    label: 'foreign currency conversion (%)',
    placeholder: '0',
  },
  GRACE: {
    label: 'Grace Period',
    placeholder: '0.00',
  },
  TERM: {
    label: 'term',
    placeholder: 'Select Term',
  },
  COVERAGE_AMOUNT: {
    label: 'coverage amount',
    placeholder: '0',
  },
  GUARANTEED: {
    label: 'guaranteed premiums',
    placeholder: '$10,000',
  },
  EXCLUSION_ITEM: {
    label: 'exclusion item',
  },
  MARKETING_ITEMS: {
    label: 'Marketing Item',
  },
  REDEEMABLE: {
    label: 'redeemable',
    placeholder: 'Select Redeemable',
  },
  INSURER: {
    label: 'insurer',
    placeholder: '0',
  },
  MIN_ACCOUNT_BALANCE: {
    label: 'minimum account balance',
    placeholder: '$2,000',
  },
  MAN_FEES: {
    label: 'management fees',
    placeholder: '2.50%',
  },
  TRADE_COSTS: {
    label: 'trade costs',
    placeholder: '$9.99/trade',
  },
  NUMBER_TRADES: {
    label: 'number of free monthly trades',
    placeholder: '10',
  },
  MER: {
    label: 'mer',
    placeholder: '0.50%',
  },
  MORTGAGE_TYPE: {
    label: 'mortgage type',
    placeholder: 'Select a mortgage type',
  },
  OPEN: {
    label: 'open',
    placeholder: 'Select an option',
  },
  YEAR: {
    label: 'TERM (year)',
    placeholder: '0',
  },
  CONVERTIBLE: {
    label: 'convertible',
    placeholder: 'Select an option',
  },
  PRE_APPROVAL: {
    label: 'pre-approval',
    placeholder: 'Select an option',
  },
  RATE_TYPE: {
    label: 'rate type',
    placeholder: 'Select an option',
  },
  APR: {
    label: 'APR (%)',
    placeholder: '0.00',
  },
  NOT_FOUND: 'Page not found',
};

export const MARKETING_ITEMS = {
  TITLE: 'Marketing Items',
  ADD_LABEL: 'Add Marketing Item',
};
export const COMMISSIONS = 'Associated Commissions';
export const MORTGAGE_OPTIONS = ['Fixed', 'Variable', 'Heloc', 'Other'];
