import { useEffect, useReducer, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { useLazyQuery, useMutation } from '@apollo/client';
import { useModal, useUserInfo, useToast } from '../../../../../hooks';
import { ELIGIBILITY_FEEDBACK_ACTIONS, eligibilityFeedbackModalReducer } from '../Components/EligibilityFeedbackModal/reducers';
import { useFintelCheckContext } from '../../hooks';
import { GET_FINTEL_CHECK_DETAILS } from '../../FintelCheckChangeHistory/graphql/queries';
import { GetFintelCheckDetailsOutput, GetFintelCheckDetailsInput, GFCDDetailType } from '../../FintelCheckChangeHistory/types';
import { GET_FINTEL_CHECK_SUMMARY, GET_CHECK_RULES } from '../graphql/queries';
import { compareValues } from '../../../../Reports/PerfomanceReport/utils';
import { UPDATE_FINTEL_CHECK_SUMMARIES, UPDATE_FINTEL_CHECK_DETAILS } from '../graphql/mutations';
import { FEEDBACK_ACTIONS, feedbackModalReducer, FeedbackReducerModalProps } from '../../FintelCheckDetails/Modal/reducers';
import {
  GCRRuleType, GetCheckRulesInput, GetCheckRulesOutput,
  GetFintelCheckSummaryInput, GetFintelCheckSummaryOutput, GFCSSummaryType,
  UpdateFintelCheckDetailsInput, UpdateFintelCheckDetailsOutput,
  UpdateFintelCheckSummariesInput, UpdateFintelCheckSummariesOutput,
} from '../types';

const RECORDS_PER_PAGE = 10;

export const useRuleDetails = () => {
  const { hookWhoAmI } = useUserInfo();
  const navigate = useNavigate();
  const { contextState } = useFintelCheckContext();
  const { hookShowToast } = useToast();

  const [errorMessage, setErrorMessage] = useState<string>('');

  const [summaryData, setSummaryData] = useState<GFCSSummaryType>();
  const [ruleData, setRuleData] = useState<GCRRuleType>();

  const [tableData, setTableData] = useState<GFCDDetailType[]>([]);
  const [allData, setAllData] = useState<GFCDDetailType[]>([]);
  const [totalPages, setTotalPages] = useState<number>(1);
  const [currentPage, setCurrentPage] = useState<number>(1);
  const [sortColumn, setSortColumn] = useState<TableSortColumn>({ column: 'status', direction: 'asc' });

  const [statusOpen, setStatusOpen] = useModal();
  const [statusModalState, statusModalDispatcher] = useReducer(feedbackModalReducer, {
    correctStatus: undefined, currStatus: undefined, id: undefined, shouldBeStatus: undefined,
  });
  const [statusError, setStatusError] = useState<string>('');

  const [eligibilityOpen, setEligibilityOpen] = useModal();
  const [eligibilityModalState, eligibilityModalDispatcher] = useReducer(eligibilityFeedbackModalReducer, { correctStatus: undefined });

  // Queries and Mutations
  const [getDetails, { loading: getDetailsLoading }] = useLazyQuery<GetFintelCheckDetailsOutput, GetFintelCheckDetailsInput>(GET_FINTEL_CHECK_DETAILS);
  const [getSummary, { loading: getSummaryLoading }] = useLazyQuery<GetFintelCheckSummaryOutput, GetFintelCheckSummaryInput>(GET_FINTEL_CHECK_SUMMARY);
  const [getCheckRules, { loading: getCheckRulesLoading }] = useLazyQuery<GetCheckRulesOutput, GetCheckRulesInput>(GET_CHECK_RULES);

  const [updateEligibilty, { loading: updateEligibiltyLoading }] = useMutation<UpdateFintelCheckSummariesOutput, UpdateFintelCheckSummariesInput>(UPDATE_FINTEL_CHECK_SUMMARIES);
  const [updateFeedback, { loading: updateFeedbackLoading }] = useMutation<UpdateFintelCheckDetailsOutput, UpdateFintelCheckDetailsInput>(UPDATE_FINTEL_CHECK_DETAILS);

  const updateEligibilityHandler = async () => {
    setErrorMessage('');
    if (!summaryData) return;

    const newEligibilityFeedback = {
      correct: eligibilityModalState.correctStatus ? 'Yes' : 'No',
      currStatus: summaryData.ruleStatus || '',
      inputType: 'eligibility' as const,
      inputVal: 'eligibility' as const,
      issue: 'Eligibility criteria' as const,
      shouldBeStatus: '' as const,
    };

    const { data } = await updateEligibilty({
      variables: {
        input: {
          id: summaryData?.id,
          eligibilityFeedback: newEligibilityFeedback,
        },
      },
      fetchPolicy: 'no-cache',
      onError(err) {
        setErrorMessage(err.message);
      },
    });
    if (data) {
      hookShowToast('Feedback for Fintel Check submitted.');
      setSummaryData({ ...summaryData, eligibilityFeedback: newEligibilityFeedback });
    }
    setEligibilityOpen();
  };

  const updateRuleDetailStatus = async () => {
    setStatusError('');
    if (!summaryData) return;

    const newFeedback = {
      correct: statusModalState.correctStatus ? 'Yes' : 'No',
      currStatus: summaryData.ruleStatus || '',
      inputType: '' as const,
      inputVal: '' as const,
      shouldBeStatus: statusModalState.shouldBeStatus || '',
    };

    const { data } = await updateFeedback({
      variables: {
        input: {
          id: statusModalState?.id || '',
          feedback: newFeedback,
        },
      },
      fetchPolicy: 'no-cache',
      onError(err) {
        setStatusError(err.message);
      },
    });
    if (data) {
      hookShowToast('Feedback for Fintel Check submitted.');
      // Update currently shown data
      const updatedRowIndex = allData.findIndex((row) => row.id === statusModalState.id);
      if (updatedRowIndex !== -1) {
        const newAllData = [...allData];
        newAllData[updatedRowIndex] = { ...newAllData[updatedRowIndex], feedback: { ...newFeedback, issue: summaryData.feedback?.issue || '' } };
        setTableData(newAllData.slice(((currentPage - 1) * RECORDS_PER_PAGE), (currentPage * RECORDS_PER_PAGE)));
        setAllData(newAllData);
      }
    }
    setStatusOpen();
  };

  const getSummariesHandler = async () => {
    setErrorMessage('');

    const [{ data: detailsData }, { data: fintelCheckSummaryData }, { data: rulesData }] = await Promise.all([
      getDetails({
        variables: {
          input: {
            merchantId: hookWhoAmI.companyId?.toString() || '0',
            publisherId: contextState.publisherId || hookWhoAmI.companyId?.toString() || '0',
            ...contextState.publisherName && { publisherName: contextState.publisherName },
            checkDate: contextState.date,
            referralUrl: contextState.referralUrl,
            ruleName: contextState.ruleName,
            productId: contextState.productId,
          },
        },
        fetchPolicy: 'no-cache',
        onError(err) {
          setErrorMessage(err.message);
        },
      }),
      getSummary({
        variables: {
          input: {
            id: contextState.fintelCheckSummaryId,
          },
        },
        fetchPolicy: 'no-cache',
        onError(err) {
          setErrorMessage(err.message);
        },
      }),
      getCheckRules({
        variables: {
          input: {
            merchantId: hookWhoAmI.companyId?.toString() || '',
            ruleName: contextState.ruleName,
          },
        },
        fetchPolicy: 'no-cache',
        onError(err) {
          setErrorMessage(err.message);
        },
      }),
    ]);

    if (detailsData && detailsData.fintelCheckDetails) {
      const detailsList: GFCDDetailType[] = detailsData.fintelCheckDetails.fintelChecks.map((row) => ({ ...row, ruleStatus: row.status }));
      const sortFunction = compareValues(sortColumn.column, sortColumn.direction);
      detailsList.sort(sortFunction);
      setTableData(detailsList.slice(0, RECORDS_PER_PAGE));
      setAllData(detailsList);
      setCurrentPage(1);
      setTotalPages(Math.ceil(detailsList.length / RECORDS_PER_PAGE));
    }

    if (fintelCheckSummaryData && fintelCheckSummaryData.fintelCheckSummary) {
      setSummaryData(fintelCheckSummaryData.fintelCheckSummary);
      if (fintelCheckSummaryData.fintelCheckSummary.eligibilityFeedback) {
        const state = fintelCheckSummaryData.fintelCheckSummary.eligibilityFeedback.correct === 'Yes';
        eligibilityModalDispatcher({ type: ELIGIBILITY_FEEDBACK_ACTIONS.SET_STATUS_CONFIRMATION, data: { correctStatus: state } });
      }
    }

    if (rulesData && rulesData?.checkRules) {
      // If only one rule is returned you can assume it is the correct rule.
      if (rulesData.checkRules.count === 1) {
        setRuleData(rulesData.checkRules.checkRules[0]);
      } else {
        // If more then one rule returned with same (ruleName, MerchantId) must determine correct rule by Dates.
        // The Rule's start date must be before the runDate and after any other rule matching first condition.
        const rulesList = rulesData.checkRules.checkRules;
        const runDate = new Date(contextState.date);

        // Closest Start Date without going over runDate (Price Is Right rules)
        let closestStartDate: Date | undefined;
        let closestStartDateRule = rulesList[0];

        rulesList.forEach((rule) => {
          if (rule.startDate) {
            const newDate = new Date(rule.startDate);
            if (!closestStartDate || (newDate < closestStartDate && newDate > runDate)) {
              closestStartDate = newDate;
              closestStartDateRule = rule;
            }
          }
        });
        setRuleData(closestStartDateRule);
      }
    }
  };

  const handleSort = (dataField: string, direction: any) => {
    const detailsList = [...allData];
    let sortFunction;
    if (sortColumn.direction === null) {
      setSortColumn({ column: dataField, direction });
      sortFunction = compareValues(dataField, direction);
    } else {
      const newDirection = sortColumn.direction === 'asc' ? 'desc' : 'asc';
      setSortColumn({ column: dataField, direction: newDirection });
      sortFunction = compareValues(dataField, newDirection);
    }
    detailsList.sort(sortFunction);
    setTableData(detailsList.slice(((currentPage - 1) * RECORDS_PER_PAGE), (currentPage * RECORDS_PER_PAGE)));
    setAllData(detailsList);
  };

  const backButtonHandler = () => {
    navigate(-1);
  };

  const statusModalDispatcherHandler = (type: symbol, data: Record<string, any>) => {
    statusModalDispatcher({ type, data });
  };

  const openStatusModalHandler = () => {
    setStatusOpen();
  };

  const statusModalHandler = (modalInfo: FeedbackReducerModalProps) => {
    statusModalDispatcher({ type: FEEDBACK_ACTIONS.SET_STATUS_CONFIRMATION, data: modalInfo });
  };

  const statusModalInfo = {
    state: statusModalState,
    dispatch: statusModalDispatcherHandler,
    modalOpenDispatcher: setStatusOpen,
    updateFeedback: updateRuleDetailStatus,
    errorMessage: statusError,
    isLoading: updateFeedbackLoading,
  };

  const eligibilityModalDispatcherHandler = (type: symbol, data: Record<string, any>) => {
    eligibilityModalDispatcher({ type, data });
  };

  const openEligibilityModalHandler = () => {
    setEligibilityOpen();
  };

  const setCurrentPageHandler = (newPage: number) => {
    setCurrentPage(newPage);
    setTableData(allData.slice((newPage - 1) * RECORDS_PER_PAGE, newPage * RECORDS_PER_PAGE));
  };

  const eligibilityModalInfo = {
    state: eligibilityModalState,
    dispatch: eligibilityModalDispatcherHandler,
    modalOpenDispatcher: setEligibilityOpen,
    updateEligibilityHandler,
    updateEligibiltyLoading,
  };

  useEffect(() => {
    getSummariesHandler();
  }, [window.location.href]);

  return {
    hookContext: contextState,
    hookLoading: getSummaryLoading || getDetailsLoading || getCheckRulesLoading,
    hookErrorMessage: errorMessage,
    hookBack: backButtonHandler,

    hookRuleData: ruleData,
    hookSummaryData: summaryData,
    hookData: tableData,
    hookSortColumn: sortColumn,
    hookHandleSort: handleSort,
    hookTotalPages: totalPages,
    hookCurrentPage: currentPage,
    hookSetCurrentPageHandler: setCurrentPageHandler,

    hookStatusModalOpen: statusOpen,
    hookSetStatusModalOpen: openStatusModalHandler,
    hookStatusModalState: statusModalState,
    hookStatusModalDispatcher: statusModalDispatcherHandler,
    hookStatusModalInfo: statusModalInfo,
    hookStatusModalSetInfo: statusModalHandler,

    hookUpdateEligibilityHandler: updateEligibilityHandler,
    hookEligibilityModalOpen: eligibilityOpen,
    hookSetEligibilityModalOpen: openEligibilityModalHandler,
    hookEligibilityModalState: eligibilityModalState,
    hookEligibilityModalDispatcher: eligibilityModalDispatcherHandler,
    hookEligibilityModalInfo: eligibilityModalInfo,
  };
};
