/* eslint-disable @typescript-eslint/no-unused-vars */
import React from 'react';

import {
  AppWrapper, Button, Calendar, ErrorBanner, InputCalendar, PageTitle, Pagination, Table,
} from '../../../../components';
import { path } from '../../../../utils';
import { columns } from './contracts';
import { typeOptions } from './enums';
import { useCommissionHistory } from './hooks';
import { DefaultPropsType } from '../../../../types';

import * as Styled from './styles';

type CommissionsHistoryProps = DefaultPropsType;

const CommissionsHistory = ({ permissionsCodeList = [] }: CommissionsHistoryProps) => {
  const hook = useCommissionHistory();
  const loadingText = 'Loading Commissions History';

  return (
    <AppWrapper permissionsCodeList={permissionsCodeList}>
      <ErrorBanner
        message={hook.hookErrorMessage}
        isOpen={!!hook.hookErrorMessage}
      />
      <Styled.HeaderStyled>
        <PageTitle
          loadingText={loadingText}
          isLoading={hook.hookIsLoading}
        >
          {path.commissionsHistory.name}
        </PageTitle>
        <Button
          theme="tertiary"
          width="90px"
          onClick={hook.hookNavigate}
        >
          Back
        </Button>
      </Styled.HeaderStyled>
      <Styled.FilterStyled>
        <Styled.Row1Styled>
          <Styled.SelectStyled
            selected={hook.hookSelectedType}
            options={typeOptions}
            onChange={hook.hookSetSelectedType}
            isLoading={hook.hookIsLoading}
          />
          <Styled.SelectStyled
            selected={hook.hookSelectedProduct}
            options={hook.hookProductOptions}
            onChange={hook.hookSetSelectedProductHandler}
            isLoading={hook.hookIsLoading}
          />
          <InputCalendar
            placeholder="Select date"
            value={hook.hookSelectedDate ? hook.hookSelectedDate.toDateString() : ''}
            onClick={hook.hookOpenCalendar}
            isDisabled={hook.hookIsLoading}
          />
        </Styled.Row1Styled>
        <Styled.Row2Styled>
          <Button
            theme="text-only"
            onClick={hook.hookClearDate}
          >
            Clear Date
          </Button>
        </Styled.Row2Styled>
      </Styled.FilterStyled>

      <Table
        columns={columns}
        data={hook.hookCommissionsData}
        isLoading={hook.hookIsLoading}
        onSort={hook.hookHandleSort}
        sortColumn={hook.hookSortColumn}
        loadWithData={hook.hookCommissionsData.length > 0}
        onRowClick={hook.hookOnRowClick}
      />
      <Pagination
        currentPage={hook.hookCommissionsData?.length > 0 ? hook.hookCurrentPage : 0}
        total={hook.hookTotalPages}
        onPageChange={hook.hookSetCurrentPage}
      />

      <Calendar
        date={hook.hookSelectedDate}
        onApply={hook.hookOnApplySelectedCalendar}
        onCancel={hook.hookOnCancelSelectedCalendar}
        isOpen={hook.hookCalendarIsOpen}
        hasRange={false}
        isSingle
      />
    </AppWrapper>
  );
};

export default CommissionsHistory;
