import styled from 'styled-components';
import {
  Button, InputText, InputTextarea, Select,
} from '../../../../components';
import { colors, fonts } from '../../../../styles/theme';

export const PageDescriptionStyled = styled.div`
  ${fonts.lato.H16};
  color: ${colors.color1};
  line-height: 1.5;
  margin: 1.5rem 0 2.5rem;
`;

export const DateSectionStyled = styled.div`
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  grid-column-gap: 2%;
  grid-row-gap: 3rem;
`;

export const SelectStyled = styled(Select)`
  grid-column: span 1;
`;

export const ClearDateStyled = styled.div`
  grid-column: span 1;
`;

export const ClearDateButtonStyled = styled(Button)`
  ${fonts.lato.H16};
  padding-left: 0;
`;

export const DescriptionStyled = styled(InputTextarea)`
  margin-top: 1rem;
`;

export const PublisherGroupsWrapper = styled.div`
  margin-top: 2rem;
  color: ${colors.color1};
`;

export const PublisherGroupsTitleStyled = styled.div`
  ${fonts.gotham.H22};
`;

export const PublisherGroupsDescriptionStyled = styled.div`
  line-height: 1.5;
  margin: 1.5rem 0 2.5rem;
`;

export const InputTextStyled = styled(InputText)`
  margin-bottom: 2rem;
`;

export const ButtonSectionStyled = styled.div`
  display: flex;
  justify-content: flex-end;
  margin: 2rem 0 1rem;
`;

export const ButtonStyled = styled(Button)`
  height: 52px;
  margin-left: 1rem;
`;
