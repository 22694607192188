import { useMutation, useQuery } from '@apollo/client';
import { useEffect, useState } from 'react';
import { useUserInfo, useToast } from '../../../../../hooks';
import { URL_STATUSES } from '../../../../../utils';
import { useValidation } from '../../../../../utils/validation';
import { UPDATE_TRACKING_SETTING } from '../graphql/mutations';
import { GET_COMPANY } from '../graphql/queries';

export const useTrackingSettings = () => {
  const { hookShowToast } = useToast();
  const userHook = useUserInfo();
  const vali = useValidation();

  const [trackingSettingId, setTrackingSettingId] = useState('');
  const [landingPageURL, setLandingPageURL] = useState('');
  const [queryURL, setQueryURL] = useState('');
  const [thirdPartyURLFormat, setThirdPartyURLFormat] = useState('');
  const [thirdPartyStatusTracker, setThirdPartyStatusTracker] = useState(false);
  const [thirdPartyTrackingURL, setThirdPartyTrackingURL] = useState('');
  const [landingUrlError, setLandingUrlError] = useState('');
  const [thirdPartyUrlError, setThirdPartyUrlError] = useState('');
  const [landingUrlStatus, setLandingUrlStatus] = useState('');
  const [thirdPartyUrlStatus, setThirdPartyUrlStatus] = useState('');
  const [cookieDuration, setCookieDuration] = useState('');
  const [noFollow, setNoFollow] = useState(null);
  const [statsRelay, setStatsRelay] = useState(null);

  const [editTrackingSetting] = useMutation(UPDATE_TRACKING_SETTING);

  const { data } = useQuery(GET_COMPANY, {
    variables: {
      id: userHook.hookWhoAmI.companyId,
    },
    fetchPolicy: 'no-cache',
  });
  const getCompanyHandler = () => {
    if (data?.company.program.trackingSettings) {
      setLandingPageURL(data.company.program.trackingSettings.landingPage);
      setQueryURL(data.company.program.trackingSettings.urlQueryString);
      setThirdPartyTrackingURL(data.company.program.trackingSettings.thirdPartyUrl);
      setThirdPartyStatusTracker(data.company.program.trackingSettings.thirdPartyStatus);
      setThirdPartyURLFormat(data.company.program.trackingSettings.thirdPartyUrlFormat);
      setCookieDuration(data.company.program.trackingSettings.cookieDuration);
      setNoFollow(data.company.program.trackingSettings.nofollow);
      setStatsRelay(data.company.program.trackingSettings.statsRelay);
      if (data.company.program.trackingSettings.id) setTrackingSettingId(data.company.program.trackingSettings.id);
    }
  };

  const validateWebsite = () => {
    vali.validateUrlStatus(landingPageURL, setLandingUrlStatus);
    vali.renderUrlCheck(landingUrlStatus, setLandingUrlError);

    if (thirdPartyTrackingURL !== '' && thirdPartyTrackingURL !== null) {
      vali.validateUrlStatus(thirdPartyTrackingURL, setThirdPartyUrlStatus);
      vali.renderUrlCheck(thirdPartyUrlStatus, setThirdPartyUrlError);
    }
  };

  const editTrackingSettingHandler = async () => {
    if (landingPageURL === '' || landingUrlStatus === URL_STATUSES.EMPTY_WEBSITE.STATUS || landingUrlStatus === URL_STATUSES.INVALID_WEBSITE.STATUS || landingUrlStatus === URL_STATUSES.INACTIVE_WEBSITE.STATUS || (thirdPartyTrackingURL === '' || thirdPartyUrlStatus === URL_STATUSES.EMPTY_WEBSITE.STATUS || thirdPartyUrlStatus === URL_STATUSES.INVALID_WEBSITE.STATUS || thirdPartyUrlStatus === URL_STATUSES.INACTIVE_WEBSITE.STATUS)) return;

    const { errors } = await editTrackingSetting({
      variables: {
        input: {
          programsId: userHook.hookWhoAmI.programId,
          id: trackingSettingId,
          landingPage: landingPageURL,
          urlQueryString: queryURL,
          thirdPartyUrl: thirdPartyTrackingURL,
          thirdPartyUrlFormat: thirdPartyURLFormat,
          thirdPartyStatus: thirdPartyStatusTracker,
        },
      },
    });

    if (errors) {
      hookShowToast(errors[0].message);
    } else {
      hookShowToast('Tracking settings updated successfully');
    }
  };

  const setCheckThirdPartyStatusHandler = () => {
    setThirdPartyStatusTracker(!thirdPartyStatusTracker);
  };

  const setQueryURLHandler = (e: React.ChangeEvent<HTMLInputElement>) => {
    setQueryURL(e?.target.value);
  };

  const setLandingPageURLHandler = (e: React.ChangeEvent<HTMLInputElement>) => {
    setLandingPageURL(e?.target.value);
  };

  const setThirdPartyURLHandler = (e: React.ChangeEvent<HTMLInputElement>) => {
    setThirdPartyURLFormat(e?.target.value);
  };

  const setThirdPartyTrackingURLHandler = (e: React.ChangeEvent<HTMLInputElement>) => {
    setThirdPartyTrackingURL(e?.target.value);
  };

  useEffect(() => {
    getCompanyHandler();
  }, [data]);

  useEffect(() => {
    if (landingPageURL === '') {
      setLandingUrlError('');
    }
    vali.validateUrlStatus(landingPageURL, setLandingUrlStatus);
  }, [landingPageURL]);

  useEffect(() => {
    if (thirdPartyTrackingURL === '') {
      setThirdPartyUrlError('');
    }
    vali.validateUrlStatus(thirdPartyTrackingURL, setThirdPartyUrlStatus);
  }, [thirdPartyTrackingURL]);

  return {
    hookLandingPageURL: landingPageURL,
    hookSetLandingpageURL: setLandingPageURLHandler,

    hookQueryURL: queryURL,
    hookSetQueryURL: setQueryURLHandler,

    hookThirdPartyURLFormat: thirdPartyURLFormat,
    hookSetThirdPartyURLFormat: setThirdPartyURLHandler,

    hookThirdPartyStatus: thirdPartyStatusTracker,
    hookCheckThirdPartyStatus: setCheckThirdPartyStatusHandler,

    hookThirdPartyTrackingURL: thirdPartyTrackingURL,
    hookSetThirdPartyTrackingURL: setThirdPartyTrackingURLHandler,

    hookCookieDuration: cookieDuration,
    hookNoFollow: noFollow,
    hookStatsRelay: statsRelay,

    hookValidateWebsite: validateWebsite,
    hookLandingUrlError: landingUrlError,
    hookThirdPartyUrlError: thirdPartyUrlError,

    hookTrackingSettingsId: trackingSettingId,

    hookEditTrackingSetting: editTrackingSettingHandler,

    hookLandingPageValidation: landingUrlStatus,
    hookTrackingUrl: thirdPartyUrlStatus,
  };
};
