/* eslint-disable @typescript-eslint/no-unused-vars */
interface Tier {
    upTo: string | null;
    commissionAmount: string | null;
  }
interface CommissionBase {
    commissionBase: string | null;
    commissionFee: string | null;
    minimumSaleAmount?: string | null;
    tiers?: Tier[];
    cutoffAmount?: string | null;
  }
interface CommissionStructure {
  cpaFlat: {
    minimumSaleAmount: string | null
    commissionFee: string | null
    commissionBase: 'GrossSales' | 'NetSales' | 'SalesNumber' | 'CPA' | null
  }
  cpaTiered: {
    commissionBase: 'GrossSales' | 'NetSales' | 'SalesNumber' | 'CPA' | null
    tiers: { upTo: string | null, commissionAmount: string | null }[]
    cutoffAmount: string | null
    minimumSaleAmount: string | null
  }
  referral: { levels: string[] }
  revShareFlat: {
    commissionFee: string | null
    commissionBase: 'GrossSales' | 'NetSales' | 'SalesNumber' | 'CPA' | null
  }
  revShareTiered: {
    tiers: { upTo: string, commissionAmount: string }[]
    cutoffAmount: string | null
    commissionBase: 'GrossSales' | 'NetSales' | 'SalesNumber' | 'CPA' | null
  }
  bonusFlat: {
    minimumSaleAmount: string | null
    commissionFee: string | null
    commissionBase: 'GrossSales' | 'NetSales' | 'SalesNumber' | 'CPA' | null
  }
  bonusTiered: {
    minimumSaleAmount: string | null
    commissionBase: 'GrossSales' | 'NetSales' | 'SalesNumber' | 'CPA' | null
    period: string | null
    calculationType: 'Stepped' | 'Lookup' | null
    tiers: { upTo: string, commissionAmount: string }[]
    cutoffAmount: string
  }
}

interface Commission {
  id: string;
  endDate: string | null;
  startDate: string | null;
  description: string | null;
  commissionType: string | null;
  commissionStructure: CommissionStructure;
  commissionTarget: {
    publisherGroupTargets: { endDate: string | null, publisherGroupId: string, startDate: string | null }[]
    publisherTargets: { endDate: string | null, publisherId: string, startDate: string | null }[]
  };
  publisherGroups: { id: string, name: string }[];
  publishers: { id: string, companyName: string }[];
  products: { id: string; name: string | null }[];
  merchant: { id: string, companyName?: string };
}

export interface Transaction {
  commissionBase: string;
  minimumSaleAmount: string;
}

export interface CommissionFormatted {
  id: string;
  idNum: number;
  commissionType: string | null;
  description: string | null;
  memberType: string;
  transaction: Transaction;
  product: {
      id: string;
      name: string | null;
  };
  publishers: {
      id: string;
      companyName: string;
  }[] | null;
  publisherGroups: {
      id: string;
      name: string;
  }[] | null;
  payable: string;
  commissionStructure: CommissionStructure;
  commissionTarget: {
      publisherGroupTargets: {
          endDate: string | null;
          publisherGroupId: string;
          startDate: string | null;
      }[];
      publisherTargets: {
          endDate: string | null;
          publisherId: string;
          startDate: string | null;
      }[];
  };
  merchant: {
    id: string;
    companyName?: string;
  };
  startDate: string | null;
  endDate: string | null;
  publisherStartDate?: Date | null
  publisherEndDate?: Date | null
}

const commissionBaseFormatter = (commissionBase: string): string => {
  if (commissionBase === 'GrossSales') {
    return 'gross sales';
  }
  if (commissionBase === 'NetSales') {
    return 'net sales';
  }
  return commissionBase;
};

const formatBase = (commissionBase: string): string => {
  switch (commissionBase) {
    case 'NetSales':
      return 'Net sales';
    case 'GrossSales':
      return 'Gross sales';
    case 'CPA':
      return 'CPA';
    default:
      return 'Any Transaction';
  }
};

const formatTransaction = (input: Partial<CommissionBase>): Transaction => {
  const { commissionBase, minimumSaleAmount } = input;
  const base = formatBase(commissionBase ?? '');
  const minimum = minimumSaleAmount ? `Min $${minimumSaleAmount}` : '';
  const transaction: Transaction = {
    commissionBase: base,
    minimumSaleAmount: minimum,
  };
  return transaction;
};

const formatPayable = (commissionStructure: CommissionStructure, commissionType: string): { payable: string, transaction: Transaction } => {
  let payable = '';
  let transaction: Transaction = { commissionBase: 'Any Transaction', minimumSaleAmount: '' };
  if (commissionStructure) {
    const {
      cpaFlat,
      cpaTiered,
      referral,
      revShareFlat,
      revShareTiered,
      bonusFlat,
      bonusTiered,
    } = commissionStructure;
    switch (commissionType) {
      case 'CPA':
        if (cpaFlat && cpaFlat.commissionBase) {
          payable = `Earns $${cpaFlat.commissionFee}`;
          transaction = formatTransaction(cpaFlat);
        } else if (cpaTiered && cpaTiered.commissionBase) {
          let num = 0;
          const tiersFormat = cpaTiered?.tiers?.map((x) => {
            const xFormat = num === 0
              ? `Up to ${x.upTo} earns $${x.commissionAmount}`
              : `${num} - ${x.upTo} earns $${x.commissionAmount}`;
            num = Number(x.upTo) + 1;
            return xFormat;
          });
          const lastCutOff = cpaTiered.cutoffAmount
            ? `, over ${num} earns $${cpaTiered.cutoffAmount}`
            : `, over ${num} earns $0`;
          payable = tiersFormat ? tiersFormat.join(', ') + lastCutOff : '';
          transaction = formatTransaction(cpaTiered);
        }
        break;
      case 'Referral':
        if (referral && referral.levels && referral.levels.length) {
          const levelsFormat = referral.levels.map((x, i) => {
            const xFormat = `Level ${i + 1} earns ${x}%`;
            return xFormat;
          });
          payable = levelsFormat.join(', ');
        }
        transaction.commissionBase = 'N/A';
        transaction.minimumSaleAmount = 'N/A';
        break;
      case 'RevShare':
        if (revShareTiered && revShareTiered.tiers && revShareTiered.tiers.length) {
          let num = 0;
          const tiersFormat = revShareTiered.tiers.map((x) => {
            const xFormat = num === 0
              ? `Up to ${x.upTo} earns ${x.commissionAmount}%`
              : `${num} - ${x.upTo} earns ${x.commissionAmount}%`;
            num = Number(x.upTo) + 1;
            return xFormat;
          });
          const lastCutOff = revShareTiered.cutoffAmount
            ? `, over ${num} earns ${revShareTiered.cutoffAmount}%`
            : `, over ${num} earns 0%`;
          payable = tiersFormat.join(', ') + lastCutOff;
          transaction.commissionBase = revShareTiered.commissionBase || 'N/A';
        } else if (revShareFlat && revShareFlat.commissionBase) {
          const commissionBaseFormat = commissionBaseFormatter(revShareFlat.commissionBase);
          payable = `Earns flat ${revShareFlat.commissionFee}% on ${commissionBaseFormat}`;
          transaction.commissionBase = revShareFlat.commissionBase || 'N/A';
        }
        transaction.minimumSaleAmount = 'N/A';
        break;
      case 'Bonus':
        if (bonusFlat && bonusFlat.commissionBase) {
          const commissionBaseFormat = commissionBaseFormatter(bonusFlat.commissionBase);
          payable = `Earns $${bonusFlat.commissionFee} on ${commissionBaseFormat}`;
          transaction = formatTransaction(bonusFlat);
        } else if (bonusTiered && bonusTiered.commissionBase) {
          let num = 0;
          const tiersFormat = bonusTiered?.tiers?.map((x) => {
            const xFormat = num === 0
              ? `Up to ${x.upTo} earns $${x.commissionAmount}`
              : `${num} - ${x.upTo} earns $${x.commissionAmount}`;
            num = Number(x.upTo) + 1;
            return xFormat;
          });
          const lastCutOff = bonusTiered.cutoffAmount
            ? `, over ${num} earns $${bonusTiered.cutoffAmount}`
            : `, over ${num} earns $0`;
          payable = tiersFormat ? tiersFormat.join(', ') + lastCutOff : '';
          transaction = formatTransaction(bonusTiered);
        }
        break;
      default:
        return { payable, transaction };
    }
  }
  return { payable, transaction };
};

export const monthToNumber = (month: string): number | null => {
  if (!month) return null;
  switch (month) {
    case 'Jan':
      return 1;
    case 'Feb':
      return 2;
    case 'Mar':
      return 3;
    case 'Apr':
      return 4;
    case 'May':
      return 5;
    case 'Jun':
      return 6;
    case 'Jul':
      return 7;
    case 'Aug':
      return 8;
    case 'Sep':
      return 9;
    case 'Oct':
      return 10;
    case 'Nov':
      return 11;
    case 'Dec':
      return 12;
    default:
      return null;
  }
};

const dayToNumber = (day: any) => day.replace('st', '').replace('rd', '').replace('nd', '').replace('th', '');

export const toUTC = (dateString: string | Date, dateType?: string): string => {
  if (dateString) {
    let date: Date;

    if (typeof dateString === 'string' && dateString.includes(' / ')) {
      const monthPart = dateString.split(' / ')[0].split(' ')[0];
      const month = monthPart ? monthToNumber(monthPart) : 0;
      const monthIndex = month !== null ? month - 1 : 0;
      const dayIndex = dayToNumber(dateString.split(' / ')[0].split(' ')[1]);
      const yearIndex = dateString.split(' / ')[1];
      date = new Date(Number(yearIndex), monthIndex, dayIndex);
    } else if (typeof dateString === 'string') {
      date = new Date(dateString);
    } else {
      date = dateString;
    }
    const year = date.getFullYear();
    const monthIndex = date.getMonth();
    const dayIndex = date.getDate();
    const month = `0${monthIndex + 1}`.slice(-2);
    const day = `0${dayIndex}`.slice(-2);
    if (dateType === 'startDate') {
      return `${year}-${month}-${day}T00:00:00Z`;
    }
    return `${year}-${month}-${day}T23:59:59Z`;
  }
  return '';
};

export const isAfterOrEqual = (date1Format: Date, date2Format: Date): boolean => date1Format >= date2Format;

export const isInRange = (date: Date, startDate: Date, endDate: Date): boolean => date >= startDate && date <= endDate;

export const commissionsFormatter = (commissions: Commission[], isPublisher: boolean): CommissionFormatted[] => {
  const now = new Date();
  const commissionFormat = commissions
    .filter((commission) => {
      if (!isPublisher) {
        return true;
      }
      if (commission.endDate && commission.startDate) {
        return isInRange(now, new Date(commission.startDate), new Date(commission.endDate));
      }
      if (!commission.endDate && commission.startDate) {
        return isAfterOrEqual(now, new Date(commission.startDate));
      }
      return false;
    })
    .map((commission) => {
      const {
        id,
        products,
        publishers,
        merchant,
        publisherGroups,
        commissionType,
        startDate,
        endDate,
        description,
        commissionTarget,
        commissionStructure,
      } = commission;
        // set memberType
      let memberType = '';
      if (!publisherGroups.length && !publishers.length) {
        memberType = 'All Members';
      } else if (!publisherGroups.length && publishers.length) {
        memberType = publishers.map((x) => x.companyName).join(', ');
      } else if (publisherGroups.length && !publishers.length) {
        memberType = publisherGroups.map((x) => x.name).join(', ');
      } else {
        memberType = 'Custom';
      }
      // set product
      let product: { id: string, name: string | null } = { id: '', name: 'All Products' };
      if (products.length) {
        [product] = products;
      }
      // set commission detail
      const { payable, transaction } = formatPayable(commissionStructure, commissionType ?? '');
      // If isPublisher Get the start/end Date that belong to them for that commission
      let publisherStartDate = null;
      let publisherEndDate = null;
      if (isPublisher) {
        if (!commissionTarget.publisherGroupTargets.length && !commissionTarget.publisherTargets.length) {
          publisherStartDate = startDate ? new Date(startDate) : null;
          publisherEndDate = endDate ? new Date(endDate) : null;
        } else if (!commissionTarget.publisherGroupTargets.length && commissionTarget.publisherTargets.length) {
          publisherStartDate = commissionTarget.publisherTargets[0].startDate ? new Date(commissionTarget.publisherTargets[0].startDate) : null;
          publisherEndDate = commissionTarget.publisherTargets[0].endDate ? new Date(commissionTarget.publisherTargets[0].endDate) : null;
        } else if (commissionTarget.publisherGroupTargets.length && !commissionTarget.publisherTargets.length) {
          publisherStartDate = commissionTarget.publisherGroupTargets[0].startDate ? new Date(commissionTarget.publisherGroupTargets[0].startDate) : null;
          publisherEndDate = commissionTarget.publisherGroupTargets[0].endDate ? new Date(commissionTarget.publisherGroupTargets[0].endDate) : null;
        } else {
          publisherStartDate = startDate ? new Date(startDate) : null;
          publisherEndDate = endDate ? new Date(endDate) : null;
        }
      }

      // format final commission
      const newCommission: CommissionFormatted = {
        id,
        idNum: Number(id),
        commissionType,
        description,
        memberType,
        transaction,
        product,
        publishers,
        publisherGroups,
        payable,
        commissionStructure,
        commissionTarget,
        merchant,
        startDate,
        endDate,
        publisherStartDate,
        publisherEndDate,
      };
      return newCommission;
    });
  return commissionFormat;
};

export const commissionFilter = (commissions: any[], user: any) => {
  const publisherGroupIds: any[] = [];
  const publisherId = user.company.id;
  user.company.memberships.forEach((membership: any) => {
    const { publisherGroups } = membership;
    if (publisherGroups.length > 0) {
      publisherGroups.forEach((publisherGroup: any) => {
        if (!publisherGroupIds.includes(publisherGroup.id)) {
          publisherGroupIds.push(publisherGroup.id);
        }
      });
    }
  });
  let filteredCommissions: any = [];
  const customCommissions: any = [];
  const defaultCommissions: any = [];
  commissions.forEach((commission) => {
    // for each commission
    if (
      commission.commissionTarget.publisherGroupTargets.length
      || commission.commissionTarget.publisherTargets.length
    ) {
      let isInTarget = false; // Check if the commission targets has that publisher
      commission.commissionTarget.publisherGroupTargets.forEach((publisherGroupTarget: any) => {
        if (publisherGroupIds.includes(publisherGroupTarget.publisherGroupId)) {
          isInTarget = true;
        }
      });
      commission.commissionTarget.publisherTargets.forEach((publisherTarget: any) => {
        if (publisherTarget.publisherId === publisherId) {
          isInTarget = true;
        }
      });
      if (isInTarget) {
        customCommissions.push(commission);
      }
    } else {
      defaultCommissions.push(commission);
    }
  });
  defaultCommissions.forEach((defaultCommission: any) => {
    let shouldNotFiltered = true;
    customCommissions.forEach((customCommission: any) => {
      if (
        customCommission.product.name === defaultCommission.product.name
        && customCommission.merchant.id === defaultCommission.merchant.id
      ) {
        if (defaultCommission.endDate && customCommission.endDate) {
          // both have endDate
          if (
            isInRange(
              defaultCommission.startDate,
              customCommission.startDate,
              customCommission.endDate,
            )
            && isInRange(
              defaultCommission.endDate,
              customCommission.startDate,
              customCommission.endDate,
            )
          ) {
            shouldNotFiltered = false;
          }
        } else if (
          (defaultCommission.endDate && !customCommission.endDate)
          || (!defaultCommission.endDate && !customCommission.endDate)
        ) {
          // only custom commission is endless or both are endless
          if (isAfterOrEqual(defaultCommission.startDate, customCommission.startDate)) {
            shouldNotFiltered = false;
          }
        }
      }
    });
    if (shouldNotFiltered) {
      filteredCommissions = [...filteredCommissions, defaultCommission];
    }
  });
  filteredCommissions = [...customCommissions, ...filteredCommissions];
  return filteredCommissions;
};
