import { useLazyQuery, useMutation } from '@apollo/client';
import { useEffect, useState } from 'react';
import { useNavigate, useLocation } from 'react-router-dom';
import { useUserInfo } from '../../../../../hooks';
import {
  MERCHANT_PREFIX, path, TOAST_ERR_MESSAGES_NO_PAGE, toUTCHours,
} from '../../../../../utils';
import { UpdateCommissionInput, UpdateCommissionOutput, UPDATE_COMMISSION } from '../graphql/mutations';
import {
  GET_COMMISSION_DETAILS, GetCommissionDetailsInput, GetCommissionDetailsOutput, GCDCommission, GET_PUBLISHER_GROUPS, GetPublisherGroupsOutput, GetPublisherGroupsInput, GetMembershipsOutput, GetMembershipsInput, GET_MEMBERSHIPS,
} from '../graphql/queries';
import { compareValues } from '../../../../Reports/PerfomanceReport/utils';
import { DESC_ASC_OPTIONS, SORT_DATE } from '../enums';
import { Permission } from '../../../../../entities';

export type PublisherRow = {
  id: string
  idNum: number // So can sort correctly on id
  name: string
  startDate: Date | null // Must be not set already to set it
  endDate: Date | null // Must be greater then startDate
  type: 'Publisher Group' | 'Publisher'
  selected: boolean // Checkbox status
  registered: boolean // is already included in the commission
  disabled: boolean // Determines if row greyed out
  publishersInGroup: string[] | null // Need to get publishers part of group
}

const TABLE_ROW_SIZE = 6;

const sortPublisherRows = (sortBy: string, sortOrder: 'asc' | 'desc', publisherDataToSort: PublisherRow[], searchValue?: string): PublisherRow[] => {
  const sortFunction = compareValues(sortBy, sortOrder);
  let resortedPublishers: PublisherRow[] = [];
  // Only Filter by the search value if it is not empty
  // When search empty display all active Publisher/PublisherGroups
  // Keep selected Publishers on list even if not matching
  if (searchValue) {
    resortedPublishers = [...publisherDataToSort].sort(sortFunction).filter((row) => row.id.includes(searchValue)
      || row.name.toLowerCase().includes(searchValue.toLowerCase())
      || row.selected);
  } else {
    resortedPublishers = [...publisherDataToSort].sort(sortFunction).filter((row) => row.registered
      || row.selected
      || row.startDate);
  }
  return resortedPublishers;
};

export const useEditCommissions = (permissionsCodeList: string[] = []) => {
  // Global Values
  const navigate = useNavigate();
  const hookLocation = useLocation();
  const comId = typeof hookLocation.state === 'object' ? hookLocation.state?.val : hookLocation.state;

  const { hookWhoAmI } = useUserInfo();

  const [loadingMessage, setLoadingMessage] = useState<string>('');
  const [errorMessage, setErrorMessage] = useState<string>('');

  // Commission Info
  const [commissionData, setCommissionData] = useState<GCDCommission>();
  const [isCustom, setIsCustom] = useState<boolean>(false);
  const [commissionType, setCommissionType] = useState<string>('');

  // Details Tab
  const [detailsEndDate, setDetailsEndDate] = useState<Date | undefined>();
  const [detailsStartDate, setDetailsStartDate] = useState<Date>(new Date());
  const [detailsCalendarOpen, setDetailsCalendarOpen] = useState(false);
  const [detailsMinDate, setDetailsMinDate] = useState<Date>(new Date());

  // Publisher/Groups Tab
  // Filters & Dropdowns
  const [sortByOrderSelected, setsortByOrderSelected] = useState<SelectOption>(DESC_ASC_OPTIONS[0]);
  const [sortByDateSelected, setsortByDateSelected] = useState<SelectOption>(SORT_DATE[0]);

  // Table Status
  const [totalPages, setTotalPages] = useState<number>(1);
  const [currentPage, setCurrentPage] = useState<number>(0);
  const [getPublisherInfo, setGetPublisherInfo] = useState<boolean>(false); // Triggers fetching the Merchant's Publisher Data when true
  const [publishersSelected, setPublishersSelected] = useState<string[]>([]); // Ids of Publisher/PublisherGroups Selected

  // Publisher/PublisherGroup Data
  const [completePublisherList, setCompletePublisherList] = useState<PublisherRow[]>([]); // Complete list of all Publishers and Publisher Groups
  const [publisherList, setPublisherList] = useState<PublisherRow[]>([]); // List of Publisher Rows that match current filters
  const [tableData, setTableData] = useState<PublisherRow[]>([]); // Publishers to display on table currently

  // For Calculating the disabled status of Publisher/PublisherGroups
  // publishersSelected get added to registeredIds
  const [registeredIds, setRegisteredIds] = useState<string[]>([]); // The displayed list of Registered Ids (changes when making selections on table)

  const [startDate, setStartDate] = useState<Date>();
  const [startDateMin, setStartDateMin] = useState<Date>();
  const [startDateMax, setStartDateMax] = useState<Date>();
  const [startCalendarOpen, setStartCalendarOpen] = useState(false);

  const [endDate, setEndDate] = useState<Date>();
  const [endDateMin, setEndDateMin] = useState<Date>();
  const [endDateMax, setEndDateMax] = useState<Date>();
  const [endCalendarOpen, setEndCalendarOpen] = useState(false);

  // const [publisherData, setPublisherData] = useState<PublisherItem[]>([]);
  const [updateDateWarning, setDateWarning] = useState<boolean>(false);
  const [search, setSearch] = useState<string>('');

  // Queries
  const [getCommission, { loading: getCommissionLoading }] = useLazyQuery<GetCommissionDetailsOutput, GetCommissionDetailsInput>(GET_COMMISSION_DETAILS);
  const [updateCommission, { loading: updateCommissionLoading }] = useMutation<UpdateCommissionOutput, UpdateCommissionInput>(UPDATE_COMMISSION);
  const [getPublisherGroups, { loading: getPublisherGroupsLoading }] = useLazyQuery<GetPublisherGroupsOutput, GetPublisherGroupsInput>(GET_PUBLISHER_GROUPS);
  const [getMemberships, { loading: getMembershipsLoading }] = useLazyQuery<GetMembershipsOutput, GetMembershipsInput>(GET_MEMBERSHIPS);

  // Saves updates made to the commission
  const saveCommissionHandler = async () => {
    setErrorMessage('');
    setLoadingMessage('Updating Commission');

    let newDate: Date | undefined;
    if (detailsEndDate) newDate = toUTCHours(detailsEndDate, 'end');

    // Send in all the startDate and endDate info for all Publisher/PublisherGroups with a start date if custom
    const updateInput: UpdateCommissionInput = isCustom ? {
      input: {
        id: comId,
        endDate: newDate || '',
        commissionTarget: {
          productIds: commissionData?.products.map((product) => product.id) || [null],
          publisherTargets:
            completePublisherList.filter((row) => row.type === 'Publisher' && row.startDate).map((row) => ({
              publisherId: row.id,
              startDate: row.startDate as Date,
              endDate: row.endDate || '',
            })) || [],
          publisherGroupTargets:
            completePublisherList.filter((row) => row.type === 'Publisher Group' && row.startDate).map((row) => ({
              publisherGroupId: row.id,
              startDate: row.startDate as Date,
              endDate: row.endDate || '',
            })) || [],
        },
      },
    } : {
      input: {
        id: comId,
        endDate: newDate || '',
      },
    };

    const { errors } = await updateCommission({
      variables: updateInput,
      onError(err) {
        setErrorMessage(TOAST_ERR_MESSAGES_NO_PAGE(err.message));
      },
    });
    if (errors && errors[0]) {
      setErrorMessage(TOAST_ERR_MESSAGES_NO_PAGE(errors[0]?.message));
      return;
    }
    // On success Navigate Back
    navigate(`${MERCHANT_PREFIX}${path.manageCommissions.href}`);
  };

  // Gets the Commissions data for the commission Id received from useNavigate
  const getCommissionHandler = async () => {
    setErrorMessage('');
    setLoadingMessage('Loading Commission');

    const { data } = await getCommission({
      variables: {
        commissionId: comId,
      },
      fetchPolicy: 'no-cache',
      onError(err) {
        setErrorMessage(TOAST_ERR_MESSAGES_NO_PAGE(err.message));
      },
    });

    if (data && data.commission) {
      const { commission } = data;
      setCommissionData(data.commission);

      // Should there be Publishers/Groups Tab, If there is fetch the info for it
      if (commission?.publishers?.length > 0 || commission?.publisherGroups?.length > 0) {
        setIsCustom(true);
        setGetPublisherInfo(true);
      } else {
        setIsCustom(false);
      }

      // Get the commission Type
      setCommissionType(commission.commissionType === 'Revenue Share' ? 'RevShare' : commission.commissionType || '');

      if (data.commission.startDate) {
        setDetailsStartDate(new Date(data.commission.startDate));
      }

      // Is there an end date to set?
      if (data.commission.endDate) {
        const newEndDate = new Date(data.commission.endDate);
        setDetailsEndDate(newEndDate);
      }

      // Get the start date for calendar constraints
      // If Commission start date is after current Date use that as the min Date
      if (data.commission.startDate) {
        const newStartDate = toUTCHours(new Date((data.commission.startDate)), 'beginning');
        const newMinDate = toUTCHours(new Date(), 'beginning');
        if (newStartDate > newMinDate) {
          setDetailsMinDate(newStartDate);
        } else {
          setDetailsMinDate(newMinDate);
        }
      }
    }
  };

  const setDetailsCalendarHandler = (state: boolean) => {
    setDetailsCalendarOpen(state);
  };

  const onApplyDetailsCalendarHandler = (newDate: Date) => {
    setDetailsEndDate(newDate);
    setDetailsCalendarOpen(false);
  };

  // If the commission is 'Custom' get the Publisher info for that Merchant
  const getMerchantsPublisherInfo = async () => {
    setErrorMessage('');
    setLoadingMessage('Loading Commission');

    /** List of all the Merchant's available Publisher Groups and Publishers (Not yet checked if should be disabled) */
    const publisherRows: PublisherRow[] = [];
    /** List of all currently Registered Publisher Ids */
    const registeredIdsArray: string[] = [];

    const [{ data: publisherGroupsData }, { data: membershipData }] = await Promise.all([
      getPublisherGroups({
        variables: {
          programId: hookWhoAmI.programId || '0',
        },
        fetchPolicy: 'no-cache',
        onError(err) {
          setErrorMessage(TOAST_ERR_MESSAGES_NO_PAGE(err.message));
        },
      }),
      getMemberships({
        variables: {
          input: {
            merchantId: hookWhoAmI.companyId?.toString() || '0',
            status: 'Approved',
          },
        },
        fetchPolicy: 'no-cache',
        onError(err) {
          setErrorMessage(TOAST_ERR_MESSAGES_NO_PAGE(err.message));
        },
      }),
    ]);

    // Handle formatting the Publisher Group List Info
    if (publisherGroupsData && publisherGroupsData.groups) {
      /** List of all the Merchants Publisher Groups */
      const PublisherGroupsList: PublisherRow[] = publisherGroupsData.groups.groups.map((pubGroup): PublisherRow => {
        const targetGroup = commissionData?.commissionTarget.publisherGroupTargets.find((pub) => pub.publisherGroupId === pubGroup.id);
        if (targetGroup) registeredIdsArray.push(...(pubGroup.memberships.map((membership) => membership.publisher.id)));
        return {
          id: pubGroup.id,
          idNum: Number(pubGroup.id),
          name: pubGroup.name,
          startDate: (targetGroup && targetGroup.startDate) ? new Date(targetGroup.startDate) : null,
          endDate: (targetGroup && targetGroup.endDate) ? new Date(targetGroup.endDate) : null,
          type: 'Publisher Group',
          selected: false,
          registered: !!targetGroup,
          disabled: false,
          publishersInGroup: pubGroup.memberships.map((membership) => membership.publisher.id),
        };
      });
      publisherRows.push(...PublisherGroupsList);
    }

    // Handle formatting the Publisher List Info
    if (membershipData && membershipData.memberships) {
      const newMemberships = membershipData.memberships.memberships;
      const PublishersList: PublisherRow[] = newMemberships.map((mem): PublisherRow => {
        const targetPub = commissionData?.commissionTarget.publisherTargets.find((pub) => pub.publisherId === mem.publisher.id);
        if (targetPub) registeredIdsArray.push(targetPub.publisherId);
        return ({
          id: mem.publisher.id,
          idNum: Number(mem.publisher.id),
          name: mem.publisher.companyName,
          startDate: (targetPub && targetPub.startDate) ? new Date(targetPub.startDate) : null,
          endDate: (targetPub && targetPub.endDate) ? new Date(targetPub.endDate) : null,
          type: 'Publisher',
          selected: false,
          registered: !!targetPub,
          disabled: false,
          publishersInGroup: null,
        });
      });
      publisherRows.push(...PublishersList);
    }

    // Disable the Publisher/PublisherGroup Rows that are not registered and have an overlapping Publisher Id
    // with a Publisher/Publisher Group that is already registered.
    const publisherRowsDisableChecked = publisherRows.map((pub) => {
      if (pub.type === 'Publisher') {
        if (!pub.registered && registeredIdsArray.some((regId) => regId === pub.id)) {
          return { ...pub, disabled: true };
        }
      }
      if (pub.type === 'Publisher Group') {
        if (!pub.registered && registeredIdsArray.some((regId) => pub.publishersInGroup?.includes(regId))) {
          return { ...pub, disabled: true };
        }
      }
      return pub;
    });

    // Set the Min and Max Dates allowed on the Calendars
    const newMinDate = toUTCHours(new Date(), 'beginning');
    if (newMinDate > detailsMinDate) {
      setEndDateMin(newMinDate);
      setStartDateMin(newMinDate);
    } else {
      const newDate = new Date(detailsMinDate);
      setEndDateMin(detailsMinDate);
      newDate.setDate(detailsMinDate.getDate() + 1);
      setStartDateMin(newDate);
    }
    setStartDateMax(detailsEndDate);
    setEndDateMax(detailsEndDate);

    const sortFunction = compareValues('idNum', 'asc');
    publisherRowsDisableChecked.sort(sortFunction);
    const displayedPublishers = publisherRowsDisableChecked.filter((row) => row.registered);

    setRegisteredIds(registeredIdsArray);
    setCurrentPage(1);
    setTotalPages(Math.ceil(displayedPublishers.length / TABLE_ROW_SIZE));
    setCompletePublisherList(publisherRowsDisableChecked);
    setPublisherList(publisherRowsDisableChecked);
    setTableData(publisherRowsDisableChecked.filter((row) => row.registered).slice(0, TABLE_ROW_SIZE));
  };

  const handleSetSortByOrderSelected = (newSortOrder: SelectOption) => {
    setsortByOrderSelected(newSortOrder);
    setCurrentPage(1);
    const resortedPublishers = sortPublisherRows(sortByDateSelected.value, newSortOrder.value === 'asc' ? 'asc' : 'desc', completePublisherList, search);
    setPublisherList(resortedPublishers);
    setTotalPages(Math.ceil(resortedPublishers.length / TABLE_ROW_SIZE));
    setTableData(resortedPublishers.slice(0, TABLE_ROW_SIZE));
  };

  const handleSetSortByDateSelected = (newSortColumn: SelectOption) => {
    setsortByDateSelected(newSortColumn);
    setCurrentPage(1);
    const resortedPublishers = sortPublisherRows(newSortColumn.value, sortByOrderSelected.value === 'asc' ? 'asc' : 'desc', completePublisherList, search);
    setPublisherList(resortedPublishers);
    setTotalPages(Math.ceil(resortedPublishers.length / TABLE_ROW_SIZE));
    setTableData(resortedPublishers.slice(0, TABLE_ROW_SIZE));
  };

  const searchHandler = (e: React.ChangeEvent<HTMLInputElement>) => {
    setSearch(e.target.value);
    setCurrentPage(1);
    const resortedPublishers = sortPublisherRows(sortByDateSelected.value, sortByOrderSelected.value === 'asc' ? 'asc' : 'desc', completePublisherList, e.target.value);
    setPublisherList(resortedPublishers);
    setTotalPages(Math.ceil(resortedPublishers.length / TABLE_ROW_SIZE));
    setTableData(resortedPublishers.slice(0, TABLE_ROW_SIZE));
  };

  const clearSearch = () => {
    setSearch('');
    setCurrentPage(1);
    const resortedPublishers = sortPublisherRows(sortByDateSelected.value, sortByOrderSelected.value === 'asc' ? 'asc' : 'desc', completePublisherList, '');
    setPublisherList(resortedPublishers);
    setTotalPages(Math.ceil(resortedPublishers.length / TABLE_ROW_SIZE));
    setTableData(resortedPublishers.slice(0, TABLE_ROW_SIZE));
  };

  const handleSetPage = (pageNum: number) => {
    setCurrentPage(pageNum);
    setTableData(publisherList.slice((pageNum - 1) * TABLE_ROW_SIZE, pageNum * TABLE_ROW_SIZE));
  };

  // Selecting Publisher/PublisherGroup needs to update which rows on the table are disabled
  // Need to prevent selecting publishers who are part of Selected PublisherGroups and vice versa
  const handleChangeCheck = (row: PublisherRow, add: boolean) => {
    if (row.disabled) return; // Disabled rows can't be checked

    // Update list of selected Publishers (check boxes on table)
    if (add) {
      setPublishersSelected([...publishersSelected, row.id]);
    } else {
      setPublishersSelected(publishersSelected.filter((publisher) => publisher !== row.id));
    }

    // Update the list of registeredIds (used for disable status on table)
    let newRegisteredIds: string[] = [];
    if (row.registered || row.startDate) { // Already Registered/startDated Publishers do not change registeredIds status on select
      newRegisteredIds = [...registeredIds];
    } else if (add) {
      if (row.type === 'Publisher') {
        newRegisteredIds = [...registeredIds, row.id];
      }
      if (row.type === 'Publisher Group' && row.publishersInGroup) {
        newRegisteredIds = [...registeredIds, ...row.publishersInGroup];
      }
    } else {
      if (row.type === 'Publisher') {
        newRegisteredIds = registeredIds.filter((publisher) => publisher !== row.id);
      }
      if (row.type === 'Publisher Group' && row.publishersInGroup) {
        newRegisteredIds = registeredIds.filter((publisher) => !row.publishersInGroup?.includes(publisher));
      }
    }
    setRegisteredIds(newRegisteredIds);

    // Create the new PublisherRows with updated registeredIds
    const newPublisherRows = [...completePublisherList];
    const targetIndex = newPublisherRows.findIndex((obj) => obj.id === row.id);
    if (targetIndex === -1) return;
    newPublisherRows[targetIndex].selected = add;

    // Disable the Publisher/PublisherGroup Rows that are not registered or selected
    // and have an overlapping Id with the registeredIds.
    const publisherRowsDisableChecked = newPublisherRows.map((pub) => {
      if (pub.type === 'Publisher') {
        if (!(pub.registered || pub.selected || pub.startDate) && newRegisteredIds.some((regId) => regId === pub.id)) {
          return { ...pub, disabled: true };
        }
      }
      if (pub.type === 'Publisher Group') {
        if (!(pub.registered || pub.selected || pub.startDate) && newRegisteredIds.some((regId) => pub.publishersInGroup?.includes(regId))) {
          return { ...pub, disabled: true };
        }
      }
      return { ...pub, disabled: false };
    });

    setCompletePublisherList(publisherRowsDisableChecked);
    const resortedPublishers = sortPublisherRows(sortByDateSelected.value, sortByOrderSelected.value === 'asc' ? 'asc' : 'desc', publisherRowsDisableChecked, search);
    setPublisherList(resortedPublishers);
    setTableData(resortedPublishers.slice((currentPage - 1) * TABLE_ROW_SIZE, currentPage * TABLE_ROW_SIZE));
  };

  const setStartCalendarHandler = (state: boolean) => {
    setStartCalendarOpen(state);
  };

  const onApplyStartCalendarHandler = (newStartDate: Date) => {
    setStartCalendarOpen(false);
    setStartDate(newStartDate);
    // EndDate can't be earlier then startDate
    setEndDateMin(newStartDate);
  };

  const setEndCalendarHandler = (state: boolean) => {
    setEndCalendarOpen(state);
  };

  const onApplyEndCalendarHandler = (newEndDate: Date) => {
    setEndCalendarOpen(false);
    setEndDate(newEndDate);
    // StartDate can't be after EndDate
    setStartDateMax(newEndDate);
  };

  const updateDateHandler = () => {
    setDateWarning(true);
    // Update the Complete Publisher List Row Values
    const newPublisherRows = completePublisherList.map((row) => {
      // Update Rows that are selected
      if (publishersSelected.includes(row.id)) {
        const newRow = { ...row };
        // Already Registered Rows can't change startDate
        if (row.registered && row.startDate && endDate) {
          if (row.startDate <= endDate) {
            newRow.endDate = endDate;
          }
        }
        // If not Registered Row freely change startDate and endDate
        if (!row.registered) {
          newRow.startDate = startDate || detailsStartDate;
          newRow.endDate = endDate || detailsEndDate || null;
        }
        return newRow;
      }
      return row;
    });

    // Update Publisher Lists values
    setCompletePublisherList(newPublisherRows);
    const resortedPublishers = sortPublisherRows(sortByDateSelected.value, sortByOrderSelected.value === 'asc' ? 'asc' : 'desc', newPublisherRows, search);
    setPublisherList(resortedPublishers);
    setTableData(resortedPublishers.slice((currentPage - 1) * TABLE_ROW_SIZE, currentPage * TABLE_ROW_SIZE));
  };

  const navigateBack = () => {
    if (typeof hookLocation.state === 'object' && hookLocation.state?.goBack) {
      navigate(hookLocation.state?.goBack);
    } else {
      navigate(`${MERCHANT_PREFIX}${path.manageCommissions.href}`);
    }
  };

  // Should be triggered if Commission is 'Custom'
  if (getPublisherInfo) {
    setGetPublisherInfo(false);
    getMerchantsPublisherInfo();
  }

  useEffect(() => {
    if (comId === null) {
      setErrorMessage(TOAST_ERR_MESSAGES_NO_PAGE('Commission not found'));
      return;
    }
    getCommissionHandler();
  }, [window.location.href]);

  return {
    // Global
    hookPageLoading: getCommissionLoading || updateCommissionLoading || getPublisherGroupsLoading || getMembershipsLoading,
    hookLoadingMessage: loadingMessage,
    hookErrorMessage: errorMessage,
    hookNavigateBack: navigateBack,
    // Commission
    hookCommissionData: commissionData,
    hookIsCustom: isCustom,
    hookSaveCommissionLoading: updateCommissionLoading,
    hookCommissionType: commissionType,
    // Buttons
    hookSaveCommission: saveCommissionHandler,

    // Details Tab
    hookDetailsEndDate: detailsEndDate,
    hookDetailsStartDate: detailsStartDate,
    hookDetailsMinDate: detailsMinDate,
    hookDetailsCalendarOpen: detailsCalendarOpen,
    hookSetDetailsCalendarState: setDetailsCalendarHandler,
    hookOnApplyDetailsCalendar: onApplyDetailsCalendarHandler,

    // Publishers/Groups Tab
    // Table
    hookTableData: tableData,
    hookPageTotal: totalPages,
    hookCurrentPage: currentPage,
    hookSetPage: handleSetPage,
    hookHandleChangeCheck: handleChangeCheck,

    // Filters/Dropdowns
    hookSearch: search,
    hookSearchHandler: searchHandler,
    hookSortByOrderSelected: sortByOrderSelected,
    hookSetSortByOrderSelected: handleSetSortByOrderSelected,
    hookSortByDateSelected: sortByDateSelected,
    hookSetSortByDateSelected: handleSetSortByDateSelected,

    // Update Footer Bar
    // Start Calendar
    hookStartDate: startDate,
    hookStartDateMin: startDateMin,
    hookStartDateMax: startDateMax,
    hookStartCalendarOpen: startCalendarOpen,
    hookSetStartCalendarState: setStartCalendarHandler,
    hookOnApplyStartCalendar: onApplyStartCalendarHandler,
    // End Calendar
    hookEndDate: endDate,
    hookEndDateMin: endDateMin,
    hookEndDateMax: endDateMax,
    hookEndCalendarOpen: endCalendarOpen,
    hookSetEndCalendarState: setEndCalendarHandler,
    hookOnApplyEndCalendar: onApplyEndCalendarHandler,
    // Update Button
    hookUpdateDateWarning: updateDateWarning,
    hookUpdateDateHandler: updateDateHandler,

    hookIsReadOnlyList: Permission.readOnlyPermissionsList(permissionsCodeList),

    hookClearSearch: clearSearch,
  };
};
