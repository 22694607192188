import React from 'react';

import { AppWrapper, PageTitle } from '../../../../components';
import { path } from '../../../../utils';
import { RunDate, SummaryReport } from '../Components';
import { HISTORY_REPORT } from './enums';
import { useHistoryReport } from './hooks';
import { useSummaryReport } from '../Components/SummaryReport/hooks';
import { DefaultPropsType } from '../../../../types';

import * as Styled from './styles';

type FintelCheckHistoryReportProps = DefaultPropsType;

const FintelCheckHistoryReport = ({ permissionsCodeList = [] }: FintelCheckHistoryReportProps) => {
  const hook = useHistoryReport();
  const summaryHook = useSummaryReport(hook.hookContextState.date);

  return (
    <AppWrapper
      background
      permissionsCodeList={permissionsCodeList}
    >
      <Styled.HeaderStyled>
        <Styled.HeaderInnerStyled>
          <PageTitle>{path.fintelCheckReport.name}</PageTitle>
          <RunDate
            date={hook.hookRunDate}
          />
        </Styled.HeaderInnerStyled>

        <Styled.ButtonStyled
          theme="secondary"
          onClick={() => hook.hookNavigate(-1)}
        >
          {HISTORY_REPORT.BACK}
        </Styled.ButtonStyled>
      </Styled.HeaderStyled>

      <Styled.ReportWrappe>
        <SummaryReport
          hook={summaryHook}
          isMainReport={false}
        />
      </Styled.ReportWrappe>
    </AppWrapper>
  );
};

export default FintelCheckHistoryReport;
