import styled from 'styled-components';
import { toRem } from '../../../../../utils';

type CellWrapperStyledProps = {
  readonly theme?: string
}

export const CellWrapperStyled = styled.div<CellWrapperStyledProps>`
  height: 100%;
  min-height: ${({ theme }) => (theme === 'checkBox' ? 'auto' : toRem(38))};
  display: flex;
  align-items: ${({ theme }) => (theme === 'checkBox' ? 'center' : 'flex-start')};
  justify-content: ${({ theme }) => (theme === 'checkBox' ? 'center' : 'space-between')};
  flex-direction: ${({ theme }) => (theme === 'checkBox' ? 'row' : 'column')};
`;

export const ErrorColumnWrapper = styled.div`
  display: flex;
  flex-direction: row;
  height: 38px;
`;

export const ErrorSpanWrapper = styled.span`
  display: flex;
  flex-direction: row;
  align-items: center;
`;

export const ContentButtonWrapper = styled.div`
  & button {
    padding: 0;
  }
  display: flex;
`;
