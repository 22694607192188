import React from 'react';
import {
  CellWrapper, CheckboxCell, DetailCell, IdCell, MultiLineCell,
} from './Components';

export const columns = (isReadOnly: boolean): TableColumn[] => [
  {
    dataField: 'check',
    text: '',
    width: '60px',
    formatter: (val, row, tableData, setTableData) => (
      <CheckboxCell
        val={val}
        row={row}
        tableData={tableData}
        setTableData={setTableData}
        isReadOnly={isReadOnly}
      />
    ),
  },
  {
    dataField: 'publisher',
    text: 'ID',
    width: '100px',
    cellOverflow: true,
    formatter: (val) => <IdCell val={val} />,
  },
  {
    dataField: 'company',
    text: 'Company Name',
    width: 'calc(43% - 210px)',
    formatter: (val, row) => (
      <MultiLineCell
        val={val}
        disabled={row.disabled}
      />
    ),
  },
  {
    dataField: 'location',
    text: 'Location/IP',
    width: '15%',
    formatter: (val, row) => (
      <MultiLineCell
        val={val}
        disabled={row.disabled}
      />
    ),
  },
  {
    dataField: 'application',
    text: 'Application Date',
    width: '12%',
    formatter: (val) => <CellWrapper val={val} />,
  },
  {
    dataField: 'memberSince',
    text: 'Member Since',
    width: '12%',
    formatter: (val) => <CellWrapper val={val} />,
  },
  {
    dataField: 'url',
    text: 'URL',
    width: '10%',
    formatter: (val) => <CellWrapper val={val} />,
  },
  {
    dataField: 'status',
    text: 'Status',
    width: '8%',
    formatter: (val) => <CellWrapper val={val} />,
  },
  {
    dataField: 'detail',
    text: '',
    width: '50px',
    formatter: (_, row) => (
      <DetailCell
        publisherId={row.publisher.id}
        membershipId={row.id}
      />
    ),
  },
];
