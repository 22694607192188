import {
  MERCHANT_PREFIX, path, PUBLISHER_PREFIX, securityMap,
} from '../../../utils';

export const admin = [
  {
    key: 'dashboard',
    href: path.dashboard.href,
    label: path.dashboard.name,
    icon: path.dashboard.icon,
  },
  {
    key: securityMap.admin.merchants,
    label: path.merchants.name,
    icon: path.merchants.icon,
    management: {
      href: path.merchantManagement.href,
      label: path.merchantManagement.name,
    },
    subSections: [
      {
        key: securityMap.merchant.merchantDashboard,
        href: MERCHANT_PREFIX + path.dashboard.href,
        label: path.dashboard.name,
      },
      {
        key: securityMap.merchant.merchantAccount,
        label: path.account.name,
        subSections: [
          {
            key: [securityMap.merchant.merchantAccountAccountDetails, securityMap.merchant.merchantAccountUserManagement],
            href: path.merchantManagementDetail.href,
            label: path.accountDetails.name,
          },
          {
            key: securityMap.merchant.merchantAccountProgramSettings,
            href: MERCHANT_PREFIX + path.programSettings.href,
            label: path.programSettings.name,
          },
          {
            key: securityMap.merchant.merchantAccountAccountBalance,
            href: MERCHANT_PREFIX + path.accountBalance.href,
            label: path.accountBalance.name,
          },
          {
            key: securityMap.merchant.merchantAccountProductCatalogue,
            href: MERCHANT_PREFIX + path.productCatalog.href,
            label: path.productCatalog.name,
          },
          {
            key: securityMap.merchant.merchantAccountTrackingTransactionSettings,
            href: MERCHANT_PREFIX + path.trackingSettings.href,
            label: path.trackingSettings.name,
          },
          {
            key: securityMap.merchant.merchantPostbackDetails,
            href: MERCHANT_PREFIX + path.postbackDetails.href,
            label: `Merchant ${path.postbackDetails.name}`,
          },
        ],
      },
      {
        key: securityMap.merchant.merchantCommissions,
        href: MERCHANT_PREFIX + path.manageCommissions.href,
        label: path.manageCommissions.name,
      },
      {
        key: securityMap.merchant.merchantPublishers,
        label: path.publishers.name,
        subSections: [
          {
            key: securityMap.merchant.merchantPublishersMembershipManagement,
            href: MERCHANT_PREFIX + path.membershipManagement.href,
            label: path.membershipManagement.name,
          },
          {
            key: securityMap.merchant.merchantPublishersPublisherInvitations,
            href: MERCHANT_PREFIX + path.publisherInvitations.href,
            label: path.publisherInvitations.name,
          },
          {
            key: securityMap.merchant.merchantPublishersPublisherSearch,
            href: MERCHANT_PREFIX + path.publisherSearch.href,
            label: path.publisherSearch.name,
          },
          {
            key: securityMap.merchant.merchantPublishersPublisherGroup,
            href: MERCHANT_PREFIX + path.publisherGroups.href,
            label: path.publisherGroups.name,
          },
          {
            key: securityMap.merchant.merchantPublishersInternalPublisher,
            href: MERCHANT_PREFIX + path.internalPublisher.href,
            label: path.internalPublisher.name,
          },
        ],
      },
      {
        key: securityMap.merchant.merchantAds,
        label: path.ads.name,
        subSections: [
          {
            key: securityMap.merchant.merchantAdSearch,
            href: MERCHANT_PREFIX + path.adSearch.href,
            label: path.adSearch.name,
          },
          {
            key: securityMap.merchant.merchantAdsCampaignManagement,
            href: MERCHANT_PREFIX + path.campaignManagement.href,
            label: path.campaignManagement.name,
          },
        ],
      },
      {
        key: securityMap.merchant.merchantReports,
        label: path.reports.name,
        subSections: [
          {
            key: securityMap.merchant.merchantReportsPerformanceReport,
            href: MERCHANT_PREFIX + path.performanceReport.href,
            label: path.performanceReport.name,
          },
          {
            key: securityMap.merchant.merchantReportsTransactionMaster,
            href: MERCHANT_PREFIX + path.transactionMaster.href,
            label: path.transactionMaster.name,
          },
          {
            key: securityMap.merchant.merchantReportsPaymentReport,
            href: MERCHANT_PREFIX + path.paymentReport.href,
            label: path.paymentReport.name,
          },
        ],
      },
      {
        key: securityMap.merchant.merchantFintelCheck,
        label: path.fintelChecks.name,
        subSections: [
          {
            key: securityMap.merchant.merchantFintelCheckFintelCheckReport,
            href: MERCHANT_PREFIX + path.fintelCheckReport.href,
            label: path.fintelCheckReport.name,
          },
          {
            key: securityMap.merchant.merchantFintelCheckHistory,
            href: MERCHANT_PREFIX + path.fintelCheckHistory.href,
            label: path.fintelCheckHistory.name,
          },
          {
            key: securityMap.merchant.merchantFintelCheckRuleManager,
            href: MERCHANT_PREFIX + path.ruleManager.href,
            label: path.ruleManager.name,
          },
          {
            key: securityMap.merchant.merchantFintelCheckLinkManager,
            href: MERCHANT_PREFIX + path.linkManager.href,
            label: path.linkManager.name,
          },
          {
            key: securityMap.merchant.merchantFintelCheckNewSiteMonitoring,
            href: MERCHANT_PREFIX + path.newSiteMonitoring.href,
            label: path.newSiteMonitoring.name,
          },
          {
            key: securityMap.merchant.merchantFintelCheckFintelCheckingSettings,
            href: MERCHANT_PREFIX + path.fintelCheckSettings.href,
            label: path.fintelCheckSettings.name,
          },
          {
            key: securityMap.merchant.merchantFintelCheckPublisherList,
            href: MERCHANT_PREFIX + path.fintelCheckCustomPublisherList.href,
            label: path.fintelCheckCustomPublisherList.name,
          },
        ],
      },
      {
        key: securityMap.merchant.merchantTransactions,
        label: path.transactions.name,
        subSections: [
          {
            key: securityMap.merchant.merchantTransactionsPendingTransactions,
            href: MERCHANT_PREFIX + path.pendingTransactions.href,
            label: path.pendingTransactions.name,
          },
          {
            key: securityMap.merchant.merchantTransactionsAddTransactions,
            href: MERCHANT_PREFIX + path.addTransactions.href,
            label: path.addTransactions.name,
          },
        ],
      },
    ],
  },
  {
    key: securityMap.admin.publishers,
    label: path.publishers.name,
    icon: path.publishers.icon,
    management: {
      href: path.publisherSearch.href,
      label: path.publisherSearch.name,
    },
    subSections: [
      {
        key: securityMap.publisher.publisherDashboard,
        href: PUBLISHER_PREFIX + path.dashboard.href,
        label: path.dashboard.name,
      },
      {
        key: securityMap.publisher.publisherAccount,
        label: path.account.name,
        subSections: [
          {
            key: [securityMap.publisher.publisherAccountAccountDetails, securityMap.publisher.publisherAccountUserManagement],
            href: path.publisherSearchDetail.href,
            label: path.accountDetails.name,
          },
          {
            key: securityMap.publisher.publisherAccountTrackingDetails,
            href: PUBLISHER_PREFIX + path.trackingDetails.href,
            label: path.trackingDetails.name,
          },
          {
            key: securityMap.publisher.publisherPostbackDetails,
            href: PUBLISHER_PREFIX + path.postbackDetails.href,
            label: `Publisher ${path.postbackDetails.name}`,
          },
        ],
      },
      {
        key: securityMap.publisher.publisherOverview,
        href: PUBLISHER_PREFIX + path.overview.href,
        label: path.overview.name,
      },
      {
        key: securityMap.publisher.publisherMerchants,
        label: path.merchants.name,
        subSections: [
          {
            key: securityMap.publisher.publisherMerchantsMerchantManagement,
            href: PUBLISHER_PREFIX + path.memberships.href,
            label: path.memberships.name,
          },
          {
            key: securityMap.publisher.publisherMerchantsSearchPrograms,
            href: PUBLISHER_PREFIX + path.searchProgram.href,
            label: path.searchProgram.name,
          },
        ],
      },
      {
        key: securityMap.publisher.publisherCommissions,
        href: PUBLISHER_PREFIX + path.activeComissions.href,
        label: path.activeComissions.name,
      },
      {
        key: securityMap.publisher.publisherAds,
        label: path.ads.name,
        subSections: [
          {
            key: securityMap.publisher.publisherAdsAdSearch,
            href: PUBLISHER_PREFIX + path.adSearch.href,
            label: path.adSearch.name,
          },
          {
            key: securityMap.publisher.publisherAdsProductFeed,
            href: PUBLISHER_PREFIX + path.productFeed.href,
            label: path.productFeed.name,
          },
        ],
      },
      {
        key: securityMap.publisher.publisherReports,
        label: path.reports.name,
        subSections: [
          {
            key: securityMap.publisher.publisherReportsPerformanceReport,
            href: PUBLISHER_PREFIX + path.performanceReport.href,
            label: path.performanceReport.name,
          },
          {
            key: securityMap.publisher.publisherReportsTransactionMaster,
            href: PUBLISHER_PREFIX + path.transactionMaster.href,
            label: path.transactionMaster.name,
          },
          {
            key: securityMap.publisher.publisherReportsPaymentReport,
            href: PUBLISHER_PREFIX + path.paymentReport.href,
            label: path.paymentReport.name,
          },
        ],
      },
      {
        key: securityMap.publisher.publisherFintelMonitor,
        label: path.fintelMonitor.name,
        subSections: [
          {
            key: securityMap.publisher.publisherFintelMonitorLinkManager,
            href: PUBLISHER_PREFIX + path.linkManager.href,
            label: path.linkManager.name,
          },
        ],
      },
    ],
  },
  {
    key: securityMap.admin.ads,
    label: path.ads.name,
    icon: path.ads.icon,
    subSections: [
      {
        key: securityMap.admin.adsSearch,
        href: path.adSearch.href,
        label: path.adSearch.name,
      },
      {
        key: securityMap.admin.adsCampaignManagement,
        href: path.campaignManagement.href,
        label: path.campaignManagement.name,
      },
    ],
  },
  {
    key: securityMap.admin.reports,
    label: path.reports.name,
    icon: path.reports.icon,
    subSections: [
      {
        key: securityMap.admin.reportsPerformanceReport,
        href: path.performanceReport.href,
        label: path.performanceReport.name,
      },
      {
        key: securityMap.admin.reportsTransactionMaster,
        href: path.transactionMaster.href,
        label: path.transactionMaster.name,
      },
      {
        key: securityMap.admin.reportsManageSavedReports,
        href: path.manageSavedReports.href,
        label: path.manageSavedReports.name,
      },
    ],
  },
  {
    key: securityMap.admin.fintelCheck,
    label: path.fintelChecks.name,
    icon: path.fintelChecks.icon,
    subSections: [
      // Hidden Refer to ticket FIN-2492
      // {
      //   key: securityMap.admin.fintelCheckPlans,
      //   href: path.fintelCheckPlans.href,
      //   label: path.fintelCheckPlans.name,
      // },
      {
        key: securityMap.admin.fintelCheckLinkManager,
        href: path.linkManager.href,
        label: path.linkManager.name,
      },
      {
        key: securityMap.admin.fintelCheckNewSiteMonitoring,
        href: path.newSiteMonitoring.href,
        label: path.newSiteMonitoring.name,
      },
    ],
  },
  {
    key: securityMap.admin.transactions,
    label: path.transactions.name,
    icon: path.transactions.icon,
    subSections: [
      {
        key: securityMap.admin.transactionsPendingTransactions,
        href: path.pendingTransactions.href,
        label: path.pendingTransactions.name,
      },
      {
        key: securityMap.admin.transactionsAddTransactions,
        href: path.addTransactions.href,
        label: path.addTransactions.name,
      },
    ],
  },
  {
    key: securityMap.admin.payments,
    label: path.payments.name,
    icon: path.payments.icon,
    subSections: [
      {
        key: securityMap.admin.paymentsGeneratePayments,
        href: path.generatePayment.href,
        label: path.generatePayment.name,
      },
      {
        key: securityMap.admin.paymentsProcessPayments,
        href: path.processPayment.href,
        label: path.processPayment.name,
      },
      {
        key: securityMap.admin.paymentsPaymentReport,
        href: path.paymentReport.href,
        label: path.paymentReport.name,
      },
      {
        key: securityMap.admin.paymentsCurrencyRates,
        href: path.currencyRates.href,
        label: path.currencyRates.name,
      },
      {
        key: securityMap.admin.paymentsManualPublisherBonuses,
        href: path.manualPublisherBonuses.href,
        label: path.manualPublisherBonuses.name,
      },
      {
        key: securityMap.admin.paymentsAccountBalance,
        href: path.merchantAccountBalanceManagement.href,
        label: path.merchantAccountBalanceManagement.name,
      },
    ],
  },
  {
    key: securityMap.admin.settings,
    label: path.settings.name,
    icon: path.settings.icon,
    subSections: [
      {
        key: securityMap.admin.settingsUserManagement,
        href: path.userManagement.href,
        label: path.userManagement.name,
      },
      {
        key: securityMap.admin.settingsPublisherInvites,
        href: path.publisherInvites.href,
        label: path.publisherInvites.name,
      },
      {
        key: securityMap.admin.settingsSecurityPermissions,
        href: path.securityPermissions.href,
        label: path.securityPermissions.name,
      },
    ],
  },
  {
    key: securityMap.admin.messageHub,
    href: path.messageHub.href,
    label: path.messageHub.name,
    icon: path.messageHub.icon,
    link: path.messageHub.link,
  },
];
