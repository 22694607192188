import { useLazyQuery } from '@apollo/client';
import { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { useUserInfo } from '../../../../../hooks';
import { MERCHANT_PREFIX, path, toUTCHours } from '../../../../../utils';
import { commissionsFormatter, CommissionFormatted } from '../../../../../utils/formatCommissions';
import { defaultProductOptions, defaultTypeOption } from '../enums';
import {
  LIST_HISTORY_MERCHANT_COMMISSIONS, ListHistoryMerchantCommissionsInput, ListHistoryMerchantCommissionsOutput,
  GET_MERCHANT_PRODUCTS, GetMerchantProductsInput, GetMerchantProductsOutput,
} from '../graphql/queries';

export const useCommissionHistory = () => {
  const { hookWhoAmI } = useUserInfo();
  const navigate = useNavigate();

  const [pageloading, setPageLoading] = useState(false);

  const [currentPage, setCurrentPage] = useState(1);
  const [totalPages, setTotalPages] = useState(0);

  const [commissionsData, setCommissionsData] = useState<CommissionFormatted[]>([]);

  const [sortColumn, setSortColumn] = useState<TableSortColumn>({ column: 'id', direction: 'asc' });

  const [selectedType, setSelectedType] = useState<SelectOption>(defaultTypeOption);

  const [selectedProduct, setSelectedProduct] = useState<SelectOption>(defaultProductOptions[0]);
  const [productOptions, setProductOptions] = useState<SelectOption[]>([]);

  const [selectedDate, setSelectedDate] = useState<Date>();
  const [calendarIsOpen, setCalendarIsOpen] = useState<boolean>(false);

  const [errorMessage, setErrorMessage] = useState<string>('');
  const [refetchTable, setRefetchTable] = useState<boolean>(false);

  const [getProductOptions, { loading: getProductOptionsLoading }] = useLazyQuery<GetMerchantProductsOutput, GetMerchantProductsInput>(GET_MERCHANT_PRODUCTS);
  const [getCommissionHistory, { loading: getCommissionHistoryLoading }] = useLazyQuery<ListHistoryMerchantCommissionsOutput, ListHistoryMerchantCommissionsInput>(LIST_HISTORY_MERCHANT_COMMISSIONS);

  const resetPage = () => {
    setCurrentPage(1);
    setSelectedDate(undefined);
    setSelectedType(defaultTypeOption);
    setSelectedProduct(defaultProductOptions[0]);
    setCommissionsData([]);
    setTotalPages(0);
  };

  const getOptionsHandler = async () => {
    setPageLoading(true);
    setErrorMessage('');

    const { data } = await getProductOptions({
      variables: {
        programId: hookWhoAmI?.programId || '0',
      },
      fetchPolicy: 'no-cache',
      onError(err) {
        setPageLoading(false);
        setErrorMessage(err.message);
      },
    });

    setPageLoading(false);
    if (data && data.products && data.products.products) {
      setProductOptions([...defaultProductOptions, ...data.products.products.map((item) => ({
        label: item.name,
        value: item.id,
      }))]);
    } else {
      setProductOptions(defaultProductOptions);
    }
  };

  const setCurrentPageHandler = (newPage: number) => {
    setCurrentPage(newPage);
    setRefetchTable(true);
  };

  const getCommissionHistoryHandler = async () => {
    if (!hookWhoAmI.programId) {
      return;
    }
    setPageLoading(true);
    setErrorMessage('');

    const { data } = await getCommissionHistory({
      variables: {
        input: {
          filters: {
            merchantId: hookWhoAmI.companyId?.toString() || '0',
            ...selectedType.value && { commissionType: selectedType.value },
          },
          sortBy: sortColumn.column || 'startDate',
          sortDirection: sortColumn.direction || 'asc',
          limit: 8,
          currentPage,
          ...selectedDate && { targetDate: toUTCHours(selectedDate, 'beginning') },
          ...selectedProduct.value && { productId: selectedProduct.value },
        },
      },
      fetchPolicy: 'no-cache',
      onError(err) {
        setPageLoading(false);
        setCommissionsData([]);
        setCurrentPage(1);
        setTotalPages(0);
        setErrorMessage(err.message);
      },
    });
    setPageLoading(false);
    if (data && data.getCommissionsNew) {
      setTotalPages(data.getCommissionsNew.totalPages || 0);

      setPageLoading(false);
      if (data && data.getCommissionsNew) {
        setTotalPages(data.getCommissionsNew.totalPages || 0);
        const list = data.getCommissionsNew.commissions;

        const formattedList = commissionsFormatter(list, false);
        setCommissionsData(formattedList);
      }
    } else {
      setCommissionsData([]);
      setTotalPages(0);
      setCurrentPage(1);
    }
  };

  const setSelectedTypeHandler = (type: SelectOption) => {
    setSelectedType(type);
    setCurrentPage(1);
  };

  const setSelectedProductHandler = (product: SelectOption) => {
    setSelectedProduct(product);
    setCurrentPage(1);
  };

  const setCalendarIsOpenHandler = () => {
    setCalendarIsOpen(!calendarIsOpen);
  };

  const onApplySelectedCalendarHandler = (start: Date) => {
    setSelectedDate(start);
    setCurrentPage(1);
    setCalendarIsOpen(false);
  };

  const onCancelSelectedCalendarHandler = () => {
    setCalendarIsOpen(false);
  };

  const setSortByHandler = (dataField: string, direction: any) => {
    if (sortColumn.direction === null) {
      setSortColumn({ column: dataField, direction });
    } else {
      setSortColumn({ column: dataField, direction: sortColumn.direction === 'asc' ? 'desc' : 'asc' });
    }
    setRefetchTable(true);
  };

  const onRowClick = (rowData: CommissionFormatted) => {
    navigate(`${MERCHANT_PREFIX}${path.editCommissions.href}`, {
      state: rowData.id,
    });
  };

  const clearDateHandler = () => {
    setSelectedDate(undefined);
  };

  const navigateHandler = () => {
    navigate(`${MERCHANT_PREFIX}${path.manageCommissions.href}`);
  };

  if (refetchTable) {
    setRefetchTable(false);
    getCommissionHistoryHandler();
  }

  useEffect(() => {
    resetPage();
    getCommissionHistoryHandler();
    getOptionsHandler();
  }, [window.location.href]);

  useEffect(() => {
    getCommissionHistoryHandler();
  }, [selectedType, selectedProduct, selectedDate]);

  return {
    hookIsLoading: getCommissionHistoryLoading || pageloading || getProductOptionsLoading,
    hookErrorMessage: errorMessage,
    hookNavigate: navigateHandler,

    hookSelectedType: selectedType,
    hookSetSelectedType: setSelectedTypeHandler,

    hookSelectedProduct: selectedProduct,
    hookProductOptions: productOptions,
    hookSetSelectedProductHandler: setSelectedProductHandler,

    hookSelectedDate: selectedDate,
    hookCalendarIsOpen: calendarIsOpen,
    hookOpenCalendar: setCalendarIsOpenHandler,
    hookOnApplySelectedCalendar: onApplySelectedCalendarHandler,
    hookOnCancelSelectedCalendar: onCancelSelectedCalendarHandler,

    hookClearDate: clearDateHandler,

    hookCommissionsData: commissionsData,

    hookHandleSort: setSortByHandler,
    hookSortColumn: sortColumn,
    hookOnRowClick: onRowClick,

    hookCurrentPage: currentPage,
    hookTotalPages: totalPages,
    hookSetCurrentPage: setCurrentPageHandler,
  };
};
